<template>
  <div class="flat-type">
    <div class="flat-type__label">{{ $cte('property.propertyTypeLabel') }} <span class="required">*</span></div>

    <div class="flat-type__boxes">
      <div v-for="item in propertyTypes"
           class="flat-type__box"
           :class="{'active': isActive(item.id)}"
           @click="select(item.id)"
           :key="item.id">
        <img class="flat-type__img" :src="item.img" :alt="$cte(item.label)">
        <div class="flat-type__title">{{ $cte(item.title) }}</div>
      </div>
    </div>
  </div>
</template>

<script>

const propertyTypes = [
  {
    id: 100000,
    img: require('../../assets/img/stepper/apartment.svg'),
    title: 'property.apartment',
    alt: 'property.apartment'
  },
  // {id: 100001, img: require('../../assets/img/stepper/bedroom.svg'), title: 'property.room', alt: 'property.room'},
  // {id: 300002, img: require('../../assets/img/stepper/house.svg'), title: 'House', alt: 'House'},
]

export default {
  name: "RentPropertyTypeSelect",
  props: {
    value: {
      type: Number
    }
  },
  data() {
    return {
      propertyTypes: propertyTypes
    }
  },
  methods: {
    select(id) {
      this.$emit('input', id);
    },
    isActive(id) {
      return this.value === id;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        if (!val) {
          this.value = this.propertyTypes[0].id;
          this.$emit('input', this.value);
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
