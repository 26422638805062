import SearchService from '../../services/search.service';

const initialState = {
    searchResult: [],
    searchValues: {
        countryId: "",
        cityId: "",
        periodId: "",
        priceMin: "",
        priceMax: "",
        sizeMin: "",
        sizeMax: "",
        filter: "",
        page: "",
        itemPerPage: "",
        sortBy: "",
        sortDesc: "",
        withDeleted: "",
    }
}

  export const search = {
    namespaced: true,
    state: initialState,

    actions: {
        saveSearch({ commit }, values) {
          return SearchService.search(values).then(
            datas => {
              commit('setState', {
                  result: datas,
                  searchValues: values
              });
              return Promise.resolve(datas);
            },
            error => {
              commit('setStateFailure');
              return Promise.reject(error);
            }
          );
        }
      },

      mutations: {
        setState(state, payload) {
          state.searchResult = payload.result;
          state.searchValues = payload.searchValues;
        },
        setStateFailure(state) {
            state.searchValues = {};
        }
      }
  };