<template>
  <div class="messages">
    <h4 class="messages__title">{{ $cte("profile.messages.inbox") }}</h4>
    <div class="messages__body">

      <EmptyPartnerList v-if="emptyPartnerList"/>

      <div :class="(getPartner && getPartner.id === partner.id) ? 'messages__box opened' : 'messages__box closed'"
           v-for="partner in getPartners" :key="partner.id"
           @click="selectPartner(partner)">
<!--        <div class="box-head">-->
          <!--          TODO today yesterday, ... -->
<!--          <span class="date">{{ getLatestMessageDate }}</span>-->
<!--          <span class="time">{{ getLatestMessageDate }}</span>-->
<!--        </div>-->
        <div class="box-person">
          <div class="user-avatar mr-2"
               :style="getChatAvatar(partner)"></div>
          <div class="d-flex">
            <div class="box-name">{{ partner.name | truncate(15) }}</div>
            <span class="badge badge--bg2 badge-pill ml-3" v-show="partner.unseenMessages > 0">
              {{ partner.unseenMessages }}
            </span>
          </div>
        </div>
<!--        TODO-->
<!--        <div class="box-short-msg">{{ getLatestMessage }}</div>-->
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AppStorage from "../../helper/AppStorage";
import EmptyPartnerList from '../chat/EmptyPartnerList';
import getChatAvatar from "@/mixins/GetChatAvatar";

export default {
  name: "Inbox",
  mixins: [
    getChatAvatar
  ],
  components: {
    EmptyPartnerList
  },
  data() {
    return {
      // messagesDemo: [
      //     {
      //         id: 1,
      //         avatar: "message-avatar.png",
      //         name: "Connor McTominay",
      //         sentDate: "Today",
      //         sentTime: "9:02 PM",
      //         latestMessage: "How does it work?",
      //         status: "opened"
      //     },
      //     {
      //         id: 2,
      //         avatar: "message-avatar.png",
      //         name: "Connor McTominay",
      //         sentDate: "Today",
      //         sentTime: "9:02 PM",
      //         latestMessage: "How does it work?",
      //         status: "closed"
      //     },
      //     {
      //         id: 3,
      //         avatar: "message-avatar.png",
      //         name: "Connor McTominay",
      //         sentDate: "Today",
      //         sentTime: "9:02 PM",
      //         latestMessage: "How does it work?",
      //         status: "closed"
      //     },
      // ]
    }
  },
  methods: {
    selectPartner(partner) {
      this.$store.dispatch('chat/selectPartner', partner);
    }
  },
  created() {
    this.$store.commit("chat/SET_IS_STANDALONE_CHAT", false);
    this.$store.dispatch('chat/getPartners');
    this.user = AppStorage.getItem('user');
  },
  computed: {
    ...mapGetters('chat', [
      'getPartners',
      'getPartner',
      // 'getLatestMessage',
      // 'getLatestMessageDate'
    ]),
    emptyPartnerList() {
      return this.getPartners.length === 0;
    }
  }
}
</script>

<style>

.profile #messages {
  border-top-right-radius: var(--rentible-border-radius);
  border-top-left-radius: var(--rentible-border-radius);
}

.profile .messages .messages__title {
  padding: 40px 35px 30px 35px;
}

.profile .messages__box {
  cursor: pointer;
}

.profile .chat-box {
  min-height: 400px;
  padding: 0;
}

.user-avatar {
  border: 1px solid #b9b9b9;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  margin-right: 5px;
}

</style>
