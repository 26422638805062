<template>
  <div>
    <label :for="key">{{ label }} <span v-if="rules && rules.required" class="required">*</span></label>
    <ValidationProvider
      :name="label"
      :rules="rules"
      v-slot="{ errors }">

      <div class="select-wrapper">
        <select class="form-control" :name="label" :id="key" v-model="selected" @change="change">
          <option :value="null">{{ $cte('form.selectDefault') }}</option>
          <option v-for="(item, idx) in optionList" :value="item.value" :key="`${idx} - ${item.toString()}`">
            {{ $cte(item.text) }}
          </option>
        </select>
        <div class="icon icon-arrow">
          <svg xmlns="http://www.w3.org/2000/svg" width="14.477" height="8.834" viewBox="0 0 14.477 8.834">
            <g id="down-arrow" transform="translate(0)">
              <g id="Group_6" data-name="Group 6" transform="translate(0)">
                <path id="Path_1412" data-name="Path 1412"
                      d="M13.98,98.49A1.7,1.7,0,0,0,12.78,98H1.7a1.7,1.7,0,0,0-1.2.491,1.666,1.666,0,0,0,0,2.372l5.542,5.48a1.713,1.713,0,0,0,2.4,0l3.64-3.615a.555.555,0,0,0,0-.791.57.57,0,0,0-.8,0l-3.639,3.614a.571.571,0,0,1-.8,0L1.3,100.072a.555.555,0,0,1,0-.791.565.565,0,0,1,.4-.164H12.78a.565.565,0,0,1,.4.164.555.555,0,0,1,0,.791.555.555,0,0,0,0,.791.57.57,0,0,0,.8,0A1.666,1.666,0,0,0,13.98,98.49Z"
                      transform="translate(0.001 -97.999)"/>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <small class="form-text text-error">
        {{ errors[0] }}
      </small>
    </ValidationProvider>
  </div>
</template>

<script>
import {
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full.esm";
import CodeStoreService from "../../services/codeStore.service";

export default {
  name: "RentCodeStoreSelect",
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: Object,
    },
    codeStoreId: {
      type: Number,
      default: null
    },
    label: {
      type: String,
      required: true
    },
    key: {
      type: String,
      required: true
    },
    value: {
      type: [Object, String, Number]
    }
  },
  data() {
    return {
      optionList: [],
      selected: null
    }
  },
  created() {
    if (this.codeStoreId) {
      CodeStoreService.findById(this.codeStoreId).then(res => {
        return this.optionList = res.data.map(item => {
          return {text: item.text, value: item.id}
        });
      })
    }
  },
  methods: {
    change() {
      this.$emit('input', this.selected);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.selected = val;
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
