<template>
  <div>

    <slot></slot>

    <div id="messages">
      <div id="messages-content">
        <div class="message-container" ref="message-container">
          <div v-for="(item, index) in messages" :key="index"
               class="message-wrapper"
               :class="{ 'my-message': isMyMessage(item), 'partner-message' : !isMyMessage(item)}">

            <div :class="'message' + item.id" class="message-details collapse">
              <div class="message-time">{{ formatDateTime(item.timestamp) }}</div>
            </div>

            <div class="chat-message-body" data-toggle="collapse"
                 :data-target="'.message' + item.id"
                 aria-expanded="false" :aria-controls="'message' + item.id">
              {{ item.content }}
            </div>

            <div :class="'message' + item.id" class="message-details collapse"
                 v-if="isMyMessage(item)">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "ChatMessages",
  props: {
    messages: {
      type: Array,
      default: () => []
    },

    // TODO store
    user: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    // this.$store.dispatch('chat/getMessages', this.user);
  },
  methods: {
    isMyMessage(message) {
      return message.senderId === this.user.id;
    },

    formatDateTime(date) {
      return new Date(date).toLocaleString('es-CL')
    },

    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs['message-container'].scrollIntoView(false);
      });
    },

  },
  computed: {
    ...mapGetters('chat', ['getPartner'])
  },
  watch: {
    messages: {
      deep: true,
      immediate: true,
      handler: 'scrollToBottom'
    }
  }
}
</script>

<style scoped>

#messages {
  background-color: #fff;
  padding: 0 1em;
  overflow: auto;
  height: 300px;
  border-left: 1px solid #F0F3EF;
  border-right: 1px solid #F0F3EF;
}

.message-wrapper {
  color: black;
  margin: 5px 10px 0 10px;
  overflow-wrap: break-word;
  font-size: 14px;
}

.message-wrapper.partner-message {
  text-align: left;
}

.message-wrapper.my-message {
  text-align: right;
}

.message-wrapper .chat-message-body {
  display: inline-block;
  padding: 6px 7px 6px 7px;
  cursor: pointer;
  border-radius: var(--rentible-border-radius-sm);
  word-break: break-word;
}

.message-wrapper.partner-message .chat-message-body {
  background-color: #f8f2ef;
}

.message-wrapper.my-message .chat-message-body {
  background-color: var(--rentible-main);
  color: #fff;
}

</style>
