import axios from "axios";

const API_URL = process.env.VUE_APP_BACKEND_URL + '/subscribe';

export default class SubscriptionService {

  static subscribe(data) {
    const url = `${API_URL}`;
    return axios.post(url, data);
  }
}
