<template>
  <div class="py-5 text-center text-muted">
    {{ $cte('chat.noMessagesYet') }}
  </div>
</template>

<script>
export default {
  name: "EmptyPartnerList"
}
</script>

<style scoped>

</style>
