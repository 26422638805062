<template>
  <div class="lease">
    <div class="container">
      <div class="row align-items-stretch">
        <div class="col-12 col-md-3 col-lg-3 col-xl-4 main-column-left">
          <div class="circle-graphics"></div>
          <div class="rentible-logo">
            <a href="#"
            ><img src="../assets/img/auth/rentible-logo.png" alt="Rentible.io"
            /></a>
          </div>
        </div>
        <!-- ./ main-column-left -->
        <div class="col-12 col-md-9 col-lg-9 col-xl-8 main-column-right">
          <div class="content-box">
            <h2 class="content-box__title">Start your lease agreement here</h2>
            <p class="content-box__text">
              Please fill all the fields below to initiate your online lease
              agreement
            </p>

            <div class="lease--step01">
              <ValidationObserver ref="validationObserver" v-slot="{}">
              <form class="form--lease" @submit.prevent="onSubmit">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <RentInput
                          id="fullname"
                          :label="$cte('leaseAgreement.fullName')"
                          :rules="{required: true}"
                          v-model="model.renteeFullName"
                          :placeholder="$cte('leaseAgreement.fullNamePl')"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <RentDatePicker
                        id="birthDate"
                        :label="$cte('leaseAgreement.renteeDateOfBirth')"
                        v-model="model.renteeDateOfBirth"
                    />
<!--                    <div class="form-group">-->
<!--                      <label for="íbirth">Date of birth</label>-->
<!--                      <div class="select-wrapper">-->
<!--                        <input-->
<!--                            class="form-control"-->
<!--                            v-model="model.renteeDateOfBirth"-->
<!--                            type="date"-->
<!--                            id="birthDate"-->
<!--                            name="birth"-->
<!--                            placeholder="Your Birthday"-->
<!--                        />-->
<!--                        <div class="icon icon-calendar">-->
<!--                          <svg-->
<!--                              id="calendar"-->
<!--                              xmlns="http://www.w3.org/2000/svg"-->
<!--                              width="19.149"-->
<!--                              height="19.149"-->
<!--                              viewBox="0 0 19.149 19.149"-->
<!--                          >-->
<!--                            <g id="Group_5188" data-name="Group 5188">-->
<!--                              <g id="Group_5187" data-name="Group 5187">-->
<!--                                <path-->
<!--                                    id="Path_6907"-->
<!--                                    data-name="Path 6907"-->
<!--                                    d="M279,280.254a1.5,1.5,0,0,0,1.5,1.5h1.758a1.5,1.5,0,0,0,1.5-1.5V278.5a1.5,1.5,0,0,0-1.5-1.5H280.5a1.5,1.5,0,0,0-1.5,1.5Zm1.5-1.758h1.758v1.758H280.5Z"-->
<!--                                    transform="translate(-268.565 -266.64)"-->
<!--                                />-->
<!--                                <circle-->
<!--                                    id="Ellipse_194"-->
<!--                                    data-name="Ellipse 194"-->
<!--                                    cx="1"-->
<!--                                    cy="1"-->
<!--                                    r="1"-->
<!--                                    transform="translate(13.149 7)"-->
<!--                                />-->
<!--                                <circle-->
<!--                                    id="Ellipse_195"-->
<!--                                    data-name="Ellipse 195"-->
<!--                                    cx="1"-->
<!--                                    cy="1"-->
<!--                                    r="1"-->
<!--                                    transform="translate(10.149 7)"-->
<!--                                />-->
<!--                                <path-->
<!--                                    id="Path_6908"-->
<!--                                    data-name="Path 6908"-->
<!--                                    d="M18.4,13.165a.748.748,0,0,0,.748-.748V4.488A3,3,0,0,0,16.157,1.5h-.972V.748a.748.748,0,1,0-1.5,0V1.5h-3.4V.748a.748.748,0,1,0-1.5,0V1.5H5.423V.748a.748.748,0,0,0-1.5,0V1.5H2.992A3,3,0,0,0,0,4.488V16.157a3,3,0,0,0,2.992,2.992H16.157a3,3,0,0,0,2.992-2.992.748.748,0,0,0-1.5,0,1.5,1.5,0,0,1-1.5,1.5H2.992a1.5,1.5,0,0,1-1.5-1.5V4.488a1.5,1.5,0,0,1,1.5-1.5h.935V3.74a.748.748,0,1,0,1.5,0V2.992H8.789V3.74a.748.748,0,1,0,1.5,0V2.992h3.4V3.74a.748.748,0,1,0,1.5,0V2.992h.972a1.5,1.5,0,0,1,1.5,1.5v7.929A.748.748,0,0,0,18.4,13.165Z"-->
<!--                                />-->
<!--                                <circle-->
<!--                                    id="Ellipse_196"-->
<!--                                    data-name="Ellipse 196"-->
<!--                                    cx="0.5"-->
<!--                                    cy="0.5"-->
<!--                                    r="0.5"-->
<!--                                    transform="translate(4.149 14)"-->
<!--                                />-->
<!--                                <ellipse-->
<!--                                    id="Ellipse_197"-->
<!--                                    data-name="Ellipse 197"-->
<!--                                    cx="0.5"-->
<!--                                    cy="1"-->
<!--                                    rx="0.5"-->
<!--                                    ry="1"-->
<!--                                    transform="translate(4.149 7)"-->
<!--                                />-->
<!--                                <ellipse-->
<!--                                    id="Ellipse_198"-->
<!--                                    data-name="Ellipse 198"-->
<!--                                    cx="0.5"-->
<!--                                    cy="1"-->
<!--                                    rx="0.5"-->
<!--                                    ry="1"-->
<!--                                    transform="translate(4.149 10)"-->
<!--                                />-->
<!--                                <circle-->
<!--                                    id="Ellipse_199"-->
<!--                                    data-name="Ellipse 199"-->
<!--                                    cx="1"-->
<!--                                    cy="1"-->
<!--                                    r="1"-->
<!--                                    transform="translate(7.149 10)"-->
<!--                                />-->
<!--                                <circle-->
<!--                                    id="Ellipse_200"-->
<!--                                    data-name="Ellipse 200"-->
<!--                                    cx="1"-->
<!--                                    cy="1"-->
<!--                                    r="1"-->
<!--                                    transform="translate(7.149 7)"-->
<!--                                />-->
<!--                                <ellipse-->
<!--                                    id="Ellipse_201"-->
<!--                                    data-name="Ellipse 201"-->
<!--                                    cx="1"-->
<!--                                    cy="0.5"-->
<!--                                    rx="1"-->
<!--                                    ry="0.5"-->
<!--                                    transform="translate(7.149 14)"-->
<!--                                />-->
<!--                              </g>-->
<!--                            </g>-->
<!--                          </svg>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <RentInput
                          id="idNumber"
                          :label="$cte('leaseAgreement.idPassportNumber')"
                          :rules="{required: true}"
                          v-model="model.renteeIdPassPortNumber"
                          :placeholder="$cte('leaseAgreement.idPassportNumberPl')"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <RentInput
                          id="nationality"
                          :label="$cte('leaseAgreement.nationality')"
                          :rules="{required: true}"
                          v-model="model.renteeNationality"
                          :placeholder="$cte('leaseAgreement.nationalityPl')"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <RentCodeStoreSelect v-model="model.renteeMaritalId"
                                           key="marital"
                                           :rules="{required: true}"
                                           :label="$cte('leaseAgreement.maritalStatus')"
                                           :codeStoreId="12"/>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <RentInput
                          id="prof"
                          :label="$cte('leaseAgreement.renteeProfession')"
                          :rules="{required: true}"
                          v-model="model.renteeProfession"
                          :placeholder="$cte('leaseAgreement.renteeProfessionPl')"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group">
                      <RentInput
                          id="address"
                          :label="$cte('leaseAgreement.renteeHomeAddress')"
                          :rules="{required: true}"
                          v-model="model.renteeHomeAddress"
                          :placeholder="$cte('leaseAgreement.renteeHomeAddressPl')"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <RentInput
                          id="mobile"
                          :label="$cte('leaseAgreement.renteeMobile')"
                          :rules="{required: true}"
                          v-model="model.renteeMobile"
                          :placeholder="$cte('leaseAgreement.renteeMobilePl')"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <RentInput
                          id="email"
                          :label="$cte('leaseAgreement.email')"
                          :rules="{required: true, email: true}"
                          v-model="model.renteeEmail"
                          :placeholder="$cte('leaseAgreement.emailPl')"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <RentDatePicker
                        id="moveInDate"
                        :label="$cte('leaseAgreement.movein_date')"
                        v-model="model.validFrom"
                    />
                  </div>
                  <div class="col-md-4">
                    <RentDatePicker
                        id="moveOutDate"
                        :label="$cte('leaseAgreement.moveout_date')"
                        v-model="model.validTo"
                    />
                  </div>
                </div>
              </form>
              </ValidationObserver>
            </div>

            <div class="action-buttons">
              <a href="#" class="btn-outline btn-lg" @click.prevent="goBack">{{ $cte('common.Back') }}</a>

              <b-tooltip :label="$cte('leaseAgreement.acceptContractTooltip')">
                <button
                    href="#"
                    class="btn-primary btn-lg"
                    :disabled="hasMissingParams"
                    @click="onSubmit"
                >{{ $cte('leaseAgreement.acceptContract') }}</button
                >
              </b-tooltip>
            </div>
          </div>
        </div>
        <!-- ./ main-column-right -->
      </div>
    </div>
  </div>
</template>

<script>
import Util from "../helper/Utils";
import RentCodeStoreSelect from "@/components/forms/RentCodeStoreSelect";
import RentDatePicker from '@/components/forms/RentDatePicker';
import RentInput from "../components/forms/RentInput";
import ContractService from "../services/contract.service";
import { ValidationObserver } from "vee-validate";
import {mapGetters} from "vuex";
export default {
  name: "LeaseAgreement",
  components: {
    ValidationObserver,
    RentInput,
    RentDatePicker,
    RentCodeStoreSelect
  },
  data() {
    return {
      model: {
        validFrom: null,
        validTo: null,
        roomAdId : null,
        renteeFullName: null,
        renteeDateOfBirth: '',
        renteeIdPassPortNumber: null,
        renteeNationality: null,
        renteeMaritalId: null,
        renteeProfession: null,
        renteeHomeAddress: null,
        renteeMobile: null,
        renteeEmail: null,
      }
    }
  },
  methods: {
    onSubmit() {
      this.$refs['validationObserver'].validate().then(valid => {
        if (!valid) return;

        /**
         * TODO Caution! If the dates are not an ISO date format,
         * The server doesnt save anything,
         * but the response goes back with status code 200
         */
        let payload = this.model;
        payload.dateOfBirth = Util.formatDateISO(payload.dateOfBirth);
        payload.validFrom = Util.formatDateISO(payload.validFrom);
        payload.validTo = Util.formatDateISO(payload.validTo);

        ContractService.save(payload).then(res => {
          console.log(res);
          // TODO redirect to profile tab
          this.$router.push({name: 'Profile', params: { id: this.getUser.id }});
        }).catch(err => console.error('err: ', err))

      })
    },
    goBack() {
      // mixins triggers
      this.$router.go(-1);
    },
    getDateParams() {
      if (this.$route.params.from) {
        this.model.validFrom = this.$route.params.from
      }

      if (this.$route.params.to) {
        this.model.validTo = this.$route.params.to
      }

      if (this.$route.params.roomAdId) {
        this.model.roomAdId = this.$route.params.roomAdId;
      }
    }
  },
  created() {
    this.getDateParams();

    if (this.hasMissingParams) {
      this.$buefy.toast.open({
        duration: 3000,
        message: `Error! Missing data!`,
        position: 'is-bottom',
        type: 'is-danger'
      });

      window.setTimeout(() => {
        this.goBack();
      }, 3000)
    }
  },
  computed: {
    ...mapGetters('auth', [
      'getUser'
    ]),
    hasMissingParams() {
      return !this.model.validFrom || !this.model.validTo || !this.model.roomAdId;
    },
  }
}
</script>

<style>
@import "../assets/css/style.css";

.hero__title {
  margin-bottom: 5px;
}

.lease .container {
  max-width: 100% !important;
}

.b-tooltip.is-primary .tooltip-content {
  background: #888 !important
}
</style>
