<template>
  <div class="page--forget-process">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-12 col-md-12 col-lg-4 login-left">
          <div class="circle-graphics"></div>
          <div class="rentible-logo">
            <router-link to="/"
              ><img
                src="../../assets/img/auth/rentible-logo.png"
                alt="Rentible.io"
            /></router-link>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-8 login-right">
          <PasswordSteps :currentActive="currentStep" />

          <div class="auth-box">
            <h2 class="auth-box__title">{{ $cte("forgotpass.title") }}</h2>
            <p class="auth-box__text">{{ $t("forgotpass.text") }}</p>

            <ValidationObserver v-slot="{ invalid }">
              <form
                class="form--forget-pass pt-3 pt-md-5"
                @submit.prevent="onSubmit"
              >
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <div class="form-group is-validate">
                    <!-- TODO: add .is-validate class when input is valid -->
                    <label class="form-label mb-0" for="l-email">{{
                      $t("form.email")
                    }}</label>
                    <div class="input-wrapper">
                      <input
                        class="form-control"
                        type="text"
                        id="l-email"
                        name="email"
                        :placeholder="$t('form.email_placeholder')"
                        autofocus
                        autocomplete="off"
                        v-model="email"
                      />
                      <i v-show="false" class="icon--success"></i>
                      <!-- Succes icon -->
                    </div>
                    <small class="form-text text-error"
                      ><!-- TODO: show when has error-->
                      {{ errors[0] }}
                    </small>
                  </div>
                </ValidationProvider>

                <div class="form-submit mt-0 pt-4 mb-4">
                  <button
                    class="btn btn-primary btn--fullwidth"
                    :disabled="invalid"
                  >
                    <div v-show="loading" class="loader"></div>
                    <span v-show="!loading"> {{ $t("button.resetPass") }}</span>
                  </button>
                </div>

                <div class="form-action pt-5">
                  <router-link
                    to="/login"
                    class="link link--mute link--hasicon"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.898"
                      height="9.778"
                      viewBox="0 0 17.898 9.778"
                    >
                      <g
                        id="layer1"
                        transform="translate(18.155 302.012) rotate(180)"
                      >
                        <path
                          id="path9413"
                          d="M1.108,297.933H15.38l-2.674,2.674a.815.815,0,0,0,1.152,1.152l2.435-2.439,1.626-1.628a.813.813,0,0,0,0-1.146l-4.061-4.066a.815.815,0,1,0-1.152,1.152l2.68,2.674H1.065a.814.814,0,1,0,.042,1.627Z"
                          transform="translate(0 0)"
                          fill="#707070"
                        />
                      </g>
                    </svg>
                    {{ $t("button.backLogin") }}
                  </router-link>
                </div>
              </form>
            </ValidationObserver>
          </div>

          <div class="auth-footer-links">
            <p>
              <a href="#">{{ $cte("footer.menu.assistance.item04") }}</a>
              {{ $cte("common.and") }}
              <a href="#">{{ $cte("footer.menu.assistance.item07") }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./page--login -->
</template>

<script>
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { ValidationObserver } from "vee-validate";
import PasswordSteps from "../../components/auth/PasswordSteps.vue";

// extend('positive', value => {
//   return value >= 0;
// });

export default {
  name: "ForgetPassword",
  components: {
    ValidationProvider,
    ValidationObserver,
    PasswordSteps,
  },
  data() {
    return {
      currentStep: "step-01",
      loading: false,
      message: "",
      email: "",
    };
  },
  methods: {
    togglePassword() {
      // show password
      let togglePassTrigger = document.querySelectorAll(".js-show-pass");

      togglePassTrigger.forEach(function (item) {
        item.addEventListener("click", function () {
          let prevElement = item.previousElementSibling;

          let wordHide = item.dataset.hide;
          let wordShow = item.dataset.show;

          if (prevElement.getAttribute("type") == "password") {
            prevElement.setAttribute("type", "text");
            item.innerHTML = wordHide;
          } else {
            prevElement.setAttribute("type", "password");
            item.innerHTML = wordShow;
          }
        });
      });
    },
    onSubmit() {
      this.loading = true;

      let payload = {
        email: this.email,
      };

      this.$store.dispatch("auth/forgotPass", payload).then(
        (response) => {
          console.log(response);
          this.$router.push({
            path: "forget-password-check",
            query: { email: payload.email },
          });
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    mounted() {
      this.togglePassword();
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/auth.css";

.loader {
  background: transparent url("../../assets/img/loader/loader.gif") no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
}
</style>
