import axios from "axios";

const API_URL = process.env.VUE_APP_BACKEND_URL + '/codeStore';

export default class CodeStoreService {

  static findById(id) {
    const url = `${API_URL}/getCodeStore/${id}`;
    return axios.get(url);
  }
}
