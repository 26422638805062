<template>
  <div>
    <GmapMap
        :center="getCenter"
        :zoom="getZoom"
        style="width: 100%; height: 300px"
        :options="options"
        ref="gmap"
    >
      <GoogleMapMarker :key="index"
                       :markerClickable="markerClickable"
                       @click.native="openInfoWindow(item)"
                       :item="item"
                       v-for="(item, index) in items">
        <img :src="markerImg" alt="google-maps-marker"/>
      </GoogleMapMarker>
      <GmapInfoWindow
          :options="{maxWidth: 300, pixelOffset: {
            width: 0,
            height: -44
          }}"
          :position="info.position"
          :opened="info.open"
          @closeclick="info.open=false">
        <CardVertical v-if="selected"
                      :item="selected"
                      :key="selected.id"
                      :isCompactCard="showCompactCards"/>
      </GmapInfoWindow>
    </GmapMap>
  </div>
</template>

<script>
import CardVertical from "./card/CardVertical";
import GoogleMapMarker from '@/components/GoogleMapMarker';
import constants from "../config/constants";

export default {
  name: "MapLocation",
  props: {
    items: {
      type: Array,
      default: () => []
    },
    showCompactCards: {
      type: Boolean,
      default: false
    },
    markerClickable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CardVertical,
    GoogleMapMarker,
    //google: gmapApi,
  },
  data() {
    return {
      markerImg: require('@/assets/img/map/custom-marker-success.png'),
      markers: [],
      info: {
        position: {lat: 0, lng: 0},
        open: false,
        template: ''
      },
      selected: null,
      center: {lat: 45.508, lng: -73.587},
      marker: null,
      options: constants.GOOGLE_MAPS_CONFIG
    };
  },
  methods: {
    openInfoWindow(item) {
      if (!this.markerClickable) {
        return;
      }

      const {locationLat, locationLng} = item;
      this.info.position = {"lat": parseFloat(locationLat), "lng": parseFloat(locationLng)}
      this.selected = item;
      this.info.open = true;
    },
    latRad(lat) {
      var sin = Math.sin(lat * Math.PI / 180);
      var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    },
    calculateZoom(lat_a, lng_a, lat_b, lng_b) {
      let latDif = Math.abs(this.latRad(lat_a) - this.latRad(lat_b))
      let lngDif = Math.abs(lng_a - lng_b)

      let latFrac = latDif / Math.PI
      let lngFrac = lngDif / 360

      let lngZoom = Math.log(1/latFrac) / Math.log(2)
      let latZoom = Math.log(1/lngFrac) / Math.log(2)
      
      return Math.min(lngZoom, latZoom)
    },
  },
  computed: {
    getSmallestLatitude() {
      let lat = this.items.map(item => item.locationLat);
      return Math.min(...lat);
    },
    getBiggestLatitude() {
      let lat = this.items.map(item => item.locationLat);
      return Math.max(...lat);
    },
    getSmallestLongitude() {
      let lng = this.items.map(item => item.locationLng);
      return Math.min(...lng);
    },
    getBiggestLongitude() {
      let lng = this.items.map(item => item.locationLng);
      return Math.max(...lng);
    },
    getCenter() {
      if (!this.items || this.items.length === 0) {
        return {lat: 0, lng: 0}
      }
      
      let centerLat = ((this.getBiggestLatitude + this.getSmallestLatitude) / 2);
      let centerLng = ((this.getBiggestLongitude + this.getSmallestLongitude) / 2);
      return {lat: centerLat, lng: centerLng}
      // return {lat: 13.689967, lng: -89.2181911}
    },
    getZoom() {
      let zoom = this.calculateZoom(this.getSmallestLatitude, this.getSmallestLongitude, this.getBiggestLatitude, this.getBiggestLongitude) + 1;
      if (zoom > 14) {
        return 14;
      }
      return zoom;
      // return 12;
    }
  }
};
</script>

<style>
</style>
