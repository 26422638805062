<template>
  <GmapCustomMarker
    @click.native="openInfoWindow(item)"
    :marker="{ lat: item.locationLat, lng: item.locationLng }"
  >
    <div
      :style="[markerClickable ? { cursor: 'pointer' } : { cursor: 'inherit' }]"
    >
      <div class="marker-bubble"></div>
      <div class="marker-circle"></div>
    </div>
    <!-- TODO AmountCurrency Component -->

    <span
      class="price"
      :style="[markerClickable ? { cursor: 'pointer' } : { cursor: 'inherit' }]"
    >
      ${{ item.rentPrice }}
    </span>
  </GmapCustomMarker>
</template>

<script>
import GmapCustomMarker from "vue2-gmap-custom-marker";

export default {
  name: "GoogleMapMarker",
  props: {
    item: {
      type: Object,
      required: true,
    },
    markerClickable: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    GmapCustomMarker,
  },
  methods: {
    openInfoWindow(item) {
      this.$emit("click", item);
    },
  },
};
</script>

<style scoped>
.price {
  cursor: pointer;
  font-family: "BizmoBold", sans-serif;
  font-weight: 700;
  bottom: 23px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}

.marker-bubble {
  position: relative;
  bottom: 20px;
  width: 60px;
  height: 22px;
  background: #ffff;
  box-shadow: 5px 5px 10px #d0d0d0;
  border-radius: 8px;
}

.marker-bubble::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #fff;
  border-bottom: 0;
  margin-top: -5px;
  margin-left: -5px;
  margin-bottom: -5px;
}

.marker-circle {
  position: absolute;
  right: 34%;
  top: 40%;
  background: #308383;
  border: 2px solid #fff;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
</style>
