<template>
  <div class="content-wrapper">
    <div class="cards">
      <div class="cards__header">
        <h2 class="cards__title">Messages</h2>
      </div>
      <div v-if="getPartner">
        <ChatMessages :messages="getMessages" :user="user"/>
        <NewMessageInput :user="user"
                         :inputDisabled="false"
                         @sendMessage="sendMessage" />
      </div>
      <div v-else class="chat-box">
        <div class="w-100 text-center mt-5">
          {{ $t('chat.pleaseSelectPartner') }}
        </div>
      </div>

<!--      <div class="chat-box">-->
<!--        <div class="chat-box__group">-->
<!--          <div class="group-title">Today</div>-->
<!--          <div class="chat-box__message chat-box__message&#45;&#45;other">-->
<!--            <img src="../../assets/img/profile/message-avatar.png" alt="" />-->
<!--            <div class="chat-text">Hello John!</div>-->
<!--          </div>-->
<!--          <div class="chat-box__message chat-box__message&#45;&#45;other">-->
<!--            <img src="../../assets/img/profile/message-avatar.png" alt="" />-->
<!--            <div class="chat-text">-->
<!--              Please let me know how we can assist you, we’re here to guide you-->
<!--              through the process.-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="chat-box__message chat-box__message&#45;&#45;own">-->
<!--            <div class="chat-text">How does it work?</div>-->
<!--            <img src="../../assets/img/profile/message-avatar.png" alt="" />-->
<!--          </div>-->
<!--          <div class="chat-box__message chat-box__message&#45;&#45;other">-->
<!--            <img src="../../assets/img/profile/message-avatar.png" alt="" />-->
<!--            <div class="chat-text">-->
<!--              It’s quite simple, just follow the quick onboarding flow once-->
<!--              you've have signed up.-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="chat-box__group">-->
<!--          <div class="group-title">Yesterday</div>-->
<!--          <div class="chat-box__message chat-box__message&#45;&#45;other">-->
<!--            <img src="../../assets/img/profile/message-avatar.png" alt="" />-->
<!--            <div class="chat-text">-->
<!--              It’s quite simple, just follow the quick onboarding flow once-->
<!--              you've have signed up.-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="chat-box__input-wrapper">-->
<!--          <input-->
<!--            class="chat-box__field"-->
<!--            type="text"-->
<!--            placeholder="Add Your message"-->
<!--            v-model="newMessage"-->
<!--          />-->
<!--          <button class="chat-box__submit" @click="sendMessage">Send</button>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import ChatMessages from "../../components/chat/ChatMessages";
import {mapGetters} from "vuex";
import NewMessageInput from "../../components/chat/NewMessageInput";
import AppStorage from "../../helper/AppStorage";

export default {
  name: "ProfileMessages",
  beforeDestroy () {
    clearInterval(this.unseenMessageCountInterval);
  },
  data() {
      return {
          newMessage: "",
          user: null,
          unseenMessageCountInterval: null,
          partner: null
      }
  },
  components: {
    ChatMessages,
    NewMessageInput
  },
  methods: {
    startPolling() {
      if (!this.unseenMessageCountInterval) {
        this.unseenMessageCountInterval = setInterval(() => {
          this.$store.dispatch('chat/getUnseenMessages');
        }, 10000);
      }
    },
  },
  created() {
    this.user = AppStorage.getItem('user');
    this.$store.dispatch('chat/connect');
    this.startPolling();
  },
  computed: {
    ...mapGetters('chat', ['getMessages', 'getPartners', 'getPartner', 'getUnseenMessagesCount'])
  }
};
</script>

<style>
@import "../../assets/css/style.css";

.profile .page-content {
  padding-top: 0;
}

</style>
