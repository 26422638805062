<template>
  <div>
    <TheHeader />

    <div class="hero">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-8 hero__content">
            <h1 class="hero__title">{{ $cte("home.hero.title") }}</h1>
            <p class="hero__text mb-4">{{ $cte("home.hero.subtitle") }}</p>

            <TheSearch ref="search" />

            <div class="icons-graphics mt-5" aria-hidden="true"></div>
          </div>
          <div class="hero-graphics-holder">
            <img
              class="hero__graphics"
              src="../assets/img/hero-house.png"
              alt="Evolution of Renting: Cheaper, Leaner, Crypto"
            />  
          </div>
        </div>
      </div>
    </div>

    <main class="page-content">
      <section class="block--featured">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <h2 class="block__title block__title--sm mb-1">
                {{ $cte("home.featured.title") }}
              </h2>
              <p class="block__text block__text--sm mb-5">
                {{ $cte("home.featured.subtitle") }}
              </p>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-6 col-lg-3 mb-3"
              v-for="item in featuredItems"
              :key="item.id"
            >
              <CardVertical :item="item.property" :roomAdId="item.id" />
            </div>

            <div class="text-center col-12 mt-5">
              <a href="#" class="btn btn--outline" @click.prevent="loadMore">{{
                $cte("button.seeMore")
              }}</a>
            </div>
          </div>
        </div>
      </section>

      <CtaBox />

      <section class="block--news">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <h2 class="block__title mb-1">{{ $cte("home.news.title") }}</h2>
              <p class="block__text mb-5">{{ $cte("home.news.text") }}</p>
            </div>
          </div>

          <div class="row">
            <CardNews :item="item" v-for="item in news" :key="item.id" />
          </div>
        </div>
      </section>

      <section class="block--testimonials">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-12 col-lg-6">
              <FormRegistration />
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="testimonials-box">
                <div class="testimonials-box__head mb-4">
                  <div class="head__person">
                    <img src="../assets/img/yahoo-logo.png" alt="Yahoo Logo" />
                  </div>
                </div>
                <div class="testimonials-box__body">
                  <p>
                    {{ $cte("home.testimonials.text") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- TODO: For Partners need VUE ???? -->
      <section class="block--partners">
        <div class="container-fluid">
          <div class="partners">
            <div class="row">
              <div
                v-for="partner in partners"
                :key="partner.id"
                class="col-4 col-md-2 grey-logo-wrap"
              >
                <div
                  class="image-holder"
                  :style="{
                    'background-image':
                      'url(' +
                      require(`@/assets/img/partners/${partner.src}`) +
                      ')',
                  }"
                ></div>
                <!-- <img :src="require(`@/assets/img/partners/${partner.src}`)" class="grey-logo" alt="."> -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <!-- ./page-content -->
    <!--    <StandaloneChat v-if="getUser"/>-->
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from "../components/TheHeader.vue";
import TheFooter from "../components/TheFooter.vue";
import TheSearch from "../components/TheSearch.vue";
import CtaBox from "../components/CtaBox.vue";
import CardNews from "../components/CardNews.vue";
import FormRegistration from "../components/forms/FormRegistration.vue";
// import StandaloneChat from "../components/StandaloneChat";
import { mapGetters } from "vuex";
import RoomAdService from "../services/roomAd.service";
import CardVertical from "../components/card/CardVertical";

export default {
  name: "Home",
  components: {
    CardVertical,
    TheHeader,
    TheFooter,
    TheSearch,
    CtaBox,
    FormRegistration,
    // StandaloneChat,
    CardNews,
  },
  data() {
    return {
      news: [
        {
          id: "12",
          image: require("@/assets/img/news-01.png"),
          badge01: "Crypto",
          badge02: "Metaverse",
          title: "home.news.firstNewTitle",
          link: "home.news.firstNewLink",
          text: "home.news.firstNewText",
          date: "home.news.date",
          role: "home.news.role",
        },
        {
          id: "13",
          image: require("@/assets/img/news-02.png"),
          badge01: "Crypto",
          badge02: "Metaverse",
          title: "home.news.secondNewTitle",
          link: "home.news.secondNewLink",
          text: "home.news.secondNewText",
          date: "home.news.date",
          role: "home.news.role",
        },
        {
          id: "14",
          image: require("@/assets/img/news-03.png"),
          badge01: "Crypto",
          badge02: "Metaverse",
          title: "home.news.thirdNewTitle",
          link: "home.news.thirdNewLink",
          text: "home.news.thirdNewText",
          date: "home.news.date",
          role: "home.news.role",
        },
        {
          id: "15",
          image: require("@/assets/img/news-04.png"),
          badge01: "Crypto",
          badge02: "Metaverse",
          title: "home.news.fourthNewTitle",
          link: "home.news.fourthNewLink",
          text: "home.news.fourthNewText",
          date: "home.news.date",
          role: "home.news.role",
        },
      ],
      featuredItems: [],
      partners: [
        { id: "1", src: "yahoo-finance.png" },
        { id: "2", src: "499.png" },
        { id: "3", src: "bancor.png" },
        { id: "4", src: "cision.png" },
        { id: "0", src: "coinpost.png" },
        { id: "5", src: "finanzen.png" },
        { id: "6", src: "hackernoon.png" },
        { id: "7", src: "newsbtc.png" },
        { id: "8", src: "benziga.png" },
        { id: "9", src: "market-watch.png" },
        { id: "10", src: "landlordzone.png" },
        { id: "11", src: "toutiao.png" },
      ],
    };
  },
  created() {
    RoomAdService.moreMatches().then((res) => {
      if (res.data && res.data.rows) {
        this.featuredItems = res.data.rows;
      }
    });
  },
  methods: {
    loadMore() {
      // TODO pagination or redirect???
      // load more items
      this.$refs["search"].doSearch();
      console.log("load new properties");
    },
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },
};
</script>

<style>
@import "../assets/css/style.css";

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}

.image-holder {
  width: 90%;
  background-size: contain;
  margin: auto;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.hero__title {
  margin-bottom: 5px;
}

#navbar {
  align-items: center;
}
</style>
