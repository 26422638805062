<template>
  <div class="menu" >
    <div class="menu__title">{{ $cte("profile.menu.maintitle") }}</div>
    <ul class="menu__list" v-if="menuType == 'Dashboard'">
      <li>
        <a href="#" @click="loadComponent($event, 'Properties')">
         {{ $cte("profile.menu.my_properties") }}</a
        >
      </li>
      <li>
        <a href="#" @click="loadComponent($event, 'Request')"> {{ $cte("profile.menu.request") }} (1)</a>
      </li>
      <li>
        <a href="#" @click="loadComponent($event, 'Payment')"
          >{{ $cte("profile.menu.payment") }}</a
        >
      </li>
      <li>
        <a href="#" @click="loadComponent($event, 'Disputes')">{{ $t("profile.menu.disputes") }} (0)</a>
      </li>
    </ul>
     <ul class="menu__list" v-else>
      <li>
        <a href="#" @click="loadComponent($event, 'Rental')">
         {{ $t("profile.menu.my_rental") }} (2)</a
        >
      </li>
      <li>
        <a href="#" @click="loadComponent($event, 'Payment')"
          >{{ $t("profile.menu.payment") }}</a
        >
      </li>
      <li>
        <a href="#" @click="loadComponent($event, 'Disputes')">{{ $t("profile.menu.disputes") }} (0)</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ProfileMenu",
  props: {
    menuType: {
        type: String,
        default: "Dashboard"
    },
    propertiesNumber: String,
    requestsNumber: String,
  },
  data() {
    return {
      activeItem: "",
      list: [],
    };
  },
  methods: {
    loadComponent(e, type) {
      e.preventDefault();

      this.$emit("loadComponent", type);
    },
  },
};
</script>
