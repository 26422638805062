import axios from "axios";
import * as SockJS from "sockjs-client";
const BASE_URL = process.env.VUE_APP_BACKEND_URL;

export default class ChatService {

  /**
   * Number of unseen messages
   * @returns {Promise<AxiosResponse<any>>}
   */
  static getUnseenMessagesCount() {
    const url = BASE_URL + '/get-unseen-messages-count';
    return axios.get(url);
  }

  /**
   * Get chat partners
   * @returns {Promise<AxiosResponse<any>>}
   */
  static getUsersToChatList() {
    const url = BASE_URL + '/list-to-chat';
    return axios.get(url);
  }

  /**
   * Get chat messages
   * @param senderId
   * @param recipientId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static getMessageArrayByNames({senderId, recipientId}) {
    const url = BASE_URL + '/messages/' + senderId + '/' + recipientId;
    return axios.get(url);
  }

  static createWsConnection() {
    return new SockJS(BASE_URL + '/ws');
  }
}
