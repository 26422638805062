<template>
  <button class="btn-wallet" @click="connect">
    <img src="@/assets/img/fox.png" alt="metamask logo"/>
    {{ $cte("header.wallet-text") }}
    <span :class="{'is-connected' : getConnected}"></span>
  </button>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ConnectWalletButton",
  methods: {
    connect() {
      this.$store.dispatch('crypto/connectWallet');
    }
  },
  computed: {
    ...mapGetters('crypto', ['getConnected'])
  }
}
</script>

<style scoped>

/*TODO a többi css máshonann jön jelenleg*/
.btn-wallet {
  position: relative;
  padding-right: 16px;
}

.is-connected {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #763D16;
  right: 7px;
  top: 7px;
}

</style>
