<template>
  <div class="form-group">
    <label :for="id">{{ label }}  <span v-if="rules && rules.required" class="required">*</span></label>
    <div class="input-wrapper">
      <ValidationProvider
          :name="label"
          :rules="rules"
          v-slot="{ errors }"
      >
        <b-field>
          <b-datepicker
              v-model="inputData"
              :locale="pickerLocale"
              :placeholder="$cte('common.chooseDate')"
              trap-focus>
          </b-datepicker>
        </b-field>
        <small class="form-text text-error">
          {{ errors[0] }}
        </small>
      </ValidationProvider>
      <div class="icon icon-calendar">
        <svg id="calendar" xmlns="http://www.w3.org/2000/svg" width="19.149" height="19.149"
             viewBox="0 0 19.149 19.149">
          <g id="Group_5188" data-name="Group 5188">
            <g id="Group_5187" data-name="Group 5187">
              <path id="Path_6907" data-name="Path 6907"
                    d="M279,280.254a1.5,1.5,0,0,0,1.5,1.5h1.758a1.5,1.5,0,0,0,1.5-1.5V278.5a1.5,1.5,0,0,0-1.5-1.5H280.5a1.5,1.5,0,0,0-1.5,1.5Zm1.5-1.758h1.758v1.758H280.5Z"
                    transform="translate(-268.565 -266.64)"/>
              <circle id="Ellipse_194" data-name="Ellipse 194" cx="1" cy="1" r="1"
                      transform="translate(13.149 7)"/>
              <circle id="Ellipse_195" data-name="Ellipse 195" cx="1" cy="1" r="1"
                      transform="translate(10.149 7)"/>
              <path id="Path_6908" data-name="Path 6908"
                    d="M18.4,13.165a.748.748,0,0,0,.748-.748V4.488A3,3,0,0,0,16.157,1.5h-.972V.748a.748.748,0,1,0-1.5,0V1.5h-3.4V.748a.748.748,0,1,0-1.5,0V1.5H5.423V.748a.748.748,0,0,0-1.5,0V1.5H2.992A3,3,0,0,0,0,4.488V16.157a3,3,0,0,0,2.992,2.992H16.157a3,3,0,0,0,2.992-2.992.748.748,0,0,0-1.5,0,1.5,1.5,0,0,1-1.5,1.5H2.992a1.5,1.5,0,0,1-1.5-1.5V4.488a1.5,1.5,0,0,1,1.5-1.5h.935V3.74a.748.748,0,1,0,1.5,0V2.992H8.789V3.74a.748.748,0,1,0,1.5,0V2.992h3.4V3.74a.748.748,0,1,0,1.5,0V2.992h.972a1.5,1.5,0,0,1,1.5,1.5v7.929A.748.748,0,0,0,18.4,13.165Z"/>
              <circle id="Ellipse_196" data-name="Ellipse 196" cx="0.5" cy="0.5" r="0.5"
                      transform="translate(4.149 14)"/>
              <ellipse id="Ellipse_197" data-name="Ellipse 197" cx="0.5" cy="1" rx="0.5" ry="1"
                       transform="translate(4.149 7)"/>
              <ellipse id="Ellipse_198" data-name="Ellipse 198" cx="0.5" cy="1" rx="0.5" ry="1"
                       transform="translate(4.149 10)"/>
              <circle id="Ellipse_199" data-name="Ellipse 199" cx="1" cy="1" r="1"
                      transform="translate(7.149 10)"/>
              <circle id="Ellipse_200" data-name="Ellipse 200" cx="1" cy="1" r="1"
                      transform="translate(7.149 7)"/>
              <ellipse id="Ellipse_201" data-name="Ellipse 201" cx="1" cy="0.5" rx="1" ry="0.5"
                       transform="translate(7.149 14)"/>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import {ValidationProvider} from "vee-validate/dist/vee-validate.full.esm";

export default {
  name: "RentDatePicker",
  components: {
    ValidationProvider
  },
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    rules: {
      type: Object,
      default: () => {}
    },
    placeholder: {
      type: String
    },
    value: {
      type: String
    }
  },
  data() {
    return {
      inputData: null
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.inputData = val;
      },
      immediate: true
    },
    inputData: function (val) {
      this.$emit('input', val);
    }
  }
}
</script>

<style scoped>

</style>
