<template>
  <div>
  <div :class="{'search-box__label': !activeCity}">{{ !activeCity ? $cte("search.location") : $cte("search.chooseCity") }}:</div>
  
  <b-dropdown aria-role="list" :mobile-modal="false">
    <template #trigger>
      
      <div
          class="search-box__field dropdown-toggle"
      >
        <i v-if="!activeCity" class="icon icon--magnify"></i>
        
        <svg v-else  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512" xml:space="preserve" class="icon custom-search-icon"><path d="M344.5,298c15-23.6,23.8-51.6,23.8-81.7c0-84.1-68.1-152.3-152.1-152.3C132.1,64,64,132.2,64,216.3
                                                                                    c0,84.1,68.1,152.3,152.1,152.3c30.5,0,58.9-9,82.7-24.4l6.9-4.8L414.3,448l33.7-34.3L339.5,305.1L344.5,298z M301.4,131.2
                                                                                    c22.7,22.7,35.2,52.9,35.2,85c0,32.1-12.5,62.3-35.2,85c-22.7,22.7-52.9,35.2-85,35.2c-32.1,0-62.3-12.5-85-35.2
                                                                                    c-22.7-22.7-35.2-52.9-35.2-85c0-32.1,12.5-62.3,35.2-85c22.7-22.7,52.9-35.2,85-35.2C248.5,96,278.7,108.5,301.4,131.2z"></path></svg>
        <input
            class="city-field"
            type="text"
            name="location"
            @input="inputHandler"
            :placeholder="$cte('search.searchLocation')"
            autocomplete="off"
            v-model="query"
        />
      </div>
    </template>

    <div class="search-dropdown search-dropdown--location ">

      <h4 class="search-dropdown__title">{{ $t("search.location") }}</h4>
      <div class="search-dropdown__result" data-simplebar>
        <b-dropdown-item aria-role="listitem">
          <div class="city-option"
               v-for="item in cityResults"
               :key="item.id"
               @click="selectLocation(item)"> {{ item.city }}
          </div>
        </b-dropdown-item>
      </div>
    </div>
  </b-dropdown>
  </div>
</template>

<script>
import CityService from "../../services/city.service";
export default {
  name: "LocationInput",
  props: {
    value: {
      type: Number
    },
    activeCity: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cities: [],
      selected: null,
      query: ''
    }
  },
  mounted() {
    this.getCities();
  },
  methods: {
    getCities() {
      CityService.getCities().then(res => {
        this.cities = res.data;
        this.getSelected(this.value);
      })
    },
    selectLocation(location) {
      this.selected = location;
      this.query = location.city;
      this.$emit('select', this.selected);
    },
    getSelected(cityId) {
      if (!cityId) return;

      let city = this.cities.find(item => item.id == cityId);
      this.selected = city;
      this.query = city.city;
    },
    inputHandler() {
      if (this.query === '') {
        this.$emit('select', null);
      }
    }
  },
  computed: {
    cityResults() {
      if (!this.query) return this.cities;
      return this.cities.filter(item => {
        return this.query
                .toLowerCase()
                .split(' ')
                .every(i => item.city.toLowerCase().includes(i))
      })
    }
  },
  watch: {
    value: {
      handler: 'getSelected'
    }
  }
}
</script>

<style scoped>
.stepper svg.icon.custom-search-icon {
  top: 10px;
  left: 0;
  position: relative;
  padding-top: 0px;
}

a.dropdown-item:hover,
.dropdown .dropdown-menu .has-link a:hover,
button.dropdown-item:hover {
  background-color: #ffff;
  color: #4a4a4a;
}

.city-option:hover {
  color: #de2279;
}
</style>
