import axios from 'axios';

const BASE_URL = process.env.VUE_APP_BACKEND_URL + '/profile';

export default class ProfileService {
  static save(payload) {
    const url = BASE_URL;
    if (payload.id) {
      return axios.put(url, payload);
    } else {
      return axios.post(url, payload);
    }
  }

  static get(id) {
    const url = BASE_URL + '/' + id;
    return axios.get(url);
  }

  static changeAvatar(data) {
    let fd = new FormData();
    fd.append("file", data.file);
    fd.append('userId', data.userId);

    return axios({
      method: "post",
      url:  BASE_URL + '/changeAvatar',
      data: fd,
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  static getAvatar(id) {
    const url = BASE_URL + '/getAvatar/' + id;
    return axios.get(url);
  }
}
