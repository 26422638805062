<template>
  <div class="content-wrapper">
    <div class="cards">
      <div class="cards__header">
        <h2 class="cards__title">{{ $t("profile.rentals.title") }}</h2>
      </div>

      <div class="cards__item--properties" v-for="item in rentals" :key="item.id">
        <MyRental :item="item" @success="successCallback"/>
      </div>

    </div>
  </div>
</template>

<script>
import ContractService from "../../services/contract.service";
import MyRental from "./MyRental";

export default {
  name: "ProfileMyRental",
  data() {
    return {
      rentals: []
    }
  },
  components: {
    MyRental
  },
  methods: {
    successCallback(ev) {
      console.log('Success', ev);
      this.getAllContracts();
    },
    errorCallback(ev) {
      console.error('Error', ev);
    },
    getImage(images) {
      if (images.length > 0) {
        return process.env.VUE_APP_BACKEND_URL + '/' + images[0].filePath;
      }
      return 'https://via.placeholder.com/150x150';
    },
    getAllContracts() {
      // get all payments for this account
      const loadingComponent = this.$buefy.loading.open({
        canCancel: true
      });

      setTimeout(() => {
        loadingComponent.close();
      }, 1500);

      ContractService.getAll().then(response => {
        this.rentals = response.data
            .filter(contract => (contract.contractStatus !== "Pending Request" && contract.contractStatus !== "Request Received"));
      }).catch(error => {
        console.log(error);
      });
    }
  },
  mounted() {
    this.getAllContracts();
  }
};
</script>

<style>
@import "../../assets/css/style.css";

.profile .page-content {
  padding-top: 0;
}
</style>
