export default {
  "en": {
    "hint": "Click or drag the file here to upload",
    "loading": "Uploading…",
    "noSupported": "Browser is not supported, please use IE10+ or other browsers",
    "success": "Upload success",
    "fail": "Upload failed",
    "preview": "Preview",
    "btn": {
      "off": "Cancel",
      "close": "Close",
      "back": "Back",
      "save": "Save"
    },
    "error": {
      "onlyImg": "Image only",
      "outOfSize": "Image exceeds size limit: ",
      "lowestPx": "Image\"s size is too low. Expected at least: "
    }
  },
  "es": {
    "hint": "Haga clic o arrastre el archivo aquí para cargarlo",
    "loading": "Cargando…",
    "noSupported": "El navegador no es compatible, por favor utilice IE10+ u otros navegadores",
    "success": "Carga Exitosa",
    "fail": "Error al Cargar",
    "preview": "Vista previa",
    "btn": {
      "off": "Cancelar",
      "close": "Cerrar",
      "back": "Volver",
      "save": "Guardar"
    },
    "error": {
      "onlyImg": "Solo Imagen",
      "outOfSize": "Imagen excede el tamaño máximo: ",
      "lowestPx": "El tamaño de la imagen es demasiado bajo. Tamaño mínimo es de: "
    }
  }
};
