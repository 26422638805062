<template>
  <div class="page--login">
    <div class="container-fluid">
      <div class="row align-items-start">
        <div class="col-12 col-md-5 col-lg-5 col-xl-6 login-left">
          <div class="circle-graphics"></div>
          <div class="rentible-logo">
            <router-link to="/"
              ><img
                src="../../assets/img/auth/rentible-logo.png"
                alt="Rentible.io"
            /></router-link>
          </div>
        </div>
        <div class="col-12 col-md-7 col-lg-7 col-xl-6 login-right">
          <div class="auth-box">
            <h2 class="auth-box__title">{{ $cte("login.title") }}</h2>
            <p class="auth-box__text">
              {{ $cte("login.text") }}
            </p>

            <ValidationObserver v-slot="{ invalid }">
              <form class="form--login pt-2 pt-lg-5" @submit.prevent="onSubmit">
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <div class="form-group is-validate">
                    <!-- TODO: add .is-validate class when input is valid -->
                    <label class="form-label mb-0" for="l-email">
                      {{ $cte("form.email") }}</label
                    >
                    <div class="input-wrapper">
                      <input
                        class="form-control"
                        type="email"
                        id="l-email"
                        name="email"
                        :placeholder="$cte('form.email_placeholder')"
                        autofocus
                        autocomplete="off"
                        v-model="user.email"
                      />
                      <i v-show="isValidEmail" class="icon--success"></i>
                    </div>
                    <div>
                      <small class="form-text text-error">
                        {{ errors[0] }}
                      </small>
                    </div>
                  </div>
                </ValidationProvider>

                <ValidationProvider
                  name="passowrd"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div class="form-group">
                    <label class="form-label mb-0" for="l-password">{{
                      $cte("form.password")
                    }}</label>
                    <div class="input-wrapper">
                      <input
                        class="form-control"
                        type="password"
                        id="l-password"
                        name="password"
                        :placeholder="$cte('form.password_placeholder')"
                        v-model="user.password"
                      />
                    </div>
                    <div>
                      <small class="form-text text-error">
                        {{ errors[0] }}
                      </small>
                    </div>
                  </div>
                </ValidationProvider>

                <!--                <div class="form-checkbox">-->
                <!--                  <div class="form-check">-->
                <!--                    <input-->
                <!--                      class="form-check-input"-->
                <!--                      type="checkbox"-->
                <!--                      value=""-->
                <!--                      id="remember"-->
                <!--                      v-modal="rememberMe"-->
                <!--                    />-->
                <!--                    <label class="form-check-label" for="remember">-->
                <!--                      Remember me-->
                <!--                    </label>-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="form-submit">
                  <div
                    v-show="loading"
                    class="loadingio-spinner-rolling-3m72f4ve39n"
                  >
                    <div class="ldio-d701fl78k4p"></div>
                  </div>
                  <button
                    class="btn btn-primary btn--fullwidth"
                    :disabled="invalid"
                  >
                    <div v-show="loading" class="loader"></div>
                    <span v-show="!loading"> {{ $cte("login.title") }}</span>
                  </button>
                </div>
                <div class="form-group">
                  <div
                    v-if="message"
                    class="alert alert-danger mt-2"
                    role="alert"
                  >
                    {{ message }}
                  </div>
                </div>
                <div class="form-action pt-3">
                  <p class="mb-0">
                    {{ $cte("form.text1") }}
                    <router-link to="/registration" class="link">{{
                      $cte("form.signup")
                    }}</router-link>
                  </p>
                  <router-link to="forget-password" class="link">
                    {{ $cte("form.text2") }}
                  </router-link>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { ValidationObserver } from "vee-validate";
import User from "../../models/User";

export default {
  name: "Login",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      user: new User("", ""),
      loading: false,
      message: "",
      isValidEmail: false,
      rememberMe: false,
    };
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    setUserToLocalStorage(user) {
      let data = user;
      let profileImage = {filePath: user.profileImagePath};
      user.profileImage = profileImage;
      localStorage.setItem("currentUser", JSON.stringify(data));
    },
    
    onSubmit() {
      this.loading = true;

      const payload = {
        username: this.user.email,
        password: this.user.password,
      };

      this.$store.dispatch("auth/login", payload).then(
        (resp) => {
          let routeQuery = this.$route.query || {};
          const { redirect } = routeQuery;
          if (redirect) {
            this.$router.replace({ path: redirect });
          } else {
            this.$router.replace("/");
          }
          this.setUserToLocalStorage(resp);
        },
        (error) => {
          this.loading = false;
          const { response } = error;
          if (response && response.status === 403) {
            this.message =
              "Login failed. You have entered an invalid username or password!";
          } else {
            this.message = "Login failed. An error occurred!";
          }
        }
      );
    },
  },
};
</script>

<style>
@import "../../assets/css/auth.css";

.loader {
  background: transparent url("../../assets/img/loader/loader.gif") no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background: #000 !important;
  border-color: #000 !important;
}
</style>
