import Util from "../helper/Utils";
import Vue from "vue";

Vue.filter('dateISO', function (date) {
  return Util.formatDateISO(date);
})

Vue.filter('boolean', function(value) {
  return value ? Vue.prototype.$cte('common.Yes') : Vue.prototype.$cte('common.No');
})

Vue.filter('codestore', function(value) {
  return value ? Vue.prototype.$cte(value.text) : '';
})

Vue.filter('truncate', function(value, maxLength) {
  return value ? Util.truncateText(value, maxLength || null) : '';
})
