<template>
  <div class="avatar text-center">
    <div class="avatar__img">
      <div class="avatar__overlay">
<!--        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"-->
<!--             x="0px" y="0px"-->
<!--             width="512px" height="512px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"-->
<!--             xml:space="preserve">-->
<!--            <g>-->
<!--              <polygon points="264,144 264,306 248,306 248,144 96,144 96,448 416,448 416,144 	"/>-->
<!--              <polygon-->
<!--                  points="264,63.4 318.8,118.1 330.4,106.5 256,32 181.5,106.5 193.2,118.1 248,63.4 248,144 264,144 	"/>-->
<!--            </g>-->
<!--            </svg>-->
      </div>

      <img :src="getImage" alt="Avatar"/>
<!--      <button class="avatar__trash" v-if="isEditable" @click="avatarDelete">-->
<!--        <svg-->
<!--            version="1.1"-->
<!--            id="Layer_1"-->
<!--            xmlns="http://www.w3.org/2000/svg"-->
<!--            x="0px"-->
<!--            y="0px"-->
<!--            width="512px"-->
<!--            height="512px"-->
<!--            viewBox="0 0 512 512"-->
<!--            style="enable-background: new 0 0 512 512"-->
<!--            xml:space="preserve"-->
<!--        >-->
<!--          <path-->
<!--              d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5-->
<!--                                       c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9-->
<!--                                       c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"-->
<!--              fill="#fff"-->
<!--          />-->
<!--        </svg>-->
<!--      </button>-->
    </div>
    <div class="avatar__name">{{ person.firstName }} {{ person.lastName }}</div>



      <button v-if="isEditable" class="btn btn-primary" @click="isCropperShow = true">{{ $cte('profile.avatarBox.ChangePicture') }}</button>
      <my-upload field="img"
                 :key="$i18n.locale"
                 noSquare
                 :lang-ext="getLanguageExt"
                 :lang-type="'en'"
                 @crop-success="cropSuccess"
                 v-model="isCropperShow"
                 :width="300"
                 :height="300"
                 img-format="png" />

  </div>
</template>

<script>
import {mapState} from "vuex";
import ProfileService from "../../services/profile.service";
import myUpload from 'vue-image-crop-upload/upload-2.vue';
import locales from '@/lang/image-cropper-locales.js';

export default {
  props: {
    person: Object,
    activeComponent: String,
  },
  components: {
    myUpload
  },
  data() {
    return {
      isCropperShow: false,
      file: '',
      imgDataUrl: '',
      defaultImg: require('../../assets/img/avatar.png'),
    }
  },
  computed: {
    ...mapState("auth", ["user", "status"]),
    getImage() {
      if (!this.person.profileImage.filePath) return this.defaultImg;
      return process.env.VUE_APP_BACKEND_URL + '/' + this.person.profileImage.filePath;
    },
    isEditable() {
      return this.activeComponent === 'ProfileHome'
    },
    getLanguageExt() {
      return locales[this.$i18n.locale] ? locales[this.$i18n.locale] : locales['en'];
    }
  },
  created() {},
  methods: {
    refreshData() {
      this.$parent.getProfile();
    },
    cropSuccess(imgDataUrl) {
      this.imgDataUrl = imgDataUrl;
      this.dataURItoBlob(imgDataUrl);
    },
    dataURItoBlob(dataURI) {
      /**
       * from here:
       * https://stackoverflow.com/a/36183085/3223100
        */
      fetch(dataURI)
          .then(res => res.blob())
          .then(file => this.file = file);
    },
    avatarDelete() {
      this.$buefy.dialog.confirm({
        message: 'Do you want to deleted the image?',
        onConfirm: () => {
          console.log("deleted started");
        }
      });

      // TODO: user id, what is the endpoint
      let userId = this.person.ownerId;
      console.log(userId);
      // TODO: need a placeholder fallback image when user delete the avatar.

    },
    validationFile(file) {
      console.log(file);
    },
    uploadImage() {
      const loadingComponent = this.$buefy.loading.open({
        canCancel: true,
      });

      setTimeout(() => {
        loadingComponent.close();
      }, 5000);

      ProfileService.changeAvatar({ file: this.file, userId: this.user.id }).then(() => {
        loadingComponent.close();
        this.$buefy.toast.open(this.$cte('system.success.avatarChanged')); // use this for messages
        this.file = '';
        this.imgDataUrl = '';
        this.refreshData();
      }).catch(err => {
        loadingComponent.close();
        this.$buefy.toast.open({
          message: this.$cte('system.error.avatarChanged'),
          type: 'is-danger'
        })
        console.error('error while change avatar image', err);
      })
    }
  },
  watch: {
    file(val) {
      if (val) {
        this.uploadImage();
      }
    }
  }
}
</script>


<style>
#avatarFile {
  height: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.dialog.modal {
  z-index: 9000;
}

.avatar__img img {
  border-radius: 50%;
  cursor: pointer;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

/*.avatar__img:hover .avatar__overlay {*/
/*  opacity: 1;*/
/*}*/

.profile .avatar__trash {
  z-index: 200;
}

.avatar__overlay {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.25);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all .25s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar__overlay svg {
  width: 45px;
  height: 45px;
}

.profile .avatar__img {
  width: 168px;
  height: 168px;
}

</style>
