<template>
  <div id="chat-partner" class="d-flex align-items-center">
    <button class="mr-2 btn" @click="backToPartnerList">
      <b-icon icon="arrow-left-thin"></b-icon>
    </button>
    <span class="text-truncate">{{ getPartner.name }}</span>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "BackToPartnerList",
  props: {
    recipient: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    backToPartnerList() {
      this.$emit('backToPartnerList')
    }
  },
  computed: {
    ...mapGetters('chat', ['getPartner']),
  }
}
</script>

<style scoped>

#chat-partner {
  background-color: #fff;
  border: 1px solid #f0f3ef;
  padding: 0.5rem;
  color: var(--rentible-main);
}

</style>
