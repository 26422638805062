<template>
  <div>
    <button @click="showConfirm()" class="item__btn item__btn--lg item__btn--withdraw">
      {{ $cte("crypto.button.sendDeposit") }}
    </button>

    <Modal ref="confirm-modal">
      <div class="custom-modal-center">
        <div class="custom-modal-text">
          <h2>{{ $cte('payDepositModal.title') }}</h2>
          <p v-html="$cte('payDepositModal.paragraph01')"></p>
          <p v-html="$cte('payDepositModal.paragraph02')"></p>
        </div>
        <div class="button-wrapper text-center mt-3">
          <!--          <button class="btn btn-outline" @click="closeModal">{{ $cte('propertyContractPanel.closeButton') }}</button>-->
          <button class="btn btn-primary" @click="sendDeposit">{{ $cte('payDepositModal.okButton') }}</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "../Modal";
import CryptoService from "@/services/crypto.service";
export default {
  name: "SendDepositButton",
  components: {
    Modal
  },
  props: {
    amount: {
      type: Number,
      required: true
    },
    landlordWalletAddress: {
      type: String,
      required: true
    },
    contractId: {
      type: Number,
      required: true
    },
    roomAdId: {
      type: Number,
      required: true
    }
  },
  methods: {
    showConfirm() {
      this.$refs['confirm-modal'].isOpen = true;
    },
    async sendDeposit() {
      this.$refs['confirm-modal'].isOpen = false;
      try {
        let response = await CryptoService
            .payRentDeposit({
              amount: this.amount,
              landlordAddress: this.landlordWalletAddress,
              roomAdId: this.roomAdId,
              contractId: this.contractId
            });
        this.$emit('success', response);
      } catch (error) {
        this.$emit('error', error)
      }
    }
  }
}
</script>

<style scoped>

</style>
