<template>
  <div class="compare">
    <TheHeader />

   <main class="page-content">

                <div class="banner"><!-- TODO: leave empty when hasn't got any content -->
                </div><!-- ./banner -->

                <div class="content-wrapper">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-2 pr-0">
                            <div class="page-intro">
                                <h1>Compare</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis enim orci. Nulla tincidunt, nisl</p>
                                <a class="btn-back" href="#">Back</a>
                            </div>
                            </div>
                            <div class="col-md-10">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="compare-item">
                                            <div class="item-image">
                                                <img class="img-fluid" src="../assets/img/compare/compare-01.png" alt="Fully Furnished Aparment">
                                            </div>
                                            <h2>Fully Furnished Aparment</h2>
                                            <p>Budapest, Krisztina krt. 2</p>
                                            <a href="#" class="btn-primary btn-lg btn-compare">Contact publisher</a>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="compare-item">
                                            <div class="item-image">
                                                <img class="img-fluid" src="../assets/img/compare/compare-02.png" alt="Fully Furnished Aparment">
                                            </div>
                                            <h2>Fully Furnished Aparment</h2>
                                            <p>Budapest, Krisztina krt. 2</p>
                                            <a href="#" class="btn-primary btn-lg btn-compare">Contact publisher</a>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="compare-item">
                                            <div class="item-image">
                                                <img class="img-fluid" src="../assets/img/compare/compare-03.png" alt="Flat with a view to the Danube">
                                            </div>
                                            <h2>Flat with a view to the Danube</h2>
                                            <p>Budapest, Krisztina krt. 2</p>
                                            <a href="#" class="btn-primary btn-lg btn-compare">Contact publisher</a>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="compare-item">
                                            <div class="item-image">
                                                <img class="img-fluid" src="../assets/img/compare/compare-04.png" alt="Apartment for Rent in VIVA Residence">
                                            </div>
                                            <h2>Apartment for Rent in VIVA Residence</h2>
                                            <p>Budapest, Krisztina krt. 2</p>
                                            <a href="#" class="btn-primary btn-lg btn-compare">Contact publisher</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!-- ./ row -->

                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table--striped table--compare">
                                        <tr>
                                            <td>Price</td>
                                            <td>$1000 / month</td>
                                            <td>$1200 / month</td>
                                            <td>$1000 / month</td>
                                            <td>$1200 / month</td>
                                        </tr>
                                        <tr>
                                            <td>Size</td>
                                            <td>60 Sqm</td>
                                            <td>120 Sqm</td>
                                            <td>60 Sqm</td>
                                            <td>120 Sqm</td>
                                        </tr>
                                        <tr>
                                            <td>Furnished</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>Balcony/Terrace</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>Heating</td>
                                            <td>No</td>
                                            <td>No</td>
                                            <td>No</td>
                                            <td>No</td>
                                        </tr>
                                        <tr>
                                            <td>Air-condintiong</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>Parking</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>Lift</td>
                                            <td>No</td>
                                            <td>No</td>
                                            <td>No</td>
                                            <td>No</td>
                                        </tr>
                                        <tr>
                                            <td>Near to public trans</td>
                                            <td>Tram, Bus</td>
                                            <td>Metro, Bus</td>
                                            <td>All</td>
                                            <td>Bus</td>
                                        </tr>
                                        <tr>
                                            <td>Pet friendly</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>University nearby</td>
                                            <td>No</td>
                                            <td>No</td>
                                            <td>No</td>
                                            <td>No</td>
                                        </tr>

                                        <tr>
                                            <td>Green party nearby</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>Beach nearby</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>Fitness centre nearby</td>
                                            <td>No</td>
                                            <td>No</td>
                                            <td>No</td>
                                            <td>No</td>
                                        </tr>
                                        <tr>
                                            <td>Pool nearby</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                            <td>Yes</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div><!-- ./ row -->
                    </div>
                </div>
            </main>

    <TheFooter />
  </div>
  <!-- ./page-content -->
</template>

<script>
import TheHeader from "../components/TheHeader.vue";
import TheFooter from "../components/TheFooter.vue";

export default {
  name: "Search",
  components: {
    TheHeader,
    TheFooter,
  },
};
</script>

<style>
@import "../assets/css/style.css";

.compare .page-content {
    padding-top: 0; 
}
</style>
