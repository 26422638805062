import axios from "axios";

const API_URL = process.env.VUE_APP_BACKEND_URL + '/roomAd';

export default class RoomAdService {

  static save(data) {
    const url = API_URL;
    if (data.id) {
      return axios.put(url, data)
    }
    return axios.post(url, data);
  }

  static uploadImagesWithDescription(data) {
    let fd = new FormData();

    if (data.files && data.files.length > 0) {
      let files = data.files;
      for (let i = 0; i < files.length; i++) {
        if (files[i] !== undefined) {
        fd.append("files", files[i]);
      }
      }
    }

    if (data.oldImageList && data.oldImageList.length > 0) {
      console.log("data.oldImageList", data.oldImageList);
      let oldImageList = data.oldImageList;
      for (let i = 0; i < oldImageList.length; i++) {
        if (oldImageList[i] !== undefined && oldImageList !== null) {
        fd.append("oldImageList", oldImageList[i]);
      }
      }
    }
    fd.append('description', data.description);
    fd.append('id', data.id);

    return axios({
      method: "post",
      url: API_URL + '/uploadImage',
      data: fd,
      headers: {"Content-Type": "multipart/form-data"},
    });
  }

  static deleteImageById(id) {
    const url = process.env.VUE_APP_BACKEND_URL + '/fileDescriptor/' + id;
    return axios.delete(url);
  }

  static publishAd(id) {
    const url = API_URL + '/publish/' + id;
    return axios.put(url);
  }

  static moreMatches() {
    const url = API_URL + '/more-matches';
    return axios.get(url);
  }

  static search(params) {
    /**
     * cityId removed from search
     * only San Salvador is the valid city with id 1
     */
    // if (params.filter.cityId) {
    //   delete params.filter.cityId;
    // }
    if (params.filter.termOfLease) {
      params.filter.termOfLease = params.filter.termOfLease.join(',');
    }
    const url = API_URL + '/search';
    return axios.get(url, {params}
    )
  }

  static findById(id) {
    const url = API_URL + '/findById/' + id;
    return axios.get(url);
  }

  static getMoreMatches(id) {
    const url = API_URL + '/more-matches/' + id;
    return axios.get(url);
  }

  static findAll() {
    const url = API_URL;
    return axios.get(url);
  }
}


