<template>
  <div class="upload-items__bar 01">
    <div class="upload-items__thumb">
      <img
          class="img-fluid"
          :src="file.src ? file.src : getImage(file)"
          alt=""
      />
    </div>
    <span class="upload-items__filename">{{ file.fileName ? file.fileName : file.originalName }}</span>
    <button
        class="upload-items__trash"
        title="Trash File"
        @click.prevent="onDeleteClick(file.id)"
    >
      <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="512px"
          height="512px"
          viewBox="0 0 512 512"
          style="enable-background: new 0 0 512 512"
          xml:space="preserve"
      >
        <g>
          <path
              d="M400,113.3h-80v-20c0-16.2-13.1-29.3-29.3-29.3h-69.5C205.1,64,192,77.1,192,93.3v20h-80V128h21.1l23.6,290.7
                                                                                            c0,16.2,13.1,29.3,29.3,29.3h141c16.2,0,29.3-13.1,29.3-29.3L379.6,128H400V113.3z M206.6,93.3c0-8.1,6.6-14.7,14.6-14.7h69.5
                                                                                            c8.1,0,14.6,6.6,14.6,14.7v20h-98.7V93.3z M341.6,417.9l0,0.4v0.4c0,8.1-6.6,14.7-14.6,14.7H186c-8.1,0-14.6-6.6-14.6-14.7v-0.4
                                                                                            l0-0.4L147.7,128h217.2L341.6,417.9z"
              fill="#848383"
          />
          <g>
            <rect x="249" y="160" width="14" height="241"/>
            <polygon points="320,160 305.4,160 294.7,401 309.3,401 		"/>
            <polygon points="206.5,160 192,160 202.7,401 217.3,401 		"/>
          </g>
        </g>
      </svg>
    </button>
<!--    <span class="upload-items__percent">0%</span>-->
  </div>
</template>

<script>
export default {
  name: "UploadPhoto",
  props: ["file"],
  methods: {
    getImage(file) {
    return process.env.VUE_APP_BACKEND_URL + '/' + file.filePath;
    },
    onDeleteClick() {
      this.$emit("deleteClicked");
    }
  },
}
</script>

<style scoped>
</style>
