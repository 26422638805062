<template>
  <div class="carousel">
    <swiper class="flat-slider" ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="image in images" :key="image">
        <img :src="getImage(image)" alt="."/>
      </swiper-slide>
    </swiper>

    <button class="flat-slider__step flat-slider__prev" @click="prev">
      <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="512px"
          height="512px"
          viewBox="0 0 512 512"
          style="enable-background: new 0 0 512 512"
          xml:space="preserve"
      >
        <path
            d="M213.7,256L213.7,256L213.7,256L380.9,81.9c4.2-4.3,4.1-11.4-0.2-15.8l-29.9-30.6c-4.3-4.4-11.3-4.5-15.5-0.2L131.1,247.9
                                                        c-2.2,2.2-3.2,5.2-3,8.1c-0.1,3,0.9,5.9,3,8.1l204.2,212.7c4.2,4.3,11.2,4.2,15.5-0.2l29.9-30.6c4.3-4.4,4.4-11.5,0.2-15.8
                                                        L213.7,256z"
        />
      </svg>
    </button>
    <button class="flat-slider__step flat-slider__next" @click="next">
      <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="512px"
          height="512px"
          viewBox="0 0 512 512"
          style="enable-background: new 0 0 512 512"
          xml:space="preserve"
      >
        <path
            d="M298.3,256L298.3,256L298.3,256L131.1,81.9c-4.2-4.3-4.1-11.4,0.2-15.8l29.9-30.6c4.3-4.4,11.3-4.5,15.5-0.2l204.2,212.7
                                                    c2.2,2.2,3.2,5.2,3,8.1c0.1,3-0.9,5.9-3,8.1L176.7,476.8c-4.2,4.3-11.2,4.2-15.5-0.2L131.3,446c-4.3-4.4-4.4-11.5-0.2-15.8
                                                    L298.3,256z"
        />
      </svg>
    </button>
    <button class="flat-slider__show-more" @click="showMasonryModal">{{ $cte('carousel.ShowAllPhotos') }}</button>


    <Modal ref="masonry-modal" :full-screen="true">
      <div class="container-fluid masonry-modal-container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-6">
            <masonry-wall :items="images" :column-width="300" :gap="16">
              <template #default="{ item }">
                <img :src="getImage(item)" alt="."/>
              </template>
            </masonry-wall>
          </div>

        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {Swiper, SwiperSlide, directive} from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import Modal from "../components/Modal";
import MasonryWall from '@yeger/vue2-masonry-wall'

export default {
  name: "Carousel",
  props: {
    images: Array,
  },
  components: {
    Swiper,
    SwiperSlide,
    Modal,
    MasonryWall
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
      },
      defaultImg: 'http://via.placeholder.com/251x182'
    };
  },
  directives: {
    swiper: directive,
  },
  methods: {
    getImage(image) {
      return process.env.VUE_APP_BACKEND_URL + '/' + image.filePath;
    },
    next() {
      this.swiper.slideNext();
    },
    prev() {
      this.swiper.slidePrev();
    },
    showMasonryModal() {
      this.$refs['masonry-modal'].isOpen = true;
    },
  },
  mounted() {
  },
};
</script>

<style>
.carousel {
  overflow: hidden;
}

.single .flat-slider__step,
.single .flat-slider__show-more {
  z-index: 1000;
}

.masonry-modal-container {
  padding-top: 30px;
  padding-bottom: 30px;
  max-height: 100%;
  overflow: auto;
}
</style>
