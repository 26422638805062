<template>
  <div class="form-group">
    <label :for="key">{{ label }} <span v-if="rules && rules.required" class="required">*</span></label>
    <ValidationProvider  :name="label"
                         :rules="rules"
                         v-slot="{ errors }">
      <v-select class="rentible-select"
                :multiple="true"
                :id="key"
                :reduce="getReduceFn"
                :placeholder="placeholder"
                :options="options"
                :disabled="disabled"
                @input="updateValue"
                :getOptionLabel="getLabel"
                v-model="value">
        <template v-slot:option="option">
          {{ codeStoreId ? $cte(option.label) : option.label }}
        </template>
        <template #no-options>
          {{ $cte('common.noResult') }}
        </template>
      </v-select>
      <small class="form-text text-error">
        {{ errors[0] }}
      </small>
    </ValidationProvider>
  </div>
</template>

<script>
import {ValidationProvider} from 'vee-validate';
import CodeStoreService from "../../services/codeStore.service";

export default {
  name: "RentSelect",
  components: {ValidationProvider},
  props: {
    key: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
    },
    apiCallback: {
      type: Function
    },
    optionList: {
      type: Array
    },
    codeStoreId: {
      type: Number
    },
    reduce: {
      type: Function
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: null,
      options: [],
    }
  },
  created() {
    if (this.codeStoreId) {
      CodeStoreService.findById(this.codeStoreId).then(res => {
        this.options = res.data.map(item => {
          return {label: item.text, value: item.id}
        });
      })
    } else if (this.apiCallback) {
      this.apiCallback()
          .then(res => {
            this.options = res.data;
          }).catch(err => console.log(err))
    } else {
      this.options = [...this.optionList];
    }

  },
  methods: {
    updateValue() {
      this.$emit('input', this.value)
    },
    getLabel(option) {
      if (this.codeStoreId) {
        return this.$cte(option.label);
      }

      if (this.getOptionLabel) {
        return this.getOptionLabel;
      }

      return option;
    }
  },
  watch: {
    value: {
      handler: function (n) {
        if (n && this.codeStoreId && this.multiple) {
          this.value = n.value.data.map(item => {
            return {label: item.text, value: item.id}
          });
        } else {
          this.value = n ? n : (this.multiple ? [] : null);
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    getReduceFn() {
      if (this.codeStoreId) {
        return (item) => item.value;
      } else {
        return () => {}
      }
    }
  }
}
</script>

<style>

.rentible-select {
  letter-spacing: -0.32px;
  width: 100%;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  background: #fff;
}

.rentible-select .vs__search::placeholder {
  color: #707070;
  font-weight: 500;
  letter-spacing: -0.32px;
}

.rentible-select .vs__dropdown-toggle {
  min-height: 66px;
  background: #f8f2ef39;
  color: #000;
  border: 1px solid #6565655c;
  border-radius: 8px;
  padding: 0 10px 4px;
}

.rentible-select .vs__deselect {
  margin-left: 13px;
  fill: #000;
}

.rentible-select .vs__selected {
  border-radius: 8px;
  padding: 0 1em;
  border: none;
  background-color: #f1f1f1;
}

.rentible-select .vs__selected-options {
  padding: 10px;
}

.rentible-select .vs__search::placeholder,
.rentible-select .vs__dropdown-toggle,
.rentible-select .vs__dropdown-menu {

}

.rentible-select .vs__search {

}

.rentible-select .vs__clear,
.rentible-select .vs__open-indicator {

}


</style>
