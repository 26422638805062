import axios from "axios";
import config from "../config/constants.js";
import AppStorage from "../helper/AppStorage";
var qs = require('qs');

const API_LOGIN = config.API_LOGIN;
const API_REG = config.API_REG;
//const API_RESET_PASS = config.API_RESET_PASS;
const API_NEW_PASS = config.API_NEW_PASS;
const API_FORGOT_PASS = config.API_FORGOT_PASS;

const HEADER_SETTINGS = { "content-type": "application/x-www-form-urlencoded" };

class AuthService {
  login(user) {
    return axios
      .post(
        API_LOGIN,
        qs.stringify({ username: user.username, password: user.password }),
        {
          headers: HEADER_SETTINGS,
        }
      )
      .then((response) => {
        AppStorage.setItem('token', response.headers.authorization);
        AppStorage.setItem('user', response.data);
        return response;
      });
  }

  logout() {
    AppStorage.removeItem('user');
    AppStorage.removeItem('token');
  }

  register(user) {
    return axios
      .post(API_REG, user)
      .then((response) => {
        if (response.data.success == "ok") {
          return { status: "OK" };
        }
      });
  }

  /**
   * Request new password
   * @param {*} data
   * @returns
   */
  forgotPass(data) {
    return axios
      .post(API_FORGOT_PASS, data)
      .then((response) => {
        if (response.data.success == "ok") {
          return { status: "OK" };
        }
      });
  }

  /**
   * Set new password
   * @param {*} data - id, email, password, newPassword, newPasswordAgain
   * @returns
   */
  newPass(data) {
    return axios
      .put(API_NEW_PASS, data)
      .then((response) => {
        if (response.data.success == "ok") {
          return { status: "OK" };
        }
      });
  }

  /**
   * Checks the password reset eligibility
   * Returns a passwordResetToken that contains the email
   * @param token
   * @returns
   */
  getPasswordResetToken(token) {
    const url = config.BASE_URL + '/forgottenPassword/setNewPassword/' + token;
    return axios.get(url);
  }
}

export default new AuthService();
