<template>
  <div class="row align-items-center" v-if="item">
    <div class="col-md-4">
      <div class="item__title">{{ property.title }}</div>
<!--      <div class="item__subtitle">-->
<!--        Tenant: {{ request.tenant }}-->
<!--      </div>-->
    </div>
    <div class="col-md-2">
<!--      <div :class="`status status&#45;&#45;`+request.status">{{ request.contractType }}</div>-->
      <div :class="`status status--success`">{{ item.contractStatus }}</div>
    </div>
    <div class="col-md-3">
      <div class="item__date">{{ item.validFrom | dateISO }} - {{ item.validTo | dateISO }}</div>
    </div>
    <div class="col-md-3 card-action">
      <div class="btns">
        <!--ONLY not landlord button-->
        <AcceptContractButton
            v-if="isAcceptReady"
            :contractId="item.id"
            :roomAdId="roomAd.id"
            @success="successCallback"
            @error="errorCallback"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AcceptContractButton from "../../components/crypto/AcceptContractButton";
export default {
  name: "PendingRequestCard",
  components: {
    AcceptContractButton,
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      STATUS: {
        PENDING_REQUEST: "Pending Request",
        REQUEST_RECEIVED: "Request Received",
        PAY_DEPOSIT: "Pay Deposit",
        DEPOSIT_DEPOSIT: "Deposit Pending",
        START_ARBITRATION: "Start Arbitration"
      },
    }
  },
  methods: {
    successCallback(response) {
      this.$emit("success", response);
    },
    errorCallback(error) {
      this.$emit("error", error);
    }
  },
  computed: {
    roomAd: function() {
      return this.item.roomAd;
    },
    property: function() {
      return this.item.roomAd.property;
    },
    isPending: function () {
      return this.item.contractStatus === this.STATUS.REQUEST_RECEIVED || this.item.contractStatus === this.STATUS.PENDING_REQUEST
    },
    isAcceptReady: function() {
      return this.isPending && this.item.isLandlordInContract;
    },
  }
}
</script>

<style scoped>
</style>
