<template>
        <div class="col-md-12 col-lg-8">

        </div>
</template>

<script>


import axios from "axios";
import config from "../../config/constants.js";

export default {
  name: "Profile",
  components: {
  },
  data() {
    return {
      profile: {
        id: "123",
        firstName: "Connor",
        lastName: "Hello",
        emailAddress: "",
        dateOfBirth: "",
        profileImage: "",
        gender: "",
        yourWalletAddress: "",
        walletCode: "BEP-20",
        phoneNumber: "",
        languages: ["English", "Spanish"],
        occupation: "",
        password: "",
        agencyName: "",
        categories: "",
      },
      activeTab: "profile", // it will changes based on tab click
    };
  },
  methods: {
    changeGender(event) {
      let target = event.target;

      let getAllboxes = document.querySelectorAll(".gender-box .box");

      getAllboxes.forEach(function (item) {
        item.classList.remove("active");
      });

      target.classList.add("active");
    },
    togglePassword() {
      // go to mixin
      // show password
      let togglePassTrigger = document.querySelectorAll(".js-show-pass");

      togglePassTrigger.forEach(function (item) {
        item.addEventListener("click", function () {
          let prevElement = item.previousElementSibling;

          let wordHide = item.dataset.hide;
          let wordShow = item.dataset.show;

          if (prevElement.getAttribute("type") == "password") {
            prevElement.setAttribute("type", "text");
            item.innerHTML = wordHide;
          } else {
            prevElement.setAttribute("type", "password");
            item.innerHTML = wordShow;
          }
        });
      });
    },
    changePassword() {
      // valahova megy
      let payload = {
        newPassword: this.pass1,
        newPasswordAgain: this.pass2,
        token: this.$store.getters.accessToken,
      };

      axios
        .put(config.BASE_URL + "/user/changePassword", JSON.stringify(payload))
        .then((response) => {
          console.log(response); // success show some toast
          this.$buefy.toast.open("successfully"); // use this for messages
        })
        .catch((error) => console.log(error));
    },
    onSubmit() {
      let payload = {
        emailAddress: this.profile.emailAddress,
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        language: this.selectedLang,
      };

      axios
        .put(config.BASE_URL + "/user/changeData", JSON.stringify(payload))
        .then((response) => {
          console.log(response); // success show some toast
          this.$buefy.toast.open("successfully"); // use this for messages
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.togglePassword();

    const loadingComponent = this.$buefy.loading.open({
        canCancel: true
    });

    let userID = this.$route.params.id;

    let token = this.$store.getters.accessToken;

    let payload = {
      userID,
      token,
    };

    setTimeout(() => {
           loadingComponent.close();
    }, 2000);


    axios
      .get(config.BASE_URL + "/user/findById/", JSON.stringify(payload))
      .then((response) => {
        console.log(response);
        this.profile = response.userDetail;
      })
      .catch((error) => console.log(error));
  }
};
</script>

<style>
@import "../../assets/css/style.css";

.profile .page-content {
  padding-top: 0;
}

.datepicker .input {
  height: 66px;
  border-radius: 13px;
  background: #f8f2ef39;
  color: #000;
  border: 1px solid #6565655c;
}

.profile .btn-primary {
  border-radius: 5px;
  margin: 20px 0;
  padding: 3px 15px;
  min-width: 120px;
  height: 50px;
}

.profile .menu a {
  color: #000;
}
</style>
