<template>
  <div class="password-steps">
    <ul>
      <li
        :class="
          currentActive == 'step-01'
            ? 'password-steps__item step-01 active'
            : 'password-steps__item step-01'
        "
      >
        <!-- TODO: .active class will set the active state for this step -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28.947"
          height="33.082"
          viewBox="0 0 28.947 33.082"
        >
          <g id="password" transform="translate(0)">
            <g id="Group_5113" data-name="Group 5113" transform="translate(0)">
              <g id="Group_5112" data-name="Group 5112">
                <ellipse
                  id="Ellipse_163"
                  data-name="Ellipse 163"
                  cx="1.444"
                  cy="1.444"
                  rx="1.444"
                  ry="1.444"
                  transform="translate(20.212 21.174)"
                  fill="#b9b9b9"
                />
                <path
                  id="Path_6886"
                  data-name="Path 6886"
                  d="M59.654,23.39A1.292,1.292,0,0,0,60.946,22.1V17.316a5.175,5.175,0,0,0-5.169-5.169H54.224V7.59a7.755,7.755,0,0,0-15.507,0v4.557H37.169A5.175,5.175,0,0,0,32,17.316v10.6a5.175,5.175,0,0,0,5.169,5.169H55.777a5.175,5.175,0,0,0,5.169-5.169,1.292,1.292,0,0,0-2.585,0A2.587,2.587,0,0,1,55.777,30.5H37.169a2.587,2.587,0,0,1-2.585-2.585v-10.6a2.587,2.587,0,0,1,2.585-2.585H55.777a2.587,2.587,0,0,1,2.584,2.585V22.1A1.292,1.292,0,0,0,59.654,23.39ZM51.64,12.147H41.3V7.59a5.172,5.172,0,0,1,10.338,0Z"
                  transform="translate(-32)"
                  fill="#b9b9b9"
                />
                <ellipse
                  id="Ellipse_164"
                  data-name="Ellipse 164"
                  cx="1.444"
                  cy="1.444"
                  rx="1.444"
                  ry="1.444"
                  transform="translate(10.587 21.174)"
                  fill="#b9b9b9"
                />
                <ellipse
                  id="Ellipse_165"
                  data-name="Ellipse 165"
                  cx="1.444"
                  cy="1.444"
                  rx="1.444"
                  ry="1.444"
                  transform="translate(5.775 21.174)"
                  fill="#b9b9b9"
                />
                <ellipse
                  id="Ellipse_166"
                  data-name="Ellipse 166"
                  cx="1.444"
                  cy="1.444"
                  rx="1.444"
                  ry="1.444"
                  transform="translate(15.399 21.174)"
                  fill="#b9b9b9"
                />
              </g>
            </g>
          </g>
        </svg>

        <span
          >{{ $cte("forgotpass.forget") }}<br />
          {{ $cte("forgotpass.password") }}</span
        >
      </li>
      <li class="separator"></li>
      <li
        :class="
          currentActive == 'step-02'
            ? 'password-steps__item step-02 active'
            : 'password-steps__item step-02'
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35.268"
          height="30.584"
          viewBox="0 0 35.268 30.584"
        >
          <g id="authentication" transform="translate(0)">
            <g
              id="Group_5109"
              data-name="Group 5109"
              transform="translate(0 0)"
            >
              <g id="Group_5108" data-name="Group 5108">
                <path
                  id="Path_6882"
                  data-name="Path 6882"
                  d="M166.769,279h-4.306a3.647,3.647,0,0,0-3.446,2.329c-.072.188-.161.426-.253.7h-9.02a1.378,1.378,0,0,0-.988.418l-2.367,2.438a1.378,1.378,0,0,0,.006,1.925l2.411,2.453a1.378,1.378,0,0,0,.982.412h4.477a1.378,1.378,0,0,0,0-2.755h-3.9L149.3,285.84l1.023-1.054H159.8a1.377,1.377,0,0,0,1.338-1.05,11.383,11.383,0,0,1,.454-1.419.9.9,0,0,1,.878-.562h4.3a.94.94,0,0,1,.875.546,8.9,8.9,0,0,1,.815,3.512,9.159,9.159,0,0,1-.809,3.575.941.941,0,0,1-.865.564h-4.35a.98.98,0,0,1-.888-.61,9.161,9.161,0,0,1-.427-1.363,1.378,1.378,0,1,0-2.679.641,11.856,11.856,0,0,0,.559,1.773,3.716,3.716,0,0,0,3.432,2.315h4.357a3.693,3.693,0,0,0,3.387-2.218,11.929,11.929,0,0,0,1.038-4.682,11.664,11.664,0,0,0-1.055-4.63A3.712,3.712,0,0,0,166.769,279Z"
                  transform="translate(-135.944 -262.124)"
                  fill="#b9b9b9"
                />
                <ellipse
                  id="Ellipse_162"
                  data-name="Ellipse 162"
                  cx="1.026"
                  cy="1.539"
                  rx="1.026"
                  ry="1.539"
                  transform="translate(28.729 21.874)"
                  fill="#b9b9b9"
                />
                <path
                  id="Path_6883"
                  data-name="Path 6883"
                  d="M29.757,34H5.511A5.517,5.517,0,0,0,0,39.511V55.422a5.517,5.517,0,0,0,5.511,5.511h.482a1.378,1.378,0,0,0,0-2.755H5.511a2.758,2.758,0,0,1-2.755-2.755V39.8l11.969,7.442a5.476,5.476,0,0,0,5.82,0L32.512,39.8v7.354a1.378,1.378,0,0,0,2.755,0V39.511A5.517,5.517,0,0,0,29.757,34ZM19.089,44.905a2.738,2.738,0,0,1-2.91,0L3.906,37.273a2.74,2.74,0,0,1,1.605-.518H29.757a2.739,2.739,0,0,1,1.605.518Z"
                  transform="translate(0 -34)"
                  fill="#b9b9b9"
                />
              </g>
            </g>
          </g>
        </svg>

        <span
          >{{ $cte("forgotpass.checkYour") }}<br />
          {{ $cte("forgotpass.email") }}</span
        >
      </li>
      <li class="separator"></li>
      <li
        :class="
          currentActive == 'step-03'
            ? 'password-steps__item step-03 active'
            : 'password-steps__item step-03'
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26.58"
          height="30.378"
          viewBox="0 0 26.58 30.378"
        >
          <g id="password" transform="translate(0)">
            <g id="Group_5113" data-name="Group 5113" transform="translate(0)">
              <g id="Group_5112" data-name="Group 5112">
                <ellipse
                  id="Ellipse_163"
                  fill="#b9b9b9"
                  data-name="Ellipse 163"
                  cx="1.326"
                  cy="1.326"
                  rx="1.326"
                  ry="1.326"
                  transform="translate(18.56 19.443)"
                />
                <path
                  id="Path_6886"
                  fill="#b9b9b9"
                  data-name="Path 6886"
                  d="M57.394,21.478a1.187,1.187,0,0,0,1.187-1.187V15.9a4.752,4.752,0,0,0-4.746-4.747H52.408V6.969a7.121,7.121,0,0,0-14.239,0v4.185H36.746A4.752,4.752,0,0,0,32,15.9v9.73a4.752,4.752,0,0,0,4.746,4.747H53.834a4.752,4.752,0,0,0,4.746-4.747,1.187,1.187,0,0,0-2.373,0A2.376,2.376,0,0,1,53.834,28H36.746a2.376,2.376,0,0,1-2.373-2.373V15.9a2.376,2.376,0,0,1,2.373-2.373H53.834A2.376,2.376,0,0,1,56.207,15.9v4.391A1.187,1.187,0,0,0,57.394,21.478ZM50.034,11.154H40.541V6.969a4.749,4.749,0,0,1,9.493,0Z"
                  transform="translate(-32)"
                />
                <ellipse
                  id="Ellipse_164"
                  fill="#b9b9b9"
                  data-name="Ellipse 164"
                  cx="1.326"
                  cy="1.326"
                  rx="1.326"
                  ry="1.326"
                  transform="translate(9.722 19.443)"
                />
                <ellipse
                  id="Ellipse_165"
                  fill="#b9b9b9"
                  data-name="Ellipse 165"
                  cx="1.326"
                  cy="1.326"
                  rx="1.326"
                  ry="1.326"
                  transform="translate(5.303 19.443)"
                />
                <ellipse
                  id="Ellipse_166"
                  fill="#b9b9b9"
                  data-name="Ellipse 166"
                  cx="1.326"
                  cy="1.326"
                  rx="1.326"
                  ry="1.326"
                  transform="translate(14.141 19.443)"
                />
              </g>
            </g>
          </g>
        </svg>

        <span
          >{{ $cte("forgotpass.setNew") }}<br />
          {{ $cte("forgotpass.password") }}</span
        >
      </li>
      <li class="separator"></li>
      <li
        :class="
          currentActive == 'step-04'
            ? 'password-steps__item step-04 active'
            : 'password-steps__item step-04'
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28.946"
          height="33.082"
          viewBox="0 0 28.946 33.082"
        >
          <g id="security" transform="translate(0)">
            <g
              id="Group_5111"
              data-name="Group 5111"
              transform="translate(0 0)"
            >
              <g id="Group_5110" data-name="Group 5110">
                <path
                  id="Path_6884"
                  data-name="Path 6884"
                  d="M60.174,5.924,46.928.109a1.292,1.292,0,0,0-1.043,0L32.769,5.926A1.292,1.292,0,0,0,32,7.107V17.316a16.643,16.643,0,0,0,4.183,10.821,15.113,15.113,0,0,0,4.582,3.571,12.675,12.675,0,0,0,5.708,1.374,13.1,13.1,0,0,0,7.739-2.573,1.292,1.292,0,1,0-1.521-2.09A10.533,10.533,0,0,1,46.473,30.5c-6.222,0-11.889-6.283-11.889-13.181V7.948L46.411,2.7,58.362,7.951v9.365a13.681,13.681,0,0,1-1.977,6.964,1.292,1.292,0,0,0,2.222,1.32,16.265,16.265,0,0,0,2.34-8.284V7.107A1.292,1.292,0,0,0,60.174,5.924Z"
                  transform="translate(-32 0)"
                  fill="#b9b9b9"
                />
                <path
                  id="Path_6885"
                  data-name="Path 6885"
                  d="M138.691,150.483l-8.012,10.012-4.492-4.32a1.292,1.292,0,0,0-1.792,1.863l4.554,4.381.005.005a2.491,2.491,0,0,0,1.729.692l.1,0a2.493,2.493,0,0,0,1.771-.835l.046-.055,8.1-10.125a1.292,1.292,0,1,0-2.018-1.615Z"
                  transform="translate(-118.055 -140.306)"
                  fill="#b9b9b9"
                />
              </g>
            </g>
          </g>
        </svg>

        <span
          >{{ $cte("forgotpass.reset") }}<br />
          {{ $cte("forgotpass.password") }}</span
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PasswordSteps",
  props: {
    currentActive: String,
  },
};
</script>
