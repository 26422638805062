<template>
  <div class="page-top">
    <header class="page-header">
      <div class="container-fluid">
        <nav
          id="navbar"
          class="navbar navbar-dark navbar-expand-xl bg-transparent"
        >
          <router-link to="/">
            <img
              src="../assets/img/logos/beta-logo.svg"
              alt="Rentible.io"
            />
          </router-link>

          <button
            class="navbar-toggler collapsed"
            type="button"
            @click="mobileNavOpen = !mobileNavOpen"
            data-toggle="collapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div
            class="navbar-collapse collapse"
            :class="{ show: mobileNavOpen }"
          >
            <TheMenu />

            <div class="wallet-btn-wrapper">
              <ConnectWalletButton />
            </div>

            <!-- TODO: hide when user logged -->
            <!-- <router-link to="/profile/1" class="profile-box" v-if="isLogged"> -->
            <div class="profile-box">
              <div v-if="!getImage" class="avatar-wrapper">
                <img src="../assets/img/avatar.png" alt="name" />
              </div>
              <div v-else class="avatar-wrapper">
                <img :src="getImage" alt="name" />
              </div>
              <div class="porfile-box__menu">
                <div class="d-none d-lg-block">
                  <svg
                    class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="17"
                    viewBox="0 0 25 17"
                  >
                    <g
                      id="avatar_icon"
                      data-name="Group 4"
                      transform="translate(-1624 -49)"
                    >
                      <rect
                        id="Rectangle_2204"
                        data-name="Rectangle 2204"
                        width="16"
                        height="3"
                        transform="translate(1626 49)"
                      />
                      <rect
                        id="Rectangle_2205"
                        data-name="Rectangle 2205"
                        width="17"
                        height="3"
                        transform="translate(1632 56)"
                        fill="#d91f6e"
                      />
                      <rect
                        id="Rectangle_2206"
                        data-name="Rectangle 2206"
                        width="17"
                        height="3"
                        transform="translate(1624 63)"
                      />
                    </g>
                  </svg>
                </div>
                <div class="d-block d-lg-none text-mb">
                  {{ $cte("header.account") }}
                </div>
              </div>

              <div class="profile-dropdown">
                <ul v-if="status.loggedIn">
                  <li>
                    <router-link
                      :to="{ name: 'Profile', params: { id: user.id } }"
                      >{{ $cte("profileDropdown.MyProfile") }}</router-link
                    >
                  </li>
                  <li>
                    <a href="#" @click.prevent="doLogout">{{
                      $cte("profileDropdown.Logout")
                    }}</a>
                  </li>
                </ul>
                <ul v-else>
                  <li>
                    <router-link to="/login">{{
                      $cte("profileDropdown.SignIn")
                    }}</router-link>
                  </li>
                  <li>
                    <router-link to="/registration">{{
                      $cte("profileDropdown.SignUp")
                    }}</router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="lang">
              <b-dropdown
                aria-role="list"
                position="is-bottom-left"
                :mobile-modal="false"
              >
                <template #trigger>
                  <button
                    class="btn btn-secondary dropdown-toggle lang-color"
                    type="button"
                    id="langMenu"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <svg class="mr-1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.508"
                      height="17.519"
                      viewBox="0 0 17.508 17.519"
                    >
                      <g id="Layer_37" transform="translate(-1 -1)">
                        <path
                          id="Path_1411"
                          data-name="Path 1411"
                          d="M18.041,12.5A8.687,8.687,0,0,0,9.754,1,8.594,8.594,0,0,0,1,9.754a8.888,8.888,0,0,0,2.393,6.011,8.734,8.734,0,0,0,12.255.467,10.431,10.431,0,0,0,1.284-1.459A8.236,8.236,0,0,0,18.041,12.5Zm-.7-2.743a9.78,9.78,0,0,1-.35,2.334l-.233-.058a2.7,2.7,0,0,1-1.342-1.342A1.451,1.451,0,0,1,15.532,9.4a3.19,3.19,0,0,0,.292-2.568,2.088,2.088,0,0,0-2.218-1.4c-.992.058-2.043.817-3.151,2.218a2.438,2.438,0,0,1-2.8.992A3.291,3.291,0,0,1,5.669,5.669a3.394,3.394,0,0,0-.525-1.926,7.463,7.463,0,0,1,4.61-1.576A7.572,7.572,0,0,1,17.341,9.754ZM2.692,12.438a.829.829,0,0,1,.35.117,2.441,2.441,0,0,1,.875,1.109c.525,1.109,1.167,1.459,3.151.7.584-.233.875-.058.992.117a3.275,3.275,0,0,1,.292,2.743,7.529,7.529,0,0,1-5.661-4.785Zm7.061,4.9H9.52a4.071,4.071,0,0,0-.584-3.618,1.986,1.986,0,0,0-2.276-.409c-1.342.525-1.4.409-1.692-.117a3.719,3.719,0,0,0-1.284-1.576,1.88,1.88,0,0,0-1.342-.35,6.728,6.728,0,0,1-.175-1.517,7.471,7.471,0,0,1,2.1-5.194A2.087,2.087,0,0,1,4.5,5.669,4.39,4.39,0,0,0,7.3,9.754a3.537,3.537,0,0,0,4.085-1.342c2.159-2.8,3.21-1.4,3.385-1.05a1.857,1.857,0,0,1-.292,1.576,2.505,2.505,0,0,0-.175,2.218A3.814,3.814,0,0,0,16.524,13.2a7.685,7.685,0,0,1-6.77,4.144Z"
                        />
                      </g>
                    </svg>
                    {{ currentLang }}
                  </button>
                </template>

                <b-dropdown-item
                  aria-role="listitem"
                  v-for="locale in locales"
                  :key="locale"
                  @click="switchLocale(locale)"
                >
                  {{ locale }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
import { mapState } from "vuex";

import TheMenu from "../components/TheMenu.vue";
import Uimixins from "../mixins/UImixins.js";
import ConnectWalletButton from "@/components/crypto/ConnectWalletButton";

export default {
  name: "TheHeader",
  mixins: [Uimixins],
  data() {
    return {
      locales: ["EN", "ES", "TH"],
      isLogged: false,
      mobileNavOpen: false,
      get user() {
        return localStorage.getItem("currentUser");
      },
    };
  },
  components: {
    ConnectWalletButton,
    TheMenu,
  },
  computed: {
    ...mapState("auth", ["user", "status"]),
    currentLang() {
      return this.$i18n.locale.toUpperCase();
    },
    getImage() {
      let user = JSON.parse(this.user);
      if (user === undefined || user === null) return null;
      if (user && !user.profileImage.filePath) return null;
      return process.env.VUE_APP_BACKEND_URL + "/" + user.profileImage.filePath;
    },
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale.toLowerCase();
      }
    },

    doLogout() {
      const loadingComponent = this.$buefy.loading.open();

      localStorage.setItem("currentUser", null);

      this.$store.dispatch("auth/logout").then((message) => {
        console.log(message);
        if (message) {
          setTimeout(() => loadingComponent.close(), 2000);
          this.$router.push("/");
        }
      });
    },
  },
  created() {
    let userLogged = this.status.loggedIn;
    if (userLogged) {
      this.isLogged = true;
    }
  },
};
</script>

<style>
@import "../assets/css/style.css";

.dropdown-content {
  padding: 0 !important;
  box-shadow: none !important;
  width: 100%;
}

.dropdown-content a.dropdown-item {
  padding: 5px 0;
}

.profile-box {
  position: relative;
}

.profile-box:hover .profile-dropdown {
  opacity: 1;
  visibility: visible;
  z-index: 1000;
}

.lang-color:hover {
  color: #de2279 !important;
  fill: #de2279;
}

.profile-dropdown {
  position: absolute;
  background: #fff;
  border-radius: 6px;
  min-width: 200px;
  right: 0;
  top: 65px;
  z-index: 100;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.45s ease-in-out;
  padding: 10px 15px;
  z-index: -1;
}

.profile-dropdown a {
  display: block;
  color: #000;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
}

.profile-dropdown a:hover {
  background: rgba(222, 34, 121, 0.1);
  color: #000;
}

@media (max-width: 1199.98px) {
  .navbar-collapse {
    background: #fff;
    padding: 35px;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 120px;
    z-index: 9000;
    overflow: auto;
  }

  .profile-dropdown {
    left: 0;
  }

  .lang .dropdown-menu {
    display: flex;
  }
}

@media (max-width: 992px) {
  .navbar-collapse {
    top: 66px;
  }
}

.page-header .lang .dropdown-menu {
  margin-top: 5px;
  border: 0;
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.15);
  min-width: 70px;
  text-align: center;
}

@media (max-width: 1199.98px) {
  .page-header .lang .dropdown-menu {
    display: block !important;
    position: static;
    left: auto;
    right: auto;
    display: flex !important;
    box-shadow: none;
    border-top: 1px solid rgba(108, 123, 138, 0.1);
    padding-top: 30px;
    margin-top: 30px;
    width: 100%;
  }
}

.page-header .lang .dropdown-menu a {
  font-weight: 600;
}

@media (max-width: 1199.98px) {
  .page-header .lang .dropdown-menu a {
    width: auto;
    display: inline;
    font-size: 24px;
    font-weight: 500;
    padding: 0;
    margin-right: 25px;
  }

  .page-header .lang .dropdown-menu a:before {
    content: "";
    background: url("../../src/assets/img/svgs/globe.svg") no-repeat;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .dropdown-content {
    width: auto;
  }
}
</style>
