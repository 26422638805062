<template>
  <div v-if="item" class="h-100">
    <router-link :to="{name: 'Detail', params: {id: detailId}}" class="card card--featured h-100">
      <CardImage :item="item"/>
      <div class="card-body card-body-responsive">
        <div>
          <div class="card-price">
            <div class="price mb-2 mb-md-4">
              <!--TODO hardcoded currency-->
              <span>${{ item.rentPrice }}</span>/{{ $cte('property.month') }}
            </div>
            <div class="action">
              <button class="action-compare">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24.918"
                    height="20.388"
                    viewBox="0 0 24.918 20.388"
                >
                  <g id="compare" transform="translate(-1 -3)">
                    <path
                        id="Path_6876"
                        data-name="Path 6876"
                        d="M2.133,9.8h8.592l-2.6,2.6a1.133,1.133,0,1,0,1.6,1.6l4.53-4.531a1.133,1.133,0,0,0,0-1.6L9.729,3.332a1.133,1.133,0,1,0-1.6,1.6l2.6,2.6H2.133a1.133,1.133,0,1,0,0,2.265Z"
                        fill="#6b6867"
                    />
                    <path
                        id="Path_6877"
                        data-name="Path 6877"
                        d="M23.459,15.531H14.867l2.6-2.6a1.133,1.133,0,1,0-1.6-1.6l-4.53,4.531a1.133,1.133,0,0,0,0,1.6l4.531,4.531a1.133,1.133,0,1,0,1.6-1.6l-2.6-2.6h8.592a1.133,1.133,0,0,0,0-2.265Z"
                        transform="translate(1.326 1.061)"
                        fill="#6b6867"
                    />
                  </g>
                </svg>
              </button>
              <button class="action-favorite">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24.003"
                    height="21.313"
                    viewBox="0 0 24.003 21.313"
                >
                  <g
                      id="heart_3_"
                      data-name="heart (3)"
                      transform="translate(1 1)"
                  >
                    <path
                        id="Path"
                        d="M20.306,1.707a5.764,5.764,0,0,0-8.189,0L11,2.83,9.885,1.707a5.765,5.765,0,0,0-8.189,0,5.854,5.854,0,0,0,0,8.241l1.116,1.123L11,19.313l8.189-8.241,1.116-1.123a5.852,5.852,0,0,0,0-8.241Z"
                        fill="none"
                        stroke="#6b6867"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div class="card-title-wrap" :style="isCompactCard && { minHeight: '30px'}">
            <h3 v-if="!isCompactCard" class="card-title mb-1 mt-0">
              {{ item.title }}
            </h3>
            <p class="card-text">{{ item.address.address1 }}</p>
          </div>
        </div>
        <div class="card-properties">
          <div class="property">
            <i class="icon icon--beds"></i>
            {{ item.bedsNumber  == 61 ? $cte('property.6ormore') : item.bedsNumber }} {{ $cte('property.beds') }}
          </div>
          <div class="property">
            <i class="icon icon--bath"></i>
            {{item.bathroom == 31 ? $cte('property.3ormore') : item.bathroom }} {{ $cte('property.bath') }}
          </div>
          <div class="property">
            <i class="icon icon--size"></i>
            {{ item.roomSize }} sqm
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import CardImage from '@/components/card/CardImage';

export default {
  name: "CardVertical",
  components: {
    CardImage
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isCompactCard: {
      type: Boolean,
      default: false,
    },
    roomAdId: {
      type: Number
    }
  },
  methods: {
    addFavorite() {
      // TODO:  item.id

      console.log("favorite ")

      // TODO need endpoint
    },
    doCompare() {
      // TODO:  item.id

      console.log("compare ")

      // TODO need endpoint
    },
  },
  computed: {
    detailId() {
      if (this.roomAdId) {
        return this.roomAdId
      } else {
        return this.item.id;
      }
    }
  }
}
</script>

<style scoped>
.card--featured {
  border: 0;
  box-shadow: 10px 22px 44px #1b1d210f;
  border-radius: 13px;
}

.action-favorite {
  margin-left: 0;
}
</style>
