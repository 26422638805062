<template>
  <div>
    <label>{{ $cte('common.Gender') }} <span class="required">*</span></label>
    <div class="gender-box">
      <div v-for="item in genders"
           class="box"
           :class="{'active': isActive(item.id)}"
           @click="select(item.id)"
           :key="item.id">
        <img v-if="item.img" class="flat-type__img" :src="item.img" :alt="$cte(item.label)">
        <div class="gender-title">{{ $cte(item.title) }}</div>

      </div>
    </div>
  </div>
</template>

<script>

const genders = [
  {
    id: 600000,
    img: require('../../assets/img/profile/female.svg'),
    title: 'common.Female',
    alt: 'common.Female'
  },
  {
    id: 600001,
    img: require('../../assets/img/profile/male.svg'),
    title: 'common.Male',
    alt: 'common.Male'
  },
  {
    id: 600002,
    img: null,
    title: 'common.PreferNotToSay', alt: null}
]

export default {
  name: "RentGenderSelect",
  props: {
    value: {
      type: Number
    }
  },
  data() {
    return {
      genders: genders
    }
  },
  methods: {
    select(id) {
      this.$emit('input', id);
    },
    isActive(id) {
      return this.value === id;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        if (!val) {
          this.value = this.genders[2].id;
          this.$emit('input', this.value);
        }
      }
    }
  }
}
</script>

<style scoped>

.box {
  background: #fff;
  border: 1px solid transparent;
  border-radius: 13px;
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 144px;
  margin-right: 9px;
  transition: all .35s ease-in-out;
  opacity: 0.5;
}

.box.active {
  box-shadow: 0 3px 24px #00000014;
  border: 1px solid #d22673;
  opacity: 1;
}

.gender-title {
  color: #707070;
  font-size: 1rem;
  letter-spacing: -.32px;
  margin-top: 10px;
  font-weight: 600;
}

.profile .gender-box .box {
  flex-direction: column;
}
.profile .gender-box .box svg {
  margin-bottom: 15px;
}

</style>

