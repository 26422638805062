import VueJwtDecode from "vue-jwt-decode";
import router from "@/router/router";
import AppStorage from "./AppStorage";

export default class UserUtil {

  static hasRole(search) {
    let roleArray = AppStorage.getItem('user').roles;

    let searchedRole = roleArray.find(function (role) {
      return role.role === search
    });
    return !!searchedRole;
  }

  static hasPermission(search) {
    let roleArray = AppStorage.getItem('user').roles;

    roleArray.forEach(function (role) {
      role.permissions.forEach(function (permission) {
        if (permission.name === search) {
          return true;
        }
      })
    });
    return false;
  }

  static checkUserRole() {
    let token;
    try {
      token = VueJwtDecode.decode(AppStorage.getItem('token').replace("Bearer ", ""));
    } catch (e) {
      router.push("/login");
      return false;
    }

    const {roles, permissions} = this.getUserPermissions();

    if (token.role.length === roles.length && token.permission.length === permissions.length) {
      return JSON.stringify(token.role.sort()) === JSON.stringify(roles.sort()) && JSON.stringify(token.permission.sort()) === JSON.stringify(permissions.sort())
    } else {
      return false;
    }
  }

  static getUserPermissions() {
    let userPermissions = {
      permissions: [],
      roles: []
    };

    let roleArray = AppStorage.getItem('user').roles;

    roleArray.forEach(role => {
      userPermissions.roles.push(role.role);
      if (role.permissions) {
        role.permissions.forEach(permission => {
          userPermissions.permissions.push(permission.name);
        })
      }
    });
    return userPermissions;
  }

}
