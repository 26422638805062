<template>
  <div class="page--forget-process">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-md-4 login-left">
          <div class="circle-graphics"></div>
          <div class="rentible-logo">
            <router-link to="/"
              ><img
                src="../../assets/img/auth/rentible-logo.png"
                alt="Rentible.io"
            /></router-link>
          </div>
        </div>
        <div class="col-md-8 login-right">
          <PasswordSteps :currentActive="currentStep" />

          <div class="auth-box">
            <h2 class="auth-box__title">{{ $t("forgotpass.newpassTitle") }}</h2>
            <p class="auth-box__text">{{ $t("forgotpass.newpassText") }}</p>

            <ValidationObserver v-slot="{ invalid }">
              <form
                class="form--forget-pass pt-2 pt-lg-4"
                @submit.prevent="onSubmit"
              >
                <ValidationProvider
                  name="password"
                  rules="required|min:8"
                  v-slot="{ errors }"
                >
                  <div class="form-group">
                    <label class="form-label mb-0" for="l-pass">{{
                      $t("forgotpass.newpassLabel1")
                    }}</label>
                    <div class="input-wrapper">
                      <input
                        class="form-control"
                        type="password"
                        id="l-pass"
                        name="password"
                        :placeholder="$t('form.password_placeholder')"
                        autofocus
                        v-model="password"
                      />
                      <div
                        class="pass-toggle js-show-pass"
                        :data-hide="$t('forgotpass.newpassHide')"
                        :data-show="$t('forgotpass.newpassShow')"
                      >
                        {{ $t("forgotpass.newpassShow") }}
                      </div>
                    </div>
                    <small class="form-text text-error">
                      {{ errors[0] }}
                    </small>
                  </div>
                </ValidationProvider>

                <ValidationProvider
                  name="password2"
                  rules="required|min:8|confirmed:password"
                  v-slot="{ errors }"
                >
                  <div class="form-group">
                    <label class="form-label mb-0" for="l-pass2">{{
                      $t("forgotpass.newpassLabel2")
                    }}</label>
                    <div class="input-wrapper">
                      <input
                        class="form-control"
                        type="password"
                        id="l-pass2"
                        name="password2"
                        :placeholder="$t('form.password2_placeholder')"
                        v-model="password2"
                      />
                      <div
                        class="pass-toggle js-show-pass"
                        :data-hide="$t('forgotpass.newpassHide')"
                        :data-show="$t('forgotpass.newpassShow')"
                      >
                        {{ $t("forgotpass.newpassShow") }}
                      </div>
                    </div>
                    <small class="form-text text-error">
                      {{ errors[0] }}
                    </small>
                  </div>
                </ValidationProvider>

                <div class="form-submit mt-0 pt-4 mb-4">
                  <button
                    class="btn btn-primary btn--fullwidth"
                    :disabled="invalid"
                  >
                    <div v-show="loading" class="loader"></div>
                    <span v-show="!loading">{{
                      $t("forgotpass.newpassBtn")
                    }}</span>
                  </button>
                </div>
                <div class="form-action pt-5">
                  <router-link
                    to="/login"
                    class="link link--mute link--hasicon"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.898"
                      height="9.778"
                      viewBox="0 0 17.898 9.778"
                    >
                      <g
                        id="layer1"
                        transform="translate(18.155 302.012) rotate(180)"
                      >
                        <path
                          id="path9413"
                          d="M1.108,297.933H15.38l-2.674,2.674a.815.815,0,0,0,1.152,1.152l2.435-2.439,1.626-1.628a.813.813,0,0,0,0-1.146l-4.061-4.066a.815.815,0,1,0-1.152,1.152l2.68,2.674H1.065a.814.814,0,1,0,.042,1.627Z"
                          transform="translate(0 0)"
                          fill="#707070"
                        />
                      </g>
                    </svg>
                    {{ $t("button.backLogin") }}
                  </router-link>
                </div>
              </form>
            </ValidationObserver>
          </div>

          <div class="auth-footer-links">
            <p>
              <a href="#">{{ $cte("footer.menu.assistance.item04") }}</a>
              {{ $cte("common.and") }}
              <a href="#">{{ $cte("footer.menu.assistance.item07") }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./page--login -->
</template>

<script>
import {
  ValidationProvider,
  extend,
} from "vee-validate/dist/vee-validate.full.esm";
import { ValidationObserver } from "vee-validate";
import AuthService from "../../services/auth.service";

extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
});

export default {
  name: "ForgetPasswordEdit",
  data() {
    return {
      loading: false,
      message: "",
      currentStep: "step-03",
      password: "",
      password2: "",
      token: "",
      email: "",
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    togglePassword() {
      // show password
      let togglePassTrigger = document.querySelectorAll(".js-show-pass");

      togglePassTrigger.forEach(function (item) {
        item.addEventListener("click", function () {
          let prevElement = item.previousElementSibling;

          let wordHide = item.dataset.hide;
          let wordShow = item.dataset.show;

          if (prevElement.getAttribute("type") == "password") {
            prevElement.setAttribute("type", "text");
            item.innerHTML = wordHide;
          } else {
            prevElement.setAttribute("type", "password");
            item.innerHTML = wordShow;
          }
        });
      });
    },

    onSubmit() {
      this.loading = true;

      if (!this.$route.params.token) {
        this.$buefy.toast.open({
          duration: 2000,
          message: `Token is missing.`,
          position: "is-top",
          type: "is-danger",
        });
        this.loading = false;
        return false;
      }

      this.tokenAdd = this.$route.params.token;
      AuthService.getPasswordResetToken(this.tokenAdd)
        .then((res) => {
          this.email = res.data.email;

          let payload = {
            email: this.email,
            newPassword: this.password,
            newPasswordAgain: this.password2,
            token: this.tokenAdd,
          };

          AuthService.newPass(payload)
            .then(() => {
              this.loading = false;
              this.$router.replace({ name: "ForgetPasswordSuccess" });
            })
            .catch((err) => {
              throw err;
            });
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
          this.$buefy.toast.open({
            duration: 2000,
            message: this.$cte("forgotpass.resetPassError"),
            position: "is-top",
            type: "is-danger",
          });
        });
    },
  },
  mounted() {
    this.togglePassword();
  },
  created() {},
};
</script>

<style scoped>
@import "../../assets/css/auth.css";

.loader {
  background: transparent url("../../assets/img/loader/loader.gif") no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
}
</style>
