<template>
  <div class="row">
    <div class="col-md-3">
      <div class="horizontal-card-image" :style="{'background-image': 'url(' + getImage(property.images) + ')'}"></div>
    </div>
    <div class="col-md-6 item-col">
      <div class="item__title">{{ property.title }}</div>
      <div class="item__subtitle">{{ property.address.address1 }}</div>
      <div class="item__properties">
        <div class="property">
          <i class="icon icon--beds"></i>
          {{ property.bedsNumber  == 61 ? $cte('property.6ormore') : property.bedsNumber }} {{ $cte('property.beds') }}
        </div>
        <div class="property">
          <i class="icon icon--bath"></i>
          {{ property.toilet == 31 ? $cte('property.3ormore') : property.toilet }} {{ $cte('property.bath') }}
        </div>
        <div class="property">
          <i class="icon icon--size"></i>
          {{ property.roomSize }} m2
        </div>
      </div>
    </div>
    <div class="col-md-3 align-self-center">
      <div class="item-col--last">
<!--        <div class="item__price">${{ property.rentPrice + property.deposit }}</div>-->
        <SendDepositButton v-if="isDepositReady"
                           @success="successCallback"
                           :room-ad-id="roomAd.id"
                           :contract-id="item.id"
                           :landlord-wallet-address="item.landlordWalletAddress"
                           :amount="getAmount"/>
<!--        TODO uncomment after backend work-->
<!--        <ReleaseDepositButton v-if="isReleaseReady"-->
<!--                              @success="successCallback"-->
<!--                              :renter-wallet-address="item.renterWalletAddress"-->
<!--                              :landlord-wallet-address="item.landlordWalletAddress" />-->
        <button disabled class="item__btn item__btn--accept" v-if="isActive">
          {{ $cte('profile.rentals.active') }}
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import SendDepositButton from "../../components/crypto/SendDepositButton";
// import ReleaseDepositButton from "../../components/crypto/ReleaseDepositButton";
export default {
  name: "MyRental",
  components: {
    SendDepositButton,
    // ReleaseDepositButton
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      STATUS: {
        PENDING_REQUEST: "Pending Request",
        REQUEST_RECEIVED: "Request Received",
        PAY_DEPOSIT: "Pay Deposit",
        DEPOSIT_DEPOSIT: "Deposit Pending",
        START_ARBITRATION: "Start Arbitration"
      },
    };
  },
  computed: {
    property: function () {
      return this.item.roomAd.property
    },
    roomAd: function() {
      return this.item.roomAd;
    },
    isDepositReady: function() {
      return (this.item.contractStatus === this.STATUS.PAY_DEPOSIT) && !this.item.isLandlordInContract;
    },
    isActive: function() {
      return this.item.contractStatus === this.STATUS.START_ARBITRATION && !this.item.isLandlordInContract;
    },
    isReleaseReady: function() {
      return (this.item.contractStatus === this.STATUS.START_ARBITRATION) && this.item.isLandlordInContract;
    },
    getAmount: function() {
      return parseInt(this.property.deposit) + parseInt(this.property.rentPrice);
    },
  },
  methods: {
    getImage: function (images) {
      if (images.length > 0) {
        return process.env.VUE_APP_BACKEND_URL + '/' + images[0].filePath
      } else {
        return 'https://via.placeholder.com/150';
      }
    },
    errorCallback: function (error) {
      console.log(error)
    },
    successCallback: function (result) {
      console.log(result)
    }
  }
}
</script>

<style scoped>
.profile .cards .item__img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 13px 0 0 13px;
  height: 182px;
}

.horizontal-card-image {
  width: 100%;
  height: 100%;
  border-radius: 13px 0 0 13px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
