import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store/index.js';
import 'buefy/dist/buefy.css'
import {i18n} from './i18n/i18n.js';
import Util from "./helper/Utils";
import * as VueGoogleMaps from 'vue2-google-maps'
import '@/config/axios';
import '@/filters/filters';
import '@mdi/font/css/materialdesignicons.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import OpenIndicator from '@/components/forms/OpenIndicator';
import PortalVue from 'portal-vue';
import VueScreen from 'vue-screen';
import PickerLocale from "./mixins/PickerLocale";
import VCalendar from "v-calendar";

Vue.use(VCalendar, {
  componentPrefix: "vc",
  // Bootstrap breakpoints config
  screens: {
    "sm": "576px",  // (min-width: 576px)
    "md": "768px",  // (min-width: 768px)
    "lg": "992px", // (min-width: 992px)
    "xl": "1200px"  // (min-width: 1200px)
  }
});

Vue.component('v-select', vSelect);
vSelect.props.components.default = () => ({ OpenIndicator });

import Buefy from 'buefy'

Vue.config.productionTip = false

Vue.use(Buefy);
Vue.use(PortalVue);
Vue.use(VueScreen, 'bootstrap');

Vue.mixin(PickerLocale);

Vue.prototype.$cte = function (x, dataArray = []) {
  return Util.translationEngine(x, dataArray);
}

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAEZuIVeK1v7vyTDV2bYxwZ6CUxsdv4eUw',
    libraries: 'places',
  }
});

new Vue({
  router,
  i18n,
  store,
  beforeCreate() {
    this.$store.commit('auth/initializeStore');
  },
  render: h => h(App),
}).$mount('#app')
