import Util from "./Utils";

const storage = localStorage;

const PREFIX = process.env.VUE_APP_STORAGE_PREFIX;

export default class AppStorage {
  static getItem(key) {
    let item = storage.getItem(`${PREFIX}-${key}`);

    if (Util.isJson(item)) {
      return JSON.parse(item);
    } else {
      return item;
    }
  }

  static getAllItem() {
    let allKeys = Object.keys(storage);
    let data = [];

    allKeys.forEach((key) => {
      if (key.indexOf(PREFIX) > -1) {
        data.push(this.getItem(key.replace(`${PREFIX}-`, '')));
      }
    });
    return data;
  }

  static setItem(key, value) {
    storage.setItem(`${PREFIX}-${key}`, JSON.stringify(value));
  }

  static removeItem(key) {
    storage.removeItem(`${PREFIX}-${key}`);
  }

  static removeAllItem() {
    let allKeys = Object.keys(storage);

    allKeys.forEach((key) => {
      if (key.indexOf(PREFIX) > -1) {
        this.removeItem(key.replace(`${PREFIX}-`, ''));
      }
    });
  }
}
