<template>
  <div>
    <TheHeader />

    <div class="profile">
      <main class="page-content">
        <div class="banner"></div>
        <!-- ./banner -->

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 col-lg-4">
              <AvatarBox
                :person="profile"
                :active-component="currentTabComponent"
                v-show="currentTabComponent != 'ProfileMessages'"
              />

              <Inbox v-show="currentTabComponent == 'ProfileMessages'" />

              <!-- menu box -->
              <MenuBox
                v-show="
                  currentTabComponent != 'ProfileHome' &&
                  currentTabComponent != 'ProfileMessages'
                "
                @loadComponent="handleComponent"
                :menuType="menuType"
              />
            </div>

            <div class="col-md-12 col-lg-8">
              <div class="tabs">
                <ul class="nav nav-tabs" id="profileTabs" role="tablist">
                  <li class="nav-item" v-for="tab in tabs" :key="tab">
                    <button
                      :class="[
                        'nav-link',
                        { active: currentTabComponent === tab.cname },
                      ]"
                      @click="currentTabComponent = tab.cname"
                    >
                      {{ tab.title }}
                      <span
                        v-if="tab.cname === 'ProfileMessages'"
                        id="unread-message-counter"
                        class="badge badge-dark badge-pill"
                        v-show="getUnseenMessagesCount > 0"
                      >
                        {{ getUnseenMessagesCount }}
                      </span>
                    </button>
                  </li>
                </ul>
              </div>

              <component
                :is="currentTabComponent"
                :profile="profile"
              ></component>
            </div>
          </div>
        </div>
      </main>
    </div>

    <TheFooter />
  </div>
</template>

<script>
import { mapState } from "vuex";

import TheHeader from "../../components/TheHeader.vue";
import TheFooter from "../../components/TheFooter.vue";
import AvatarBox from "../../components/profile/AvatarBox.vue";
import Inbox from "../../components/profile/Inbox.vue";
import MenuBox from "./MenuBox.vue";

import ProfileHome from "./ProfileHome.vue";
import ProfileMyRentals from "./ProfileMyRentals.vue";
import ProfileMessages from "./ProfileMessages.vue";
import ProfileDashboard from "./ProfileDashboard.vue";
import ProfilePayments from "./ProfilePayments.vue";
import ProfileMyProperties from "./ProfileMyRoomAds.vue";
import ProfileRequest from "./ProfileRequest.vue";
import ProfileDisputes from "./ProfileDisputes.vue";

// import axios from "axios";
// import config from "../../config/constants.js";
import { mapGetters } from "vuex";
import ProfileService from "../../services/profile.service";

export default {
  name: "Profile",
  components: {
    TheHeader,
    TheFooter,
    AvatarBox,
    Inbox,
    MenuBox,
    ProfileHome,
    ProfileMyRentals,
    ProfileMessages,
    ProfileDashboard,
    ProfilePayments,
    ProfileMyProperties,
    ProfileRequest,
    ProfileDisputes,
  },
  data() {
    return {
      startLoading: false,
      profile: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        dateOfBirth: "",
        profileImage: "",
        gender: "",
        yourWalletAddress: "",
        // walletCode: "",
        phoneNumber: "",
        languages: ["English", "Spanish"],
        spokenLanguages: [],
        occupation: "",
        password: "",
        agencyName: "",
        categories: "",
      },
      tabs: [
        {
          title: this.$cte("profile.title"),
          cname: "ProfileHome",
        },
        {
          title: this.$cte("profile.rentalDashboard"),
          cname: "ProfileMyProperties",
        },
        {
          title: this.$cte("profile.myRentals"),
          cname: "ProfileMyRentals",
        },
        {
          title: this.$cte("profile.messagesOnProfile"),
          cname: "ProfileMessages",
        },
      ],
      currentTabComponent: "ProfileHome", // it will changes based on tab click
    };
  },
  methods: {
    handleComponent(type) {
      console.log(type);
      if (type == "Payment") {
        this.currentTabComponent = "ProfilePayments";
      } else if (type == "Properties") {
        this.currentTabComponent = "ProfileMyProperties";
      } else if (type == "Request") {
        this.currentTabComponent = "ProfileRequest";
      } else {
        this.currentTabComponent = "ProfileHome";
      }
    },
    getLocalStorageProfile() {
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      let profile = currentUser;
      profile.genderId = currentUser.gender ? currentUser.gender.id : null;
      profile.spokenLanguageIds = currentUser.spokenLanguages.map(
        (item) => item.id
      );
      profile.categoryTypeId = currentUser.categoryType
        ? currentUser.categoryType.id
        : null;
      profile.occupationId = currentUser.occupation
        ? currentUser.occupation.id
        : null;
      profile.dateOfBirth = profile.dateOfBirth
        ? new Date(profile.dateOfBirth)
        : null;
      this.profile = profile;
    },
    
    getUserId() {
      let currentUser = localStorage.getItem("currentUser");
      if (currentUser) {
        return JSON.parse(currentUser).id;
      }
      return null;
    },
    
    getProfile() {
      // let userID = this.$route.params.id;
      // get all fields
      ProfileService.get(this.getUserId()).then(res => {
        const {data} = res;
        let profile = data;
        profile.genderId = data.gender ? data.gender.id : null;
        profile.spokenLanguageIds = data.spokenLanguages.map(item => item.id);
        profile.categoryTypeId = data.categoryType ? data.categoryType.id : null;
        profile.occupationId = data.occupation ? data.occupation.id : null;
        profile.dateOfBirth = profile.dateOfBirth ? new Date(profile.dateOfBirth) : null;
        this.profile = profile;
        console.log(this.profile, 'profile')
        localStorage.setItem("currentUser", JSON.stringify(data));
      }).catch(err => {
        console.error(err);
      })
    }
  },
  computed: {
    ...mapState("auth", ["user", "status"]),
    ...mapGetters("chat", ["getUnseenMessagesCount"]),
    menuType() {
      if (this.currentTabComponent == "ProfileMyRentals") {
        return "Rental";
      } else {
        return "Dashboard";
      }
    },
  },
  mounted() {
    // loading start
    const loadingComponent = this.$buefy.loading.open({
      canCancel: true,
    });

    setTimeout(() => {
      loadingComponent.close();
    }, 1500);

    this.getLocalStorageProfile();
  },
};
</script>

<style>
@import "../../assets/css/style.css";

.profile .page-content {
  padding-top: 0;
}

.datepicker .input {
  height: 66px;
  border-radius: 13px;
  background: #f8f2ef39;
  color: #000;
  border: 1px solid #6565655c;
}

.profile .btn-primary {
  border-radius: 5px;
  margin: 20px 0;
  padding: 3px 15px;
  min-width: 120px;
  font-size: 16px;
  height: 50px;
  font-family: "BizmoBold";
}

.profile .menu a {
  color: #000;
}

.datepicker .dropdown-trigger .input {
  box-shadow: none;
  border: 1px solid #6565655c;
}

.profile .form--profile .form-input--hasIcon input {
  padding-left: 25px;
}

.loading-overlay.is-full-page {
  z-index: 9999 !important;
}

#unread-message-counter {
  position: absolute;
  top: 0;
}
</style>
