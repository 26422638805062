<template>
  <div>
    <div class="search-box__label">{{ inputLabel }}</div>

    <b-dropdown aria-role="list" multiple :mobile-modal="false">
      <template #trigger>
        <div class="search-box__field dropdown-toggle"
        >
          <input
              type="text"
              :placeholder="placeholder"
              :value="formatted"
              autocomplete="off"
          />
        </div>
      </template>

      <div class="search-dropdown search-dropdown--budget">
        <h4 class="search-dropdown__title">{{ panelTitle }}</h4>
        <div class="search-dropdown__date">
          <div class="values">
            <div>{{ getMinLabel }}</div>
            <div>{{ getMaxLabel }}</div>
          </div>

          <b-dropdown-item has-link aria-role="listitem">
            <b-field>
              <b-slider v-model="inputValue" :min="min" :max="max" :step="step" ticks
                        @change="budgetChange($event)">
              </b-slider>
            </b-field>
          </b-dropdown-item>
        </div>

        <div class="search-dropdown__footer">
          <button type="button"
                  class="btn btn-primary search-dropdown__btn close-drop" @click="closeDrop"
          >{{ $cte("search.done") }}
          </button
          >
        </div>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "SliderPanel",
  props: {
    value: {
      type: Array
    },
    valuePrefix: {
      type: String
    },
    valueSuffix: {
      type: String
    },
    placeholder: {
      type: String
    },
    inputLabel: {
      type: String
    },
    panelTitle: {
      type: String
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    step: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      minLabel: null,
      maxLabel: null,
      inputValue: [],
    }
  },
  methods: {
    budgetChange(event) {
      this.minLabel = event[0];
      this.maxLabel = event[1];
      this.$emit('input', [this.minLabel, this.maxLabel])
    },
    closeDrop() {
      if (this.value.length === 0) {
        this.inputValue = [this.min, this.max];
        this.budgetChange(this.inputValue);
      }
      document.querySelector('body').click();
    }
  },
  created() {
    if (this.value) {
      this.minLabel = this.value[0];
      this.maxLabel = this.value[1];
      this.inputValue = this.value;
    } else {
      this.inputValue = [this.min, this.max]
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.minLabel = this.value[0];
        this.maxLabel = this.value[1];
      }
    }
  },
  computed: {
    formatted() {
      if (this.maxLabel <= this.max && this.minLabel >= this.min) {
        return `${this.valuePrefix || ''}${this.minLabel}${this.valueSuffix || ''} - ${this.valuePrefix || ''}${this.maxLabel}${this.valueSuffix || ''}`;
      } else {
        return '';
      }
    },
    getMinLabel() {
      return `${this.valuePrefix || ''}${this.minLabel || this.min}${this.valueSuffix || ''}`;
    },
    getMaxLabel() {
      return `${this.valuePrefix || ''}${this.maxLabel || this.max}${this.valueSuffix || ''}`;
    }
  }
}
</script>

<style scoped>

</style>
