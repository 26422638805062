import axios from "axios";
import config from "../config/constants.js";

const API_SEARCH = config.API_SEARCH;

class SearchService {
  search(data) {
    console.log(data);
    return axios
      .get(
        API_SEARCH,
        data
      )
      .then((response) => {
        console.log(response);
        if (response) {
          return response.data;
        }
      });
  }
}

export default new SearchService();
