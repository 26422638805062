<template>
  <div class="content-wrapper">
    <div class="cards">
      <div class="cards__header">
        <h2 class="cards__title">{{ $cte("profile.myproperties.title") }}</h2>
      </div>

      <div class="cards__item--properties" v-for="item in roomAds" :key="item.id" style="min-height: 150px">
        <MyRoomAd :item="item" />
      </div>

    </div>
  </div>
</template>

<script>
import RoomAdService from "../../services/roomAd.service";
import MyRoomAd from "../profile/MyRoomAd";

export default {
  name: "MyRoomAds",
  components: {
    MyRoomAd
  },
  data() {
    return {
      roomAds: [],
    };
  },
  methods: {
    openEditModal(property) {
      console.log(property);
      this.$buefy.modal.open({});
    },

    findAll() {
      const loadingComponent = this.$buefy.loading.open({
        canCancel: true,
      });

      RoomAdService.findAll().then(response => {
        this.roomAds = response.data;
      }).catch(error => {
        console.error(error);
        this.$buefy.toast.open({
          message: this.$cte('system.error.common'),
          type: "is-danger",
        });
      });

      setTimeout(() => {
        loadingComponent.close();
      }, 1500);
    }
  },
  mounted() {
    this.findAll();
  },
  computed: {

  }
};
</script>

<style>
@import "../../assets/css/style.css";

.profile .page-content {
  padding-top: 0;
}
</style>
