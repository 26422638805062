import AuthService from '../../services/auth.service';
import AppStorage from "../../helper/AppStorage";

const initialState = {
  user: null,
  status: null
}

export const auth = {
  namespaced: true,
  state: initialState,

  getters: {
    token: (state) => {
      return state.user.token;
    },
    getUser: (state) => {
      return state.user;
    },
    getUserStatus: (state) => {
      return state.status;
    }
  },

  actions: {
    login({commit}, user) {
      return AuthService.login(user).then(
        response => {
          commit('loginSuccess', response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({commit}) {
      AuthService.logout();
      commit('logout');
      return Promise.resolve({message: "OK"});
    },
    register({commit}, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    forgotPass({commit}, data) {
      return AuthService.forgotPass(data).then(
        message => {
          commit('forgotSuccess', message);
          return Promise.resolve(message);
        },
        error => {
          commit('forgotFailure');
          return Promise.reject(error);
        }
      );
    },
    // newPass(data) {
    //   return AuthService.newPass(data).then(
    //     message => {
    //       return Promise.resolve(message);
    //     },
    //     error => {
    //       return Promise.reject(error);
    //     }
    //   );
    // },
  },

  mutations: {
    initializeStore(state) {
      // Check if the ID exists
      if (AppStorage.getItem('user')) {
        state.user = AppStorage.getItem('user');
        state.status = {loggedIn: true}
      } else {
        state.user = null;
        state.status = {loggedIn: false};
      }
    },
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.registered = true;
    },
    registerFailure(state) {
      state.status.registered = false;
    },
    forgotSuccess(state) {
      state.status.registered = false;
    },
    forgotFailure(state) {
      state.status.registered = false;
    }
  }
};
