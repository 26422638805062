export default {
  rnbContractAbiJSON: require('./rnb-contract-abi.json'),
  rnbContractAddress: "0xadec335a2e3881303a9b0203eb99de12202280df",

  stablecoinContractAbiJSON: require('./stablecoin-contract-abi.json'),
  stablecoinContractAddress: "0x55d398326f99059fF775485246999027B3197955",

  rentContractAddress: "0x6A7A7C387b1f15768C8B38C0920c6Cd3899f3811",
  rentContractAbiJSON: require('./rent-contract-abi.json')
}
