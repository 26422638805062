import AppStorage from "./AppStorage";

export default function authHeader() {
  let user = AppStorage.getItem('user');
  let userToken = AppStorage.getItem('token');

  if (user && userToken) {
    return {Authorization: userToken};
  } else {
    return {};
  }
}
