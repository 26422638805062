import axios from "axios";

const API_URL = process.env.VUE_APP_BACKEND_URL + '/contract';

export default class ContractService {

  /**
   * Initiate contract
   * @example
   * {
   *  roomAdId : 1,
   *  renteeFullName: "teszt",
   *  renteeDateOfBirth : "1987-10-19",
   *  renteeIdPassPortNumber : "passportnumber",
   *  renteeNationality : "hungary",
   *  renteeMaritalId : 1200001,
   *  renteeProfession : "diak",
   *  renteeHomeAddress : "Pécs, Valami utva 32.",
   *  renteeMobile : "+3630543543534",
   *  renteeEmail : "teeme@dgf.hu",
   *  validFrom : "2022-02-01",
   *  validTo : "2022-03-01"
   * }
   * @returns {Promise<AxiosResponse<any>>}
   */
  static save(data) {
    const url = API_URL;
    if (data.id) {
      return axios.put(url, data);
    }
    return axios.post(url, data);
  }

  static getAll() {
    const url = API_URL;
    return axios.get(url);
  }
}
