<template>
  <div id="partner-list" v-if="getIsPartnerListOpen">

    <EmptyPartnerList v-if="emptyPartnerList"/>

    <div v-else v-for="partner in partners" :key="partner.id" class="partner"
         @click="selectPartner(partner)">
      <div class="user-avatar"
           :style="getChatAvatar(partner)"></div>
      <span class="partner-email text-truncate">{{ partner.name }}</span>
      <div class="notifications text-right">
        <span class="badge badge--bg2 badge-pill ml-3" v-show="partner.unseenMessages > 0">{{
            partner.unseenMessages
          }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import EmptyPartnerList from "@/components/chat/EmptyPartnerList";
import getChatAvatar from "@/mixins/GetChatAvatar";

export default {
  name: "PartnerList",
  components: {EmptyPartnerList},
  mixins: [
    getChatAvatar
  ],
  props: {
    visiblePartnerCount: {
      type: Number,
      default: null
    },

    partners: {
      type: Array,
      default: () => []
    },

    user: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {

  },
  data() {
    return {
    }
  },
  methods: {
    selectPartner(partner) {
      this.$emit('selectPartner', partner);
      //       this.$store.commit('chat/SET_PARTNER', partner);
    }
  },
  computed: {
    ...mapGetters('chat', ['getIsPartnerListOpen']),
    emptyPartnerList() {
      return this.partners.length === 0;
    }
  }
}
</script>

<style scoped>
#partner-list {
  min-height: 441px;
  border-bottom-left-radius: var(--rentible-border-radius);
  border-bottom-right-radius: var(--rentible-border-radius);
  border: 1px solid #F0F3EF;
}

#partner-list {
  background-color: #fff;
}

.partner {
  padding: 1rem;
  border-bottom: 1px solid #F8F5F4;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.partner-email {
  width: 220px;
}

.partner:last-child {
  border-bottom: none;
}

.user-avatar {
  border: 1px solid #b9b9b9;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  margin-right: 10px;
}

</style>
