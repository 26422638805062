<template>
  <div class="page--forget-process">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-md-4 login-left">
          <div class="circle-graphics"></div>
          <div class="rentible-logo">
            <router-link to="/"
              ><img
                src="../../assets/img/auth/rentible-logo.png"
                alt="Rentible.io"
            /></router-link>
          </div>
        </div>
        <div class="col-md-8 login-right">
          <PasswordSteps :currentActive="currentStep" />

          <div class="auth-box">
            <h2 class="auth-box__title">{{ $t("forgotpass.checkTitle") }}</h2>
            <p class="auth-box__text">
              {{ $t("forgotpass.checkText") }} <strong>{{ userEmail }}</strong>
            </p>

            <div class="pt-3 pb-4">
              <a
                :href="'mailto:' + userEmail"
                targer="_blank"
                class="btn btn-primary btn--fullwidth btn--md"
                >{{ $t("forgotpass.checklabel01") }}</a
              >
            </div>

            <p class="mb-5 pt-3 pt-lg-0 form-helper">
              {{ $t("forgotpass.checklabel02") }}
              <a href="#" class="link" @click.prevent="passwordResend">{{
                $t("forgotpass.checklabel03")
              }}</a>
            </p>
            <p class="has-error" v-if="hasError">{{ errorMsg }}</p>

            <div class="form-action pt-5">
              <router-link to="/login" class="link link--mute link--hasicon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.898"
                  height="9.778"
                  viewBox="0 0 17.898 9.778"
                >
                  <g
                    id="layer1"
                    transform="translate(18.155 302.012) rotate(180)"
                  >
                    <path
                      id="path9413"
                      d="M1.108,297.933H15.38l-2.674,2.674a.815.815,0,0,0,1.152,1.152l2.435-2.439,1.626-1.628a.813.813,0,0,0,0-1.146l-4.061-4.066a.815.815,0,1,0-1.152,1.152l2.68,2.674H1.065a.814.814,0,1,0,.042,1.627Z"
                      transform="translate(0 0)"
                      fill="#707070"
                    />
                  </g>
                </svg>
                {{ $t("button.backLogin") }}
              </router-link>
            </div>
          </div>

          <!-- TODO: other static pages ??? -->
          <div class="auth-footer-links">
            <p>
              <a href="#">{{ $cte("footer.menu.assistance.item04") }}</a>
              {{ $cte("common.and") }}
              <a href="#">{{ $cte("footer.menu.assistance.item07") }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./page--login -->
</template>

<script>
import PasswordSteps from "../../components/auth/PasswordSteps.vue";

export default {
  name: "ForgetPasswordCheck",
  components: {
    PasswordSteps,
  },
  data() {
    return {
      userEmail: "",
      hasError: false,
      errorMsg: "",
      currentStep: "step-02",
    };
  },
  methods: {
    validateEmail(value) {
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        this.hasError = true;
        return "Enter a valid email";
      }

      // All is good
      this.hasError = false;

      return true;
    },
    passwordResend() {
      if (!this.validateEmail(this.userEmail)) {
        this.errorMsg = this.validateEmail(this.userEmail);
        return false;
      }

      var payload = {
        email: this.userEmail,
      };

      this.$store.dispatch("auth/forgotPass", payload).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
  created() {
    // get user information
    // this.userEmail = ;
    console.log(this.$route.query);
    this.userEmail = this.$route.query.email;
  },
};
</script>

<style scoped>
@import "../../assets/css/auth.css";

.has-error {
  color: red;
  font-weight: 700;
  font-size: 18px;
  margin: 30px 0;
}
</style>
