import RoomAdService from "../../services/roomAd.service";

const getDefaultState = () => {
  return {
    stepValues: {
      address1: '',
      title: "",
      termOfLease: [],
      bathroom: 1,
      bedsNumber: 1,
      roomSize: "",
      toilet: 1,
      size: "",
      rentPrice: "",
      rentPriceCurrency: 200000,
      availableFrom: "",
      availableUntil: "",
      crypto: "",
      deposit: "",
      depositCurrency: 200000,
      rentalType: "",
      smokingAllowed: "",
      elevator: "",
      lift: "",
      furnished: "",
      parking: "",
      airConditioning: "",
      balcony: "",
      heating: "",
      petFriendly: "",
      view: "",
      floorType: "",
      nearToMetro: "",
      distanceToPublicTransport: "",
      files: [],
      description: "",
      locationLat: 0,
      locationLng: 0
    }
  }
}

const initialState = getDefaultState();

export const property = {
  namespaced: true,
  state: initialState,

  getters: {
    getStepValues: (state) => {
      return state.stepValues;
    }
  },

  actions: {
    resetState({commit}) {
      commit('resetState')
    },
    saveStep01(context, data) {
      context.commit('setStep01', data);
      return Promise.resolve(data);
    },
    saveStep02(context, data) {
      context.commit('setStep02', data);
      return Promise.resolve(data);
    },
    saveStep03(context, data) {
      context.commit('setStep03', data);
      return Promise.resolve(data);
    },
    saveStep04(context, data) {
      context.commit('setStep04', data);
      return Promise.resolve(data);
    },
    saveAd(context) {
      let payload = {
        ...context.state.stepValues
      };
      delete payload.files;
      delete payload.description;

      RoomAdService.save(payload).then(res => {
        let filesPayload = {
          id: res.data.id,
          files: context.state.stepValues.files.map(item => item.file),
          description: context.state.stepValues.description,
          oldImageList: context.state.stepValues.oldImageList
        }
        RoomAdService.uploadImagesWithDescription(filesPayload).then(res => {
          RoomAdService.publishAd(res.data.id).then((res) => {
            context.commit('resetState');
            return Promise.resolve(res);
          })
        }).catch(err => {
          console.error(err);
          return Promise.reject(err)
        })
      }).catch(err => {
        console.error(err)
        return Promise.reject(err);
      })
    }
  },

  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setStep01(state, values) {
      Object.keys(values).forEach(key => {
        state.stepValues[key] = values[key];
      });
    },
    setStep02(state, values) {
      Object.keys(values).forEach(key => {
        state.stepValues[key] = values[key];
      });
    },
    setStep03(state, values) {
      Object.keys(values).forEach(key => {
        state.stepValues[key] = values[key];
      });
    },
    setStep04(state, values) {
      Object.keys(values).forEach(key => {
        state.stepValues[key] = values[key];
      });
    }
  },
};
