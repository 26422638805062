<template>
  <div class="stepper">
    <div class="container">
      <div class="row align-items-stretch">
        <div class="col-12 col-md-3 col-lg-3 col-xl-3 main-column-left">
          <div class="circle-graphics"></div>

          <TheLogo/>

          <StepList currentItem="step5"/>
        </div>
        <!-- ./ main-column-left -->
        <div class="col-12 col-md-9 col-lg-9 col-xl-9 main-column-right">
          <div class="content-box">
            <h2 class="content-box__title">{{ $t("steps.five.title") }}</h2>

            <div class="flat-finish">
              <div class="flat-finish__box">
                <div class="flat-finish__top">
                  <div class="row">
                    <div class="col-md-9">
                      <h2 class="flat-finish__title">{{ $t("steps.five.box-title") }}</h2>
                      <p class="flat-finish__text">
                        {{ $t("steps.five.box-text") }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <img
                          class="flat-finish__img"
                          src="../../assets/img/stepper/promote-illustration.png"
                          alt="Promote your listing"
                      />
                    </div>
                  </div>
                </div>
                <div class="flat-finish__bottom">
                  <div class="flat-finish__price">
                    {{ $t("steps.five.price") }}
                    <span>{{ packageItem.price }} {{ packageItem.priceCurrency }}</span>
                    <!--TODO: Where price comes from? -->
                  </div>

                  <button disabled @click="choosePackage" class="btn-primary btn-lg flat-finish__btn"
                  >{{ $cte('uploadSteps.SelectPackage') }}
                  </button
                  >
                </div>
              </div>
            </div>
            <!-- flat finish -->

            <div class="action-buttons">
              <button @click.prevent="goBack" class="btn-outline btn-lg">{{ $cte('uploadSteps.GoBack') }}</button>
              <button @click="onSubmit" class="btn-primary btn-lg">{{ $cte('uploadSteps.Publish') }}</button>
            </div>
          </div>
        </div>
        <!-- ./ main-column-right -->
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import TheLogo from "../../components/TheLogo.vue";
import StepList from "./StepList.vue";
// import RoomAdService from "../../services/roomAd.service";

export default {
  name: "Step5",
  components: {
    StepList,
    TheLogo
  },
  data() {
    return {
         propertyDetail: null,
      queryId: null,
      packageItem: {
        price: "15",
        priceCurrency: "RNB"
      },
    }
  },
  computed: {
    ...mapState('auth', [
      'user'
    ]),
    ...mapGetters('property', [
      'getStepValues'
    ])
  },
  methods: {
    goBack() {
      if (this.queryId) {
      this.$router.push("/new-ad-4/?id=" + this.queryId);
    } else {
      this.$router.push("/new-ad-4");
    }
    },
    choosePackage() {
      // TODO: where we go from here
    },
    onSubmit(e) {
      e.preventDefault();

      // TODO
      let userId = this.user.id;

      /**
       * Save all
       * 1. property ad model
       * 2. upload images, to property ad
       * 3. publish ad by id
       */

      this.$store.dispatch("property/saveAd").then(
          () => {
            this.$buefy.toast.open(this.$cte('system.default.Processing'));
            this.$router.push(`/profile/${userId}`);
          },
          (error) => {
            console.error(error);
            this.$buefy.toast.open({
              duration: 2500,
              message: `Something wrong!`,
              position: 'is-bottom',
              type: 'is-danger'
            });
          }
      );
    },
    getAdId() {
      this.queryId = this.$route.query.id;
    },
  },
  created() {
    // get any inforamtion from the store
    this.getAdId();
  }
}
</script>

<style scoped>

.content-box__text {
  margin-bottom: 20px;
}

button[disabled] {
  opacity: 0.3;
  user-select: none;
  cursor: not-allowed;
}

</style>
