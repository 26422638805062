<template>
  <div id="main-chat-container">
    <transition name="fade">
      <div id="chat-window" class="expanded" v-show="getChatIsOpen">
        <ChatHeader/>
        <PartnerList :partners="getPartners"
                     :user="getUser"
                     :isPartnerListOpen="getIsPartnerListOpen"
                     @selectPartner="selectPartner" />
        <ChatMessages :messages="getMessages"
                      v-if="!getIsPartnerListOpen"
                      :user="getUser">
          <BackToPartnerList :recipient="getPartner"
                             @backToPartnerList="backToPartnerList" />
        </ChatMessages>
        <NewMessageInput :user="getUser"
                         v-if="!getIsPartnerListOpen"
                         :inputDisabled="inputDisabled"
                         @sendMessage="sendMessage" />
      </div>
    </transition>
    <div id="chat-bubble" @click="toogleChatOpen">Chat
      <div id="chat-logo-container"></div>
      <span id="unread-message-counter" class="badge badge-dark badge-pill" v-show="getUnseenMessagesCount > 0">
        {{ getUnseenMessagesCount }}
      </span>
    </div>
  </div>
</template>

<script>
import ChatHeader from "./chat/ChatHeader";
import NewMessageInput from "./chat/NewMessageInput";
import ChatMessages from "./chat/ChatMessages";
import PartnerList from "./chat/PartnerList";
import BackToPartnerList from "./chat/BackToPartnerList";
import {mapGetters} from "vuex";

export default {
  name: "StandaloneChat",
  components: {
    BackToPartnerList,
    ChatHeader,
    NewMessageInput,
    PartnerList,
    ChatMessages
  },
  beforeDestroy () {
    clearInterval(this.unseenMessageCountInterval);
    this.$store.commit("chat/SET_IS_STANDALONE_CHAT", false);
  },
  data() {
    return {
      LOADER_AUTO_FALSE_MS: 5000,
      inputDisabled: false,
      isLoaderVisible: false,
      landlord: null,
      unseenMessageCountInterval: null,
      recipient: null,
      userImages: [],
      partners: [],
      userChatMessages: [],
    }
  },
  mounted() {
    this.$store.commit("chat/SET_IS_STANDALONE_CHAT", true);
    this.$store.dispatch('chat/connect');
    this.$store.dispatch('chat/getPartners');
    this.$store.dispatch('chat/getUnseenMessages')
    this.startPolling();
  },
  computed: {
    sortedUserChatMessages: function () {
      let messages = this.$store.state.messages;
      return messages.reverse();
    },
    ...mapGetters('chat', [
      'getChatIsOpen',
      'getIsPartnerListOpen',
      'getPartner',
      'getUnseenMessagesCount',
      'getMessages',
      'getPartners'
    ]),
    ...mapGetters('auth', [
      'getUser'
    ])
  },
  methods: {
    startPolling() {
      if (!this.unseenMessageCountInterval) {
        this.unseenMessageCountInterval = setInterval(() => {
          this.$store.dispatch('chat/getUnseenMessages');
        }, 10000);
      }
    },

    toogleChatOpen() {
      this.$store.dispatch('chat/setChatOpen', !this.getChatIsOpen);
    },

    selectPartner(partner) {
      this.$store.commit('chat/SET_PARTNER_LIST_OPEN', false);
      this.$store.dispatch('chat/selectPartner', partner);
    },

    backToPartnerList() {
      this.$store.dispatch('chat/getPartners');
      this.$store.commit('chat/SET_PARTNER_LIST_OPEN', true);
    },

    isLoading(loading) {
      let timeout;
      if (loading) {
        let self = this;
        self.isLoaderVisible = true;
        timeout = window.setTimeout(
          () => self.isLoaderVisible = false,
          self.LOADER_AUTO_FALSE_MS
        );
      }

      if (!loading) {
        this.isLoaderVisible = false;
        window.clearTimeout(timeout);
      }
    },

    scrollToBottom() {
      document.getElementsByClassName('message-container')[0].scrollIntoView(false);
    },
  }
}
</script>

<style>

/*TODO refact*/
:root {
  --rentible-main: #DE2279;
  --rentible-dark-purple: #202869;
  --rentible-light-purple: #cfd4ff;
  --rentible-green: #4ddea0;
  --rentible-dark-green: #43bd89;
  --rentible-border-color: #7380e9;
  --rentible-input-border-color: #6b79e7;
  --rentible-dblue: #202869;
  --rentible-grey: #7f8691;
  --rentible-border-radius: 13px;
  --rentible-border-radius-sm: 8px;
  --font-heading: 'BizmoBold';
  --font-family-sans-serif: 'Manrope', sans-serif;
}

#main-chat-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
}

#chat-bubble {
  position: relative;
  user-select: none;
  cursor: pointer;
  text-align: right;
  font-family: var(--font-heading);
  padding-right: 31px;
  float: right;
  width: 146px;
  color: #fff;
  line-height: 50px;
  height: 50px;
  box-shadow: 2px 10px 20px #00000029;
  border-radius: 50px;
  background-color: var(--rentible-main);
}

.notifications {
  width: 50px;
}

#chat-window {
  width: 357px;
  margin-bottom: 20px;
  box-shadow: 10px 22px 44px #1b1d210f;
  border-radius: var(--rentible-border-radius);
}

#unread-message-counter {
  position: absolute;
  top: -5px;
}

#chat-logo-container {
  position: absolute;
  width: 52px;
  height: 52px;
  background-image: url("../assets/img/logos/rentible_color_logo_only.png");
  border-radius: 50%;
  background-size: 88%;
  background-position: center center;
  left: -1px;
  top: -1px;
  box-shadow: 0 3px 6px #00000029;
  background-color: #fff;
}

.spinner-container {
  position: fixed;
  display: flex;
  padding-top: 90px;
  height: 455px;
  justify-content: center;
  width: 357px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


</style>
