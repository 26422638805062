import Vue from 'vue';
import VueRouter from 'vue-router';
import config from '../config/constants.js';
import * as qs from "qs";

Vue.config.silent = true;

Vue.use(VueRouter);

import Login from "../views/auth/Login.vue";
import ForgetPassword from "../views/auth/ForgetPassword.vue";
import ForgetPasswordCheck from "../views/auth/ForgetPasswordCheck.vue";
import ForgetPasswordEdit from "../views/auth/ForgetPasswordEdit.vue";
import ForgetPasswordSuccess from "../views/auth/ForgetPasswordSuccess.vue";

import Properties from "../views/Properties.vue";
// import PropertyList from "../views/PropertyList.vue";

import Home from "../views/Home.vue";
import Detail from "../views/Detail.vue";
import NotFound from "../views/NotFound.vue";
import Profile from "../views/profile/Profile.vue";
import Registration from "../views/auth/Registration.vue";
import NewAd from "../views/newad/Step1.vue";
import NewAd2 from "../views/newad/Step2.vue";
import NewAd3 from "../views/newad/Step3.vue";
import NewAd4 from "../views/newad/Step4.vue";
import NewAd5 from "../views/newad/Step5.vue";
import LeaseAgreement from "../views/LeaseAgreement.vue";

import Compare from "../views/Compare.vue";
import Staking from "../views/Staking.vue";
//import SendMoney from "../views/profile/SendMoney.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { menuActive: 'Home' }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/registration",
    name: "Registration",
    component: Registration,
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: ForgetPassword,
  },
  {
    path: "/forget-password-check",
    name: "ForgetPasswordCheck",
    component: ForgetPasswordCheck,
  },
  {
    path: "/forgottenPassword/setNewPassword/:token",
    name: "ForgetPasswordEdit",
    component: ForgetPasswordEdit,
  },
  {
    path: "/forget-password-success",
    name: "ForgetPasswordSuccess",
    component: ForgetPasswordSuccess,
  },
  {
    path: "/properties",
    name: "Properties",
    component: Properties,
    meta: { menuActive: 'Properties' }
  },
  // {
  //   path: "/property-list",
  //   name: "PropertyList",
  //   component: PropertyList,
  // },
  {
    path: "/detail/:id",
    name: "Detail",
    component: Detail,
    meta: { menuActive: 'Properties' }
  },
  {
    path: "/new-ad",
    name: "NewAd",
    component: NewAd,
  },
  {
    path: "/new-ad/:id",
    name: "NewAd",
    component: NewAd,
  },
  {
    path: "/new-ad-2",
    name: "NewAd2",
    component: NewAd2,
  },
  {
    path: "/new-ad-2/:id",
    name: "NewAd2",
    component: NewAd2,
  },
  {
    path: "/new-ad-3",
    name: "NewAd3",
    component: NewAd3,
  },
  {
    path: "/new-ad-3/:id",
    name: "NewAd3",
    component: NewAd3,
  },
  {
    path: "/new-ad-4",
    name: "NewAd4",
    component: NewAd4,
  },
  {
    path: "/new-ad-4/:id",
    name: "NewAd4",
    component: NewAd4,
  },
  {
    path: "/new-ad-5",
    name: "NewAd5",
    component: NewAd5,
  },
  {
    path: "/new-ad-5/:id",
    name: "NewAd5",
    component: NewAd5,
  },
  {
    path: "/profile/:id",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/lease-agreement",
    name: "Agreement",
    component: LeaseAgreement,
  },
  {
    path: "/compare",
    name: "Compare",
    component: Compare,
  },
  {
    path: "/staking",
    name: "Staking",
    component: Staking,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: config.API_BASE,
  routes,
  parseQuery(query) {
    // console.log("parse res", qs.parse(query));
    return qs.parse(query);
  },
  stringifyQuery(query) {
    let result = qs.stringify(query);
    // console.log("stringify res", result);
    return result ? "?" + result : "";
  },
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({x: 0, y: 0});
      }, 400);
    });
  },
});

// router.beforeEach((to, from, next) => {
//     const publicPages = ['/login', '/home', '/'];
//     const authRequired = !publicPages.includes(to.path);
//     const loggedIn = localStorage.getItem('rentibleUser');

//     console.log(loggedIn);

//     if (authRequired && !loggedIn) {
//       next('/login');
//     } else {
//       next();
//     }
// });


export default router;
