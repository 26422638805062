<template>
  <div>
    <div class="search-box__label">{{ $cte('property.propertyTypeLabel') }}</div>

    <b-dropdown aria-role="list" multiple :mobile-modal="false">
      <template #trigger>
        <div class="search-box__field dropdown-toggle">
          <input type="text"
                 name="property_type"
                 :value="getFormattedValues"
                 :placeholder="$cte('search.selectSize')">
        </div>
      </template>

      <div class="search-dropdown search-dropdown--property" aria-labelledby="dropMenu4Button">
        <!-- PROPERTY COMPONENTS -->
        <div class="row">
          <div class="col-md-7">
            <h4 class="search-dropdown__title">{{ $cte('property.propertyTypeLabel') }}</h4>
            <div class="house-type">
              <div v-for="item in rentalTypes"
                   class="type"
                   :class="{'active': isActive(item.id)}"
                   @click="!item.disabled && select(item.id)"
                   :title="item.disabled && $cte('common.ComingSoon')"
                   :key="item.id">
                <img class="flat-type__img" :src="item.img" :alt="$cte(item.label)">
                <div class="flat-type__title">{{ $cte(item.title) }}</div>
              </div>
            </div>
          </div>
          <div class="col-md-5 stepper-col">
            <h4 class="search-dropdown__title">{{ $cte('property.beds') }}</h4>
            <div class="stepper mb-4">
              <div class="stepper-step stepper-neg" @click="minusBed">-</div>
              <div class="stepper-value">{{ model.beds ? model.beds : minValue }}</div>
              <div class="stepper-step stepper-pos" @click="plusBed">+</div>
            </div>
            <h4 class="search-dropdown__title">{{ $cte('property.BathroomsLabel') }}</h4>
            <div class="stepper">
              <div class="stepper-step stepper-neg" @click="minusBath">-</div>
              <div class="stepper-value">{{ model.baths ? model.baths : minValue }}</div>
              <div class="stepper-step stepper-pos" @click="plusBath">+</div>
            </div>
          </div>
          <div class="col-12">
            <h4 class="search-dropdown__title">{{ $cte('search.propertySize(m2)') }}</h4>
            <div class="budget-slider search-dropdown__date">
              <div class="values">
                <div>{{ getMinLabel }}</div>
                <div>{{ getMaxLabel }}</div>
              </div>
              <!-- BUDGET COMPONENTS -->
              <b-field>
                <b-slider v-model="sizes" :min="min" :max="max" :step="step" ticks
                          @change="budgetChange($event)">
                </b-slider>
              </b-field>
            </div>
          </div>
        </div>

        <div class="search-dropdown__content">
          <!-- BUDGET COMPONENTS -->
        </div>
        <div class="search-dropdown__footer">
          <button type="button" class="btn btn-primary search-dropdown__btn close-drop" @click="closeDrop">{{
              $cte("search.done")
            }}
          </button>
        </div>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "PropertyTypePanel",
  props: {
    sizes: {
      type: Array,
    },
    bedrooms: {
      type: Number
    },
    bathrooms: {
      type: Number
    },
    rentalType: {
      type: [Number, String],
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    step: {
      type: Number,
      default: 10
    },
    valueSuffix: {
      type: String
    },
  },
  data() {
    return {
      rentalTypes: [
        {
          id: 100000,
          img: require('../../assets/img/search/apartment.svg'),
          title: 'property.apartment',
          alt: 'property.apartment'
        },
        {
          id: 100001,
          img: require('../../assets/img/search/bedroom.svg'),
          title: 'property.room',
          alt: 'property.room',
          disabled: true
        },
        {
          // TODO doesn't exist yet in the db!!!
          id: 100002,
          img: require('../../assets/img/search/house.svg'),
          title: 'property.house',
          alt: 'property.house',
          disabled: true
        },
      ],
      model: {
        type: null,
        beds: null,
        baths: null,
        minLabel: null,
        maxLabel: null,
      },
      formattedValues: [],
      changes: {},
      propertySizes: [],
      // minimum value of badrooms and bathrooms
      minValue: 1,
    }
  },
  methods: {
    select(id) {
      this.model.type = id;
      this.$emit('rentalType', id);
    },
    isActive(id) {
      return this.model.type === id;
    },
    budgetChange(event) {
      this.model.minLabel = event[0];
      this.model.maxLabel = event[1];
      this.$emit('sizes', [this.model.minLabel, this.model.maxLabel]);
    },
    plusBed() {
      if (!this.model.beds) {this.model.beds = this.minValue}
      this.model.beds = this.model.beds + 1;
      this.$emit('bedrooms', this.model.beds);
    },
    minusBed() {
      if (!this.model.beds) {this.model.beds = this.minValue}
      if (this.isNextDisabled(this.model.beds)) return;
      this.model.beds = this.model.beds - 1;
      this.$emit('bedrooms', this.model.beds);
    },
    plusBath() {
      if (!this.model.baths) {this.model.baths = this.minValue}
      this.model.baths = this.model.baths + 1;
      this.$emit('bathrooms', this.model.baths);
    },
    minusBath() {
      if (!this.model.baths) {this.model.baths = this.minValue}
      if (this.isNextDisabled(this.model.baths)) return;
      this.model.baths = this.model.baths - 1;
      this.$emit('bathrooms', this.model.baths);
    },
    isNextDisabled(actual) {
      return actual === this.minValue;
    },
    closeDrop() {
      this.setDefaults();
      document.querySelector('body').click();
    },
    setDefaults() {
      if (!this.model.minLabel && !this.model.maxLabel) {
        this.model.minLabel = this.min;
        this.model.maxLabel = this.max;
        this.$emit('sizes', [this.model.minLabel, this.model.maxLabel]);
      }
      this.model.beds = this.minValue;
      this.model.baths = this.minValue;
      this.$emit('bedrooms', this.model.beds);
      this.$emit('bathrooms', this.model.baths);
    }
  },
  computed: {
    getMinLabel() {
      return `${this.valuePrefix || ''}${this.model.minLabel || this.min}${this.valueSuffix || ''}`;
    },
    getMaxLabel() {
      return `${this.valuePrefix || ''}${this.model.maxLabel || this.max}${this.valueSuffix || ''}`;
    },
    getFormattedValues() {
      return this.formattedValues.filter(Boolean).join(', ');
    },
  },
  created() {
    if (this.sizes.length > 0) {
      this.model.minLabel = this.sizes[0];
      this.model.maxLabel = this.sizes[1];
      this.propertySizes = this.sizes;
    } else {
      this.propertySizes = [this.min, this.max]
    }

    if (this.bedrooms) {
      this.model.beds = this.bedrooms;
    }

    if (this.bathrooms) {
      this.model.baths = this.bathrooms;
    }

    if (this.rentalType) {
      this.model.type = this.rentalType;
    }
  },
  watch: {
    'model.minLabel': function (val) {
      if (val !== null) {
        this.$set(this.formattedValues, 1, `${this.model.minLabel}-${this.model.maxLabel}`);
      }
    },
    'model.maxLabel': function (val) {
      if (val !== null) {
        this.$set(this.formattedValues, 1, `${this.model.minLabel}-${this.model.maxLabel}`);
      }
    },
    'model.beds': function (val) {
      if (val) {
        this.$set(this.formattedValues, 2, `${this.model.beds} ${this.$cte('property.Beds')}`);
      }
    },
    'model.baths': function (val) {
      if (val) {
        this.$set(this.formattedValues, 3, `${this.model.baths} ${this.$cte('property.BathroomsLabel')}`);
      }
    },
    'model.type': function (val) {
      if (val) {
        this.$set(this.formattedValues, 0, this.$cte(this.rentalTypes.filter(type => type.id === this.model.type)[0].title));
      }
    }
  },
}
</script>

<style scoped>

.stepper-step,
.house-type {
  user-select: none;
}

</style>
