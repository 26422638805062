import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '../lang';

Vue.use(VueI18n);

export const fallbackLocale = [ 'en', 'es', 'th' ];

export const i18n = new VueI18n({
    locale: 'en', 
    fallbackLocale: fallbackLocale,
    messages,
    // TODO átmenetileg kikapcsolva
    silentTranslationWarn: true
});
