// import Vue from "vue";
import Axios from "axios";
// import store from "@/store";
import AppStorage from "@/helper/AppStorage";
import UserUtil from "@/helper/UserUtil";
import router from "@/router/router";
import '@/config/axios'

const errorHandler = (error) => {
  const {currentRoute} = router || {};
  const {path} = currentRoute || {};
  // vagy: window.location.pathname

  if (error.response.status === 403 && path !== '/login') {
    router.push({path: "/login", query: {redirect: router.currentRoute.path}});
  }

  // store.dispatch('app/changeAsyncLoading', false);

  // if (error.config.toastConfig.showToast) {
  //   Vue.prototype.$toast.danger(
  //     Vue.prototype.$cte(error.config.toastConfig.errorMessage ?? 'errors.requestError'),
  //     Vue.prototype.$cte(error.config.toastConfig.errorTitle ?? 'errors.requestError')
  //   )
  // }

  return Promise.reject({...error})
};

const successHandler = (response) => {
  // store.dispatch('app/changeAsyncLoading', false);

  // if (response.config.toastConfig.showToast) {
  //   Vue.prototype.$toast.success(
  //     Vue.prototype.$cte(response.config.toastConfig.successMessage ?? 'errors.requestSuccess'),
  //     Vue.prototype.$cte(response.config.toastConfig.successTitle ?? 'errors.requestSuccess')
  //   )
  // }
  return response
};

Axios.defaults.params = {};

Axios.interceptors.request.use(
  (request) => {

    const token = AppStorage.getItem('token');

    if (token) {
      if (!!AppStorage.getItem('user') && !UserUtil.checkUserRole()) {
        router.push("/login");
      }
      request.headers.authorization = token;
    }

    if (!request.params) {
      request.params = {};
    }

    request.params['timestamp'] = new Date().valueOf();

    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);
