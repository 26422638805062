<template>
  <div class="stepper">
    <div class="container">
      <div class="row align-items-stretch">
        <div class="col-12 col-md-3 col-lg-3 col-xl-3 main-column-left">
          <div class="circle-graphics"></div>
          <TheLogo/>

          <StepList currentItem="step4"/>
        </div>
        <!-- ./ main-column-left -->
        <div class="col-12 col-md-9 col-lg-9 col-xl-9 main-column-right--v2">
          <div class="content-box">
            <h2 class="content-box__title">{{ $cte('uploadSteps.step4') }}</h2>
            <p class="content-box__text">{{ $cte('uploadSteps.step4Subtitle') }}</p>

            <form action="#" class="flat-form--photos">
              <ValidationObserver v-slot="{ invalid }">
                <div class="flat-photos">

                  <div class="upload-wrapper">
                    <div class="upload-box">
                      <vue-dropzone ref="photosVueDropzone" id="dropzone" :options="dropzoneOptions"
                                    :useCustomSlot="true" v-on:vdropzone-thumbnail="thumbnail"
                                    @vdropzone-total-upload-progress="progress">
                        <div class="upload-box__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="28"
                            viewBox="0 0 22 28"
                          >
                            <g
                              id="Layer_8"
                              data-name="Layer 8"
                              transform="translate(-5 -2)"
                            >
                              <path
                                id="Path_6921"
                                data-name="Path 6921"
                                d="M20.71,14.29a1,1,0,1,1-1.42,1.42L17,13.41V21a1,1,0,0,1-2,0V13.41l-2.29,2.3a1,1,0,0,1-1.42-1.42l4-4a1,1,0,0,1,1.42,0ZM27,9.72V27a3,3,0,0,1-3,3H8a3,3,0,0,1-3-3V5A3,3,0,0,1,8,2H20.06a3,3,0,0,1,2.31,1.08L26.3,7.8A3,3,0,0,1,27,9.72ZM21,7a1,1,0,0,0,1,1h1.86L21,4.56Zm4,3H22a3,3,0,0,1-3-3V4H8A1,1,0,0,0,7,5V27a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1Z"
                                fill="#707070"
                              />
                            </g>
                          </svg>
                        </div>
                        <span>{{ $cte('dropZone.DropFilesHere') }}</span><br>
                        <small>{{ $cte('dropZone.or') }}</small><br>
                        <button type="button" class="upload-box__btn">
                          {{ $cte('dropZone.Browse') }}
                        </button>
                      </vue-dropzone>

                      <div class="upload-box__helper">{{ $cte('dropZone.MaximumSize') }}: 10mb</div>
                    </div>

                    <div class="upload-items">
                      <UploadPhoto v-for="file in uploadFiles" :key="file.src" :file="file" @deleteClicked="onImageDelete(file.id)"/>
                    </div>
                    <!-- /. upload-items -->
                  </div>

                </div>

                <div class="content-group">
                  <h2 class="content-group__title">{{ $cte('uploadSteps.step4Description') }}</h2>
                  <p class="content-group__text">
                    {{ $cte('uploadSteps.step4DescriptionSubtitle') }}
                  </p>

                  <div class="form-group pt-2">
                    <label for="description">{{ $cte('property.PleaseDescribeYourFlatLabel') }}</label>
                    <ValidationProvider
                      name="desctiption"
                      rules="required"
                      v-slot="{ errors }">
                                            <textarea
                                              class="form-control"
                                              name="description"
                                              id="description"
                                              v-model="model.description"
                                            ></textarea>
                      <small class="form-text text-error">
                        {{ errors[0] }}
                      </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="action-buttons">
                  <button @click.prevent="goBack" class="btn-outline btn-lg">{{ $cte('uploadSteps.GoBack') }}</button>
                  <button class="btn-primary btn-lg" @click="onSubmit" :disabled="invalid">{{ $cte('uploadSteps.NextStep') }}</button>
                </div>
              </ValidationObserver>
            </form>
          </div>
        </div>
        <!-- ./ main-column-right -->
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {ValidationProvider} from "vee-validate/dist/vee-validate.full.esm";
import {ValidationObserver} from "vee-validate";
import authHeader from "../../helper/auth-header.js";
import UploadPhoto from "../../components/UploadPhoto";
import RoomAdService from "@/services/roomAd.service";

//import axios from "axios";
//import config from "../../config/constants.js";

import TheLogo from "../../components/TheLogo.vue";
import StepList from "./StepList.vue";

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {i18n} from "../../i18n/i18n";

//import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

export default {
  name: "Step4",
  components: {
    StepList,
    TheLogo,
    ValidationProvider,
    ValidationObserver,
    vueDropzone: vue2Dropzone,
    UploadPhoto
    // simplebar,
  },
  data() {
    return {
      propertyDetail: null,
      queryId: null,
      oldImageList: null,
      dropzoneOptions: {
        url: 'https://httpbin.org/post', // TODO: get URL for images upload
        thumbnailWidth: 66,
        autoProcessQueue: false,
        thumbnailHeight: 40,
        maxFilesize: 10,
        headers: authHeader(), // sent auth header
        previewTemplate: this.template(),
        accept: (file, done) => {
          if (!file.type.includes("image/jpeg") && !file.type.includes("image/png") && !file.type.includes("image/jpg")) {
            this.$buefy.toast.open({
              duration: 3000,
              message: i18n.t('uploadSteps.imageMimeTypeError'),
              type: 'is-danger'
            });
          } else {
            done();
          }
        }
      },
      newFile: {
        src: "",
        fileName: ""
      },
      model: {
        description: "",
        files: []
      }
    }
  },
  computed: {
    ...mapState('auth', [
      'user'
    ]),
    ...mapGetters('property', ['getStepValues']),
    uploadFiles() {
      return this.model.files;
    }
  },
  methods: {
    template: function () {
      return `<div class="dz-preview dz-file-preview">
                    <div class="dz-image">
                        <div data-dz-thumbnail-bg></div>
                    </div>
                    <div class="dz-details">
                        <div class="dz-size"><span data-dz-size></span></div>
                        <div class="dz-filename"><span data-dz-name></span></div>
                    </div>
                    <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                    <div class="dz-error-message"><span data-dz-errormessage></span></div>
                    <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                    <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                </div>
                `;
    },
    thumbnail: function (file, dataUrl) {
      if (file.previewElement) {
        this.newFile = { ...this.newFile };
        this.newFile.src = dataUrl;
        this.newFile.fileName = file.upload.filename;
        this.newFile.file = file;
        this.model.files.push(this.newFile);
      }
    },
    setModelFromStore() {
       if (!this.queryId){
        this.model = {...this.getStepValues};
      }
    },
    goBack() {
      if (this.queryId) {
      this.$router.push("/new-ad-3/?id=" + this.queryId);
    } else {
      this.$router.push("/new-ad-3");
    }
    },
    progress(totaluploadprogress) {
      console.log(totaluploadprogress);
      //document.querySelector(".upload-items__percent").innerText = parseInt(totaluploadprogress) + "%";
    },
    onImageDelete(id) {
      console.log(this.model.files, "filesBefore");
      RoomAdService.deleteImageById(id).then(() => {
        this.getAdId();
        console.log(this.model.files, "filesAfter")
      });

    },
    onSubmit(e) {
      e.preventDefault();

      // get all upload files
      // let getFiles = this.$refs.photosVueDropzone.getAcceptedFiles();
      let getFiles = this.model.files;

      if (getFiles.length == 0) {

        this.$buefy.toast.open({
          duration: 3000,
          message: this.$cte('uploadSteps.imageRequiredError'),
          type: 'is-danger'
        });
        return false;
      }

      let payload = {
        description: this.model.description,
        files: this.model.files,
        oldImageList: this.oldImageList
      };

      // upload images to server
      // this.$refs.photosVueDropzone.processQueue();

      this.$store.dispatch("property/saveStep04", payload).then(
        (response) => {
          console.log(response);
          if (this.queryId) {
              this.$router.push("/new-ad-5/?id=" + this.queryId);
            } else {
              this.$router.push("/new-ad-5/");
            }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    getAdId() {
      this.queryId = this.$route.query.id;
      if (this.queryId) {
        this.getAdDetails();
      }
      else {
        this.setModelFromStore();
      }
    },
    getAdDetails() {
      RoomAdService.findById(this.queryId).then(
        (response) => {
          this.propertyDetail = response.data;
          this.oldImageList = response.data.property.images.map(a => a.id);
          this.addPropertyDataToModel();
          this.setModelFromStore();
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    addPropertyDataToModel() {
      let self = this;
      Object.keys(self.model).forEach(function(key)
      {
        self.model[key] = self.propertyDetail.property[key];
      });
      this.model.files = this.propertyDetail.property.images;
    }
  },
  created() {
    this.getAdId();
  }
}
</script>

<style>

.content-box__text {
  margin-bottom: 20px;
}

.toast {
  flex-basis: auto;
}

.vue-dropzone {
  background: transparent;
  border: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.vue-dropzone:hover {
  background: none;
}

.stepper .flat-photos .upload-box {
  position: relative;
  height: 250px;
}

.content-group__text {
  margin-bottom: 15px;
}

.dropzone .dz-message {
  margin-top: 30px;
}

.dz-preview {
  display: none !important;
}

.dropzone.dz-started .dz-message {
  display: block !important;
}

.upload-items__filename {
  white-space: nowrap;
}

</style>
