<template>
  <div class="single">
    <TheHeader />

    <main class="page-content">
      <div class="content-wrapper">
        <div class="single-content">
          <div class="single-content__top" v-if="property.images.length > 0">
            <div class="container">
              <Gallery :images="property.images" />
            </div>
          </div>

          <div class="entry">
            <div class="container">
              <div class="entry-header">
                <div class="entry-header__left">
                  <div class="header-type">
                    {{ $cte(property.rentalType.text) }}
                  </div>
                  <div class="header-title-wrapper">
                    <h1 class="header-title">{{ property.title }}</h1>
                    <div class="actions">
                      <b-tooltip label="Compare" position="is-top">
                        <button disabled class="actions__compare" @click="goToCompare">
                          <img src="../assets/img/svgs/compare.svg" />
                        </button>
                      </b-tooltip>
                      <b-tooltip label="Favorite" position="is-top">
                        <button disabled class="actions__favorite" @click="addFavorite">
                          <img src="../assets/img/svgs/heart.svg" />
                        </button>
                      </b-tooltip>
                    </div>
                  </div>
                  <div class="header-address">
                    {{ property.address.address1 }}
                  </div>
                </div>

                <div class="entry-header__right"></div>
              </div>

              <div class="entry-content">
                <div class="row">
                  <div class="col-md-8 entry-content-left">
                    <div class="entry-intro">
                      <p>
                        {{ property.description ? property.description : "" }}
                      </p>
                    </div>

                    <div
                      class="entry-group"
                      v-if="property.rentalType.id === 100001"
                    >
                      <h2>{{ $cte('property.RoomDetails') }</h2>
                      <ul>
                        <li>
                          <div class="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13.394"
                              height="13.394"
                              viewBox="0 0 13.394 13.394"
                            >
                              <path
                                id="Shape"
                                d="M2.093,13.394A2.1,2.1,0,0,1,0,11.3V2.093A2.1,2.1,0,0,1,2.093,0H11.3a2.1,2.1,0,0,1,2.093,2.093V8.685a.523.523,0,0,1-1.047,0V2.093A1.048,1.048,0,0,0,11.3,1.046H2.093A1.048,1.048,0,0,0,1.046,2.093V11.3a1.048,1.048,0,0,0,1.047,1.046H11.3A1.047,1.047,0,0,0,12.347,11.3a.523.523,0,0,1,1.047,0A2.1,2.1,0,0,1,11.3,13.394Zm.388-2.508a.524.524,0,0,1,0-.74L4.649,7.979H3.165a.523.523,0,1,1,0-1.046H5.127A1.309,1.309,0,0,1,6.435,8.24V10.2a.523.523,0,1,1-1.046,0V8.719L3.222,10.886a.524.524,0,0,1-.74,0Zm5.811-4.5A1.309,1.309,0,0,1,6.985,5.075V3.113a.523.523,0,0,1,1.047,0V4.6L10.2,2.429a.523.523,0,1,1,.74.74L8.771,5.337h1.484a.523.523,0,0,1,0,1.046Z"
                                fill="#fff"
                              />
                            </svg>
                          </div>
                          <div>
                            <span>{{ property.roomSize }}</span> m2
                          </div>
                        </li>
                        <li>
                          <div class="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.394"
                              height="13.32"
                              viewBox="0 0 16.394 13.32"
                            >
                              <path
                                id="Shape"
                                d="M13.832,13.32V12.139H2.562V13.32H1.281V12.139H0V8.53A1.947,1.947,0,0,1,1.921,6.562V1.968A1.947,1.947,0,0,1,3.842,0H12.52a1.947,1.947,0,0,1,1.921,1.968V6.562h.032A1.947,1.947,0,0,1,16.394,8.53v3.609H15.113V13.32Z"
                                fill="#fff"
                              />
                            </svg>
                          </div>
                          <div>
                            <span>{{ property.bedsNumber }}</span>
                            {{ $cte("property.Beds") }} Beds
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div class="entry-group" v-else>
                      <h2>{{ $cte("property.ApartmentDetails") }}</h2>
                      <ul>
                        <li>
                          <div class="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13.394"
                              height="13.394"
                              viewBox="0 0 13.394 13.394"
                            >
                              <path
                                id="Shape"
                                d="M2.093,13.394A2.1,2.1,0,0,1,0,11.3V2.093A2.1,2.1,0,0,1,2.093,0H11.3a2.1,2.1,0,0,1,2.093,2.093V8.685a.523.523,0,0,1-1.047,0V2.093A1.048,1.048,0,0,0,11.3,1.046H2.093A1.048,1.048,0,0,0,1.046,2.093V11.3a1.048,1.048,0,0,0,1.047,1.046H11.3A1.047,1.047,0,0,0,12.347,11.3a.523.523,0,0,1,1.047,0A2.1,2.1,0,0,1,11.3,13.394Zm.388-2.508a.524.524,0,0,1,0-.74L4.649,7.979H3.165a.523.523,0,1,1,0-1.046H5.127A1.309,1.309,0,0,1,6.435,8.24V10.2a.523.523,0,1,1-1.046,0V8.719L3.222,10.886a.524.524,0,0,1-.74,0Zm5.811-4.5A1.309,1.309,0,0,1,6.985,5.075V3.113a.523.523,0,0,1,1.047,0V4.6L10.2,2.429a.523.523,0,1,1,.74.74L8.771,5.337h1.484a.523.523,0,0,1,0,1.046Z"
                                fill="#fff"
                              />
                            </svg>
                          </div>
                          <div>
                            <span>{{ property.roomSize }}</span> m2
                          </div>
                        </li>
                        <li>
                          <div class="icon">
                            <img src="../assets/img/single/stairs.svg" />
                          </div>
                          <div>
                            <span>{{
                              property.floorType
                                ? $cte(property.floorType.text)
                                : ""
                            }}</span>
                          </div>
                        </li>
                        <li>
                          <div class="icon">
                            <img src="../assets/img/single/toilet.svg" />
                          </div>
                          <div>
                            <span>{{
                              property.toilet == 31
                                ? $cte("property.3ormore")
                                : property.toilet
                            }}</span>
                            {{ $cte("property.Toilet") }}
                          </div>
                        </li>
                        <!--                        TODO ez miez?-->
                        <!--                        <li>-->
                        <!--                          <div class="icon">-->
                        <!--                            <svg-->
                        <!--                                xmlns="http://www.w3.org/2000/svg"-->
                        <!--                                width="15.793"-->
                        <!--                                height="15.81"-->
                        <!--                                viewBox="0 0 15.793 15.81"-->
                        <!--                            >-->
                        <!--                              <g-->
                        <!--                                  id="view_from_the_window_you_can_draw_rocks_from_the_window_"-->
                        <!--                                  data-name="view from the window (you can draw rocks from the window)"-->
                        <!--                                  transform="translate(-3 -2.94)"-->
                        <!--                              >-->
                        <!--                                <path-->
                        <!--                                    id="Path_6960"-->
                        <!--                                    data-name="Path 6960"-->
                        <!--                                    d="M18.793,4.046c0-.487,0-.705-.417-.978-.191-.191-.128-.112-14.56-.112A.817.817,0,0,0,3,3.774v14.16a.817.817,0,0,0,.817.817h14.16a.817.817,0,0,0,.817-.817ZM3.545,3.774A.272.272,0,0,1,3.817,3.5H7.9v.73a5.835,5.835,0,0,1-3.368,5.28l-.988.395Zm.272,14.432a.272.272,0,0,1-.272-.272V10.494a7.807,7.807,0,0,0,2.81-1.547L7.9,18.205Zm9.53,0c-5.345,0-4.9.025-4.9-.044L8.236,16.9a.577.577,0,0,1,.3.152,1.054,1.054,0,0,0,1.438,0,.512.512,0,0,1,.381-.161.52.52,0,0,1,.378.161,1.054,1.054,0,0,0,1.438,0c.585-.468.711.188,1.329.272a5.686,5.686,0,0,0-.15.877Zm.229-1.419c-.324-.033-.441-.436-1.037-.436s-.716.441-1.089.441-.463-.441-1.089-.441-.711.441-1.089.441-.466-.441-1.089-.441c-.027,0,0,.112-.237-1.348.259.12.441.466,1.035.466.735,0,.8-.517,1.3-.517s.534.531,1.266.531.817-.517,1.3-.517c.425,0,.487.338.967.474Zm4.673,1.146a.272.272,0,0,1-.272.272H13.892c.817-4.9,1.266-7.589,1.547-9.258A7.668,7.668,0,0,0,18.246,10.5Zm0-8.027-.975-.389a5.811,5.811,0,0,1-2.786-2.723.273.273,0,1,0-.49.24,6.263,6.263,0,0,0,.983,1.432l-1.089,6.415c-.272-.117-.449-.474-1.057-.474-.732,0-.817.517-1.3.517s-.561-.517-1.3-.517-.792.531-1.291.531c-.455,0-.545-.433-1.133-.506L6.826,8.465a6.372,6.372,0,0,0,1.62-4.234V3.5h4.9V4.61a.273.273,0,0,0,.545-.038V3.5h4.084a.272.272,0,0,1,.272.272Z"-->
                        <!--                                    fill="#fff"-->
                        <!--                                />-->
                        <!--                                <path-->
                        <!--                                    id="Path_6961"-->
                        <!--                                    data-name="Path 6961"-->
                        <!--                                    d="M42.163,12.134a.272.272,0,0,0-.528.131c.06.248.093.506.346.506a.272.272,0,0,0,.259-.359c-.03-.09-.054-.185-.076-.278Z"-->
                        <!--                                    transform="translate(-28.11 -6.547)"-->
                        <!--                                    fill="#fff"-->
                        <!--                                />-->
                        <!--                                <path-->
                        <!--                                    id="Path_6962"-->
                        <!--                                    data-name="Path 6962"-->
                        <!--                                    d="M24.906,35a1.089,1.089,0,0,0-.817.376A1.089,1.089,0,0,0,23.272,35a.272.272,0,0,0,0,.545.545.545,0,0,1,.545.545.272.272,0,0,0,.545,0,.545.545,0,0,1,.545-.545.272.272,0,1,0,0-.545Z"-->
                        <!--                                    transform="translate(-14.554 -23.33)"-->
                        <!--                                    fill="#fff"-->
                        <!--                                />-->
                        <!--                                <path-->
                        <!--                                    id="Path_6963"-->
                        <!--                                    data-name="Path 6963"-->
                        <!--                                    d="M32.906,28a1.089,1.089,0,0,0-.817.376A1.089,1.089,0,0,0,31.272,28a.272.272,0,0,0,0,.545.545.545,0,0,1,.545.545.272.272,0,0,0,.545,0,.545.545,0,0,1,.545-.545.272.272,0,1,0,0-.545Z"-->
                        <!--                                    transform="translate(-20.376 -18.236)"-->
                        <!--                                    fill="#fff"-->
                        <!--                                />-->
                        <!--                                <path-->
                        <!--                                    id="Path_6964"-->
                        <!--                                    data-name="Path 6964"-->
                        <!--                                    d="M28.141,15.625a1.089,1.089,0,0,0-2.037.218.953.953,0,1,0-.169,1.89h2.587a1.089,1.089,0,1,0-.381-2.108Zm.381,1.563H26.011a.411.411,0,1,1-.076-.817c.234,0,.272.166.449.144a.272.272,0,0,0,.231-.286.553.553,0,1,1,1.089-.172.321.321,0,0,0,.049.131c.223.343.411-.09.768-.09a.545.545,0,0,1,0,1.089Z"-->
                        <!--                                    transform="translate(-15.991 -8.785)"-->
                        <!--                                    fill="#fff"-->
                        <!--                                />-->
                        <!--                              </g>-->
                        <!--                            </svg>-->
                        <!--                          </div>-->
                        <!--                          -->
                        <!--                          <div><strong>Street</strong> view</div>-->
                        <!--                        </li>-->
                        <li>
                          <div class="icon">
                            <img src="../assets/img/single/smoking.svg" />
                          </div>
                          <div
                            v-html="
                              property.smokingAllowed
                                ? $cte('property.smokingAllowed')
                                : $cte('property.smokingNotAllowed')
                            "
                          ></div>
                        </li>

                        <li>
                          <div class="icon">
                  <img class="ml-1" src="../assets/img/single/elevator.svg" />
                          </div>
                          <div
                            v-html="
                              property.elevator
                                ? $cte('property.elevator')
                                : $cte('property.noElevator')
                            "
                          ></div>
                        </li>
                        <li>
                          <div class="icon">
                            <img src="../assets/img/single/air-conditioner.svg"/>
                          </div>
                          <div
                            v-html="
                              property.airConditioning
                                ? $cte('property.airConditioner')
                                : $cte('property.noAirConditioner')
                            "
                          ></div>
                        </li>
                        <li>
                          <div class="icon">
                            <img src="../assets/img/svgs/heating.svg"/>
                          </div>
                          <div
                            v-html="
                              property.Heating
                                ? $cte('property.Heating')
                                : $cte('property.noHeating')
                            "
                          ></div>
                        </li>
                        <li>
                          <div class="icon">
                            <img src="../assets/img/svgs/furnsihed.svg"/>
                          </div>
                          <div
                            v-html="
                              property.Furnished
                                ? $cte('property.Furnished')
                                : $cte('property.Unfurnished')
                            "
                          ></div>
                        </li>
                        <li>
                          <div class="icon">
                            <img src="../assets/img/svgs/facade.svg"/>
                          </div>
                          <div
                            v-html="
                              property.BalconyTerrace
                                ? $cte('property.BalconyTerrace')
                                : $cte('property.noBalconyTerrace')
                            "
                          ></div>
                        </li>
                        <li>
                          <div class="icon">
                            <img src="../assets/img/svgs/dog.svg"/>
                          </div>
                          <div
                            v-html="
                              property.petFriendly
                                ? $cte('property.petFriendly')
                                : $cte('property.noPetFriendly')
                            "
                          ></div>
                        </li>
                      </ul>
                    </div>

                    <div class="entry-group entry-group--lease">
                      <h2>{{ $cte("property.LeaseTerms") }}</h2>
                      <div class="row mb-3">
                        <div class="col-md-6">
                          <div class="single-prop">
                            <div class="prop-label">
                              {{ $cte("property.Deposit") }}
                            </div>
                            <div
                              class="prop-value prop-value--lg prop-value--darker"
                            >
                              ${{ property.deposit }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="single-prop">
                            <div class="prop-label">
                              {{ $cte("property.AvailableFrom") }}
                            </div>
                            <div
                              class="prop-value prop-value--lg prop-value--darker"
                            >
                              {{ availableFrom | dateISO }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="single-prop">
                        <div class="prop-label">
                          {{ $cte("property.LeasePeriod") }}
                        </div>
                        <div class="prop-value">
                          <span
                            v-for="item in property.termOfLease"
                            :key="item.id"
                          >
                            {{ $cte(item.text) }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <PropertyContractPanel
                        class="d-md-block d-lg-none"
                        :property="property"
                        :roomAdId="roomAdId"
                    />

                    <!-- TODO: Neighborhood where is that ???? -->
                    <!-- <div class="entry-group">
                      <h2>{{ $cte("property.Neighborhood") }}</h2>
                      <div class="row">
                        <div class="col-md-3">
                          <div class="single-prop">
                            <div class="prop-label">
                              {{
                                $cte(
                                  "property.DistanceToClosestPublicTransport"
                                )
                              }}
                            </div>
                            <div class="prop-value">
                              {{ property.distanceToPublicTransport }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="single-prop">
                            <div class="prop-label">
                              {{ $cte("property.NearToMetroTram") }}
                            </div>
                            <div class="prop-value">
                              {{ property.nearToMetro }}
                            </div>
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="single-prop">
                            <div class="prop-label">{{ $cte('property.NearToUniversity') }}</div>
                            <div class="prop-value">{{ '' | boolean }}</div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="single-prop">
                            <div class="prop-label">{{ $cte('property.GreenPark')}}</div>
                            <div class="prop-value">{{ '' | boolean }}</div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="single-prop">
                            <div class="prop-label">{{ $cte('property.FitnessCenter') }}</div>
                            <div class="prop-value">{{ '' | boolean }}</div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="single-prop">
                            <div class="prop-label">{{ $cte('property.Pool') }}</div>
                            <div class="prop-value">{{ '' | boolean }}</div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="single-prop">
                            <div class="prop-label">{{ $cte('property.Library') }}</div>
                            <div class="prop-value">{{ '' | boolean }}</div>
                          </div>
                        </div> 

                        <div class="col-md-3">
                          <div class="single-prop">
                            <div class="prop-label">
                              {{ $cte("property.Parking") }}
                            </div>
                            <div class="prop-value">
                              {{ property.parking | boolean }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <div class="entry-group entry-group--publisher">
                      <h2>{{ $cte("property.AboutThePublisher") }}</h2>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="publisher-avatar">
                            <img :src="getProfileImage" alt="profile-avatar" />
                            <div class="publisher-name">
                              {{ landlord.firstName }}<br />
                              {{ landlord.lastName }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="phone">
                            <div class="label">
                              {{ $cte("property.Phone") }}
                            </div>
                            <div class="value">{{ landlord.phoneNumber }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="single-prop">
                            <div class="prop-label">
                              {{ $cte("property.Occupation") }}
                            </div>
                            <div class="prop-value">
                              {{ landlord.occupation | codestore }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="single-prop">
                            <div class="prop-label">
                              {{ $cte("property.SpokenLanguages") }}
                            </div>
                            <div class="prop-value">
                              {{ spokenLanguages }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="entry-group entry-group--location">
                      <h2>{{ $cte("property.Location") }}</h2>
                      <div class="map-wrapper">
                        <MapLocation
                          :items="[this.property]"
                          :markerClickable="false"
                        />
                      </div>
                    </div>

                    <!--                    <article class="entry-artice">-->
                    <!--                      <h2>House Rules</h2>-->
                    <!--                      <p>-->
                    <!--                        {{ property.description ? property.description : '' }}-->
                    <!--                      </p>-->
                    <!--                    </article>-->
                  </div>
                  <div class="col-md-4 entry-content-right">
                    <aside class="sidebar">
                      <PropertyContractPanel
                        class="d-none d-md-none d-lg-block"
                        :property="property"
                        :roomAdId="roomAdId"
                      />

                      <div class="sidebar__box ads">
                        <h4>{{ $cte("property.CtaTitle") }}</h4>
                        <div class="subtitle">
                          <strong>
                            {{ $cte("property.CtaSubtitle") }}
                          </strong>
                        </div>
                        <p>
                          {{ $cte("property.CtaDescription") }}
                        </p>
                        <img
                          src="../assets/img/single/illustration.png"
                          alt="Buy RNB"
                        />
                        <a
                          href="https://rnb.rentible.io/"
                          class="btn-outline"
                          >{{ $cte("property.CtaButton") }}</a
                        >
                      </div>

                      <div class="sidebar__box more-matches" v-if="matches.length">
                        <h4 class="box-title">More Matches</h4>
                        <CardVertical v-for="match in matches" :key="match.id" :item="match"/>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <StandaloneChat v-if="getUser" />

    <TheFooter />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import TheHeader from "../components/TheHeader.vue";
import TheFooter from "../components/TheFooter.vue";
import Gallery from "../components/Gallery.vue";
import MapLocation from "../components/MapLocation.vue";

import StandaloneChat from "../components/StandaloneChat";
import CardVertical from "../components/card/CardVertical";
import PropertyContractPanel from '@/components/crypto/PropertyContractPanel';
import RoomAdService from "../services/roomAd.service";

export default {
  name: "Detail",
  data() {
    return {
      defaultImage: require("../assets/img/avatar.png"),
      roomAdId: null,
      property: {
        id: 0,
        ownerId: 0,
        rentalType: {},
        rentPrice: {},
        rentPriceCurrency: {},
        deposit: {},
        depositCurrency: {},
        roomSize: {},
        bedsNumber: {},
        toilet: {},
        termOfLease: [],
        floorType: {},
        nearToMetro: "",
        distanceToPublicTransport: "",
        surrounding: "",
        elevator: "",
        locationLat: "",
        locationLng: "",
        address: "",
        title: "",
        description: "",
        petFriendly: false,
        smokingAllowed: false,
        images: [],
      },
      matches: []
    }
  },
  components: {
    CardVertical,
    PropertyContractPanel,
    StandaloneChat,
    TheHeader,
    TheFooter,
    Gallery,
    MapLocation
  },
  computed: {
    ...mapState("auth", ["user", "status"]),

    ...mapGetters("auth", ["getUser"]),

    landlord() {
      return this.property.ownerUser;
    },
    spokenLanguages() {
      return this.landlord.spokenLanguages
        .map((item) => this.$cte(item.text))
        .join(", ");
    },
    getProfileImage() {
      if (this.landlord && this.landlord.avatarImagePath) {
        return (
          process.env.VUE_APP_BACKEND_URL + "/" + this.landlord.avatarImagePath
        );
      } else {
        return this.defaultImage;
      }
    },
  },
  methods: {
    goToCompare() {
      this.$router.push("/compare");
    },
    addFavorite() {
      this.$buefy.notification.open({
        message: "Need Api Connection!",
        type: "is-warning",
      });
    },
    getMoreMatches() {
      RoomAdService.getMoreMatches(this.roomAdId).then(response => {
        const {rows} = response.data || [];
        this.matches = rows.map(item => {
          return item.property;
        });
      }).catch((error) => console.error(error));
    },
    getDetails() {
      RoomAdService.findById(this.roomAdId).then(res => {
        this.property = res.data.property;
        this.availableFrom = res.data.availableFrom;
      }).catch((error) => console.error(error));
    }

  },
  created() {
    this.roomAdId = this.$route.params.id; // flat id
    this.getDetails();
    this.getMoreMatches();
  }
};
</script>

<style>
@import "../assets/css/style.css";

.single .price-calculator .btn-primary.btn-contract {
  margin-top: 10px;
  font-size: 1.25rem;
  height: 50px;
}

.single a.btn-outline {
  color: #000 !important;
}

.single .modal .modal-content {
  width: 600px !important;
  padding: 30px !important;
  background: rgba(255, 255, 255, 0.9) !important;
}

.single .modal .modal-content .modal-title {
  text-align: center;
  font-size: 28px;
}

.modal-warning-text {
  background: #000;
  padding: 15px 10px;
  color: #fff;
  font-size: 18px;
  margin: 30px 0;
  border-radius: 7px;
}

.single .btn-outline {
  border: 1px solid #000;
  padding: 8px 15px;
  margin: 0 10px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
}

.single .btn-primary {
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
}

.single .btn-outline:hover {
  background: #000 !important;
  color: #fff !important;
}

.single .btn-group {
  justify-content: center;
}

.single-content__top > .container {
  max-width: 100% !important;
}

.price-calculator .datepicker.control input.input {
  border: none !important;
  padding: 0 !important;
  height: 20px;
  box-shadow: none !important;
}

.single .sidebar__box.ads img {
  left: 0;
}

.entry-intro p {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.single .flat-slider__step,
.single .flat-slider__show-more {
  z-index: 1;
}

.single .entry-group ul {
  max-width: 100%;
}
</style>
