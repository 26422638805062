// TODO rename
var PasswordMixin = {
    created() {
        window.addEventListener("scroll", this.handleScroll);
      },
      methods: {
        handleScroll() {
    
            let mainNavbar = document.getElementById("navbar");
            let scrollY = window.scrollY;

            if (!mainNavbar) return;
    
            if (scrollY > 1) {
              mainNavbar.classList.add("is-scrolled");
            } else {
              mainNavbar.classList.remove("is-scrolled");
            }
        },
    }
}

export default PasswordMixin;
