export default {
  methods: {
    getChatAvatar(partner) {
      return {
        backgroundImage: partner.avatarImgPath ? `url(${process.env.VUE_APP_BACKEND_URL}/${partner.avatarImgPath})` : `url('http://via.placeholder.com/251x182')`
      }
    }
  }
}

