<template>
  <div>
    <div class="search-box__label">{{ $cte("search.lease") }}</div>
    <b-dropdown aria-role="list" multiple ref="playitemdrop" :mobile-modal="false">
      <template #trigger>
        <div class="search-box__field dropdown-toggle"
        >
          <input
              type="text"
              name="add_dates"
              readonly="true"
              :placeholder="$cte('common.addDates')"
              :value="getFormatted()"
              autocomplete="off"
          />
        </div>
      </template>

      <div class="search-dropdown search-dropdown--date">
        <h4 class="search-dropdown__title">{{ $cte("search.Calendar") }}</h4>
        <div class="search-dropdown__date">

          <vc-date-picker
              class="rentible-calendar"
              id="rentible-calendar"
              ref="calendar"
              :masks="masks"
              :transition="'fade'"
              :color="'pink'"
              @input="input"
              is-range="true"
              is-expanded="true"
              :attributes="attributes"
              :select-attribute="selectAttribute"
              :locale="pickerLocale"
              :columns="$screens({ default: 1, lg: 2 })"
              :rows="$screens({ default: 2, lg: 1 })"
              v-model="range"
          >
<!--            TODO if we do not want to show the year next to the month-->
<!--            <template #header-title="{monthLabel}">-->
<!--              {{ monthLabel }}-->
<!--            </template>-->

            <template #header-left-button>
              <div class="date-step prev-month">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xml:space="preserve"><path d="m123.6 271.1 234.7 234.7c8.3 8.3 21.8 8.3 30.2 0s8.3-21.8 0-30.2L168.8 256 388.4 36.4c8.3-8.3 8.3-21.8 0-30.2-8.3-8.3-21.8-8.3-30.2 0L123.6 240.9c-8.3 8.3-8.3 21.9 0 30.2z" fill="#DE2279"></path></svg>
              </div>
            </template>

            <template #header-right-button>
              <div class="date-step next-month">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xml:space="preserve"><path d="M388.418 240.915 153.752 6.248c-8.331-8.331-21.839-8.331-30.17 0-8.331 8.331-8.331 21.839 0 30.17L343.163 256 123.582 475.582c-8.331 8.331-8.331 21.839 0 30.17 8.331 8.331 21.839 8.331 30.17 0l234.667-234.667c8.33-8.331 8.33-21.839-.001-30.17z" fill="#DE2279"></path></svg>
              </div>
            </template>

          </vc-date-picker>
        </div>

        <div class="search-dropdown__footer">
          <button type="button"
                  class="btn btn-primary search-dropdown__btn close-drop" @click="closeDrop"
          >{{ $t("search.done") }}
          </button
          >
        </div>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import Utils from "../../helper/Utils";

export default {
  name: "DateRangePickerPanel",
  props: {
    from: {
      type: String
    },
    to: {
      type: String
    }
  },
  data() {
    return {
      range: {
        start: null,
        end: null,
      },
      masks: {
        weekdays: 'WWW',
      },
      attributes: [
        {
          key: 'today',
          highlight: {
            style: {
              backgroundColor: '#de2279',
            },
            contentStyle: {
              color: '#fff',
            }
          },
          dates: new Date(),
        },
      ],
      selectAttribute: {
        highlight: {
          style: {
            backgroundColor: "#F8F2EF",
            color: "#fff",
          },
        },

      },
    };
  },
  methods: {
    input: function (range) {
      this.range = range;
      this.$emit('from', Utils.formatDateISO(range.start));
      this.$emit('to', Utils.formatDateISO(range.end));
    },
    closeDrop() {
      document.querySelector('body').click();
    },
    getFormatted() {
      return this.range.start ? `${Utils.formatLocaleDate(this.range.start)} - ${Utils.formatLocaleDate(this.range.end)}` : '';
    }
  },
  created() {
    if (this.from) {
      this.range.start = this.from;
    }

    if (this.to) {
      this.range.end = this.to;
    }
  }
}
</script>

<style scoped>

.search-advanced .search-dropdown .date-step,
.search-box .search-dropdown .date-step {
  position: absolute;
  z-index: 1000;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 7px #0000001e;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 40%;
  transition: all .35s ease-in-out
}

.search-advanced .search-dropdown .date-step:hover,
.search-box .search-dropdown .date-step:hover {
  background: #d22673;
}

.search-advanced .search-dropdown .date-step:hover svg path,
.search-box .search-dropdown .date-step:hover svg path,
.stepper .upload-steps__item.done svg path {
  fill: #fff
}

.search-advanced .search-dropdown .date-step svg,
.search-box .search-dropdown .date-step svg {
  width: 35px;
  height: 14px
}

.search-advanced .search-dropdown .date-step.next-month,
.search-box .search-dropdown .date-step.next-month {
  margin-right: -88px;
  margin-top: 115px;
}

.search-advanced .search-dropdown .date-step.prev-month,
.search-box .search-dropdown .date-step.prev-month {
  margin-left: -88px;
  margin-top: 115px;
}

/deep/ #rentible-calendar {
  gap: 3%;
}

/deep/ .rentible-calendar.vc-container {
  --day-font-family: "Manrope", sans-serif;
  --header-font-family: "Bizmobold", sans-serif;
  border-radius: 0;
}

/deep/ .rentible-calendar.vc-container .vc-header {
  font-family: var(--header-font-family);
  padding: 10px 0;
}

/deep/ .rentible-calendar.vc-container .vc-header {
  justify-content: flex-start;
  padding-left: 10px;
}

/deep/ .rentible-calendar.vc-container .vc-weeks {
  padding: 0;
}

/deep/ .rentible-calendar.vc-container .vc-weekday {
  padding: 5px 0;
  font-weight: 500;
  color: #000;
}

/deep/ .rentible-calendar.vc-container .vc-day {
  text-align: left;
  font-family: var(--day-font-family);
  padding: 5px;
  color: #707070;
  background-color: #fff;
}

/deep/ .rentible-calendar.vc-container .vc-day.weekday-1,
/deep/ .rentible-calendar.vc-container .vc-day.weekday-7 {
  color: #000;
  font-weight: 600;
}

/deep/ .rentible-calendar.vc-container .vc-day-dots {
  margin-bottom: 5px;
}

/deep/ .rentible-calendar.vc-container.vc-pink {
  --pink-100: #F8F2EF;
  --pink-200: #F8F2EF;
  --pink-300: #F8F2EF;
  --pink-400: #F8F2EF;
  --pink-500: #F8F2EF;
  --pink-600: #F8F2EF;
  --pink-700: #F8F2EF;
  --pink-800: #F8F2EF;
  --pink-900: #de2279;
}

</style>
