<template>
  <ul class="navbar-nav ml-auto">
    <li class="nav-item">
      <router-link
          :class="isActive === 'Home' ? 'nav-link active' : 'nav-link'"
          to="/"
      >{{ $cte("header.menu.home") }}
      </router-link
      >
    </li>

    <li class="nav-item">
      <router-link
          :class="isActive === 'Properties' ? 'nav-link active' : 'nav-link'"
          to="/properties"
      >{{ $cte("header.menu.properties") }}
      </router-link
      >
    </li>

    <li class="nav-item">
      <a
          href="https://alpha.rentible.io/metaverse/" target="blank"
          :class="
          isActive == 'cowmeta'
            ? 'nav-link metaverse active'
            : 'metaverse nav-link'
        "
      > <img class="mr-1" src="../assets/img/icons/Metaverse.svg" width="25"/> {{ $cte("header.menu.metaverse") }}</a
      >
    </li>

    <li class="nav-item">
      <a
          href="#"
          :class="isActive == 'coworking' ? 'nav-link active' : 'nav-link'"
          @click="openCoworking($event)"
      >{{ $cte("header.menu.coworking") }}</a
      >
    </li>

    <li class="nav-item" v-if="status.loggedIn">
      <router-link
          :class="isActive == 'newads' ? 'nav-link active' : 'nav-link'"
          to="/new-ad"
      >{{ $cte("header.menu.list_your_property") }}
      </router-link
      >
    </li>
    <Modal ref="metaverse-modal" :is-metaverse="true">
      <div class="custom-modal-center">
        <div class="custom-modal-text">
          <h2>{{ $cte('metaverse.title') }}</h2>
          <p>
            {{ $cte('metaverse.text') }}
          </p>
        </div>
        <div class="button-wrapper text-center">
          <button class="btn btn-primary btn-confirm" @click="closeMetaverse">{{ $cte('common.GoBack') }}</button><!-- where we go?? -->
        </div>
      </div>
    </Modal>

    <Modal ref="coworking-modal" :is-coworking="true">
      <div class="custom-modal-center">
        <div class="custom-modal-text">
          <h2>{{ $cte('coworking.title') }}</h2>
          <p>
            {{ $cte('coworking.text') }}
          </p>
        </div>
        <div class="button-wrapper text-center">
          <button class="btn btn-primary btn-confirm" @click="closeCoworking">{{ $cte('common.GoBack') }}</button><!-- where we go?? -->
        </div>
      </div>
    </Modal>
  </ul>
</template>

<script>
import {mapState} from "vuex";
// import {EventBus} from "../helper/event-bus";
import Modal from '@/components/Modal';

export default {
  name: "TheMenu",
  components: {
    Modal
  },
  data() {
    return {
    };
  },
  methods: {
    openMetaverse() {
      this.$refs['metaverse-modal'].isOpen = true;
    },
    openCoworking() {
      this.$refs['coworking-modal'].isOpen = true;
    },
    closeMetaverse() {
      this.$refs['metaverse-modal'].isOpen = false;
    },
    closeCoworking() {
      this.$refs['coworking-modal'].isOpen = false;
    },
  },
  computed: {
    ...mapState("auth", ["user", "status"]),
    isActive() {
      if (this.$route.meta) {
        return this.$route.meta.menuActive;
      } else {
        return null;
      }
    },
  },
};
</script>
<style>
</style>
