<template>
  <div class="sidebar__box price-calculator">
    <div class="price-calculator__head">
      <div>{{ $cte('propertyContractPanel.Price') }}:</div>
      <div>${{ property.rentPrice }}<span>/{{ $cte('propertyContractPanel.month') }}</span></div>
    </div>
    <div class="price-calculator__dates">
      <div class="date">
        <div class="label">{{ $cte('propertyContractPanel.MoveIn') }}</div>

        <b-field>
          <b-datepicker
              v-model="from"
              :show-week-number="false"
              :locale="pickerLocale"
              :placeholder="$cte('common.chooseDate')"
              trap-focus>
          </b-datepicker>
        </b-field>
      </div>
      <div class="date">
        <div class="label">{{ $cte('propertyContractPanel.MoveOut') }}</div>

        <b-field>
          <b-datepicker
              v-model="to"
              :show-week-number="false"
              :locale="pickerLocale"
              :placeholder="$cte('common.chooseDate')"
              trap-focus>
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <button class="btn-primary btn-lg btn-contract"  @click="openChat()"
    >{{ $cte('propertyContractPanel.ContactPublisher') }}
    </button
    >
    <button class="btn-primary btn-lg btn-contract" @click="initiateContract(property)"
    >{{ $cte('propertyContractPanel.SendContractRequest') }}
    </button
    >
    <div class="price-calculator__total">
      <div>{{ $cte('propertyContractPanel.Total')}}:</div>
      <div>${{ property.rentPrice }}<span>/{{ $cte('propertyContractPanel.month') }}</span></div>
    </div>

    <Modal ref="contract-confirm-modal">
      <div class="custom-modal-center">
        <div class="custom-modal-text">
          <h2>{{ $cte('propertyContractPanel.SendContractRequest') }}</h2>
          {{ $cte('propertyContractPanel.SendContractRequestText') }}
        </div>
        <div class="button-wrapper text-center mt-3">
          <button class="btn btn-primary" @click="goToAgreement">{{ $cte('propertyContractPanel.agreeButton') }}</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import {mapGetters} from "vuex";

export default {
  name: "PropertyContractPanel",
  components: {
    Modal
  },
  props: {
    property: {
      type: Object,
    },
    roomAdId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      from: null,
      to: null,
    }
  },
  methods: {
    openChat() {

      const landlord = {name: this.getUserName, id:  this.property.ownerUser.id}
      this.$store.commit('chat/SET_CHAT_OPEN', true);
      this.$store.commit('chat/SET_PARTNER_LIST_OPEN', false);
      this.$store.dispatch('chat/selectPartner', landlord);
    },
    initiateContract() {

      if (this.getUserStatus && !this.getUserStatus.loggedIn) {
        return this.$router.push({name: 'Login', query: {redirect: this.$router.currentRoute.path}});
      }

      if (!this.areValidDates()) {
        return this.$buefy.toast.open(this.$cte('propertyContractPanel.PleaseChooseValidDates'));
      }
      this.$refs['contract-confirm-modal'].isOpen = true;
    },
    goToAgreement() {
      this.$router.push({
        name: 'Agreement',
        params: {
          from: this.from,
          to: this.to,
          roomAdId: this.roomAdId
        }
      })
    },
    closeModal() {
      this.$refs['contract-confirm-modal'].isOpen = false;
    },
    areValidDates() {
      // TODO extend validation...
      return !!this.from && !!this.to;
    },
  },
  computed: {
    ...mapGetters('auth', ['getUserStatus']),
    getUserName() {
      let userName;
      const firstName = this.property.ownerUser.firstName || '';
      const lastName = this.property.ownerUser.lastName || '';

      userName = `${firstName} ${lastName}`

      if (!userName.replace(/ /g, "")) {
        userName = this.property.ownerUser.emailAddress;
      }

      return userName;
    }
  }
}
</script>

<style scoped>

</style>
