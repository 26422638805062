<template>
  <div>

    <TheHeader/>

    <main class="page-content">
      <div class="search-view">
        <div class="container-fluid container-fluid--nopad">
          <div class="row m-0">
            <div :class="hideMap ? 'col-12 col-md-12 p-0 search-advanced-bar' : 'col-12 col-md-12 p-0 search-advanced-bar col-xl-6'">
              <div :class="hideMap ? 'search-advanced-wrapper opened' : 'search-advanced-wrapper'">
                <div class="search-advanced">
                  <div class="search-box__inner">
                    <div class="search-box__col">
                      <LocationInput v-model="search.cityId" @select="selectLocation"/>
                    </div>
                    <div class="search-box__col">
                      <DateRangePickerPanel :from="search.from"
                                       :to="search.to"
                                       @from="search.from = $event"
                                       @to="search.to = $event"/>
                    </div>
                    <div class="search-box__col">
                      <SliderPanel
                          v-model="numbersBudget"
                          :inputLabel="$cte('search.budget')"
                          :placeholder="$cte('search.selectBudget')"
                          :panelTitle="$cte('search.budget')"
                          :min="0"
                          :max="5000"
                          :valuePrefix="'$'" />
                    </div>
                    <div class="search-box__col last-col">
                      <PropertyTypePanel
                          @bedrooms="search.bedrooms = $event"
                          @bathrooms="search.bathrooms = $event"
                          @sizes="numbersSize = $event"
                          @rentalType="search.rentalType = $event"
                          :rentalType="search.rentalType"
                          :bedrooms="search.bedrooms"
                          :bathrooms="search.bathrooms"
                          :sizes="numbersSize"
                          :min="0"
                          :max="700"
                          :valueSuffix="' m2'"/>
                    </div>
                    <button class="btn btn-primary search-box__btn search-btn" @click="doSearch">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22.217"
                        height="22.217"
                      >
                        <g data-name="Group 2">
                          <g data-name="Group 1">
                            <path
                              data-name="Path 7"
                              d="M9.784 0a9.784 9.784 0 1 0 9.784 9.784A9.8 9.8 0 0 0 9.784 0Zm0 17.762a7.978 7.978 0 1 1 7.978-7.978 7.987 7.987 0 0 1-7.978 7.978Z"
                              fill="#fefefe"
                            />
                          </g>
                        </g>
                        <g data-name="Group 4">
                          <g data-name="Group 3">
                            <path
                              data-name="Path 8"
                              d="m21.952 20.675-5.178-5.178a.903.903 0 0 0-1.277 1.277l5.178 5.178a.903.903 0 0 0 1.277-1.277Z"
                              fill="#fefefe"
                            />
                          </g>
                        </g>
                      </svg>
                    </button>
                  </div>

                  <div class="hide-map">
                    <span>{{ $cte("property.hideMap") }}</span>
                    <b-field>
                      <b-switch v-model="hideMap"></b-switch>
                    </b-field>
                  </div>
                </div>

                <div class="sa-more" v-show="isOpen || hideMap ">
                  <simplebar data-simplebar-auto-hide="false">
                    <div class="sa-more__inner">
                      <div class="sa-more__item">
                        <label for="furnished">{{ $cte('property.Furnished') }}</label>
                        <b-field>
                          <b-switch v-model="mySwitch.furnished"></b-switch>
                        </b-field>
                      </div>
                      <div class="sa-more__item">
                        <label for="">{{ $cte('property.Parking') }}</label>
                        <b-field>
                          <b-switch v-model="mySwitch.parking"></b-switch>
                        </b-field>
                      </div>
                      <div class="sa-more__item">
                        <label for="heating">{{ $cte('property.Heating') }}</label>
                        <b-field>
                          <b-switch v-model="mySwitch.heating"></b-switch>
                        </b-field>
                      </div>
                      <div class="sa-more__item">
                        <label for="air">{{ $cte('property.AirConditioning') }}</label>
                        <b-field>
                          <b-switch v-model="mySwitch.airConditioning"></b-switch>
                        </b-field>
                      </div>
                      <div class="sa-more__item">
                        <label for="balcony">{{ $cte('property.BalconyTerrace') }}</label>
                        <b-field>
                          <b-switch v-model="mySwitch.balconyTerrace"></b-switch>
                        </b-field>
                      </div>
                      <div class="sa-more__item">
                        <label for="lift">{{ $cte('property.Lift') }}</label>
                        <b-field>
                          <b-switch v-model="mySwitch.elevator"></b-switch>
                        </b-field>
                      </div>
                      <div class="sa-more__item">
                        <label for="pet">{{ $cte('property.petFriendly') }}</label>
                        <b-field>
                          <b-switch v-model="mySwitch.petFriendly"></b-switch>
                        </b-field>
                      </div>
                    </div>
                  </simplebar>
                </div>

                <button :class="isOpen ? 'advanced-collapse active' : 'advanced-collapse'" v-show="showAdvancedBtn"
                        type="button" @click="isOpen = !isOpen" aria-controls="advancedSearchContent">
                  <span></span>
                  {{ $cte('search.Advanced') }}
                </button>

              </div>
              <!-- ./search-advanced-wrapper -->

              <div class="search-result">
                <div class="container-fluid">

                  <div class="row">
                    <div class="col">
                      <div class="search-result__head">
                        <div class="head-left">
                          <div class="address">{{ location }}</div>
                          <div class="static-txt">{{ $cte("filter.filteredResults") }}</div>
                        </div>
                        <div class="head-right">
                          <div class="sortby">
                            <span>{{ $cte("filter.sortBy") }}</span>
                            <b-field>
                              <b-select class="custom-select-font" :placeholder="$cte('filter.recommended')" @input="doSort">
                                <option
                                  v-for="option in sortOptions"
                                  :value="option.id"
                                  :key="option.id">
                                  {{ $cte(option.value) }}
                                </option>
                              </b-select>
                            </b-field>
                          </div>
                        </div>
                      </div>
                      <!-- ./search-result__head -->
                    </div>
                  </div>

                  <div v-if="results.length > 0">
                    <div class="search-result__body result-withmap" v-show="!isMapHidden">
                      <div class="row">
                        <div class="col-md-12 mb-3" v-for="item in results" :key="item.id">
                          <CardHorizontal
                              :item="item.property"
                              :roomAdId="item.id"/>
                        </div>
                      </div>
                    </div>
                    <!-- ./search-result__body -->

                    <div class="search-result__body" v-show="isMapHidden">
                      <div class="row">
                        <div class="col-md-6 col-lg-3 mb-3" v-for="item in results" :key="item.id">
                          <CardVertical
                              :item="item.property"
                              :roomAdId="item.id"/>
                        </div>
                      </div>
                    </div>
                    <!-- ./search-result__body -->

                    <b-pagination
                        :total="total"
                        v-model="search.page"
                        @change="pagination"
                        :simple="true"
                        :rounded="true"
                        :per-page="search.itemPerPage"
                        aria-next-label="Next page"
                        aria-previous-label="Previous page"
                        aria-page-label="Page"
                        aria-current-label="Current page">
                    </b-pagination>
                  </div>
                  <div v-else>
                    <p>{{ $cte("filter.notFoundMatches") }}</p>
                  </div>
                </div>
              </div>
              <!-- ./search-result -->
            </div>

            <div class="d-none d-xl-block col-12 col-md-12 p-0 col-xl-6 search-map">
              <MapLocation v-show="!isMapHidden" :items="mapResults" :showCompactCards="true"/>
            </div>

          </div>
          <!-- ./row -->
        </div>
        <!-- ./container-fluid -->
      </div>
    </main>
    <TheFooter />
  </div>
  <!-- ./page-content -->
</template>

<script>
import {mapState} from 'vuex';
import PropertyTypePanel from "../components/search/PropertyTypePanel";
import LocationInput from "../components/search/LocationInput";
import TheHeader from "../components/TheHeader.vue";
import TheFooter from "../components/TheFooter.vue";
import 'simplebar/dist/simplebar.min.css';
import simplebar from 'simplebar-vue';
import CardHorizontal from "../components/card/CardHorizontal.vue";
import CardVertical from "../components/card/CardVertical.vue";
import MapLocation from "../components/MapLocation.vue";
import RoomAdService from "@/services/roomAd.service";
import DateRangePickerPanel from "../components/search/DateRangePickerPanel";
import SliderPanel from "../components/search/SliderPanel";

export default {
  name: "Properties",
  data() {
    return {
      total: null,
      queryParams: {},
      isOpen: true,
      location: null,
      periods: null,
      numbersBudget: [],
      numbersSize: [],
      search: {
        cityId: '',
        termOfLease: [],
        bedrooms: null,
        bathrooms: null,
        rentalType: null,
        priceMin: 0,
        priceMax: 0,
        sizeMin: 0,
        sizeMax: 0,
        from: null,
        to: null,
        filter: {},
        page: 1,
        itemPerPage: 10,
        sortBy: "weight",
        sortDesc: false,
        withDeleted: false,
      },
      sortOptions: [
        {
          id: {sortBy: 'propertyAlias.rentPrice', sortDesc: false},
          value: "filter.priceLow"
        },
        {
          id: {sortBy: 'propertyAlias.rentPrice', sortDesc: true},
          value: "filter.priceUp"
        },
        {
          id: {sortBy: 'createdOn', sortDesc: true},
          value: "filter.freshUp"
        }
      ],
      mySwitch: {
        furnished: false,
        parking: false,
        heating: false,
        airConditioning: false,
        balconyTerrace: false,
        elevator: false,
        petFriendly: false,
      },
      hideMap: false,
      results: [],
      mapResults: []
    }
  },
  props: {
    items: String
  },
  components: {
    PropertyTypePanel,
    SliderPanel,
    DateRangePickerPanel,
    TheHeader,
    TheFooter,
    LocationInput,
    MapLocation,
    CardVertical,
    CardHorizontal,
    simplebar
  },
  computed: {
    ...mapState('search', [
      'searchValues',
      'searchResult'
    ]),
    isMapHidden() {
      let br = this.$screen.breakpoint;
      const mapHiddenOnSizes = ['xs', 'sm', 'md', 'lg'];
      return mapHiddenOnSizes.includes(br) || this.hideMap;
    },
    showAdvancedBtn() {
      let br = this.$screen.breakpoint;
      const showOnSizes = ['xs', 'sm', 'md'];
      return (!this.hideMap || showOnSizes.includes(br))
    }
  },
  methods: {
    pagination() {
      this.doSearch();
    },
    getPayload() {
      return {
        filter: this.getFilter(),
        page: this.search.page,
        itemPerPage: this.search.itemPerPage,
        sortBy: this.search.sortBy,
        sortDesc: this.search.sortDesc,
        withDeleted: this.search.withDeleted,
      };
    },
    getFilter() {
      let filter = {};
      if (this.search.cityId) {
        filter.cityId = this.search.cityId
      }
      if (this.numbersBudget.length > 0) {
        filter.priceMin = this.numbersBudget[0];
        filter.priceMax = this.numbersBudget[1];
      }
      if (this.numbersSize.length > 0) {
        filter.sizeMin = this.numbersSize[0];
        filter.sizeMax = this.numbersSize[1];
      }
      if (this.search.termOfLease && this.search.termOfLease.length > 0) {
        filter.termOfLease = this.search.termOfLease;
      }

      if (this.search.from) {
        filter.from = this.search.from;
      }

      if (this.search.to) {
        filter.to = this.search.to;
      }

      if (this.search.bedrooms) {
        filter.bedrooms = this.search.bedrooms;
      }
      if (this.search.bathrooms) {
        filter.bathrooms = this.search.bathrooms;
      }
      if (this.search.rentalType) {
        filter.rentalType = this.search.rentalType;
      }

      filter = Object.assign(filter, this.mySwitch);

      return filter;
    },
    selectLocation(data) {
      if (data) {
        this.search.cityId = data.id;
      } else {
        this.search.cityId = null;
      }
    },
    propertyChange(event) {
      this.sPropertyMin = event[0];
      this.sPropertyMax = event[1];
    },
    budgetChange(event) {
      this.sBudgetMin = event[0];
      this.sBudgetMax = event[1];
    },
    doSort(event) {
      this.search = Object.assign(this.search, event);
      this.search.page = 1;
      this.doSearch();
    },
    doSearch() {
      let payload = this.getPayload();
      // (check this on BE), need this to unique routing
      payload.t = new Date().getTime();
      this.$router.push({ name: 'Properties', query: payload});
    },
    parseSearchParams() {
      let search = JSON.parse(JSON.stringify(this.searchParams))
      if (search.filter.priceMax && search.filter.priceMin >= 0) {
        this.numbersBudget[0] = search.filter.priceMin;
        this.numbersBudget[1] = search.filter.priceMax;
      }
      if (search.filter.sizeMin >= 0 && search.filter.sizeMax) {
        this.numbersSize[0] = search.filter.sizeMin;
        this.numbersSize[1] = search.filter.sizeMax;
      }
      if (search.filter.from) {
        search.from = search.filter.from;
      }
      if (search.filter.to) {
        search.to = search.filter.to;
      }

      search.cityId = search.filter.cityId;

      search.bedrooms = search.filter.bedrooms;
      search.bathrooms = search.filter.bathrooms;
      search.rentalType = search.filter.rentalType;
      this.search = search;
    },
    mappingResults(results) {
      return results.map(item => {
        return {
          ...item,
          property: {
            ...item.property,
            id: item.id
          }}
      }).map(item => item.property);
    },
    searchByUrlParams(search) {
      this.searchParams = JSON.parse(JSON.stringify(search));

      if (this.searchParams.filter) {
        this.parseSearchParams();
      }

      const loadingComponent = this.$buefy.loading.open({
        canCancel: false,
      });

      if (this.searchParams.filter) {
        // do a search
        RoomAdService.search(this.searchParams).then(res => {
          this.mapResults = this.mappingResults(res.data.rows);
          this.total = res.data.totalRows;
          this.results = res.data.rows;
          loadingComponent.close();
        });
      } else {
        RoomAdService.search(this.getPayload()).then(res => {
          this.mapResults = this.mappingResults(res.data.rows)
          this.results = res.data.rows;
          this.total = res.data.totalRows;
          loadingComponent.close();
        });
      }

      setTimeout(() => {
        loadingComponent.close();
      }, 2000);
    }
  },
  watch: {
    // Watch the query parameters of the url and do a search by them
    '$route.query': {
      handler: 'searchByUrlParams',
      deep: true,
      immediate: true
    }
  }
};
</script>

<style>
@import "../assets/css/style.css";

.pagination-previous:focus,
.pagination-next:focus,
.pagination-link:focus {
  border-color: #de2279!important;
}


.container-fluid--nopad {
  padding: 0;
}

.search-map {
  flex: 0 0 40%;
  max-width: 40%;
}

.switch input[type=checkbox]:checked + .check,
.switch:hover input[type=checkbox]:checked + .check {
  background: #de2279 !important;
}

.search-map .vue-map-container {
  position: sticky;
  height: calc(100vh - 120px) !important;
  top: 120px;
  transition: all .4s linear;
}

.search-map > div {
  min-height: 100%;
  padding-bottom: 105px;
}

.search-advanced .hide-map label {
  margin: 2px 0 0 0;
}

.hide-map {
  width: 10%;
}

.sa-more__item label {
  font-weight: 700;
}

.select select, .taginput .taginput-container.is-focusable, .textarea, .input {
  background: none !important;
  color: #000 !important;
  border: none !important;
  position: relative;
  top: -2px;
  font-size: 17px;
}

.select select:not([multiple]) {
  padding-right: 35px !important;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #000;
}

.navbar-link:not(.is-arrowless)::after, .select:not(.is-multiple):not(.is-loading)::after {
  border-top: .3em solid !important;
  border-right: .3em solid transparent !important;
  border-bottom: 0 !important;
  transform: none !important;
  border-left: .3em solid transparent !important;
  top: 25px !important;
}

.search-result {
  min-height: 100vh;
}

.search-advanced .search-dropdown__date, .search-box .search-dropdown__date {
  min-height: 100px;
}

@media (max-width: 991.98px) {
  .search-advanced-wrapper.opened .search-advanced {
    flex: 0 0 100%;
  }

  .search-advanced-wrapper.opened .sa-more {
    flex: 0 0 100%;
  }

  .search-advanced-wrapper.opened {
    flex-wrap: wrap;
  }
}

/*TODO Line height problem on deployed version only fix*/
.icon {
  line-height: 24px;
}

.custom-select-font select {
  font-family: Manrope, bold;
}

</style>
