<template>
  <div class="content-wrapper">
    <!-- pending request -->
    <div class="cards">
      <div class="cards__header cards__header--flex">
        <h2 class="cards__title">{{ $cte("profile.request.pendingTitle") }}</h2>
<!--        <div class="filter">-->
<!--          <div class="filter__lines">-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--          </div>-->
<!--          <span class="filter__text">Filter</span>-->
<!--        </div>-->
      </div>

      <div :class="`cards__item cards__item--success`" v-for="item in requestsPending" :key="item.id">
        <!--      <div :class="`cards__item cards__item&#45;&#45;`+item.status" v-for="item in requestsPending" :key="item.id">-->

        <PendingRequestCard :item="item" @success="successCallback"/>
        <!--        <div class="row align-items-center">-->
        <!--          <div class="col-md-4">-->
        <!--            <div class="item__title">{{ request.title }}</div>-->
        <!--            <div class="item__subtitle">-->
        <!--              Tenant: {{ request.tenant }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="col-md-2">-->
        <!--            <div :class="`status status&#45;&#45;`+request.status">{{ request.contractType }}</div>-->
        <!--          </div>-->
        <!--          <div class="col-md-3">-->
        <!--            <div class="item__date">{{ request.date }}</div>-->
        <!--          </div>-->
        <!--          <div class="col-md-3 card-action">-->
        <!--            <div class="btns">-->
        <!--&lt;!&ndash;              TODO debug/test only&ndash;&gt;-->
        <!--              <AcceptContractButton-->
        <!--                :contractId="1"-->
        <!--                :roomAdId="1"-->
        <!--                @success="successCallback"-->
        <!--                @error="errorCallback"-->
        <!--              />-->
        <!--&lt;!&ndash;              <button class="item__btn item__btn&#45;&#45;accept" @click="doAccept">Accept</button>&ndash;&gt;-->
        <!--&lt;!&ndash;              TODO debug/test only&ndash;&gt;-->
        <!--              <ReleaseDepositButton-->
        <!--                :landlord-wallet-address="'0xA5c4e35523e8639C861153A4afc0CA6765aD6aa3'"-->
        <!--                :renter-wallet-address="'0x73af0af4b785F3b7C4DBBB19A9Dc0d11dA7F4De5'"-->
        <!--              />-->
        <!--&lt;!&ndash;              <button @click="doDepositRelease" class="item__btn item__btn&#45;&#45;deposit"&ndash;&gt;-->
        <!--&lt;!&ndash;                >Deposit Release</button&ndash;&gt;-->
        <!--&lt;!&ndash;              >&ndash;&gt;-->
        <!--            </div>-->
        <!--            <b-dropdown-->
        <!--              append-to-body-->
        <!--              aria-role="menu"-->
        <!--              scrollable-->
        <!--              position="is-bottom-left"-->
        <!--              max-height="200"-->
        <!--              trap-focus-->
        <!--            >-->
        <!--              <template #trigger>-->
        <!--                <button class="more">-->
        <!--                  <svg-->
        <!--                    xmlns="http://www.w3.org/2000/svg"-->
        <!--                    width="4.248"-->
        <!--                    height="19.116"-->
        <!--                    viewBox="0 0 4.248 19.116"-->
        <!--                  >-->
        <!--                    <g id="more" transform="translate(0 0)">-->
        <!--                      <g-->
        <!--                        id="Group_353"-->
        <!--                        data-name="Group 353"-->
        <!--                        transform="translate(0 0)"-->
        <!--                      >-->
        <!--                        <g-->
        <!--                          id="Group_352"-->
        <!--                          data-name="Group 352"-->
        <!--                          transform="translate(0 0)"-->
        <!--                        >-->
        <!--                          <circle-->
        <!--                            id="Ellipse_48"-->
        <!--                            data-name="Ellipse 48"-->
        <!--                            cx="2.124"-->
        <!--                            cy="2.124"-->
        <!--                            r="2.124"-->
        <!--                            fill="#afafc3"-->
        <!--                          />-->
        <!--                        </g>-->
        <!--                      </g>-->
        <!--                      <g-->
        <!--                        id="Group_355"-->
        <!--                        data-name="Group 355"-->
        <!--                        transform="translate(0 7.434)"-->
        <!--                      >-->
        <!--                        <g-->
        <!--                          id="Group_354"-->
        <!--                          data-name="Group 354"-->
        <!--                          transform="translate(0)"-->
        <!--                        >-->
        <!--                          <circle-->
        <!--                            id="Ellipse_49"-->
        <!--                            data-name="Ellipse 49"-->
        <!--                            cx="2.124"-->
        <!--                            cy="2.124"-->
        <!--                            r="2.124"-->
        <!--                            fill="#afafc3"-->
        <!--                          />-->
        <!--                        </g>-->
        <!--                      </g>-->
        <!--                      <g-->
        <!--                        id="Group_357"-->
        <!--                        data-name="Group 357"-->
        <!--                        transform="translate(0 14.868)"-->
        <!--                      >-->
        <!--                        <g-->
        <!--                          id="Group_356"-->
        <!--                          data-name="Group 356"-->
        <!--                          transform="translate(0)"-->
        <!--                        >-->
        <!--                          <circle-->
        <!--                            id="Ellipse_50"-->
        <!--                            data-name="Ellipse 50"-->
        <!--                            cx="2.124"-->
        <!--                            cy="2.124"-->
        <!--                            r="2.124"-->
        <!--                            fill="#afafc3"-->
        <!--                          />-->
        <!--                        </g>-->
        <!--                      </g>-->
        <!--                    </g>-->
        <!--                  </svg>-->
        <!--                </button>-->
        <!--              </template>-->

        <!--              <b-dropdown-item custom aria-role="listitem">-->
        <!--                <button @click="downloadPDF" class="download">-->
        <!--                  <svg-->
        <!--                    xmlns="http://www.w3.org/2000/svg"-->
        <!--                    width="28.755"-->
        <!--                    height="32.863"-->
        <!--                    viewBox="0 0 28.755 32.863"-->
        <!--                  >-->
        <!--                    <g id="pdf" transform="translate(-32)">-->
        <!--                      <path-->
        <!--                        id="Path_413"-->
        <!--                        data-name="Path 413"-->
        <!--                        d="M98.054,0A2.06,2.06,0,0,0,96,2.054V30.809a2.06,2.06,0,0,0,2.054,2.054h20.539a2.06,2.06,0,0,0,2.054-2.054V8.216L112.431,0Z"-->
        <!--                        transform="translate(-59.892)"-->
        <!--                        fill="#e2e5e7"-->
        <!--                      />-->
        <!--                      <path-->
        <!--                        id="Path_414"-->
        <!--                        data-name="Path 414"-->
        <!--                        d="M354.054,8.216h6.162L352,0V6.162A2.06,2.06,0,0,0,354.054,8.216Z"-->
        <!--                        transform="translate(-299.461)"-->
        <!--                        fill="#b0b7bd"-->
        <!--                      />-->
        <!--                      <path-->
        <!--                        id="Path_415"-->
        <!--                        data-name="Path 415"-->
        <!--                        d="M390.162,134.162,384,128h6.162Z"-->
        <!--                        transform="translate(-329.407 -119.784)"-->
        <!--                        fill="#cad1d8"-->
        <!--                      />-->
        <!--                      <path-->
        <!--                        id="Path_416"-->
        <!--                        data-name="Path 416"-->
        <!--                        d="M56.647,251.3a1.03,1.03,0,0,1-1.027,1.027H33.027A1.03,1.03,0,0,1,32,251.3v-10.27A1.03,1.03,0,0,1,33.027,240H55.62a1.03,1.03,0,0,1,1.027,1.027Z"-->
        <!--                        transform="translate(0 -224.596)"-->
        <!--                        fill="#f15642"-->
        <!--                      />-->
        <!--                      <g-->
        <!--                        id="Group_411"-->
        <!--                        data-name="Group 411"-->
        <!--                        transform="translate(36.476 18.891)"-->
        <!--                      >-->
        <!--                        <path-->
        <!--                          id="Path_417"-->
        <!--                          data-name="Path 417"-->
        <!--                          d="M101.744,294.887a.565.565,0,0,1,.558-.567h1.9a2.053,2.053,0,0,1,0,4.106h-1.371v1.084a.515.515,0,0,1-.526.566.553.553,0,0,1-.558-.566Zm1.083.467V297.4H104.2a1.024,1.024,0,0,0,0-2.046Z"-->
        <!--                          transform="translate(-101.744 -294.32)"-->
        <!--                          fill="#fff"-->
        <!--                        />-->
        <!--                        <path-->
        <!--                          id="Path_418"-->
        <!--                          data-name="Path 418"-->
        <!--                          d="M188.391,301.154a.514.514,0,0,1-.567-.508v-4.664a.559.559,0,0,1,.567-.509h1.88c3.752,0,3.67,5.682.074,5.682Zm.518-4.68v3.679h1.363c2.217,0,2.316-3.679,0-3.679Z"-->
        <!--                          transform="translate(-182.299 -295.398)"-->
        <!--                          fill="#fff"-->
        <!--                        />-->
        <!--                        <path-->
        <!--                          id="Path_419"-->
        <!--                          data-name="Path 419"-->
        <!--                          d="M288.21,296.525v1.305H290.3a.635.635,0,0,1,.592.582.571.571,0,0,1-.592.493H288.21v1.724a.486.486,0,0,1-.492.508.522.522,0,0,1-.582-.508v-4.664a.519.519,0,0,1,.582-.509H290.6a.512.512,0,0,1,.575.509.567.567,0,0,1-.575.559H288.21Z"-->
        <!--                          transform="translate(-275.237 -295.383)"-->
        <!--                          fill="#fff"-->
        <!--                        />-->
        <!--                      </g>-->
        <!--                      <path-->
        <!--                        id="Path_420"-->
        <!--                        data-name="Path 420"-->
        <!--                        d="M115.512,417.027H96v1.027h19.512a1.03,1.03,0,0,0,1.027-1.027V416A1.03,1.03,0,0,1,115.512,417.027Z"-->
        <!--                        transform="translate(-59.892 -389.299)"-->
        <!--                        fill="#cad1d8"-->
        <!--                      />-->
        <!--                    </g>-->
        <!--                  </svg>-->
        <!--                  <span>Donwload PDF</span>-->
        <!--                </button>-->
        <!--              </b-dropdown-item>-->
        <!--              <b-dropdown-item custom aria-role="listitem">-->
        <!--                <button @click="submitArbitration">-->
        <!--                  <span>Arbitration</span>-->
        <!--                </button>-->
        <!--              </b-dropdown-item>-->
        <!--            </b-dropdown>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>

    <!-- pending request -->
    <div class="cards">
      <div class="cards__header cards__header--flex">
        <h2 class="cards__title">{{ $cte("profile.request.archiveTitle") }}</h2>
        <!--        <div class="filter">-->
        <!--          <div class="filter__lines">-->
        <!--            <span></span>-->
        <!--            <span></span>-->
        <!--            <span></span>-->
        <!--          </div>-->
        <!--          <span class="filter__text">Filter</span>-->
        <!--        </div>-->
      </div>

      <div :class="`cards__item cards__item--success`" v-for="item in requestsArchived" :key="item.id">
        <!--      <div :class="`cards__item cards__item&#45;&#45;`+item.status" v-for="item in requestsPending" :key="item.id">-->

        <ArchivedRequestCard :item="item" @success="successCallback"/>
        <!--        <div class="row align-items-center">-->
        <!--          <div class="col-md-4">-->
        <!--            <div class="item__title">{{ request.title }}</div>-->
        <!--            <div class="item__subtitle">-->
        <!--              Tenant: {{ request.tenant }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="col-md-2">-->
        <!--            <div :class="`status status&#45;&#45;`+request.status">{{ request.contractType }}</div>-->
        <!--          </div>-->
        <!--          <div class="col-md-3">-->
        <!--            <div class="item__date">{{ request.date }}</div>-->
        <!--          </div>-->
        <!--          <div class="col-md-3 card-action">-->
        <!--            <div class="btns">-->
        <!--&lt;!&ndash;              TODO debug/test only&ndash;&gt;-->
        <!--              <AcceptContractButton-->
        <!--                :contractId="1"-->
        <!--                :roomAdId="1"-->
        <!--                @success="successCallback"-->
        <!--                @error="errorCallback"-->
        <!--              />-->
        <!--&lt;!&ndash;              <button class="item__btn item__btn&#45;&#45;accept" @click="doAccept">Accept</button>&ndash;&gt;-->
        <!--&lt;!&ndash;              TODO debug/test only&ndash;&gt;-->
        <!--              <ReleaseDepositButton-->
        <!--                :landlord-wallet-address="'0xA5c4e35523e8639C861153A4afc0CA6765aD6aa3'"-->
        <!--                :renter-wallet-address="'0x73af0af4b785F3b7C4DBBB19A9Dc0d11dA7F4De5'"-->
        <!--              />-->
        <!--&lt;!&ndash;              <button @click="doDepositRelease" class="item__btn item__btn&#45;&#45;deposit"&ndash;&gt;-->
        <!--&lt;!&ndash;                >Deposit Release</button&ndash;&gt;-->
        <!--&lt;!&ndash;              >&ndash;&gt;-->
        <!--            </div>-->
        <!--            <b-dropdown-->
        <!--              append-to-body-->
        <!--              aria-role="menu"-->
        <!--              scrollable-->
        <!--              position="is-bottom-left"-->
        <!--              max-height="200"-->
        <!--              trap-focus-->
        <!--            >-->
        <!--              <template #trigger>-->
        <!--                <button class="more">-->
        <!--                  <svg-->
        <!--                    xmlns="http://www.w3.org/2000/svg"-->
        <!--                    width="4.248"-->
        <!--                    height="19.116"-->
        <!--                    viewBox="0 0 4.248 19.116"-->
        <!--                  >-->
        <!--                    <g id="more" transform="translate(0 0)">-->
        <!--                      <g-->
        <!--                        id="Group_353"-->
        <!--                        data-name="Group 353"-->
        <!--                        transform="translate(0 0)"-->
        <!--                      >-->
        <!--                        <g-->
        <!--                          id="Group_352"-->
        <!--                          data-name="Group 352"-->
        <!--                          transform="translate(0 0)"-->
        <!--                        >-->
        <!--                          <circle-->
        <!--                            id="Ellipse_48"-->
        <!--                            data-name="Ellipse 48"-->
        <!--                            cx="2.124"-->
        <!--                            cy="2.124"-->
        <!--                            r="2.124"-->
        <!--                            fill="#afafc3"-->
        <!--                          />-->
        <!--                        </g>-->
        <!--                      </g>-->
        <!--                      <g-->
        <!--                        id="Group_355"-->
        <!--                        data-name="Group 355"-->
        <!--                        transform="translate(0 7.434)"-->
        <!--                      >-->
        <!--                        <g-->
        <!--                          id="Group_354"-->
        <!--                          data-name="Group 354"-->
        <!--                          transform="translate(0)"-->
        <!--                        >-->
        <!--                          <circle-->
        <!--                            id="Ellipse_49"-->
        <!--                            data-name="Ellipse 49"-->
        <!--                            cx="2.124"-->
        <!--                            cy="2.124"-->
        <!--                            r="2.124"-->
        <!--                            fill="#afafc3"-->
        <!--                          />-->
        <!--                        </g>-->
        <!--                      </g>-->
        <!--                      <g-->
        <!--                        id="Group_357"-->
        <!--                        data-name="Group 357"-->
        <!--                        transform="translate(0 14.868)"-->
        <!--                      >-->
        <!--                        <g-->
        <!--                          id="Group_356"-->
        <!--                          data-name="Group 356"-->
        <!--                          transform="translate(0)"-->
        <!--                        >-->
        <!--                          <circle-->
        <!--                            id="Ellipse_50"-->
        <!--                            data-name="Ellipse 50"-->
        <!--                            cx="2.124"-->
        <!--                            cy="2.124"-->
        <!--                            r="2.124"-->
        <!--                            fill="#afafc3"-->
        <!--                          />-->
        <!--                        </g>-->
        <!--                      </g>-->
        <!--                    </g>-->
        <!--                  </svg>-->
        <!--                </button>-->
        <!--              </template>-->

        <!--              <b-dropdown-item custom aria-role="listitem">-->
        <!--                <button @click="downloadPDF" class="download">-->
        <!--                  <svg-->
        <!--                    xmlns="http://www.w3.org/2000/svg"-->
        <!--                    width="28.755"-->
        <!--                    height="32.863"-->
        <!--                    viewBox="0 0 28.755 32.863"-->
        <!--                  >-->
        <!--                    <g id="pdf" transform="translate(-32)">-->
        <!--                      <path-->
        <!--                        id="Path_413"-->
        <!--                        data-name="Path 413"-->
        <!--                        d="M98.054,0A2.06,2.06,0,0,0,96,2.054V30.809a2.06,2.06,0,0,0,2.054,2.054h20.539a2.06,2.06,0,0,0,2.054-2.054V8.216L112.431,0Z"-->
        <!--                        transform="translate(-59.892)"-->
        <!--                        fill="#e2e5e7"-->
        <!--                      />-->
        <!--                      <path-->
        <!--                        id="Path_414"-->
        <!--                        data-name="Path 414"-->
        <!--                        d="M354.054,8.216h6.162L352,0V6.162A2.06,2.06,0,0,0,354.054,8.216Z"-->
        <!--                        transform="translate(-299.461)"-->
        <!--                        fill="#b0b7bd"-->
        <!--                      />-->
        <!--                      <path-->
        <!--                        id="Path_415"-->
        <!--                        data-name="Path 415"-->
        <!--                        d="M390.162,134.162,384,128h6.162Z"-->
        <!--                        transform="translate(-329.407 -119.784)"-->
        <!--                        fill="#cad1d8"-->
        <!--                      />-->
        <!--                      <path-->
        <!--                        id="Path_416"-->
        <!--                        data-name="Path 416"-->
        <!--                        d="M56.647,251.3a1.03,1.03,0,0,1-1.027,1.027H33.027A1.03,1.03,0,0,1,32,251.3v-10.27A1.03,1.03,0,0,1,33.027,240H55.62a1.03,1.03,0,0,1,1.027,1.027Z"-->
        <!--                        transform="translate(0 -224.596)"-->
        <!--                        fill="#f15642"-->
        <!--                      />-->
        <!--                      <g-->
        <!--                        id="Group_411"-->
        <!--                        data-name="Group 411"-->
        <!--                        transform="translate(36.476 18.891)"-->
        <!--                      >-->
        <!--                        <path-->
        <!--                          id="Path_417"-->
        <!--                          data-name="Path 417"-->
        <!--                          d="M101.744,294.887a.565.565,0,0,1,.558-.567h1.9a2.053,2.053,0,0,1,0,4.106h-1.371v1.084a.515.515,0,0,1-.526.566.553.553,0,0,1-.558-.566Zm1.083.467V297.4H104.2a1.024,1.024,0,0,0,0-2.046Z"-->
        <!--                          transform="translate(-101.744 -294.32)"-->
        <!--                          fill="#fff"-->
        <!--                        />-->
        <!--                        <path-->
        <!--                          id="Path_418"-->
        <!--                          data-name="Path 418"-->
        <!--                          d="M188.391,301.154a.514.514,0,0,1-.567-.508v-4.664a.559.559,0,0,1,.567-.509h1.88c3.752,0,3.67,5.682.074,5.682Zm.518-4.68v3.679h1.363c2.217,0,2.316-3.679,0-3.679Z"-->
        <!--                          transform="translate(-182.299 -295.398)"-->
        <!--                          fill="#fff"-->
        <!--                        />-->
        <!--                        <path-->
        <!--                          id="Path_419"-->
        <!--                          data-name="Path 419"-->
        <!--                          d="M288.21,296.525v1.305H290.3a.635.635,0,0,1,.592.582.571.571,0,0,1-.592.493H288.21v1.724a.486.486,0,0,1-.492.508.522.522,0,0,1-.582-.508v-4.664a.519.519,0,0,1,.582-.509H290.6a.512.512,0,0,1,.575.509.567.567,0,0,1-.575.559H288.21Z"-->
        <!--                          transform="translate(-275.237 -295.383)"-->
        <!--                          fill="#fff"-->
        <!--                        />-->
        <!--                      </g>-->
        <!--                      <path-->
        <!--                        id="Path_420"-->
        <!--                        data-name="Path 420"-->
        <!--                        d="M115.512,417.027H96v1.027h19.512a1.03,1.03,0,0,0,1.027-1.027V416A1.03,1.03,0,0,1,115.512,417.027Z"-->
        <!--                        transform="translate(-59.892 -389.299)"-->
        <!--                        fill="#cad1d8"-->
        <!--                      />-->
        <!--                    </g>-->
        <!--                  </svg>-->
        <!--                  <span>Donwload PDF</span>-->
        <!--                </button>-->
        <!--              </b-dropdown-item>-->
        <!--              <b-dropdown-item custom aria-role="listitem">-->
        <!--                <button @click="submitArbitration">-->
        <!--                  <span>Arbitration</span>-->
        <!--                </button>-->
        <!--              </b-dropdown-item>-->
        <!--            </b-dropdown>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>

    <!--      <div class="cards__header cards__header&#45;&#45;flex">-->
    <!--        <h2 class="cards__title">Archived requests</h2>-->
    <!--        <div class="filter">-->
    <!--          <div class="filter__lines">-->
    <!--            <span></span>-->
    <!--            <span></span>-->
    <!--            <span></span>-->
    <!--          </div>-->
    <!--          <span class="filter__text">Filter</span>-->
    <!--        </div>-->
    <!--      </div>-->

    <!-- archived request -->
<!--    <div class="cards">-->
<!--      <div class="cards__header cards__header&#45;&#45;flex">-->
<!--        <h2 class="cards__title">Archived requests</h2>-->
<!--        <div class="filter">-->
<!--          <div class="filter__lines">-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--          </div>-->
<!--          <span class="filter__text">Filter</span>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="cards__item cards__item&#45;&#45;success">-->
<!--        <div class="row align-items-center">-->
<!--          <div class="col-md-4">-->
<!--            <div class="item__title">Apartment for Rent in VIVA Residence</div>-->
<!--            <div class="item__subtitle">-->
<!--              Tenant: Loreum ipsum (email@tesemail.io)-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-2">-->
<!--            <div class="status status&#45;&#45;success">Rental contract</div>-->
<!--          </div>-->
<!--          <div class="col-md-3">-->
<!--            <div class="item__date">2021 10 21 - 2022 01 29</div>-->
<!--          </div>-->
<!--          <div class="col-md-3 card-action">-->
<!--            <div class="btns">-->
<!--              <a class="item__btn item__btn&#45;&#45;accept" href="#">Accept</a>-->
<!--              <a class="item__btn item__btn&#45;&#45;deposit" href="#">Deposit Release</a>-->
<!--            </div>-->
<!--            <b-dropdown-->
<!--                append-to-body-->
<!--                aria-role="menu"-->
<!--                scrollable-->
<!--                position="is-bottom-left"-->
<!--                max-height="200"-->
<!--                trap-focus-->
<!--            >-->
<!--              <template #trigger>-->
<!--                <button class="more">-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg" width="4.248" height="19.116" viewBox="0 0 4.248 19.116">-->
<!--                    <g id="more" transform="translate(0 0)">-->
<!--                      <g id="Group_353" data-name="Group 353" transform="translate(0 0)">-->
<!--                        <g id="Group_352" data-name="Group 352" transform="translate(0 0)">-->
<!--                          <circle id="Ellipse_48" data-name="Ellipse 48" cx="2.124" cy="2.124" r="2.124"-->
<!--                                  fill="#afafc3"/>-->
<!--                        </g>-->
<!--                      </g>-->
<!--                      <g id="Group_355" data-name="Group 355" transform="translate(0 7.434)">-->
<!--                        <g id="Group_354" data-name="Group 354" transform="translate(0)">-->
<!--                          <circle id="Ellipse_49" data-name="Ellipse 49" cx="2.124" cy="2.124" r="2.124"-->
<!--                                  fill="#afafc3"/>-->
<!--                        </g>-->
<!--                      </g>-->
<!--                      <g id="Group_357" data-name="Group 357" transform="translate(0 14.868)">-->
<!--                        <g id="Group_356" data-name="Group 356" transform="translate(0)">-->
<!--                          <circle id="Ellipse_50" data-name="Ellipse 50" cx="2.124" cy="2.124" r="2.124"-->
<!--                                  fill="#afafc3"/>-->
<!--                        </g>-->
<!--                      </g>-->
<!--                    </g>-->
<!--                  </svg>-->
<!--                </button>-->
<!--              </template>-->

<!--              <b-dropdown-item custom aria-role="listitem">-->
<!--                <button @click="downloadPDF" class="download">-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg" width="28.755" height="32.863" viewBox="0 0 28.755 32.863">-->
<!--                    <g id="pdf" transform="translate(-32)">-->
<!--                      <path id="Path_413" data-name="Path 413"-->
<!--                            d="M98.054,0A2.06,2.06,0,0,0,96,2.054V30.809a2.06,2.06,0,0,0,2.054,2.054h20.539a2.06,2.06,0,0,0,2.054-2.054V8.216L112.431,0Z"-->
<!--                            transform="translate(-59.892)" fill="#e2e5e7"/>-->
<!--                      <path id="Path_414" data-name="Path 414"-->
<!--                            d="M354.054,8.216h6.162L352,0V6.162A2.06,2.06,0,0,0,354.054,8.216Z"-->
<!--                            transform="translate(-299.461)" fill="#b0b7bd"/>-->
<!--                      <path id="Path_415" data-name="Path 415" d="M390.162,134.162,384,128h6.162Z"-->
<!--                            transform="translate(-329.407 -119.784)" fill="#cad1d8"/>-->
<!--                      <path id="Path_416" data-name="Path 416"-->
<!--                            d="M56.647,251.3a1.03,1.03,0,0,1-1.027,1.027H33.027A1.03,1.03,0,0,1,32,251.3v-10.27A1.03,1.03,0,0,1,33.027,240H55.62a1.03,1.03,0,0,1,1.027,1.027Z"-->
<!--                            transform="translate(0 -224.596)" fill="#f15642"/>-->
<!--                      <g id="Group_411" data-name="Group 411" transform="translate(36.476 18.891)">-->
<!--                        <path id="Path_417" data-name="Path 417"-->
<!--                              d="M101.744,294.887a.565.565,0,0,1,.558-.567h1.9a2.053,2.053,0,0,1,0,4.106h-1.371v1.084a.515.515,0,0,1-.526.566.553.553,0,0,1-.558-.566Zm1.083.467V297.4H104.2a1.024,1.024,0,0,0,0-2.046Z"-->
<!--                              transform="translate(-101.744 -294.32)" fill="#fff"/>-->
<!--                        <path id="Path_418" data-name="Path 418"-->
<!--                              d="M188.391,301.154a.514.514,0,0,1-.567-.508v-4.664a.559.559,0,0,1,.567-.509h1.88c3.752,0,3.67,5.682.074,5.682Zm.518-4.68v3.679h1.363c2.217,0,2.316-3.679,0-3.679Z"-->
<!--                              transform="translate(-182.299 -295.398)" fill="#fff"/>-->
<!--                        <path id="Path_419" data-name="Path 419"-->
<!--                              d="M288.21,296.525v1.305H290.3a.635.635,0,0,1,.592.582.571.571,0,0,1-.592.493H288.21v1.724a.486.486,0,0,1-.492.508.522.522,0,0,1-.582-.508v-4.664a.519.519,0,0,1,.582-.509H290.6a.512.512,0,0,1,.575.509.567.567,0,0,1-.575.559H288.21Z"-->
<!--                              transform="translate(-275.237 -295.383)" fill="#fff"/>-->
<!--                      </g>-->
<!--                      <path id="Path_420" data-name="Path 420"-->
<!--                            d="M115.512,417.027H96v1.027h19.512a1.03,1.03,0,0,0,1.027-1.027V416A1.03,1.03,0,0,1,115.512,417.027Z"-->
<!--                            transform="translate(-59.892 -389.299)" fill="#cad1d8"/>-->
<!--                    </g>-->
<!--                  </svg>-->
<!--                  <span>Donwload PDF</span>-->
<!--                </button>-->
<!--              </b-dropdown-item>-->
<!--              <b-dropdown-item custom aria-role="listitem">-->
<!--                <button @click="submitArbitration"><span>Arbitration</span></button>-->
<!--              </b-dropdown-item>-->
<!--            </b-dropdown>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="cards__item cards__item&#45;&#45;warning">-->
<!--        <div class="row align-items-center">-->
<!--          <div class="col-md-4">-->
<!--            <div class="item__title">Fully Furnished Aparment</div>-->
<!--            <div class="item__subtitle">-->
<!--              Tenant: Loreum ipsum (email@tesemail.io)-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-2">-->
<!--            <div class="status status&#45;&#45;warning">Waiting Deposit</div>-->
<!--          </div>-->
<!--          <div class="col-md-3">-->
<!--            <div class="item__date">2021 10 21 - 2022 01 29</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="cards__item cards__item&#45;&#45;done">-->
<!--        <div class="row align-items-center">-->
<!--          <div class="col-md-4">-->
<!--            <div class="item__title">Fully Furnished Aparment</div>-->
<!--            <div class="item__subtitle">-->
<!--              Tenant: Loreum ipsum (email@tesemail.io)-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-2">-->
<!--            <div class="status status&#45;&#45;done">Payment done</div>-->
<!--          </div>-->
<!--          <div class="col-md-3">-->
<!--            <div class="item__date">2021 10 21 - 2022 01 29</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="cards__item cards__item&#45;&#45;withdraw">-->
<!--        <div class="row align-items-center">-->
<!--          <div class="col-md-4">-->
<!--            <div class="item__title">Fully Furnished Aparment</div>-->
<!--            <div class="item__subtitle">-->
<!--              Tenant: Loreum ipsum (email@tesemail.io)-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-2">-->
<!--            <div class="status status&#45;&#45;withdraw">Withdraw ready</div>-->
<!--          </div>-->
<!--          <div class="col-md-3">-->
<!--            <div class="item__date">2021 10 21 - 2022 01 29</div>-->
<!--          </div>-->
<!--          <div class="col-md-3">-->
<!--            <a class="item__btn item__btn&#45;&#45;withdraw" href="#">Withdraw</a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="cards__item cards__item&#45;&#45;success">-->
<!--        <div class="row align-items-center">-->
<!--          <div class="col-md-4">-->
<!--            <div class="item__title">Apartment for Rent in VIVA Residence</div>-->
<!--            <div class="item__subtitle">-->
<!--              Tenant: Loreum ipsum (email@tesemail.io)-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-2">-->
<!--            <div class="status status&#45;&#45;success">Rental contract</div>-->
<!--          </div>-->
<!--          <div class="col-md-3">-->
<!--            <div class="item__date">2021 10 21 - 2022 01 29</div>-->
<!--          </div>-->
<!--          <div class="col-md-3 card-action">-->
<!--            <div class="btns">-->
<!--              <a class="item__btn item__btn&#45;&#45;accept" href="#">Accept</a>-->
<!--              <a class="item__btn item__btn&#45;&#45;deposit" href="#">Deposit Release</a>-->
<!--            </div>-->
<!--            <b-dropdown-->
<!--                append-to-body-->
<!--                aria-role="menu"-->
<!--                scrollable-->
<!--                position="is-bottom-left"-->
<!--                max-height="200"-->
<!--                trap-focus-->
<!--            >-->
<!--              <template #trigger>-->
<!--                <button class="more">-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg" width="4.248" height="19.116" viewBox="0 0 4.248 19.116">-->
<!--                    <g id="more" transform="translate(0 0)">-->
<!--                      <g id="Group_353" data-name="Group 353" transform="translate(0 0)">-->
<!--                        <g id="Group_352" data-name="Group 352" transform="translate(0 0)">-->
<!--                          <circle id="Ellipse_48" data-name="Ellipse 48" cx="2.124" cy="2.124" r="2.124"-->
<!--                                  fill="#afafc3"/>-->
<!--                        </g>-->
<!--                      </g>-->
<!--                      <g id="Group_355" data-name="Group 355" transform="translate(0 7.434)">-->
<!--                        <g id="Group_354" data-name="Group 354" transform="translate(0)">-->
<!--                          <circle id="Ellipse_49" data-name="Ellipse 49" cx="2.124" cy="2.124" r="2.124"-->
<!--                                  fill="#afafc3"/>-->
<!--                        </g>-->
<!--                      </g>-->
<!--                      <g id="Group_357" data-name="Group 357" transform="translate(0 14.868)">-->
<!--                        <g id="Group_356" data-name="Group 356" transform="translate(0)">-->
<!--                          <circle id="Ellipse_50" data-name="Ellipse 50" cx="2.124" cy="2.124" r="2.124"-->
<!--                                  fill="#afafc3"/>-->
<!--                        </g>-->
<!--                      </g>-->
<!--                    </g>-->
<!--                  </svg>-->
<!--                </button>-->
<!--              </template>-->

<!--              <b-dropdown-item custom aria-role="listitem">-->
<!--                <button @click="downloadPDF" class="download">-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg" width="28.755" height="32.863" viewBox="0 0 28.755 32.863">-->
<!--                    <g id="pdf" transform="translate(-32)">-->
<!--                      <path id="Path_413" data-name="Path 413"-->
<!--                            d="M98.054,0A2.06,2.06,0,0,0,96,2.054V30.809a2.06,2.06,0,0,0,2.054,2.054h20.539a2.06,2.06,0,0,0,2.054-2.054V8.216L112.431,0Z"-->
<!--                            transform="translate(-59.892)" fill="#e2e5e7"/>-->
<!--                      <path id="Path_414" data-name="Path 414"-->
<!--                            d="M354.054,8.216h6.162L352,0V6.162A2.06,2.06,0,0,0,354.054,8.216Z"-->
<!--                            transform="translate(-299.461)" fill="#b0b7bd"/>-->
<!--                      <path id="Path_415" data-name="Path 415" d="M390.162,134.162,384,128h6.162Z"-->
<!--                            transform="translate(-329.407 -119.784)" fill="#cad1d8"/>-->
<!--                      <path id="Path_416" data-name="Path 416"-->
<!--                            d="M56.647,251.3a1.03,1.03,0,0,1-1.027,1.027H33.027A1.03,1.03,0,0,1,32,251.3v-10.27A1.03,1.03,0,0,1,33.027,240H55.62a1.03,1.03,0,0,1,1.027,1.027Z"-->
<!--                            transform="translate(0 -224.596)" fill="#f15642"/>-->
<!--                      <g id="Group_411" data-name="Group 411" transform="translate(36.476 18.891)">-->
<!--                        <path id="Path_417" data-name="Path 417"-->
<!--                              d="M101.744,294.887a.565.565,0,0,1,.558-.567h1.9a2.053,2.053,0,0,1,0,4.106h-1.371v1.084a.515.515,0,0,1-.526.566.553.553,0,0,1-.558-.566Zm1.083.467V297.4H104.2a1.024,1.024,0,0,0,0-2.046Z"-->
<!--                              transform="translate(-101.744 -294.32)" fill="#fff"/>-->
<!--                        <path id="Path_418" data-name="Path 418"-->
<!--                              d="M188.391,301.154a.514.514,0,0,1-.567-.508v-4.664a.559.559,0,0,1,.567-.509h1.88c3.752,0,3.67,5.682.074,5.682Zm.518-4.68v3.679h1.363c2.217,0,2.316-3.679,0-3.679Z"-->
<!--                              transform="translate(-182.299 -295.398)" fill="#fff"/>-->
<!--                        <path id="Path_419" data-name="Path 419"-->
<!--                              d="M288.21,296.525v1.305H290.3a.635.635,0,0,1,.592.582.571.571,0,0,1-.592.493H288.21v1.724a.486.486,0,0,1-.492.508.522.522,0,0,1-.582-.508v-4.664a.519.519,0,0,1,.582-.509H290.6a.512.512,0,0,1,.575.509.567.567,0,0,1-.575.559H288.21Z"-->
<!--                              transform="translate(-275.237 -295.383)" fill="#fff"/>-->
<!--                      </g>-->
<!--                      <path id="Path_420" data-name="Path 420"-->
<!--                            d="M115.512,417.027H96v1.027h19.512a1.03,1.03,0,0,0,1.027-1.027V416A1.03,1.03,0,0,1,115.512,417.027Z"-->
<!--                            transform="translate(-59.892 -389.299)" fill="#cad1d8"/>-->
<!--                    </g>-->
<!--                  </svg>-->
<!--                  <span>Donwload PDF</span>-->
<!--                </button>-->
<!--              </b-dropdown-item>-->
<!--              <b-dropdown-item custom aria-role="listitem">-->
<!--                <button @click="submitArbitration"><span>Arbitration</span></button>-->
<!--              </b-dropdown-item>-->
<!--            </b-dropdown>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="cards__item cards__item&#45;&#45;success">-->
<!--        <div class="row align-items-center">-->
<!--          <div class="col-md-4">-->
<!--            <div class="item__title">Apartment for Rent in VIVA Residence</div>-->
<!--            <div class="item__subtitle">-->
<!--              Tenant: Loreum ipsum (email@tesemail.io)-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-2">-->
<!--            <div class="status status&#45;&#45;success">Rental contract</div>-->
<!--          </div>-->
<!--          <div class="col-md-3">-->
<!--            <div class="item__date">2021 10 21 - 2022 01 29</div>-->
<!--          </div>-->
<!--          <div class="col-md-3 card-action">-->
<!--            <div class="btns">-->
<!--              <a class="item__btn item__btn&#45;&#45;active" href="#">Active</a>-->
<!--              <a class="item__btn item__btn&#45;&#45;deposit" href="#">Deposit Release</a>-->
<!--            </div>-->
<!--            <b-dropdown-->
<!--                append-to-body-->
<!--                aria-role="menu"-->
<!--                scrollable-->
<!--                position="is-bottom-left"-->
<!--                max-height="200"-->
<!--                trap-focus-->
<!--            >-->
<!--              <template #trigger>-->
<!--                <button class="more">-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg" width="4.248" height="19.116" viewBox="0 0 4.248 19.116">-->
<!--                    <g id="more" transform="translate(0 0)">-->
<!--                      <g id="Group_353" data-name="Group 353" transform="translate(0 0)">-->
<!--                        <g id="Group_352" data-name="Group 352" transform="translate(0 0)">-->
<!--                          <circle id="Ellipse_48" data-name="Ellipse 48" cx="2.124" cy="2.124" r="2.124"-->
<!--                                  fill="#afafc3"/>-->
<!--                        </g>-->
<!--                      </g>-->
<!--                      <g id="Group_355" data-name="Group 355" transform="translate(0 7.434)">-->
<!--                        <g id="Group_354" data-name="Group 354" transform="translate(0)">-->
<!--                          <circle id="Ellipse_49" data-name="Ellipse 49" cx="2.124" cy="2.124" r="2.124"-->
<!--                                  fill="#afafc3"/>-->
<!--                        </g>-->
<!--                      </g>-->
<!--                      <g id="Group_357" data-name="Group 357" transform="translate(0 14.868)">-->
<!--                        <g id="Group_356" data-name="Group 356" transform="translate(0)">-->
<!--                          <circle id="Ellipse_50" data-name="Ellipse 50" cx="2.124" cy="2.124" r="2.124"-->
<!--                                  fill="#afafc3"/>-->
<!--                        </g>-->
<!--                      </g>-->
<!--                    </g>-->
<!--                  </svg>-->
<!--                </button>-->
<!--              </template>-->

<!--              <b-dropdown-item custom aria-role="listitem">-->
<!--                <button @click="downloadPDF" class="download">-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg" width="28.755" height="32.863" viewBox="0 0 28.755 32.863">-->
<!--                    <g id="pdf" transform="translate(-32)">-->
<!--                      <path id="Path_413" data-name="Path 413"-->
<!--                            d="M98.054,0A2.06,2.06,0,0,0,96,2.054V30.809a2.06,2.06,0,0,0,2.054,2.054h20.539a2.06,2.06,0,0,0,2.054-2.054V8.216L112.431,0Z"-->
<!--                            transform="translate(-59.892)" fill="#e2e5e7"/>-->
<!--                      <path id="Path_414" data-name="Path 414"-->
<!--                            d="M354.054,8.216h6.162L352,0V6.162A2.06,2.06,0,0,0,354.054,8.216Z"-->
<!--                            transform="translate(-299.461)" fill="#b0b7bd"/>-->
<!--                      <path id="Path_415" data-name="Path 415" d="M390.162,134.162,384,128h6.162Z"-->
<!--                            transform="translate(-329.407 -119.784)" fill="#cad1d8"/>-->
<!--                      <path id="Path_416" data-name="Path 416"-->
<!--                            d="M56.647,251.3a1.03,1.03,0,0,1-1.027,1.027H33.027A1.03,1.03,0,0,1,32,251.3v-10.27A1.03,1.03,0,0,1,33.027,240H55.62a1.03,1.03,0,0,1,1.027,1.027Z"-->
<!--                            transform="translate(0 -224.596)" fill="#f15642"/>-->
<!--                      <g id="Group_411" data-name="Group 411" transform="translate(36.476 18.891)">-->
<!--                        <path id="Path_417" data-name="Path 417"-->
<!--                              d="M101.744,294.887a.565.565,0,0,1,.558-.567h1.9a2.053,2.053,0,0,1,0,4.106h-1.371v1.084a.515.515,0,0,1-.526.566.553.553,0,0,1-.558-.566Zm1.083.467V297.4H104.2a1.024,1.024,0,0,0,0-2.046Z"-->
<!--                              transform="translate(-101.744 -294.32)" fill="#fff"/>-->
<!--                        <path id="Path_418" data-name="Path 418"-->
<!--                              d="M188.391,301.154a.514.514,0,0,1-.567-.508v-4.664a.559.559,0,0,1,.567-.509h1.88c3.752,0,3.67,5.682.074,5.682Zm.518-4.68v3.679h1.363c2.217,0,2.316-3.679,0-3.679Z"-->
<!--                              transform="translate(-182.299 -295.398)" fill="#fff"/>-->
<!--                        <path id="Path_419" data-name="Path 419"-->
<!--                              d="M288.21,296.525v1.305H290.3a.635.635,0,0,1,.592.582.571.571,0,0,1-.592.493H288.21v1.724a.486.486,0,0,1-.492.508.522.522,0,0,1-.582-.508v-4.664a.519.519,0,0,1,.582-.509H290.6a.512.512,0,0,1,.575.509.567.567,0,0,1-.575.559H288.21Z"-->
<!--                              transform="translate(-275.237 -295.383)" fill="#fff"/>-->
<!--                      </g>-->
<!--                      <path id="Path_420" data-name="Path 420"-->
<!--                            d="M115.512,417.027H96v1.027h19.512a1.03,1.03,0,0,0,1.027-1.027V416A1.03,1.03,0,0,1,115.512,417.027Z"-->
<!--                            transform="translate(-59.892 -389.299)" fill="#cad1d8"/>-->
<!--                    </g>-->
<!--                  </svg>-->
<!--                  <span>Donwload PDF</span>-->
<!--                </button>-->
<!--              </b-dropdown-item>-->
<!--              <b-dropdown-item custom aria-role="listitem">-->
<!--                <button @click="submitArbitration"><span>Arbitration</span></button>-->
<!--              </b-dropdown-item>-->
<!--            </b-dropdown>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="cards__item cards__item&#45;&#45;success">-->
<!--        <div class="row align-items-center">-->
<!--          <div class="col-md-4">-->
<!--            <div class="item__title">Apartment for Rent in VIVA Residence</div>-->
<!--            <div class="item__subtitle">-->
<!--              Tenant: Loreum ipsum (email@tesemail.io)-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-2">-->
<!--            <div class="status status&#45;&#45;success">Rental contract</div>-->
<!--          </div>-->
<!--          <div class="col-md-3">-->
<!--            <div class="item__date">2021 10 21 - 2022 01 29</div>-->
<!--          </div>-->
<!--          <div class="col-md-3 card-action">-->
<!--            <div class="btns">-->
<!--              <a class="item__btn item__btn&#45;&#45;active" href="#">Active</a>-->
<!--              <a class="item__btn item__btn&#45;&#45;deposit" href="#">Deposit Release</a>-->
<!--            </div>-->
<!--            <b-dropdown-->
<!--                append-to-body-->
<!--                aria-role="menu"-->
<!--                scrollable-->
<!--                position="is-bottom-left"-->
<!--                max-height="200"-->
<!--                trap-focus-->
<!--            >-->
<!--              <template #trigger>-->
<!--                <button class="more">-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg" width="4.248" height="19.116" viewBox="0 0 4.248 19.116">-->
<!--                    <g id="more" transform="translate(0 0)">-->
<!--                      <g id="Group_353" data-name="Group 353" transform="translate(0 0)">-->
<!--                        <g id="Group_352" data-name="Group 352" transform="translate(0 0)">-->
<!--                          <circle id="Ellipse_48" data-name="Ellipse 48" cx="2.124" cy="2.124" r="2.124"-->
<!--                                  fill="#afafc3"/>-->
<!--                        </g>-->
<!--                      </g>-->
<!--                      <g id="Group_355" data-name="Group 355" transform="translate(0 7.434)">-->
<!--                        <g id="Group_354" data-name="Group 354" transform="translate(0)">-->
<!--                          <circle id="Ellipse_49" data-name="Ellipse 49" cx="2.124" cy="2.124" r="2.124"-->
<!--                                  fill="#afafc3"/>-->
<!--                        </g>-->
<!--                      </g>-->
<!--                      <g id="Group_357" data-name="Group 357" transform="translate(0 14.868)">-->
<!--                        <g id="Group_356" data-name="Group 356" transform="translate(0)">-->
<!--                          <circle id="Ellipse_50" data-name="Ellipse 50" cx="2.124" cy="2.124" r="2.124"-->
<!--                                  fill="#afafc3"/>-->
<!--                        </g>-->
<!--                      </g>-->
<!--                    </g>-->
<!--                  </svg>-->
<!--                </button>-->
<!--              </template>-->

<!--              <b-dropdown-item custom aria-role="listitem">-->
<!--                <button @click="downloadPDF" class="download">-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg" width="28.755" height="32.863" viewBox="0 0 28.755 32.863">-->
<!--                    <g id="pdf" transform="translate(-32)">-->
<!--                      <path id="Path_413" data-name="Path 413"-->
<!--                            d="M98.054,0A2.06,2.06,0,0,0,96,2.054V30.809a2.06,2.06,0,0,0,2.054,2.054h20.539a2.06,2.06,0,0,0,2.054-2.054V8.216L112.431,0Z"-->
<!--                            transform="translate(-59.892)" fill="#e2e5e7"/>-->
<!--                      <path id="Path_414" data-name="Path 414"-->
<!--                            d="M354.054,8.216h6.162L352,0V6.162A2.06,2.06,0,0,0,354.054,8.216Z"-->
<!--                            transform="translate(-299.461)" fill="#b0b7bd"/>-->
<!--                      <path id="Path_415" data-name="Path 415" d="M390.162,134.162,384,128h6.162Z"-->
<!--                            transform="translate(-329.407 -119.784)" fill="#cad1d8"/>-->
<!--                      <path id="Path_416" data-name="Path 416"-->
<!--                            d="M56.647,251.3a1.03,1.03,0,0,1-1.027,1.027H33.027A1.03,1.03,0,0,1,32,251.3v-10.27A1.03,1.03,0,0,1,33.027,240H55.62a1.03,1.03,0,0,1,1.027,1.027Z"-->
<!--                            transform="translate(0 -224.596)" fill="#f15642"/>-->
<!--                      <g id="Group_411" data-name="Group 411" transform="translate(36.476 18.891)">-->
<!--                        <path id="Path_417" data-name="Path 417"-->
<!--                              d="M101.744,294.887a.565.565,0,0,1,.558-.567h1.9a2.053,2.053,0,0,1,0,4.106h-1.371v1.084a.515.515,0,0,1-.526.566.553.553,0,0,1-.558-.566Zm1.083.467V297.4H104.2a1.024,1.024,0,0,0,0-2.046Z"-->
<!--                              transform="translate(-101.744 -294.32)" fill="#fff"/>-->
<!--                        <path id="Path_418" data-name="Path 418"-->
<!--                              d="M188.391,301.154a.514.514,0,0,1-.567-.508v-4.664a.559.559,0,0,1,.567-.509h1.88c3.752,0,3.67,5.682.074,5.682Zm.518-4.68v3.679h1.363c2.217,0,2.316-3.679,0-3.679Z"-->
<!--                              transform="translate(-182.299 -295.398)" fill="#fff"/>-->
<!--                        <path id="Path_419" data-name="Path 419"-->
<!--                              d="M288.21,296.525v1.305H290.3a.635.635,0,0,1,.592.582.571.571,0,0,1-.592.493H288.21v1.724a.486.486,0,0,1-.492.508.522.522,0,0,1-.582-.508v-4.664a.519.519,0,0,1,.582-.509H290.6a.512.512,0,0,1,.575.509.567.567,0,0,1-.575.559H288.21Z"-->
<!--                              transform="translate(-275.237 -295.383)" fill="#fff"/>-->
<!--                      </g>-->
<!--                      <path id="Path_420" data-name="Path 420"-->
<!--                            d="M115.512,417.027H96v1.027h19.512a1.03,1.03,0,0,0,1.027-1.027V416A1.03,1.03,0,0,1,115.512,417.027Z"-->
<!--                            transform="translate(-59.892 -389.299)" fill="#cad1d8"/>-->
<!--                    </g>-->
<!--                  </svg>-->
<!--                  <span>Donwload PDF</span>-->
<!--                </button>-->
<!--              </b-dropdown-item>-->
<!--              <b-dropdown-item custom aria-role="listitem">-->
<!--                <button @click="submitArbitration"><span>Arbitration</span></button>-->
<!--              </b-dropdown-item>-->
<!--            </b-dropdown>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

  </div>
  <!-- ./ content-wrapper-->
</template>

<script>
import axios from "axios";
import config from "../../config/constants.js";
// import AcceptContractButton from "../../components/crypto/AcceptContractButton";
// import ReleaseDepositButton from "../../components/crypto/ReleaseDepositButton";
import ContractService from "../../services/contract.service";
import PendingRequestCard from "./PendingRequestCard";
import ArchivedRequestCard from "./ArchivedRequestCard";

export default {
  name: "ProfileRequest",
  components: {
    ArchivedRequestCard,
    // AcceptContractButton,
    // ReleaseDepositButton,
    PendingRequestCard
  },
  data() {
    return {
      requestsArchived: [],
      requestsPending: [],
    };
  },
  methods: {
    getContracts() {
      const loadingComponent = this.$buefy.loading.open({
        canCancel: true,
      });

      setTimeout(() => {
        loadingComponent.close();
      }, 1500);

      ContractService.getAll().then(response => {
        console.log(response);
        this.requestsPending = response.data
            .filter(contract => (contract.contractStatus === "Pending Request" || contract.contractStatus === "Request Received"));

        this.requestsArchived = response.data
            .filter(contract => (contract.contractStatus !== "Pending Request" && contract.contractStatus !== "Request Received"));
      }).catch(error => {
        console.log(error);
      });
    },
    successCallback(ev) {
      // TODO toast, fetch etc.
      this.getContracts();
      console.log('Success', ev);
    },
    errorCallback(ev) {
      // TODO toast etc.
      console.error('Error', ev);
    },
    doAccept() {
      // TODO: WHERE We go?
    },
    doDepositRelease() {
      // TODO: Where we go
    },
    downloadPDF() {
      let payload = {
        userId: 1,
        requestId: 1,
      };

      axios
          .post(config.BASE_URL + "/profile/getpdf", payload)
          .then((response) => {
            console.log(response);
            // show buefy toast
            // this.$buefy.toast.open({
            //     message: 'DONE!',
            //     type: 'is-success'
            // });
          })
          .catch((error) => console.log(error));
    },
    submitArbitration() {
      let payload = {
        userId: 1,
        requestId: 1,
      };

      axios
          .post(config.BASE_URL + "/profile/arbitration", payload)
          .then((response) => {
            console.log(response);
            // show buefy toast
            // this.$buefy.toast.open({
            //     duration: 3000,
            //     message: 'DONE!',
            //     type: 'is-success'
            // });
          })
          .catch((error) => {
            console.log(error);
            this.$buefy.toast.open({
              duration: 3000,
              message: "Error! " + error,
              type: "is-danger",
            });
          });
    },
    separatedRequests() {
      // TODO: foreach all request and separated what kind of item archived or pending.
      // TODO: And what is the type for...success, done, warning, withdraw

    },
  },
  mounted() {
    this.getContracts();
  },
};
</script>

<style scoped>
.profile .cards .item__btn--deposit {
  line-height: 1.1;
  margin: 10px 0;
  border: 1px solid #449e8d;
  color: #449e8d;
  font-size: 15px;
  padding: 0 10px;
  text-align: center;
  max-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile .cards .item__btn--deposit:hover {
  background: #000;
  color: #fff;
  border: 1px solid #000;
}

.card-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btns {
  margin-right: 15px;
}

.download svg {
  width: 15px;
  margin-right: 10px;
}
</style>
