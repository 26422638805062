const BASE_URL = process.env.VUE_APP_BACKEND_URL;

// auth
const API_LOGIN = BASE_URL + "/login";
const API_REG = BASE_URL + "/registration";
const API_FORGOT_PASS = BASE_URL + "/forgottenPassword";
const API_NEW_PASS = BASE_URL + "/forgottenPassword/setNewPassword"; // 2 version tokens

const API_SEARCH = BASE_URL + "/property/search";
const API_CITY = BASE_URL + "/city";
const API_DETAIL = BASE_URL + "/property/findById"; // need param {id}
const API_PROPERTY_MATCHES = BASE_URL + "/property/moreMatches";
const API_PROPERTY = BASE_URL + "/property"; // post
const API_PROPERTY_SAVEAD = BASE_URL + "/property/saveAdForUser";

// profile
const API_PROFILE_NEW_PASS = BASE_URL + "/profile/setNewPassword";
const API_GET_AVATAR = BASE_URL + "/profile/getAvatar"; // {id}
const API_CHANGE_AVATAR = BASE_URL + "/profile/changeAvatar"; // {id}
const API_PROFILE = BASE_URL + "/user/findById"; // {id}
const API_PROFILE_REQUESTS = "/profile"; // ????
const API_PROFILE_MYPROPERTIES = "/profile/myProperties"; // ????

const API_CONTRACT_RENTALS = "/contract/myRentals";
const API_CONTRACT_PAMYENT = "/contract/paymentsTransfers";
const API_CONTRACT_ALL = "/contract/getAllContractByLoggedInUser";

const GOOGLE_MAPS_CONFIG = {
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  disableDefaultUi: false,
  /**
   * When false, map icons are not clickable.
   * A map icon represents a point of interest,
   * also known as a POI.
   * By default map icons are clickable.
   */
  clickableIcons: false,
  styles: [
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#e9e9e9",
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          lightness: 29,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#dedede",
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#ffffff",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          saturation: 36,
        },
        {
          color: "#333333",
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        {
          color: "#f2f2f2",
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#fefefe",
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#fefefe",
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
  ]
}

export default {
  BASE_URL,
  API_LOGIN,
  API_FORGOT_PASS,

  API_CITY,

  API_REG,
  API_PROFILE,
  API_NEW_PASS,

  API_CHANGE_AVATAR,
  API_GET_AVATAR,
  API_PROFILE_REQUESTS,
  API_PROFILE_MYPROPERTIES,
  API_PROPERTY_SAVEAD,
  API_PROFILE_NEW_PASS,
  GOOGLE_MAPS_CONFIG,

  API_SEARCH,
  API_DETAIL,
  API_PROPERTY_MATCHES,
  API_PROPERTY,

  API_CONTRACT_RENTALS,
  API_CONTRACT_PAMYENT,
  API_CONTRACT_ALL
}
