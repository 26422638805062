<template>
  <div class="form-group">
    <label :for="id">{{ label }} <span v-if="rules && rules.required"
                                                         class="required">*</span></label>
    <ValidationProvider
      :name="label"
      :rules="rules"
      v-slot="{ errors }">
      <div class="input-wrapper">
        <input
          :type="type"
          :name="id"
          :id="id"
          class="form-control"
          :placeholder="placeholder ? placeholder : label"
          v-model="value"
        />
        <div v-if="formHelper" class="form-helper">{{ formHelper }}</div>
      </div>
      <small class="form-text text-error">
        {{ errors[0] }}
      </small>
    </ValidationProvider>
  </div>
</template>

<script>
import {
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full.esm";

export default {
  name: "RentInput",
  components: {
    ValidationProvider
  },
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    rules: {
      type: Object,
    },
    formHelper: {
      type: String
    },
    placeholder: {
      type: String
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {}
  },
  data() {
    return {
      inputData: null
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.inputData = val;
      }
    },
    inputData: function (val) {
      this.$emit('input', val);
    }
  }
}
</script>

<style scoped>
.form-helper {
  background-color: #fff;
  padding: 0 10px;
}
</style>
