<template>
  <div>
    <portal-target name="modal-root" multiple>
    </portal-target>

    <div class="page-wrapper">
      <transition name="fade">
        <router-view/>
      </transition>

<!--      TODO delete component when the common modal component is done-->
<!--      <CowMeta/>-->

    </div>
  </div>
</template>

<script>
// import CowMeta from "@/views/CowMeta.vue";
import UserService from "./services/user.service";


export default {
  components: {
  },
  data() {
    return {
      currentUser: null,
    };
  },
  computed: {
  },
  methods: {
    checkIfLoggedIn() {
      let user = localStorage.getItem("user");
      if (user !== undefined && user !== null && user !== "") {
        UserService.getCurrentUser()
          .then((response) => {
            console.log("getCurrentUser response", response);
            this.currentUser = response.data;
          })
          .catch((error) => {
            console.error("getCurrentUser error", error);
            localStorage.setItem("currentUser", null);
          });
      }
    },
  },
  created () {
    this.checkIfLoggedIn();
  },
};
</script>

<style>
html,body{
  font-family: 'Manrope', sans-serif;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.button.is-primary {
 background: #de2279 !important;
}

.button.is-primary:hover, .button.is-primary.is-hovered {
 background: #000 !important;
}

.is-clipped .page-wrapper,
.is-noscroll .page-wrapper {
  filter: blur(5px);
}
</style>
