<!-- THIS IS SEPARATED PAGE -->
<template>
  <div class="page--login">
    <div class="container-fluid">
      <div class="row align-items-start">
        <div class="col-12 col-md-5 col-lg-5 col-xl-6 login-left">
          <div class="circle-graphics"></div>

          <div class="rentible-logo">
            <router-link to="/">
              <img
                  src="../../assets/img/auth/rentible-logo.png"
                  alt="Rentible.io"
              /></router-link>
          </div>
        </div>
        <div class="col-12 col-md-7 col-lg-7 col-xl-6 login-right">
          <div class="auth-box">
            <h2 class="auth-box__title">{{ $t('registration.title') }}</h2>
            <p class="auth-box__text">
              {{ $t('registration.text') }}
            </p>

            <ValidationObserver v-slot="{ invalid }">
              <form
                  class="form--login pt-2 pt-lg-5"
                  @submit.prevent="onSubmit"
              >

                <!--                    <ValidationProvider-->
                <!--                        name="fullname"-->
                <!--                        rules="required"-->
                <!--                        v-slot="{ errors }"-->
                <!--                    >-->
                <!--                        <div class="form-group">-->
                <!--                            <label class="form-label mb-0" for="l-fullname"-->
                <!--                            >{{ $t('form.fullname') }}</label-->
                <!--                            >-->
                <!--                            <div class="input-wrapper">-->
                <!--                            <input-->
                <!--                                class="form-control"-->
                <!--                                type="text"-->
                <!--                                id="l-fullname"-->
                <!--                                name="fullname"-->
                <!--                                :placeholder="$t('form.fullname_placeholder')"-->
                <!--                                v-model="fullname"-->
                <!--                            />-->
                <!--                            </div>-->
                <!--                            <div>-->
                <!--                            <small class="form-text text-error">-->
                <!--                                {{ errors[0] }}-->
                <!--                            </small>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </ValidationProvider>-->
                <!--

                                    <ValidationProvider
                                        name="phone"
                                        rules="required"
                                        v-slot="{ errors }"
                                    >
                                    <div class="form-group">
                                        <label class="form-label mb-0" for="l-phone">{{ $t('form.phone') }}</label>
                                        <div class="input-wrapper">
                                        <input
                                            class="form-control"
                                            type="tel"
                                            id="l-tel"
                                            name="phone"
                                            :placeholder="$t('form.phone_placeholder')"
                                            v-model="phone"
                                        />
                                        </div>
                                        <div>
                                        <small class="form-text text-error">
                                            {{ errors[0] }}
                                        </small>
                                        </div>
                                    </div>
                                     </ValidationProvider> -->


                <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ errors }"
                >
                  <div class="form-group is-validate">
                    <!-- TODO: add .is-validate class when input is valid -->
                    <label class="form-label mb-0" for="l-email">{{ $t('form.email') }}</label>
                    <div class="input-wrapper">
                      <input
                          class="form-control"
                          type="email"
                          id="l-email"
                          name="email"
                          :placeholder="$t('form.email_placeholder')"
                          autofocus
                          autocomplete="off"
                          v-model="email"
                      />
                      <i v-show="isValidEmail" class="icon--success"></i>
                    </div>
                    <div>
                      <small class="form-text text-error">
                        {{ errors[0] }}
                      </small>
                    </div>
                  </div>
                </ValidationProvider>


                <ValidationProvider
                    name="password"
                    rules="required|min:8"
                    v-slot="{ errors }"
                >
                  <div class="form-group is-validate">
                    <!-- TODO: add .is-validate class when input is valid -->
                    <label class="form-label mb-0" for="l-password">{{ $t('form.password') }}</label>
                    <div class="input-wrapper">
                      <input
                          class="form-control"
                          type="password"
                          id="l-password"
                          name="password"
                          :placeholder="$t('form.password')"
                          autofocus
                          autocomplete="new-password"
                          v-model="password"
                      />
                    </div>
                    <div>
                      <small class="form-text text-error">
                        {{ errors[0] }}
                      </small>
                    </div>
                  </div>
                </ValidationProvider>

                <RentCodeStoreSelect code-store-id="7"
                                     :label="$cte('registration.language')"
                                     v-model="languageId"
                                     :rules="{required: true}"
                                     :key="'language'"/>

                <div class="form-submit">
                  <div
                      v-show="loading"
                      class="loadingio-spinner-rolling-3m72f4ve39n"
                  >
                    <div class="ldio-d701fl78k4p"></div>
                  </div>
                  <button class="btn btn-primary btn--fullwidth" :disabled="invalid">
                    <div v-show="loading" class="loader"></div>
                    <span v-show="!loading"> {{ $t('form.signup') }}</span>
                  </button>
                </div>
                <div class="form-group">
                  <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                  </div>
                </div>
                <div class="form-action pt-3">
                  <p class="mb-0">
                    {{ $t('form.text1') }}
                    <router-link to="/login"> {{ $t('form.signin') }}</router-link>
                  </p>
                </div>
              </form>
            </ValidationObserver>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ValidationProvider} from "vee-validate/dist/vee-validate.full.esm";
import {ValidationObserver} from "vee-validate";
import RentCodeStoreSelect from "@/components/forms/RentCodeStoreSelect";

export default {
  name: "Registration",
  components: {
    ValidationProvider,
    ValidationObserver,
    RentCodeStoreSelect
  },
  data() {
    return {
      email: "",
      //phone: "",
      // fullname: "",
      languageId: null,
      loading: false,
      password: "",
      message: "",
    };
  },
  created() {
    // TODO: go to global
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    onSubmit() {
      this.loading = true;

      var payload = {
        email: this.email,
        //phone: this.phone,
        password: this.password,
        languageId: this.languageId
        // fullname: this.fullname
      };

      this.$store.dispatch("auth/register", payload).then(
          (response) => {
            console.log(response);
            this.$buefy.dialog.alert({
                    title: this.$cte("registration.welcomeMessage1"),
                    message: this.$cte("registration.welcomeMessage2"),
                    confirmText: this.$cte("common.continue"),
                    type: "is-success",
                    hasIcon: false,
                    ariaModal: true,
              onConfirm: () => {
                this.$router.push("/login");
              }
            });
          },
          (error) => {
            this.loading = false;
            this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
          }
      );
    },
    switchLocale(value) {
      // TODO hardcoded value...
      let locale = value === 700000 ? 'EN' : 'ES'
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale.toLowerCase();
      }
    },
  },
  watch: {
    languageId(val) {
      this.switchLocale(val);
    }
  }
};
</script>

<style>
@import "../../assets/css/auth.css";

.loader {
  background: transparent url("../../assets/img/loader/loader.gif") no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
}
</style>
