<template>
  <div class="search-box">
    <form @submit.prevent="doSearch">
      <div class="search-box__inner">
        <div class="search-box__col">
          <LocationInput v-model="this.search.cityId" @select="selectLocation" />
        </div>

        <div class="search-box__col">
          <DateRangePickerPanel :from="search.from"
                                :to="search.to"
                                @from="search.from = $event"
                                @to="search.to = $event" />
        </div>

        <div class="search-box__col">
          <SliderPanel
              v-model="numbersBudget"
              :inputLabel="$cte('search.budget')"
              :placeholder="$cte('search.selectBudget')"
              :panelTitle="$cte('search.budget')"
              :min="0"
              :max="5000"
              :valuePrefix="'$'" />
        </div>

        <div class="search-box__col last-col">
          <PropertyTypePanel
              @bedrooms="search.bedrooms = $event"
              @bathrooms="search.bathrooms = $event"
              @sizes="numbersSize = $event"
              @rentalType="search.rentalType = $event"
              :rentalType="search.rentalType"
              :bedrooms="search.bedrooms"
              :bathrooms="search.bathrooms"
              :sizes="numbersSize"
              :min="0"
              :max="700"
              :valueSuffix="' m2'"/>
        </div>

        <button
          type="submit"
          class="btn btn-primary search-box__btn search-btn"
        >
          {{ $cte("search.searchBtn") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import router from "@/router/router";
import DateRangePickerPanel from "./search/DateRangePickerPanel";
import PropertyTypePanel from "./search/PropertyTypePanel";
import LocationInput from '@/components/search/LocationInput';
import SliderPanel from "./search/SliderPanel";

export default {
  name: "TheSearch",
  data() {
    return {
      location: null,
      periods: null,
      numbersBudget: [],
      numbersSize: [],
      search: {
        from: null,
        to: null,
        cityId: null,
        termOfLease: [],
        bedrooms: null,
        bathrooms: null,
        rentalType: null,
        priceMin: 0,
        priceMax: 0,
        sizeMin: 0,
        sizeMax: 0,
        filter: {},
        page: 1,
        itemPerPage: 10,
        sortBy: "weight",
        sortDesc: false,
        withDeleted: false,
      },
    };
  },
  components: {
    SliderPanel,
    PropertyTypePanel,
    LocationInput,
    DateRangePickerPanel,
  },
  methods: {
    getPayload() {
      return {
        filter: this.getFilter(),
        page: this.search.page,
        itemPerPage: this.search.itemPerPage,
        sortBy: this.search.sortBy,
        sortDesc: this.search.sortDesc,
        withDeleted: this.search.withDeleted,
      };
    },
    getFilter() {
      let filter = {};
      if (this.search.cityId) {
        filter.cityId = this.search.cityId
      }
      if (this.numbersBudget.length > 0) {
        filter.priceMin = this.numbersBudget[0];
        filter.priceMax = this.numbersBudget[1];
      }
      if (this.numbersSize.length > 0) {
        filter.sizeMin = this.numbersSize[0];
        filter.sizeMax = this.numbersSize[1];
      }
      if (this.search.from) {
        filter.from = this.search.from;
      }
      if (this.search.to) {
        filter.to = this.search.to;
      }
      if (this.search.bedrooms) {
        filter.bedrooms = this.search.bedrooms;
      }
      if (this.search.bathrooms) {
        filter.bathrooms = this.search.bathrooms;
      }
      if (this.search.rentalType) {
        filter.rentalType = this.search.rentalType;
      }


      return filter;
    },
    selectLocation(data) {
      if (data) {
        this.search.cityId = data.id;
      } else {
        this.search.cityId = null;
      }
    },
    doSearch() {
      let payload = this.getPayload();
      router.push({ name: 'Properties', query: payload});
    }
  },
};
</script>

<style>
.search-advanced .search-dropdown, .search-box .search-dropdown {
  margin-top: 10px;
}

.dropdown-menu {
  border: none;
}

.search-advanced .search-dropdown--date, .search-box .search-dropdown--date {
  min-width: 330px;
}

.search-dropdown__date {
  padding-top: 15px;
}

.search-box .search-dropdown__date {
  min-height: 100px;
}

.search-dropdown__date .has-link {
  margin-bottom: 15px;
  cursor: pointer;
}

.search-dropdown__date .has-link input {
  opacity: 1;
  visibility: visible;
  margin-right: 10px
}

.b-slider .b-slider-thumb-wrapper .b-slider-thumb {
  border-radius: 50% !important;
  border: none !important;
  background: #707070 !important;
  border: 2px solid #fff !important;
  z-index: 1005;
}

.b-slider .b-slider-track {
  background: #EDEDED !important;
}

.b-slider .b-slider-tick {
  background: #EDEDED !important;
}

.search-dropdown .b-slider.is-primary .b-slider-fill {
  background: #de2279 !important;
  border: none !important;
  z-index: 1000;
}

.b-tooltip.is-primary .tooltip-content {
  background: #de2279 !important;
}

.dropdown.dropdown-menu-animation .dropdown-menu {
  background: none;
}

.b-slider .b-slider-thumb-wrapper {
  z-index: 2000;
}

.search-dropdown__date .values {
  display: flex;
  font-weight: 700;
  font-family: 'BizmoBold';
  width: 100%;
  flex: 1;
  justify-content: space-between;
}

.search-box__btn {
  margin: 12px 12px 8px 0;
}
</style>
