<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14.477" height="8.834" viewBox="0 0 14.477 8.834">
    <g id="down-arrow" transform="translate(0)">
      <g id="Group_6" data-name="Group 6" transform="translate(0)">
        <path id="Path_1412" data-name="Path 1412"
              d="M13.98,98.49A1.7,1.7,0,0,0,12.78,98H1.7a1.7,1.7,0,0,0-1.2.491,1.666,1.666,0,0,0,0,2.372l5.542,5.48a1.713,1.713,0,0,0,2.4,0l3.64-3.615a.555.555,0,0,0,0-.791.57.57,0,0,0-.8,0l-3.639,3.614a.571.571,0,0,1-.8,0L1.3,100.072a.555.555,0,0,1,0-.791.565.565,0,0,1,.4-.164H12.78a.565.565,0,0,1,.4.164.555.555,0,0,1,0,.791.555.555,0,0,0,0,.791.57.57,0,0,0,.8,0A1.666,1.666,0,0,0,13.98,98.49Z"
              transform="translate(0.001 -97.999)"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "OpenIndicator"
}
</script>

<style scoped>

</style>
