import {i18n} from "../i18n/i18n";
import Vue from "vue";

const errorDefaults = {
  duration: 5000,
  position: 'is-top',
  type: 'is-danger'
};

const successDefaults = {
  duration: 5000,
  position: 'is-top',
  type: 'is-success'
};

const warningDefaults = {
  duration: 5000,
  position: 'is-top',
  type: 'is-warning'
};

export function showError(message, configs) {
  let config = Object.assign({}, errorDefaults, {message: i18n.t(message)}, configs);
  Vue.prototype.$buefy.toast.open(config);
}

export function showSuccess(message, configs) {
  let config = Object.assign({}, successDefaults, {message: i18n.t(message)}, configs);
  Vue.prototype.$buefy.toast.open(config);
}

export function showWarning(message, configs) {
  let config = Object.assign({}, warningDefaults, {message: i18n.t(message)}, configs);
  Vue.prototype.$buefy.toast.open(config);
}
