<template>
  <div class="row">
    <div class="col-md-7 p-0">
      <Carousel :images="images"/>
    </div>
    <div class="col-md-5 d-md-block d-none">
      <!-- TODO: ez honnan jön ??? -->

      <div class="flat-gallery">
        <a href="#" v-for="image in images.slice(0, 4)"
           :key="image.uuid"
        ><img
            class="cover-fit"
            :src="getImage(image)"
            alt="."/></a>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "./Carousel.vue";

export default {
  name: "Gallery",
  props: {
    images: Array
  },
  data() {
    return {
      gallery: [],
    }
  },
  components: {
    Carousel
  },
  methods: {
    getImage(image) {
      return process.env.VUE_APP_BACKEND_URL + '/' + image.filePath;
    },
  },
  mounted() {

  }
}
</script>


<style>
.cover-fit {
  object-fit: cover;
  max-height: 295px;
  width: 100%;
}
</style>

