<template>
  <div>
    <button class="item__btn item__btn--accept" @click="showConfirm">
      {{ $cte('crypto.button.accept') }}
    </button>

    <Modal ref="confirm-modal">
      <div class="custom-modal-center">
        <div class="custom-modal-text">
          <h2>{{ $cte('acceptContractModal.title') }}</h2>
          <p>
            {{ $cte('acceptContractModal.paragraph01') }}
          </p>
          <p v-html="$cte('acceptContractModal.paragraph02')">
          </p>

          <p v-html="$cte('acceptContractModal.paragraph03')">
          </p>

          <p>
            <em>
              {{ $cte('acceptContractModal.paragraph04') }}
            </em>
          </p>
        </div>
        <div class="button-wrapper text-center mt-3">
          <!--          <button class="btn btn-outline" @click="closeModal">{{ $cte('propertyContractPanel.closeButton') }}</button>-->
          <button class="btn btn-primary" @click="acceptContract">{{ $cte('common.Accept') }}</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import CryptoService from "@/services/crypto.service";
import Modal from "../Modal";
export default {
  name: "AcceptContractButton",
  components: {
    Modal
  },
  props: {
    roomAdId: {
      type: Number,
      required: true
    },
    contractId: {
      type: Number,
      required: true
    }
  },
  methods: {
    showConfirm() {
      this.$refs['confirm-modal'].isOpen = true;
    },
    acceptContract() {
      CryptoService.acceptContract({
        roomAdId: this.roomAdId,
        contractId: this.contractId
      }).then(res => {
        this.$emit('success', res);
      }).catch(err => {
        this.$emit('error', err);
      })
    }
  }
}
</script>

<style scoped>

</style>
