import axios from "axios";
import config from "../config/constants.js";

export default class CityService {

  // TODO debug data only
  static getCities() {
    const url = config.API_CITY + '/list';
    return axios.get(url);
    // return new Promise((resolve) => {
    //   resolve({data: [
    //     {
    //       id: 1,
    //       city: 'San Salvador'
    //     },
    //   ]})
    // })
  }
}
