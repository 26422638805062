import ChatService from "@/services/chat.service";
import webstomp from "webstomp-client";

const initialState = {
  isStandaloneChat: false,
  partners: [],
  partner: null,
  isPartnerListOpen: true,
  isInputDisabled: false,
  isChatOpen: false,
  // mindig az aktuális beszélgetéshez tartozó, azaz a user és a recipient között
  messages: [],
  // WS
  stompClient: null,
  // POLL
  unseenMessages: null
};

export const chat = {
  namespaced: true,
  state: initialState,

  getters: {
    getPartners: (state) => {
      return state.partners;
    },

    getPartner: (state) => {
      return state.partner;
    },

    getMessages: (state) => {
      return state.messages;
    },

    getIsPartnerListOpen: (state) => {
      return state.isPartnerListOpen;
    },

    getIsInputDisabled: (state) => {
      return state.isInputDisabled;
    },

    getUnseenMessagesCount: (state) => {
      return state.unseenMessages;
    },

    getChatIsOpen: (state) => {
      return state.isChatOpen;
    }
  },

  // async
  actions: {
    getPartners({commit}) {
      ChatService.getUsersToChatList().then((res) => {
        const {data} = res || {};
        if (!data) return;
        let partners = data.map((item) => {
          return {
            id: item.senderId,
            name: item.senderUserName,
            unseenMessages: item.unseenMessages,
            avatarImgPath: item.imgsrc
          }
        });
        commit('SET_PARTNERS', partners);
      }).catch(err => {
        // TODO error
        console.error('Error while getting chat partners', err)
      });
    },

    getUnseenMessages({commit, state, dispatch}) {
      ChatService.getUnseenMessagesCount().then(response => {
        let newUnseenMessages = response.data;

        if (newUnseenMessages > state.unseenMessages) {
          dispatch('getPartners');
        }

        commit('SET_UNSEEN_MESSAGES', newUnseenMessages);
      }).catch(function (error) {
        console.error('an error occured ' + error);
      });
    },

    connect({commit, state, rootState}) {
      const user = rootState.auth.user;
      this.socket = ChatService.createWsConnection();


      this.stompClient = webstomp.over(this.socket);
      this.stompClient.debug = () => {
      };

      if (!this.stompClient) return Promise.reject('stompClient is null');

      commit('SET_STOMP_CLIENT', this.stompClient);

      this.stompClient.connect(
        {},
        () => {
          this.stompClient.subscribe("/user/" + user.id + "/queue/messages", (tick) => {
            let message = JSON.parse(tick.body);

            if (state.isPartnerListOpen && state.isStandaloneChat) return;

            if (message.senderId === state.partner.id) {
              ChatService.getMessageArrayByNames({senderId: user.id, recipientId: state.partner.id}).then(res => {
                commit('SET_MESSAGES', res.data);
              }).catch(err => {
                console.error('Error while getting chat messages', err);
              });
            }
          });
        },
        error => {
          console.error(error);
        }
      );
    },

    getMessages({commit, state, rootState}) {
      const user = rootState.auth.user;
      ChatService.getMessageArrayByNames({senderId: user.id, recipientId: state.partner.id}).then(res => {
        commit('SET_MESSAGES', res.data);
      }).catch(err => {
        console.error('Error while getting chat messages', err);
      })
    },

    sendMessage({commit, state, rootState}, message) {
      const user = rootState.auth.user;

      state.stompClient.send("/app/chat",
        JSON.stringify(message),
        {});

      setTimeout(() => {
        ChatService.getMessageArrayByNames({senderId: user.id, recipientId: state.partner.id}).then(res => {
          commit('SET_MESSAGES', res.data);
          return Promise.resolve()
        }).catch(err => {
          console.error('Error while getting chat messages', err);
          return Promise.reject()
        })
      }, 500)
    },

    selectPartner({commit, dispatch, rootState}, partner) {
      const user = rootState.auth.user;

      dispatch('getPartners');
      commit('SET_PARTNER', partner);
      ChatService.getMessageArrayByNames({senderId: user.id, recipientId: partner.id}).then(res => {
        commit('SET_MESSAGES', res.data);
      }).catch(err => {
        console.error('Error while getting chat messages', err);
      })
    },

    setChatOpen({commit, state, dispatch}, isOpen) {
      if (isOpen) {
        commit('SET_CHAT_OPEN', true);
        if (state.isPartnerListOpen) {
          dispatch('getPartners');
        } else {
          dispatch('getMessages');
        }
      } else {
        commit('SET_CHAT_OPEN', false);
      }

    }
  },

  // sync
  mutations: {
    SET_IS_STANDALONE_CHAT(state, isStandaloneChat) {
      state.isStandaloneChat = isStandaloneChat;
    },

    SET_PARTNERS(state, partners) {
      state.partners = partners;
    },

    SET_PARTNER(state, partner) {
      state.partner = partner;
    },

    SET_PARTNER_LIST_OPEN(state, isOpen) {
      state.isPartnerListOpen = isOpen;
    },

    SET_INPUT_DISABLED(state, isDisabled) {
      state.isInputDisabled = isDisabled;
    },

    SET_MESSAGES(state, messages) {
      state.messages = messages.reverse();
    },

    SET_STOMP_CLIENT(state, client) {
      state.stompClient = client;
    },

    SET_UNSEEN_MESSAGES(state, count) {
      state.unseenMessages = count;
    },

    SET_CHAT_OPEN(state, isOpen) {
      state.isChatOpen = isOpen;
    }
  }
};
