<template>
  <div class="page--forget-process">
    <div class="container-fluid">
      <div class="row align-items-start align-items-md-center">
        <div class="col-md-4 login-left">
          <div class="circle-graphics"></div>
          <div class="rentible-logo">
            <router-link to="/">
              ><img
                src="../../assets/img/auth/rentible-logo.png"
                alt="Rentible.io"
            /></router-link>
          </div>
        </div>
        <div class="col-md-8 login-right">
          <PasswordSteps :currentActive="currentStep" />

          <div class="auth-box auth-box--pass-success">
            <img
              class="mb-3"
              src="../../assets/img/auth/security.svg"
              alt="Password reset"
            />
            <h2 class="auth-box__title">
              {{ $cte("forgotpass.steps.resetTitle") }}
            </h2>
            <p class="auth-box__text">
              {{ $cte("forgotpass.steps.resetText") }}
            </p>

            <div class="pt-3 pb-5">
              <router-link
                to="/login"
                class="btn btn-primary btn--fullwidth btn--md"
                >{{ $cte("forgotpass.steps.resetBtn") }}</router-link
              >
            </div>
          </div>

          <!-- TODO: other static pages ??? -->
          <div class="auth-footer-links">
            <p>
              <a href="#">{{ $cte("footer.menu.assistance.item04") }}</a>
              {{ $cte("common.and") }}
              <a href="#">{{ $cte("footer.menu.assistance.item07") }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./page--login -->
</template>

<script>
import PasswordSteps from "../../components/auth/PasswordSteps.vue";

export default {
  name: "ForgetPasswordSuccess",
  data() {
    return {
      currentStep: "step-04",
    };
  },
  components: {
    PasswordSteps,
  },
};
</script>

<style scoped>
@import "../../assets/css/auth.css";
</style>
