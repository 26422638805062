<template>
  <div class="chat-header">
    Chat
  </div>
</template>

<script>
export default {
  name: "ChatHeader"
}
</script>

<style scoped>

.chat-header {
  padding: 1em;
  background-color: #B9B9B9;
  font-family: var(--font-heading);
  font-size: 1.3rem;
  color: #fff;
  border-top-left-radius: var(--rentible-border-radius);
  border-top-right-radius: var(--rentible-border-radius);
}

</style>
