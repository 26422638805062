<template>
  <div class="row" v-if="item">
    <div class="col-md-3">
      <div class="horizontal-card-image" :style="{'background-image': 'url(' + getImage + ')'}"></div>
    </div>
    <div class="col-md-6 item-col">
      <div class="item__title">{{ property.title }}</div>
      <div class="item__subtitle">
        {{ property.address.address1 }}
      </div>
      <div class="item__properties">
        <div class="property">
          <i class="icon icon--beds"></i>
          {{ property.bedsNumber  == 61 ? $cte('property.6ormore') : property.bedsNumber }} {{ $cte('property.beds') }}
        </div>
        <div class="property">
          <i class="icon icon--bath"></i>
          {{ property.toilet == 31 ? $cte('property.3ormore') : property.toilet }} {{ $cte('property.bath') }}
        </div>
        <div class="property">
          <i class="icon icon--size"></i>
          {{ property.roomSize }} m2
        </div>
      </div>
    </div>
    <div class="col-md-3 align-self-center">
      <div class="item-col--last">
        <div class="item__price">${{ property.rentPrice }}/{{ $cte('propertyContractPanel.month') }}</div>
        <button class="btn-outline" @click="editProperties">{{
            $cte("profile.myproperties.edit")
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyRoomAd",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      defaultImg: 'http://via.placeholder.com/251x182',
      propertyDatas: [],
    }
  },
  computed: {
    property: function () {
      return this.item.property
    },
    getImage: function() {
      if (this.property.images.length === 0) return this.defaultImg
      return process.env.VUE_APP_BACKEND_URL + '/' + this.property.images[0].filePath;
    },
  },
  methods: {
    editProperties() {
      this.$router.push({
        path: '/new-ad/?id=' + this.property.id,
      })
    },
  },
}
</script>

<style scoped>
.horizontal-card-image {
  width: 100%;
  height: 100%;
  border-radius: 13px 0 0 13px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
