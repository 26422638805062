<template>
  <div class="stepper">
    <div class="container">
      <div class="row align-items-stretch">
        <div class="col-12 col-md-3 col-lg-3 col-xl-3 main-column-left">
          <div class="circle-graphics"></div>
          <TheLogo/>

          <StepList currentItem="step3" class="step-location-dropdown"/>
        </div>
        <!-- ./ main-column-left -->
        <div class="col-12 col-md-9 col-lg-9 col-xl-9 main-column-right--v2">
          <div class="map--location" id="mapLocation">
            <GmapMap
                :center="center"
                :zoom="12"
                style="width: 100%; height: 22vw"
                :options="mapOptions"
            >
              <GmapMarker :position="center" :clickable="true" :draggable="true" @click="center=m.position"
                          @dragend="updateCoordinates"/>
            </GmapMap>
          </div>

          <div class="content-box">
            <h2 class="content-box__title">{{ $cte('uploadSteps.step3') }}</h2>
            <p class="content-box__text">
              {{ $cte('uploadSteps.step3Subtitle') }}
            </p>

<!--            <h1>-->
<!--              City: {{ model.city }}-->
<!--            </h1>-->
<!--            <h1>-->
<!--              Country: {{ model.country }}-->
<!--            </h1>-->
<!--            <h1>-->
<!--              <h1>-->
<!--                Address: {{ model.address1 }}-->
<!--              </h1>-->
<!--            </h1>-->
<!--            <p>-->
<!--              {{ this.center }} - {{ this.model.address1 }}-->
<!--            </p>-->

            <div class="flat-options">
              <ValidationObserver ref="validationObserver" v-slot="{ }">
                <form class="flat-form--options">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group">
                        <div class="form-input">
                          <svg
                              class="icon"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              width="512px"
                              height="512px"
                              viewBox="0 0 512 512"
                              style="enable-background: new 0 0 512 512"
                              xml:space="preserve"
                          >
                                        <path
                                            d="M344.5,298c15-23.6,23.8-51.6,23.8-81.7c0-84.1-68.1-152.3-152.1-152.3C132.1,64,64,132.2,64,216.3
                                                                                    c0,84.1,68.1,152.3,152.1,152.3c30.5,0,58.9-9,82.7-24.4l6.9-4.8L414.3,448l33.7-34.3L339.5,305.1L344.5,298z M301.4,131.2
                                                                                    c22.7,22.7,35.2,52.9,35.2,85c0,32.1-12.5,62.3-35.2,85c-22.7,22.7-52.9,35.2-85,35.2c-32.1,0-62.3-12.5-85-35.2
                                                                                    c-22.7-22.7-35.2-52.9-35.2-85c0-32.1,12.5-62.3,35.2-85c22.7-22.7,52.9-35.2,85-35.2C248.5,96,278.7,108.5,301.4,131.2z"
                                        />
                                        </svg>
                          <LocationInput :activeCity="true" v-model="model.cityId" @select="selectLocation"/>
                          <label for="location" class="mt-2">{{ $cte('property.LocationLabel') }}:</label>
                          <GmapAutocomplete
                              id="location"
                              :value="model.address1"
                              class="form-control form-control--hasIcon mt-2"
                              @input="inputHandler"
                              @place_changed="setPlace"/>
                              
                          

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="action-buttons">
                    <button @click.prevent="goBack" class="btn-outline btn-lg">{{ $cte('uploadSteps.GoBack') }}</button>
                    <button class="btn-primary btn-lg" type="button" @click="onSubmit">{{ $cte('uploadSteps.NextStep') }}</button>
                  </div>
                </form>
              </ValidationObserver>
            </div>

          </div>
        </div>
        <!-- ./ main-column-right -->
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import LocationInput from '@/components/search/LocationInput';
import RoomAdService from "@/services/roomAd.service";

//import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import {ValidationObserver} from "vee-validate";

import TheLogo from "../../components/TheLogo.vue";
import StepList from "./StepList.vue";

//import axios from "axios";
import constants from "../../config/constants.js";

export default {
  name: "Step3",
  components: {
    StepList,
    TheLogo,
    //ValidationProvider,
    ValidationObserver,
    LocationInput
  },
  data() {
    return {
      propertyDetail: null,
      queryId: null,
      model: {
        city: null,
        country: null,
        address1: null,
        cityId: null,
      },
      selected: null,
      locationLat: 0,
      locationLng: 0,
      options: {
        apiKey: "AIzaSyAEZuIVeK1v7vyTDV2bYxwZ6CUxsdv4eUw",
        deepSearch: false,
        cors: true,
        focus: false,
      },
      // San Salvador at center
      center: {lat: 13.6669373, lng: -89.214051},
      marker: null,
      currentPlace: null,
      mapOptions: constants.GOOGLE_MAPS_CONFIG
    }
  },
  computed: {
    ...mapState('auth', [
      'user'
    ]),
    ...mapGetters('property', ['getStepValues'])
  },
  methods: {
    /**
     * If the value has changed in the google autocomplete input,
     * then we need to clear the map data, to preserve the consistency
     * between the model (city, center, address) and the input.
     */
    inputHandler() {
      this.clearMapData();
    },
    selectLocation(data) {
      if (data) {
        this.model.cityId = data.id;
      } else {
        this.model.cityId = null;
      }
    },
    clearMapData() {
      this.model.address1 = null;
      this.center = {lat: 0, lng: 0};
      this.model.city = null;
      this.model.country = null;
    },
    setPlace(place) {
      this.currentPlace = place;
      this.center.lat = parseFloat(this.currentPlace.geometry.location.lat());
      this.center.lng = parseFloat(this.currentPlace.geometry.location.lng());

      this.model.city = this.extract({data: this.currentPlace.address_components, key: 'locality'});
      this.model.country = this.extract({data: this.currentPlace.address_components, key: 'country'});

      this.model.address1 = this.currentPlace.formatted_address;
    },
    extract(params) {
      let match = params.data.find(item => item.types.includes(params.key));
      if (!match) return null;
      return match.long_name;
    },
    setModelFromStore() {
       if (!this.queryId){
        this.model = {...this.getStepValues};
      }
      const {locationLat, locationLng} = this.getStepValues;
      if (locationLat && locationLng) {
        this.center = {lat: this.getStepValues.locationLat, lng: this.getStepValues.locationLng};
      }
    },
    goBack() {
    if (this.queryId) {
      this.$router.push("/new-ad-2/?id=" + this.queryId);
    } else {
      this.$router.push("/new-ad-2");
    }
    },
    updateCoordinates(coordinates) {
      this.center.lat = coordinates.latLng.lat();
      this.center.lng = coordinates.latLng.lng();
    },
    geolocate: function () {
      if (this.center.lat && this.center.lng) return;

      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    onSubmit(e) {
      e.preventDefault();

      this.$refs['validationObserver'].validate().then(valid => {
        if (!valid) return;

        if (!this.center.lat || !this.center.lng || !this.model.address1 || !this.model.city || !this.model.country) {
          this.$buefy.toast.open({
            duration: 5000,
            message: this.$cte('system.error.addALocation'),
            position: 'is-bottom',
            type: 'is-danger'
          })
          return false;
        }
        
        if (!this.model.cityId) {
          this.$buefy.toast.open({
            duration: 5000,
            message: this.$cte('system.error.selectACity'),
            position: 'is-bottom',
            type: 'is-danger'
          })
          return false;
        }

        let payload = {
          ...this.model,
          locationLat: this.center.lat,
          locationLng: this.center.lng,
        }

        this.$store.dispatch("property/saveStep03", payload).then(
            (response) => {
              console.log(response);
              if (this.queryId) {
              this.$router.push("/new-ad-4/?id=" + this.queryId);
            } else {
              this.$router.push("/new-ad-4/");
            }
            },
            (error) => {
              this.loading = false;
              this.message =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
            }
        );
      })
    },
    getAdId() {
      this.queryId = this.$route.query.id;
      if (this.queryId) {
        this.getAdDetails();
      }
      else {
        this.setModelFromStore();
      }
    },
    getAdDetails() {
      RoomAdService.findById(this.queryId).then(
        (response) => {
          this.propertyDetail = response.data;
          this.addPropertyDataToModel();
          this.setModelFromStore();
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    addPropertyDataToModel() {
      let self = this;
      Object.keys(self.model).forEach(function(key)
      {
        self.model[key] = self.propertyDetail.property[key];
      });
      this.model.cityId = this.propertyDetail.property.city.id;
      this.model.city = this.propertyDetail.property.city.city;
      this.model.address1 = this.propertyDetail.property.address.address1;
      this.model.country = this.propertyDetail.property.address.country;
    }
  },
  created() {
    this.getAdId();
  },
  mounted() {
    this.geolocate();
  },
}
</script>

<style scoped>
.stepper .map--location {
  height: 22vw;
}

.stepper .form-input svg.icon {
  top: 85%;
  left: 20px !important;
}

* >>> .dropdown {
  background-color: #ffff;
  border-radius: 13px;
  padding: 20px;
  margin-top: 5px;
  width: 100%;
}
* >>> .dropdown:focus-within {
  border: 1px solid #d91f6e;
}

* >>>.dropdown-content {
  padding: 20px 30px 15px !important;
  border-radius: 13px !important;
}

* >>> .search-dropdown__title {
    color: #b9b9b9;
    margin-top: 0;
    font-size: 16px;
    letter-spacing: -.24px;
}

* >>> .city-option {
  font-weight: bold ;
}
</style>
