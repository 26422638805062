<template>
  <div id="write-message">
    <input id="message-input"
           autocomplete="off"
           ref="sendMessageInput"
           :placeholder="$cte('chat.addYourMessage')"
           class="form-control w-100"
           v-model="messageInput.value"
           v-on:keyup.enter="sendMessage"
           name="txt_messages"
           :disabled="inputDisabled"/>
    <button class="btn btn-primary btn-send-message" @click="sendMessage">
<!--      {{ $cte('chat.send') }}-->
      <b-icon icon="send"></b-icon>
    </button>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "NewMessageInput",
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    inputDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      messageInput: {
        value: '',
      }
    }
  },
  methods: {
    sendMessage() {
      const self = this;

      if (self.inputDisabled) return;

      if (self.isEmptyMessage(self.messageInput.value)) return self.messageInput.value = '';

      self.inputDisabled = true;

      const message = {
        senderId: self.user.id,
        recipientId: self.getPartner.id,
        senderName: self.user.name,
        recipientName: self.getPartner.id,
        content: self.messageInput.value,
        timestamp: new Date(),
      };

      this.$store.dispatch('chat/sendMessage', message).then(() => {
        this.$nextTick(() => {
          self.inputDisabled = false;
          self.messageInput.value = '';
          window.setTimeout(() => {
            self.$refs.sendMessageInput.focus();
          }, 1000);
        });
      }).catch(() => {
        this.$buefy.toast.open({
          message: this.$cte('chat.errorSendingMessage'),
          type: 'is-danger',
          queue: false,
          duration: 3000
        });
      });
    },

    isEmptyMessage(msg) {
      return !msg.replace(/\s/g, '');
    }
  },
  computed: {
    ...mapGetters('chat', ['getPartner']),
  }
}
</script>

<style scoped>

#write-message {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1em;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-bottom-left-radius: var(--rentible-border-radius);
  border-bottom-right-radius: var(--rentible-border-radius);
}

#message-input {
  padding-right: 80px;
  background-color: #F8F2EF;
}

#write-message .btn-send-message {
  margin-left: -64px;
  height: 50px;
  border-radius: var(--rentible-border-radius-sm);
  min-width: auto;
}


</style>
