<template>
  <div class="content-wrapper">
    <div class="cards">
      <div class="cards__header">
        <h2 class="cards__title">Disputes</h2>
      </div>

    </div>
  </div>
</template>

<script>
import authHeader from "../../helper/auth-header.js";

import axios from "axios";
import config from "../../config/constants.js";

export default {
  name: "ProfileDisputes",
  data() {
    return {
        disputes: [
            {
                id: 22,
                image: "house-01.png",
                title: "Fully Furnished Aparment",
                description: "Apartment for Rent in VIVA Residence",
                beds: "2",
                toile: "1",
                size: "36",
                rentalPrice: "$1000", // currency need to set
            }
        ]
    };
  },
  methods: {
        openEditModal(property) {
            console.log(property);
        }   
  },
  mounted() {
    const loadingComponent = this.$buefy.loading.open({
      canCancel: true,
    });

    setTimeout(() => {
      loadingComponent.close();
    }, 1500);

    axios
        .get(config.BASE_URL + "/profile/disputes", authHeader())
        .then((response) => {
          console.log(response); // success show some toast
            this.disputes = response.data;
        })
        .catch((error) => console.log(error));
  },
};
</script>

<style>
@import "../../assets/css/style.css";

.profile .page-content {
  padding-top: 0;
}
</style>