<template>
  <img
      :src="getImage"
      class="card-img-top"
      :alt="item.title"
  />
</template>

<script>
export default {
  name: "CardImage",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // TODO const
      defaultImg: 'http://via.placeholder.com/251x182'
    }
  },
  computed: {
    getImage() {
      if (this.item.images.length === 0) return this.defaultImg
      return process.env.VUE_APP_BACKEND_URL + '/' + this.item.images[0].filePath;
    }
  }
}
</script>

<style scoped>

/*TODO max-height*/
.card--featured .card-img-top {
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  height: 194px;
}

.card--horizontal .card-img-top {
  width: 100%;
  object-fit: cover;
  border-radius: 13px 0 0 13px;
  height: 182px;
}

</style>
