<template>
  <div class="content-wrapper">
    <div class="content-group">
      <div class="content-group__header">
        <h2 class="content-group__title">{{ $cte('profile.profileHome.PersonalData') }}</h2>
      </div>

      <ValidationObserver>
        <form class="form--profile" @submit.prevent="onSubmit">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="">{{ $cte('profile.profileHome.FirstName') }}</label>
                <ValidationProvider
                  name="firstname"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    name="firstname"
                    class="form-control"
                    :placeholder="$cte('profile.profileHome.FirstNamePlaceholder')"
                    v-model="profile.firstName"
                  />

                  <small class="form-text text-error">
                    {{ errors[0] }}
                  </small>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">{{ $cte('profile.profileHome.LastName') }}</label>
                <ValidationProvider
                  name="lastname"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    name="lastname"
                    class="form-control"
                    :placeholder="$cte('profile.profileHome.LastNamePlaceholder')"
                    v-model="profile.lastName"
                  />
                  <small class="form-text text-error">
                    {{ errors[0] }}
                  </small>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">{{ $cte('profile.profileHome.Phone') }}</label>
                <ValidationProvider
                  name="phone"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    name="phone"
                    class="form-control"
                    :placeholder="$cte('profile.profileHome.PhonePlaceholder')"
                    v-model="profile.phoneNumber"
                  />

                  <small class="form-text text-error">
                    {{ errors[0] }}
                  </small>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <RentDatePicker
                  id="dateOfBirth"
                  v-model="profile.dateOfBirth"
                  :label="$cte('profile.profileHome.DateOfBirth')"
              />
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="">{{ $cte('profile.profileHome.Email') }}</label>
                <ValidationProvider
                  name="email"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    name="email"
                    class="form-control"
                    :placeholder="$cte('profile.profileHome.EmailPlaceholder')"
                    v-model="profile.emailAddress"
                  />
                  <small class="form-text text-error">
                    {{ errors[0] }}
                  </small>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <RentCodeStoreSelect code-store-id="5"
                :label="$cte('profile.profileHome.Occupation')"
                v-model="profile.occupationId"
                :key="'occupation'"/>
<!--                <label for="">Occupation</label>-->
<!--                <ValidationProvider-->
<!--                  name="Occupation"-->
<!--                  rules="required"-->
<!--                  v-slot="{ errors }"-->
<!--                >-->
<!--                  <input-->
<!--                    type="text"-->
<!--                    class="form-control"-->
<!--                    placeholder="Your occupation"-->
<!--                    v-model="profile.occupation"-->
<!--                  />-->
<!--                  <small class="form-text text-error">-->
<!--                    {{ errors[0] }}-->
<!--                  </small>-->
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <RentGenderSelect v-model="profile.genderId"/>
<!--                <label for="">Gender</label>-->
<!--                <div class="gender-box">-->
<!--                  <div class="box" data-gender="600000">-->
<!--                    <svg-->
<!--                      id="layer1"-->
<!--                      xmlns="http://www.w3.org/2000/svg"-->
<!--                      width="40.653"-->
<!--                      height="51.768"-->
<!--                      viewBox="0 0 40.653 51.768"-->
<!--                    >-->
<!--                      <path-->
<!--                        id="path9936"-->
<!--                        d="M22.979,281.124a12.934,12.934,0,0,0-12.94,12.94v21.3a8.325,8.325,0,0,0-7.394,8.269v8.314a.925.925,0,0,0,1.85,0v-8.314a6.435,6.435,0,0,1,6.465-6.473H15.2l4.351,4.351a.924.924,0,0,0,1.3,0l2.121-2.115,2.122,2.115a.924.924,0,0,0,1.3,0l4.357-4.351h4.23a6.437,6.437,0,0,1,6.466,6.473v8.314a.924.924,0,1,0,1.848,0v-8.314a8.323,8.323,0,0,0-7.387-8.267v-21.3A12.928,12.928,0,0,0,22.979,281.124Zm0,1.85a11.048,11.048,0,0,1,11.092,11.09v21.247H30.746l-2.229-2.223v-3.3a9.207,9.207,0,0,0,3.7-7.378l.014-5.546a.924.924,0,0,0-.935-.942.678.678,0,0,0-.112.01h0a4.581,4.581,0,0,1-4.485-4.613.928.928,0,0,0-.155-.523.96.96,0,0,0-.117-.142.924.924,0,0,0-.668-.269v0a.925.925,0,0,0-.908.935,4.587,4.587,0,0,1-4.618,4.618H14.673a.924.924,0,0,0-.928.92l-.014,5.546a9.186,9.186,0,0,0,3.694,7.381v3.31L15.2,315.31H11.887V294.064a11.048,11.048,0,0,1,11.092-11.09Zm2.792,11.662a6.47,6.47,0,0,0,4.609,3.07l-.01,4.692v.007a7.394,7.394,0,0,1-14.788,0l.014-4.625h4.638a.885.885,0,0,0,.121-.007,6.46,6.46,0,0,0,5.416-3.137Zm-6.5,16.23a9.224,9.224,0,0,0,7.394,0v2.22l-3.7,3.691-3.7-3.7Zm-.92,3.907,3.308,3.315L20.2,319.554l-3.308-3.315Zm9.235,0,1.467,1.465-3.308,3.315-1.467-1.465Z"-->
<!--                        transform="translate(-2.645 -281.124)"-->
<!--                      />-->
<!--                    </svg>-->
<!--                    <span>Female</span>-->
<!--                  </div>-->
<!--                  <div class="box" data-gender="600001">-->
<!--                    <svg-->
<!--                      id="layer1"-->
<!--                      xmlns="http://www.w3.org/2000/svg"-->
<!--                      width="40.651"-->
<!--                      height="51.768"-->
<!--                      viewBox="0 0 40.651 51.768"-->
<!--                    >-->
<!--                      <path-->
<!--                        id="path9971"-->
<!--                        d="M22.971,281.124a12.933,12.933,0,0,0-12.938,12.94,13.688,13.688,0,0,0,1.4,5.865,2.742,2.742,0,0,0-.466,1.525,4.9,4.9,0,0,0,.9,2.978,4.167,4.167,0,0,0,2.575,1.534,9.243,9.243,0,0,0,2.989,3.817v3.3L15.2,315.31H10.96a8.323,8.323,0,0,0-8.314,8.323v8.314a.924.924,0,1,0,1.848,0v-8.314a6.437,6.437,0,0,1,6.466-6.473h4.23l4.357,4.351a.924.924,0,0,0,1.3,0l2.121-2.115,2.122,2.115a.924.924,0,0,0,1.3,0l4.357-4.351h4.23a6.437,6.437,0,0,1,6.466,6.473v8.314a.924.924,0,1,0,1.848,0v-8.314a8.323,8.323,0,0,0-8.314-8.323H30.745l-2.229-2.223v-3.306a9.253,9.253,0,0,0,2.985-3.817,4.169,4.169,0,0,0,2.579-1.529,4.9,4.9,0,0,0,.9-2.981,2.741,2.741,0,0,0-.466-1.525,13.719,13.719,0,0,0,1.391-5.865,12.936,12.936,0,0,0-12.94-12.94Zm0,1.85a11.049,11.049,0,0,1,11.092,11.09A11.7,11.7,0,0,1,33,298.81a2.713,2.713,0,0,0-.774-.126l.007-1.824a.924.924,0,0,0-.935-.942.688.688,0,0,0-.112.01h0a4.581,4.581,0,0,1-4.485-4.613.928.928,0,0,0-.155-.523.957.957,0,0,0-.117-.142.924.924,0,0,0-.668-.269v0a.925.925,0,0,0-.908.935,4.587,4.587,0,0,1-4.618,4.618H14.671a.924.924,0,0,0-.928.92l-.007,1.83h0a2.7,2.7,0,0,0-.787.128,11.709,11.709,0,0,1-1.067-4.746,11.046,11.046,0,0,1,11.09-11.09Zm2.8,11.662a6.469,6.469,0,0,0,4.609,3.07l-.01,4.692v.007a7.394,7.394,0,0,1-14.788,0l.014-4.625h4.638a.89.89,0,0,0,.121-.007,6.46,6.46,0,0,0,5.416-3.137ZM13.732,300.53l-.007,1.868a9.268,9.268,0,0,0,.112,1.386,2.066,2.066,0,0,1-.5-.462,3.137,3.137,0,0,1-.53-1.868.9.9,0,0,1,.922-.924Zm18.486,0a.9.9,0,0,1,.919.922,3.133,3.133,0,0,1-.532,1.87,2.07,2.07,0,0,1-.505.466,9.272,9.272,0,0,0,.114-1.384Zm-5.549,10.334v2.221l-3.7,3.692-3.7-3.692v-2.22a9.225,9.225,0,0,0,7.394,0Zm-8.314,3.907,3.308,3.315-1.46,1.465-3.315-3.315Zm9.235,0,1.467,1.465-3.315,3.315-1.46-1.465Z"-->
<!--                        transform="translate(-2.646 -281.124)"-->
<!--                        fill="#b9b9b9"-->
<!--                      />-->
<!--                    </svg>-->

<!--                    <span>Male</span>-->
<!--                  </div>-->
<!--                  <div class="box" data-gender="600002">-->
<!--                    Prefer not<br />-->
<!--                    to say-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mt-4">
                <label for="">{{ $cte('profile.profileHome.YourWalletAddress') }}</label>

                <!-- TODO: Wallet Address -->
                <div class="form-input">
                  <ValidationProvider
                    name="wallet"
                    :rules="{ required: true, regex: /^0x[a-fA-F0-9]{40}$/ }"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      name="wallet"
                      class="form-control wallet-input"
                      :placeholder="$cte('profile.profileHome.YourWalletAddressPlaceholder')"
                      v-model="profile.yourWalletAddress"
                    />

                    <div class="wallet-select">
                      <select class="form-control">
                        <option value="erc20" selected>BEP-20</option>
                      </select>
                    </div>
                    <small class="form-text text-error">
                      {{ errors[0] }}
                    </small>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <RentSelect :label="$cte('profile.profileHome.SpokenLanguages')"
                          :key="'language'"
                          :codeStoreId="14"
                          v-model="profile.spokenLanguageIds"
                          :multiple="true"/>
<!--              <div class="form-group">-->
<!--                <label for="">Languages</label>-->
<!--                <ValidationProvider-->
<!--                  name="selectedLang"-->
<!--                  rules="required"-->
<!--                  v-slot="{ errors }"-->
<!--                >-->
<!--                  <select-->
<!--                    class="form-control"-->
<!--                    v-model="profile.language"-->
<!--                    name="lang"-->
<!--                  >-->
<!--                    <option>Select Languages</option>-->
<!--                    <option-->
<!--                      :value="lang"-->
<!--                      v-for="lang in profile.languages"-->
<!--                      :key="lang"-->
<!--                    >-->
<!--                      {{ lang }}-->
<!--                    </option>-->
<!--                  </select>-->
<!--                  <small class="form-text text-error">-->
<!--                    {{ errors[0] }}-->
<!--                  </small>-->
<!--                </ValidationProvider>-->
<!--              </div>-->
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <RentCodeStoreSelect code-store-id="13"
                :label="$cte('profile.profileHome.LandlordOrAgency')"
                v-model="profile.categoryTypeId"
                :rules="{required: true}"
                :key="'categoryType'"/>
<!--                <label for="">Landlord or agency?</label>-->
<!--                <ValidationProvider-->
<!--                  name="categories"-->
<!--                  rules="required"-->
<!--                  v-slot="{ errors }"-->
<!--                >-->
<!--                  <select-->
<!--                    class="form-control"-->
<!--                    v-model="profile.categoryTypeId"-->
<!--                    name="categories"-->
<!--                  >-->
<!--                    <option value="1">Landlord</option>-->
<!--                    <option value="2">Agency</option>-->
<!--                  </select>-->
<!--                  <small class="form-text text-error">-->
<!--                    {{ errors[0] }}-->
<!--                  </small>-->
<!--                </ValidationProvider>-->
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="" style="visibility: hidden">{{ $cte('profile.profileHome.Agency') }}</label>
                <ValidationProvider
                  name="agency"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    name="agency"
                    class="form-control"
                    :placeholder="$cte('profile.profileHome.AgencyPlaceholder')"
                    v-model="profile.agencyName"
                  />
                  <small class="form-text text-error">
                    {{ errors[0] }}
                  </small>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 d-flex justify-content-end">
              <button type="submit" class="btn-primary btn--md">{{ $cte('common.Save') }}</button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>

    <div class="content-group">
      <div class="content-group__header">
        <h2 class="content-group__title">{{ $cte('profile.profileHome.ChangePassword') }}</h2>
      </div>

      <ValidationObserver>
        <form class="form--profile" @submit.prevent="changePassword">
          <div class="row">
            <div class="col-md-6">
              <label for="">{{ $cte('profile.profileHome.Password') }}</label>
              <ValidationProvider
                name="pass1"
                rules="required|min:8"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <div class="form-input">
                    <input
                      type="password"
                      id="pass1"
                      name="pass1"
                      class="form-control"
                      :placeholder="$cte('profile.profileHome.PasswordPlaceholder')"
                      v-model="pass1"
                    />
                    <div
                      class="pass-toggle js-show-pass"
                      data-hide="hide"
                      data-show="show"
                    >
                      {{ $cte('common.SHOW') }}
                    </div>
                  </div>
                  <small class="form-text text-error">
                    {{ errors[0] }}
                  </small>
                </div>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <label for="">{{ $cte('profile.profileHome.ConfirmPassword') }}</label>

              <ValidationProvider
                name="pass2"
                rules="required|min:8"
                v-slot="{ errors }"
              >
                <div class="form-input">
                  <input
                    type="password"
                    id="pass2"
                    name="pass2"
                    class="form-control"
                    :placeholder="$cte('profile.profileHome.ConfirmPasswordPlaceholder')"
                    v-model="pass2"
                  />
                  <div
                    class="pass-toggle js-show-pass"
                    data-hide="hide"
                    data-show="show"
                  >
                    {{ $cte('common.SHOW') }}
                  </div>
                </div>
                <small class="form-text text-error">
                  {{ errors[0] }}
                </small>
              </ValidationProvider>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 d-flex justify-content-end">
              <button type="submit" class="btn-primary btn--md">{{ $cte('common.Save') }}</button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RentCodeStoreSelect from '@/components/forms/RentCodeStoreSelect';
import RentGenderSelect from '@/components/forms/RentGenderSelect';
// import authHeader from "../../helper/auth-header.js";
import RentSelect from '@/components/forms/RentSelect';
import RentDatePicker from '@/components/forms/RentDatePicker';

import {
  ValidationProvider,
  extend,
} from "vee-validate/dist/vee-validate.full.esm";
import { ValidationObserver } from "vee-validate";

import axios from "axios";
import config from "../../config/constants.js";
import ProfileService from "../../services/profile.service";

extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
});

export default {
  name: "ProfileHome",
  props: {
    profile: Object,
  },
  components: {
    RentDatePicker,
    ValidationProvider,
    ValidationObserver,
    RentCodeStoreSelect,
    RentGenderSelect,
    RentSelect
  },
  data() {
    return {
      pass1: "",
      pass2: "",
      selected: "1",
      // spokenLanguages: [],
      // TODO hardcoded
      walletCode: "BEP-20",
      emailAddress: "",
      firstName: "",
      lastName: "",
      yourWalletAddress: "",
      dateOfBirth: "",
      phoneNumber: "",
      gender: "",
      occupation: "",
      categoryTypeId: "",
      agencyName: "",
    };
  },
  computed: {
    ...mapState("auth", ["user", "status"]),
  },
  methods: {
    changeGender() {
      let getAllboxes = document.querySelectorAll(".gender-box .box");
      getAllboxes.forEach((item) => {
        item.addEventListener("click", () => {
          getAllboxes.forEach((val) => {
            val.classList.remove("active");
          });
          item.classList.add("active");
        });
      });
    },
    togglePassword() {
      // go to mixin
      // show password
      let togglePassTrigger = document.querySelectorAll(".js-show-pass");

      togglePassTrigger.forEach(function (item) {
        item.addEventListener("click", function () {
          let prevElement = item.previousElementSibling;

          let wordHide = item.dataset.hide;
          let wordShow = item.dataset.show;

          if (prevElement.getAttribute("type") == "password") {
            prevElement.setAttribute("type", "text");
            item.innerHTML = wordHide;
          } else {
            prevElement.setAttribute("type", "password");
            item.innerHTML = wordShow;
          }
        });
      });
    },
    changePassword() {
      // valahova megy
      let payload = {
        newPassword: this.pass1,
        newPasswordAgain: this.pass2,
        id: this.user.id, // get from the store
      };

      axios
        .put(config.API_PROFILE_NEW_PASS, payload)
        .then((response) => {
          console.log(response); // success show some toast
          this.$buefy.toast.open("Password was changed successfully"); // use this for messages
        })
        .catch((error) => console.log(error));
    },
    changeAvatar() {},
    // getGender() {
    //     let genderBoxes = document.querySelectorAll('.gender-box .box');
    //     let activeGender = -1;
    //     [].forEach.call(genderBoxes, (item) => {
    //         if (item.getAttribute('class').indexOf('active') >= 0) {
    //              activeGender = item.dataset.gender;
    //         }
    //     });
    //
    //     if (activeGender > -1) {
    //         return activeGender;
    //     }
    //
    //     return false;
    // },
    onSubmit() {

      let payload = {
        id: this.user.id,
        emailAddress: this.profile.emailAddress,
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        // language: this.profile.language,
        yourWalletAddress: this.profile.yourWalletAddress,
        walletCode: this.walletCode,
        dateOfBirth: this.profile.dateOfBirth,
        phoneNumber: this.profile.phoneNumber,
        gender: this.profile.genderId,
        occupation: this.profile.occupationId,
        categoryTypeId: this.profile.categoryTypeId,
        spokenLanguages: this.profile.spokenLanguageIds,
        agencyName: this.profile.agencyName,
      };

      console.log(payload);
      ProfileService.save(payload).then(() => {
        // get data from server
        this.$parent.getProfile();
        this.$buefy.toast.open(this.$cte('profile.profileHome.successfulSaveMessage')); // use this for messages
      }).catch(err => {
        console.error(err);
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$cte('system.error.common'),
          type: "is-danger",
        });
      })

      // axios
      //   .put(config.API_PROFILE, payload)
      //   .then((response) => {
      //     console.log(response); // success show some toast
      //     this.$buefy.toast.open("Profile datas was changed successfully"); // use this for messages
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     this.$buefy.toast.open({
      //       duration: 3000,
      //       message: `Something's wrong. Please try later.`,
      //       position: "is-bottom",
      //       type: "is-danger",
      //     });
      //   });
    },
  },
  mounted() {
    this.togglePassword();
    this.changeGender();
  },
};
</script>

<style scoped>
.profile .gender-box .box {
  flex-direction: column;
}
.profile .gender-box .box svg {
  margin-bottom: 15px;
}

.datepicker .dropdown .input[readonly], .datepicker .dropdown-trigger .input[readonly] {
    border: 1px solid #6565655c !important;
}
</style>
