import Web3 from "web3";
import {showError} from "@/helper/toasts";

const initialState = {
  // TODO get connection state from metamask
  connected: false,
  web3: null
};

export const crypto = {
  namespaced: true,
  state: initialState,

  getters: {
    getConnected: (state) => {
      return state.connected;
    }
  },

  // async
  actions: {
    async connectWallet({commit}) {
      try {
        // Metamask plugin
        if (!window.ethereum) { return showError('crypto.installMetamask') }
          await window.ethereum.request({method: 'eth_requestAccounts'});
          const web3 = new Web3(window.ethereum);
          commit('SET_CONNECTED', true);
          commit('SET_WEB3', web3);
      } catch (e) {
        /**
         * TODO ha van code akkor mappelni
         */
        // already PENDING code: -32002
        showError('crypto.errorConnectMetamask')
        console.error(e.message);
      }
    }
  },

  // sync
  mutations: {
    SET_CONNECTED(state, isConnected) {
      state.connected = isConnected;
    },
    SET_WEB3(state, web3) {
      state.web3 = web3;
    }
  }

}
