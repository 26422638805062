import axios from "axios";
import configs from '@/config/crypto/contract-configs';
import {showError, showWarning} from "@/helper/toasts";
import store from "@/store";

const BASE_URL = process.env.VUE_APP_BACKEND_URL;

export default class CryptoService {

  /**
   * Landlord contract sign in
   * Sample payload object:
   * @example
   * {
   *   roomAdId: {roomAdId},
   *   contractId: {contractId}
   * }
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  static signInContract(payload) {
    const url = BASE_URL + '/contract-signed-landlord';
    return axios.post(url, payload);
  }

  static isConnected() {
    return store.state.crypto.connected;
  }

  /**
   * from: https://ethereum.stackexchange.com/questions/41506/web3-dealing-with-decimals-in-erc20/41535
   * @param value
   * @param decimals
   */
  static toBaseUnit(value, decimals = 18) {
    const web3 = store.state.crypto.web3;
    const BN = web3.utils.BN;

    if (!(typeof value === 'string' || value instanceof String)) {
      throw new Error('Pass strings to prevent floating point precision issues.')
    }

    const ten = new BN(10);
    const base = ten.pow(new BN(decimals));

    // Is it negative?
    const negative = (value.substring(0, 1) === '-');
    if (negative) {
      value = value.substring(1);
    }

    if (value === '.') {
      throw new Error(
        `Invalid value ${value} cannot be converted to`
        + ` base unit with ${decimals} decimals.`);
    }

    // Split it into a whole and fractional part
    const comps = value.split('.');
    if (comps.length > 2) {
      throw new Error('Too many decimal points');
    }

    let whole = comps[0], fraction = comps[1];

    if (!whole) {
      whole = '0';
    }
    if (!fraction) {
      fraction = '0';
    }
    if (fraction.length > decimals) {
      throw new Error('Too many decimal places');
    }

    while (fraction.length < decimals) {
      fraction += '0';
    }

    whole = new BN(whole);
    fraction = new BN(fraction);
    let wei = (whole.mul(base)).add(fraction);

    if (negative) {
      wei = wei.neg();
    }

    return new BN(wei.toString(10), 10);
  }

  /**
   * Paying rent deposit
   * Sample payload object:
   * @example:
   * {
   *   landlordAddress: {landlordsWalletId},
   *   amount: {amountOfDeposit},
   *   roomAdId: {roomAdId},
   *   contractId: {contractId}
   * }
   */
  static async payRentDeposit(params) {
    const web3 = store.state.crypto.web3;
    const {landlordAddress, amount, contractId, roomAdId} = params;

    let amountStr = amount.toString();

    if (!web3) {
      return showError('crypto.connectMetamask')
    }

    try {
      const rnbContract = new web3.eth.Contract(configs.rnbContractAbiJSON);
      rnbContract.options.address = configs.rnbContractAddress;

      const stablecoinContract = new web3.eth.Contract(configs.stablecoinContractAbiJSON);
      stablecoinContract.options.address = configs.stablecoinContractAddress;

      const rentContract = new web3.eth.Contract(configs.rentContractAbiJSON);
      rentContract.options.address = configs.rentContractAddress;

      const amountBN = this.toBaseUnit(amountStr);
      console.log(amountBN, 'amount after toBaseUnit');
      const feeAmountBN = this.toBaseUnit("1");
      console.log(feeAmountBN, 'fee amount after toBaseUnit');

      {

        const res1 = await rnbContract.methods.allowance(web3.currentProvider.selectedAddress, configs.rentContractAddress).call();
        const allowanceBN = new web3.utils.BN(res1);

        console.log("RNB allowance now: " + allowanceBN);

        if (allowanceBN.lt(feeAmountBN)) {

          showWarning('crypto.warningRNBAllowance');

          await rnbContract.methods.approve(configs.rentContractAddress, amountBN.toString()).send({from: web3.currentProvider.selectedAddress});
        }

      }

      {

        const res1 = await stablecoinContract.methods.allowance(web3.currentProvider.selectedAddress, configs.rentContractAddress).call();
        const allowanceBN = new web3.utils.BN(res1);

        console.log("stablecoin allowance now: " + allowanceBN);

        if (allowanceBN.lt(amountBN)) {

          showWarning('crypto.warningStableCoinAllowance');

          await stablecoinContract.methods.approve(configs.rentContractAddress, amountBN.toString()).send({from: web3.currentProvider.selectedAddress});
        }

      }

      {
        const res3 = await rentContract.methods.payRentDeposit(landlordAddress, amountBN.toString()).send({from: web3.currentProvider.selectedAddress});
        const {from, transactionHash} = res3 || {};

        let data = {
          roomAdId: roomAdId,
          contractId: contractId,
          smartContractId: configs.rentContractAddress,
          renterWalletAddress: from,
          transactionId: transactionHash
        };

        return await this.setContractActivated(data);
      }

    } catch (e) {
      console.error('Error: ', e);
      showError('crypto.errorPayRentDeposit');
      throw new Error(e);
    }
  }

  /**
   * Set contract as activated after paying rent deposit
   * Sample payload object:
   * @example
   * {
   *  roomAdId: {roomAdId},
   *  smartContractId: {rentContractAddress},
   *  renterWalletAddress: {addressFromSuccessfulTransaction},
   *  transactionId: {transactionHash}
   * }
   */
  static async setContractActivated(payload) {
    try {
      const url = BASE_URL + '/contract-activated';
      return await axios.put(url, payload);
    } catch (e) {
      console.error('Error: ', e);
      showError('crypto.errorSetContractActivated');
      throw new Error(e);
    }
  }

  /**
   * Example payload:
   * @example
   * {
   *  roomAdId: {roomAdId},
   *  contractId: {contractId}
   * }
   * @returns {Promise<AxiosResponse<any>>}
   */
  static acceptContract(payload) {
    const url = BASE_URL + '/contract-signed-landlord';
    return axios.put(url, payload);
  }

  static async releaseDeposit(params) {
    const {renterWalletAddress, landlordWalletAddress} = params;
    const web3 = store.state.crypto.web3;

    if (!web3) {
      return showError('crypto.connectMetamask')
    }

    try {

      const rentContract = new web3.eth.Contract(configs.rentContractAbiJSON);
      rentContract.options.address = configs.rentContractAddress;

      // ---

      // to the landlord
      // this is not for the end of the rent when the rentee gets back the safety deposit!

      return await rentContract.methods.releaseRentDeposit(renterWalletAddress, landlordWalletAddress).send({from: web3.currentProvider.selectedAddress});
    } catch (e) {
      console.error(e.message);
      showError('crypto.errorReleaseRentDeposit');
      throw new Error(e);
    }
  }
}
