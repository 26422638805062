<template>
    <div class="content-wrapper">
      <div class="cards">
        <div class="cards__header">
          <h2 class="cards__title">Payments &amp; Transfer Records</h2>
        </div>
        <div class="table-wrapper">
          <table class="cs-table">
            <tr>
              <th>Date</th>
              <th>Invoice</th>
              <th>Property</th>
              <th></th>
              <th>Amount</th>
            </tr>

            <tr v-for="payment in paymentsItems" :key="payment.id">
              <td>{{ payment.date }}</td>
              <td>{{ payment.invoice }}</td>
              <td>{{ payment.property }} …</td>
              <td>
                  <i class=""></i>
              </td>
              <td>{{ payment.price }}</td>
            </tr>
            <tr class="empty-row"></tr>
          </table>

        </div>
      </div>
    </div>
</template>

<script>
import authHeader from "../../helper/auth-header.js";

import axios from "axios";
import config from "../../config/constants.js";

export default {
    name: 'ProfilePayments',
    data() {
        return {
            paymentsItems: [
                {
                    id: 1,
                    invoice: "0012",
                    date: "2021 10 21",
                    paymentType: "fire", // electricity, water etc... 
                    price: "$1000",
                    property: "Fully Furnished Aparment" // cutting text
                },
                {
                    id: 2,
                    invoice: "0012",
                    date: "2021 10 21",
                    paymentType: "water", // electricity, water etc... 
                    price: "$500",
                    property: "Fully Furnished Aparment" // cutting text
                },
                {
                    id: 3,
                    invoice: "0012",
                    date: "2021 10 21",
                    paymentType: "water", // electricity, water etc... 
                    price: "$500",
                    property: "Fully Furnished Aparment" // cutting text
                }
            ]
        }
    },
    mounted() {
        // get all payments for this account
        const loadingComponent = this.$buefy.loading.open({
            canCancel: true
        });

        setTimeout(() => {
           loadingComponent.close();
        }, 1500);

        axios
        .get(config.API_CONTRACT_PAMYENT, authHeader())
        .then((response) => {
          console.log(response); // success show some toast
            this.paymentsItems = response.data;
        })
        .catch((error) => console.log(error));
    }
}
</script>

<style scoped>
.icon {
    display: inline-block;
}

.icon--electricity {
  /*electricity*/ 
}
.icon--water {
    /*drop*/
}
.icon--fire {
       /*fire*/ 
}

</style>