<template>
  <div class="upload-steps">
    <div :class="activeClass1">
      <div class="upload-steps__number">1</div>
      <div class="upload-steps__text">{{ $t("uploadSteps.step1") }}</div>
    </div>
    <div :class="activeClass2">
      <div class="upload-steps__number">2</div>
      <div class="upload-steps__text">{{ $t("uploadSteps.step2") }}</div>
    </div>
    <div :class="activeClass3">
      <div class="upload-steps__number">3</div>
      <div class="upload-steps__text">{{ $t("uploadSteps.step3") }}</div>
    </div>
    <div :class="activeClass4">
      <div class="upload-steps__number">4</div>
      <div class="upload-steps__text">{{ $t("uploadSteps.step4") }}</div>
    </div>
    <div :class="activeClass5">
      <div class="upload-steps__number">5</div>
      <div class="upload-steps__text">{{ $t("uploadSteps.step5") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadSteps",
  data() {
    return {
      basicClass: "upload-steps__item",
      currentClass: "upload-steps__item active",
      doneClass: "upload-steps__item done",
    };
  },
  props: {
    currentItem: String,
  },
  computed: {
    activeClass1() {
      if (this.currentItem == "step1") {
         return this.currentClass;
      } else if (this.currentItem == "step2" || this.currentItem == "step3" || this.currentItem == "step4" || this.currentItem == "step5" ) {
            return this.doneClass;
      } else {
          return this.basicClass;
      }
    },
    activeClass2() {
      if (this.currentItem == "step2") {
        return this.currentClass;
      } else if (this.currentItem == "step3" || this.currentItem == "step4" || this.currentItem == "step5" ) {
            return this.doneClass;
      } else {
          return this.basicClass;
      }
    },
    activeClass3() {
      if (this.currentItem == "step3") {
        return this.currentClass;
       } else if (this.currentItem == "step4" || this.currentItem == "step5" ) {
            return this.doneClass;
      } else {
          return this.basicClass;
      }
    },
    activeClass4() {
      if (this.currentItem == "step4") {
         return this.currentClass;
      } else if (this.currentItem == "step5" ) {
            return this.doneClass;
      } else {
          return this.basicClass;
      }
    },
    activeClass5() {
      if (this.currentItem == "step5") {
         return this.currentClass;
      } else {
           return this.basicClass;
      }
    },
  },
};
</script>