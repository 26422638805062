<template>
  <div>
    <div class="stepper">
      <div class="container">
        <div class="row align-items-stretch">
          <div class="col-12 col-md-3 col-lg-3 col-xl-3 main-column-left">
            <div class="circle-graphics"></div>
            <TheLogo/>

            <StepList currentItem="step2"/>
          </div>
          <!-- ./ main-column-left -->
          <div class="col-12 col-md-9 col-lg-9 col-xl-9 main-column-right">
            <div class="content-box">
              <h2 class="content-box__title">{{ $cte('uploadSteps.step2') }}</h2>
              <p class="content-box__text">
                {{ $cte('uploadSteps.step2Subtitle') }}
              </p>

              <div class="flat-options">
                <ValidationObserver v-slot="{ }" ref="validationObserver">
                  <form class="flat-form--options" @submit.prevent="onSubmit">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <RentCodeStoreSelect code-store-id="4"
                                               :label="$cte('property.floorLabel')"
                                               v-model="model.floorType"
                                               :rules="{required: true}"
                                               :key="floorType"/>
                        </div>

                        <div class="form-group">
                          <RentInput v-model="model.distanceToPublicTransport"
                                     type="number"
                                     :formHelper="$cte('property.minuteAbbr')"
                                     :placeholder="$cte('property.distance')"
                                     :label="$cte('property.distanceToClosestPublicTransportLabel')"
                                     :id="'nearToMetro'"/>
                        </div>

                        <div class="form-group">
                          <RentInput v-model="model.nearToMetro"
                                     type="text"
                                     :rules="{required: true}"
                                     :label="$cte('property.nearToMetroLabel')"
                                     :id="'nearToMetro'"/>
                        </div>

                        <div class="form-group">
                          <label for="view">{{$cte('property.View')}}</label>
                          <input
                            type="text"
                            id="view"
                            name="view"
                            class="form-control"
                            :placeholder="$cte('property.ViewLikeABeachFront')"
                            v-model="model.view"
                          />
                        </div>
                      </div>
                      <div class="col-md-8 options-group">
                        <div class="row row--options">
                          <div class="col-md-4">
                            <div class="form-switch">
                              <label for="elevator">{{ $cte('property.elevator') }}</label>

                              <b-field>
                                <b-switch v-model="model.elevator"></b-switch>
                              </b-field>

                              <!-- <input
                                type="checkbox"
                                name="elevator"
                                id="elevator"
                                class="cs-switch js-switch-colorv1"
                            /> -->
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-switch">
                              <label for="furnished">{{$cte('property.Furnished')}}</label>
                              <b-field>
                                <b-switch v-model="model.furnished"></b-switch>
                              </b-field>
                              <!-- 
                            <input
                                type="checkbox"
                                name="furnished"
                                id="furnished"
                                class="cs-switch js-switch-colorv1"
                            /> -->
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-switch">
                              <label for="smoking">{{ $cte('property.SmokingInTheHouse') }}</label>
                              <b-field>
                                <b-switch v-model="model.smokingAllowed"></b-switch>
                              </b-field>
                              <!-- <input
                                type="checkbox"
                                name="smoking"
                                id="smoking"
                                class="cs-switch js-switch-colorv2"
                            /> -->
                            </div>
                          </div>
                        </div>
                        <div class="row row--options">
                          <div class="col-md-4">
                            <div class="form-switch">
                              <label for="parking">{{$cte('property.Parking')}}</label>
                              <b-field>
                                <b-switch v-model="model.parking"></b-switch>
                              </b-field>

                              <!-- <input
                                type="checkbox"
                                name="parking"
                                id="parking"
                                class="cs-switch js-switch-colorv2"
                            /> -->
                            </div>
                          </div>
                            <div class="col-md-4">
                            <div class="form-switch">
                              <label for="heating">{{$cte('property.Heating')}}</label>
                              <b-field>
                                <b-switch v-model="model.heating"></b-switch>
                              </b-field>

                              <!-- <input
                                type="checkbox"
                                name="heating"
                                id="heating"
                                class="cs-switch js-switch-colorv2"
                            /> -->
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-switch">
                              <label for="air">{{$cte('property.AirConditioning')}}</label>
                              <b-field>
                                <b-switch v-model="model.airConditioning"></b-switch>
                              </b-field>

                              <!-- <input
                                type="checkbox"
                                name="air"
                                id="air"
                                class="cs-switch js-switch-colorv2"
                            /> -->
                            </div>
                          </div>
                        </div>
                        <div class="row row--options">
                          <div class="col-md-4">
                            <div class="form-switch">
                              <label for="balcony">{{$cte('property.BalconyTerrace')}}</label>
                              <b-field>
                                <b-switch v-model="model.balcony"></b-switch>
                              </b-field>

                              <!-- <input
                                type="checkbox"
                                name="balcony"
                                id="balcony"
                                class="cs-switch js-switch-colorv2"
                            /> -->
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-switch">
                              <label for="pet">{{$cte('property.petFriendly')}}</label>

                              <b-field>
                                <b-switch v-model="model.petFriendly"></b-switch>
                              </b-field>

                              <!-- <input
                                type="checkbox"
                                name="pet"
                                id="pet"
                                class="cs-switch js-switch-colorv2"
                            /> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="action-buttons">
                      <button @click.prevent="goBack" class="btn-outline btn-lg">
                        {{ $cte('uploadSteps.GoBack') }}
                      </button>
                      <button class="btn-primary btn-lg">
                        {{ $cte('uploadSteps.NextStep') }}
                      </button>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
          <!-- ./ main-column-right -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {ValidationObserver} from "vee-validate";


import TheLogo from "../../components/TheLogo.vue";
import StepList from "./StepList.vue";
import RentInput from '@/components/forms/RentInput'
import RentCodeStoreSelect from '@/components/forms/RentCodeStoreSelect';
import RoomAdService from "@/services/roomAd.service";

export default {
  name: "Step2",
  components: {
    StepList,
    TheLogo,
    RentInput,
    ValidationObserver,
    RentCodeStoreSelect
  },
  data() {
    return {
      propertyDetail: null,
      queryId: null,
      model: {
        floorType: "",
        distanceToPublicTransport: null,
        view: "",
        nearToMetro: "",
        elevator: false,
        smokingAllowed: false,
        furnished: false,
        parking: false,
        airConditioning: false,
        balcony: false,
        heating: false,
        petFriendly: false
      }
    };
  },
  computed: {
    ...mapState('search', [
      'searchValues'
    ]),
    ...mapGetters('property', ['getStepValues'])
  },
  created() {
    this.getAdId();
  },
  methods: {
    setModelFromStore() {
      if (!this.queryId){
        this.model = {...this.getStepValues};
      }
    },
    goBack() {
      if (this.queryId) {
        this.$router.push("/new-ad/?id=" + this.queryId);
      } else {
          this.$router.push("/new-ad");
      }
    },
    onSubmit() {

      this.$refs['validationObserver'].validate().then(valid => {
        if (!valid) return;

        let payload = {
          ...this.model
        }

        this.$store.dispatch("property/saveStep02", payload).then(
          (response) => {
            console.log(response);
            if (this.queryId) {
              this.$router.push("/new-ad-3/?id=" + this.queryId);
            } else {
              this.$router.push("/new-ad-3/");
            }
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      })
    },
    getAdId() {
      this.queryId = this.$route.query.id;
      if (this.queryId) {
        this.getAdDetails();
      }
      else {
        this.setModelFromStore();
      }
    },
    getAdDetails() {
      RoomAdService.findById(this.queryId).then(
        (response) => {
          this.propertyDetail = response.data;
          this.addPropertyDataToModel();
          this.setModelFromStore();
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    addPropertyDataToModel() {
      let self = this;
      Object.keys(self.model).forEach(function(key)
      {
        self.model[key] = self.propertyDetail.property[key];
      });
      this.model.floorType = this.propertyDetail.property.floorType.id;
    }
  }
};
</script>

<style scoped>
.options-group {
  padding: 20px 0 0 100px;
}
</style>
