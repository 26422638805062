<template>
  <div class="registration-box">
    <h2 class="registration-box__title">{{ $cte('join.title') }}</h2>
    <p class="registration-box__text">
      {{ $cte('join.text') }}
    </p>
    <ValidationObserver ref="validationObserver" v-slot="{ }">
      <form
          ref="form"
          class="form--login pt-2 pt-lg-5"
          @submit.prevent="onSubmit"
      >

        <ValidationProvider
            name="name"
            rules="required"
            v-slot="{ errors }"
        >
          <div class="form-group is-validate">
            <label class="form-label mb-0" for="name">{{ $cte('form.fullname') }}</label>
            <div class="input-wrapper">
              <input
                  class="form-control"
                  type="text"
                  id="name"
                  name="name"
                  :placeholder="$cte('form.fullname_placeholder')"
                  v-model="model.name"
              />
            </div>
            <div>
              <small class="form-text text-error">
                {{ errors[0] }}
              </small>
            </div>
          </div>
        </ValidationProvider>

        <ValidationProvider
            name="phone"
            :rules="{required: true, regex: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/ }"
            v-slot="{ errors }"
        >
          <div class="form-group is-validate">
            <label class="form-label mb-0" for="phone">{{ $cte('form.phone') }}</label>
            <div class="input-wrapper">
              <input
                  class="form-control"
                  type="text"
                  id="phone"
                  name="phone"
                  :placeholder="$cte('form.phone')"
                  v-model="model.phone"
              />
            </div>
            <div>
              <small class="form-text text-error">
                {{ errors[0] }}
              </small>
            </div>
          </div>
        </ValidationProvider>

        <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ errors }"
        >
          <div class="form-group is-validate">
            <label class="form-label mb-0" for="l-email">{{ $cte('form.email') }}</label>
            <div class="input-wrapper">
              <input
                  class="form-control"
                  type="email"
                  id="l-email"
                  name="email"
                  :placeholder="$cte('form.email_placeholder')"
                  autofocus
                  autocomplete="off"
                  v-model="model.email"
              />
            </div>
            <div>
              <small class="form-text text-error">
                {{ errors[0] }}
              </small>
            </div>
          </div>
        </ValidationProvider>

        <div class="form-submit">
          <div class="form-submit text-center pb-3 pb-md-3">
            <button
                class="btn btn-primary registration-box__submit"
            >
              {{ $cte('form.submit') }}
            </button>
          </div>
          <p class="form-helper">
            {{ $cte('join.subtext') }}
          </p>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {ValidationProvider} from "vee-validate/dist/vee-validate.full.esm";
import {ValidationObserver} from "vee-validate";
import SubscriptionService from "@/services/subscription.service";

export default {
  name: "FormRegistration",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      model: {
        name: "",
        phone: "",
        email: "",
      }
    };
  },
  computed: {
    ...mapState('auth', [
      'user',
      'status'
    ]),
  },
  methods: {
    onSubmit() {

      this.$refs['validationObserver'].validate().then(valid => {
        if (!valid) {
          return;
        }

        const loadingComponent = this.$buefy.loading.open({
          canCancel: true,
        });

        setTimeout(() => {
          loadingComponent.close();
        }, 10000);

        SubscriptionService.subscribe(this.model).then(() => {
          this.$buefy.toast.open({
            message: this.$cte('system.success.subscription'),
            type: 'is-success',
            queue: false,
            duration: 3000
          });

          this.initializeModel();

          setTimeout(() => {
            this.$refs['validationObserver'].reset();
          });
        }).catch(err => {
          console.error(err);
          this.$buefy.toast.open({
            message: this.$cte('system.error.subscription'),
            type: 'is-danger',
            queue: false,
            duration: 3000
          });
        }).finally(() => {
          loadingComponent.close();
        });
      });
    },

    initializeModel() {
      Object.keys(this.model).forEach(key => {
        this.model[key] = '';
      });
    }
  }
}
</script>

<style scoped>
/deep/ .form--login label {
  color: #fff;
}

.btn-primary:disabled {
  background-color: #de2279 !important;
}
</style>
