<template>
  <div>
    <div class="stepper">
      <div class="container">
        <div class="row align-items-stretch">
          <div class="col-12 col-md-3 col-lg-3 col-xl-3 main-column-left">
            <div class="circle-graphics"></div>
            <TheLogo/>

            <StepList currentItem="step1"/>

          </div><!-- ./ main-column-left -->

          <div class="col-12 col-md-9 col-lg-9 col-xl-9 main-column-right">


            <ValidationObserver ref="validationObserver" v-slot="{ }">
              <form class="form--newad" @submit.prevent="onSubmit">
                <div class="content-box">
                  <h2 class="content-box__title">{{ $cte('uploadSteps.step1') }}</h2>
                  <p class="content-box__text">
                    {{ $cte('uploadSteps.step1Subtitle') }}
                  </p>

                  <RentPropertyTypeSelect v-model="model.rentalType"/>

                  <div class="flat-datas">
                    <div class="row">
                      <div class="col-md-6">
                        <RentInput v-model="model.title"
                                   :id="'title'"
                                   :label="$cte('property.listingTitleLabel')"
                                   :rules="{required: true}"/>
                      </div>

                      <div class="col-md-4">
                        <RentAmountWithCurrency v-model="model.rent"
                                                :placeholder="$cte('property.amountPlaceholder')"
                                                :id="'rentPrice'"
                                                :rules="{required: true}"
                                                :label="$cte('property.rentPerMonthLabel')"/>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="crypto">{{ $cte('property.SelectCrypto') }}</label>
                          <div class="select-wrapper">
                            <!-- TODO not save to db -> no model-->
                            <select class="form-control" name="crypto" id="crypto">
                              <option value="usdt" selected>USDT</option>
                            </select>
                            <div class="icon icon-arrow">
                              <svg xmlns="http://www.w3.org/2000/svg" width="14.477" height="8.834"
                                   viewBox="0 0 14.477 8.834">
                                <g id="down-arrow" transform="translate(0)">
                                  <g id="Group_6" data-name="Group 6" transform="translate(0)">
                                    <path id="Path_1412" data-name="Path 1412"
                                          d="M13.98,98.49A1.7,1.7,0,0,0,12.78,98H1.7a1.7,1.7,0,0,0-1.2.491,1.666,1.666,0,0,0,0,2.372l5.542,5.48a1.713,1.713,0,0,0,2.4,0l3.64-3.615a.555.555,0,0,0,0-.791.57.57,0,0,0-.8,0l-3.639,3.614a.571.571,0,0,1-.8,0L1.3,100.072a.555.555,0,0,1,0-.791.565.565,0,0,1,.4-.164H12.78a.565.565,0,0,1,.4.164.555.555,0,0,1,0,.791.555.555,0,0,0,0,.791.57.57,0,0,0,.8,0A1.666,1.666,0,0,0,13.98,98.49Z"
                                          transform="translate(0.001 -97.999)"/>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 col-lg-3">
                        <div class="form-group">
                          <label for="availableFrom">{{ $cte('property.AvailableFrom') }} <span class="required">*</span></label>
                          <!-- <input type="text" class="form-control" name="available" id="available" placeholder="Choose Date"> -->
                          <div class="input-wrapper">
                            <ValidationProvider
                              :name="$cte('property.AvailableFrom')"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-field>
                                <b-datepicker
                                  v-model="model.availableFrom"
                                  :show-week-number="showWeekNumber"
                                  :locale="pickerLocale"
                                  :placeholder="$cte('common.chooseDate')"
                                  trap-focus>
                                </b-datepicker>
                              </b-field>
                              <small class="form-text text-error">
                                {{ errors[0] }}
                              </small>
                            </ValidationProvider>
                            <div class="icon icon-calendar">
                              <img src="../../assets/img/calendar-icon.svg" alt="calendar-icon">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-3">
                        <div class="form-group">
                          <label for="availableUntil">{{ $cte('property.AvailableUntil') }} </label>
                          <!-- <input type="text" class="form-control" name="available" id="available" placeholder="Choose Date"> -->
                          <div class="input-wrapper">
                            <ValidationProvider
                                name="availableFrom"
                                rules=""
                                v-slot="{ errors }"
                            >
                              <b-field>
                                <b-datepicker
                                    v-model="model.availableUntil"
                                    :show-week-number="showWeekNumber"
                                    :locale="pickerLocale"
                                    :placeholder="$cte('common.chooseDate')"
                                    trap-focus>
                                </b-datepicker>
                              </b-field>
                              <small class="form-text text-error">
                                {{ errors[0] }}
                              </small>
                            </ValidationProvider>
                            <div class="icon icon-calendar">
                              <img src="../../assets/img/calendar-icon.svg" alt="calendar-icon">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-3">
                        <div class="form-group">
                          <RentAmountWithCurrency v-model="model.deposit"
                                                  :rules="{required: true}"
                                                  :id="'deposit'"
                                                  :label="$cte('property.depositLabel')"/>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-3">
                        <RentInput v-model="model.roomSize"
                                   :id="'roomSize'"
                                   :rules="{required: true}"
                                   :type="'number'"
                                   :label="$cte('property.apartmentSizeLabel')"
                                   :formHelper="'m2'"/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 col-lg-3">
                        <div class="form-group">
                          <label for="rooms">{{ $cte('property.NumberOfRoomsLabel')}}</label>
                          <div class="select-wrapper">
                            <ValidationProvider
                                name="rooms"
                                rules="required"
                                v-slot="{ errors }"
                            >
                              <select class="form-control" name="rooms" id="rooms" v-model="model.bedsNumber">
                                <option value="1" selected>1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="61">{{ $cte('property.6ormore') }}</option>
                              </select>
                              <small class="form-text text-error">
                                {{ errors[0] }}
                              </small>
                            </ValidationProvider>
                            <div class="icon icon-arrow">
                              <svg xmlns="http://www.w3.org/2000/svg" width="14.477" height="8.834"
                                   viewBox="0 0 14.477 8.834">
                                <g id="down-arrow" transform="translate(0)">
                                  <g id="Group_6" data-name="Group 6" transform="translate(0)">
                                    <path id="Path_1412" data-name="Path 1412"
                                          d="M13.98,98.49A1.7,1.7,0,0,0,12.78,98H1.7a1.7,1.7,0,0,0-1.2.491,1.666,1.666,0,0,0,0,2.372l5.542,5.48a1.713,1.713,0,0,0,2.4,0l3.64-3.615a.555.555,0,0,0,0-.791.57.57,0,0,0-.8,0l-3.639,3.614a.571.571,0,0,1-.8,0L1.3,100.072a.555.555,0,0,1,0-.791.565.565,0,0,1,.4-.164H12.78a.565.565,0,0,1,.4.164.555.555,0,0,1,0,.791.555.555,0,0,0,0,.791.57.57,0,0,0,.8,0A1.666,1.666,0,0,0,13.98,98.49Z"
                                          transform="translate(0.001 -97.999)"/>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-3">
                        <div class="form-group">
                          <label for="bathroom">{{ $cte('property.BathroomsLabel') }}</label>
                          <div class="select-wrapper">
                            <select class="form-control" name="bathroom" id="bathroom" v-model="model.bathroom">
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="31">{{ $cte('property.3ormore') }}</option>
                            </select>

                            <div class="icon icon-arrow">
                              <svg xmlns="http://www.w3.org/2000/svg" width="14.477" height="8.834"
                                   viewBox="0 0 14.477 8.834">
                                <g id="down-arrow" transform="translate(0)">
                                  <g id="Group_6" data-name="Group 6" transform="translate(0)">
                                    <path id="Path_1412" data-name="Path 1412"
                                          d="M13.98,98.49A1.7,1.7,0,0,0,12.78,98H1.7a1.7,1.7,0,0,0-1.2.491,1.666,1.666,0,0,0,0,2.372l5.542,5.48a1.713,1.713,0,0,0,2.4,0l3.64-3.615a.555.555,0,0,0,0-.791.57.57,0,0,0-.8,0l-3.639,3.614a.571.571,0,0,1-.8,0L1.3,100.072a.555.555,0,0,1,0-.791.565.565,0,0,1,.4-.164H12.78a.565.565,0,0,1,.4.164.555.555,0,0,1,0,.791.555.555,0,0,0,0,.791.57.57,0,0,0,.8,0A1.666,1.666,0,0,0,13.98,98.49Z"
                                          transform="translate(0.001 -97.999)"/>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-3">
                        <div class="form-group">
                          <label for="toilet">{{ $cte('property.NumberOfToiletsLabel') }}</label>
                          <div class="select-wrapper">
                            <select class="form-control" name="toilet" id="toilet" v-model="model.toilet">
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="31">{{ $cte('property.3ormore') }}</option>
                            </select>
                            <div class="icon icon-arrow">
                              <svg xmlns="http://www.w3.org/2000/svg" width="14.477" height="8.834"
                                   viewBox="0 0 14.477 8.834">
                                <g id="down-arrow" transform="translate(0)">
                                  <g id="Group_6" data-name="Group 6" transform="translate(0)">
                                    <path id="Path_1412" data-name="Path 1412"
                                          d="M13.98,98.49A1.7,1.7,0,0,0,12.78,98H1.7a1.7,1.7,0,0,0-1.2.491,1.666,1.666,0,0,0,0,2.372l5.542,5.48a1.713,1.713,0,0,0,2.4,0l3.64-3.615a.555.555,0,0,0,0-.791.57.57,0,0,0-.8,0l-3.639,3.614a.571.571,0,0,1-.8,0L1.3,100.072a.555.555,0,0,1,0-.791.565.565,0,0,1,.4-.164H12.78a.565.565,0,0,1,.4.164.555.555,0,0,1,0,.791.555.555,0,0,0,0,.791.57.57,0,0,0,.8,0A1.666,1.666,0,0,0,13.98,98.49Z"
                                          transform="translate(0.001 -97.999)"/>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-3">
                        <div class="form-group">
                          <RentCodeStoreSelect code-store-id="3"
                                               :label="$cte('property.termOfLease')"
                                               v-model="model.termOfLease"
                                               :rules="{required: true}"
                                               :key="'termOfLease'"/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="action-buttons">
                    <button @click.prevent="goBack" tpye="button" class="btn-outline btn-lg">{{ $cte('uploadSteps.Cancel')}}</button>
                    <button class="btn-primary btn-lg" type="submit">{{ $cte('uploadSteps.NextStep')}}</button>
                  </div>

                </div>
              </form>
            </ValidationObserver>
          </div><!-- ./ main-column-right -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

import TheLogo from "../../components/TheLogo.vue";
import StepList from "./StepList.vue";

import {
  ValidationProvider,
} from "vee-validate/dist/vee-validate.full.esm";
import {ValidationObserver} from "vee-validate";
import RentCodeStoreSelect from "../../components/forms/RentCodeStoreSelect";
import RentPropertyTypeSelect from '@/components/forms/RentPropertyTypeSelect';
import RentInput from '@/components/forms/RentInput';
import RentAmountWithCurrency from "../../components/forms/RentAmountWithCurrency";
import RoomAdService from "@/services/roomAd.service";

export default {
  name: "Step1",
  components: {
    RentAmountWithCurrency,
    RentCodeStoreSelect,
    RentPropertyTypeSelect,
    RentInput,
    StepList,
    TheLogo,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      propertyDetail: null,
      queryId: null,
      model: {
        termOfLease: null,
        rentalType: null,
        roomSize: null,
        toilet: 1,
        bathroom: 1,
        bedsNumber: 1,
        size: "",
        rent: {
          amount: null,
          currency: 200000
        },
        availableFrom: "",
        availableUntil: "",
        deposit: {
          amount: null,
          currency: 200000
        },
        title: ""
      }
    }
  },
  computed: {
    ...mapState('auth', [
      'user',
    ]),
    ...mapGetters('property', [
      'getStepValues',
    ]),
  },
  created() {
    this.getAdId();
    
  },
  methods: {
    setModelFromStore() {
      if (!this.queryId){
        this.model = {...this.getStepValues};
        this.model.termOfLease = this.model.termOfLease[0];
        this.model.deposit = {amount: this.model.deposit, currency: this.model.depositCurrency };
        this.model.rent = {amount: this.model.rentPrice, currency: this.model.rentPriceCurrency };
      }
    },
    goBack() {
      this.$store.dispatch('property/resetState');
      this.$router.push("/");
    },
    onSubmit() {

      this.$refs['validationObserver'].validate().then(valid => {
        if (!valid) return;

        let ownerId = this.user.id;

        let payload = {
          ...this.model,
          ownerId: ownerId,
          rentPrice: this.model.rent.amount,
          rentPriceCurrency: this.model.rent.currency,
          rentalType : this.model.rentalType.id,
          deposit: this.model.deposit.amount,
          depositCurrency: this.model.deposit.currency,
          termOfLease: [this.model.termOfLease],
        };
        if (this.queryId) {
          payload.id = parseInt(this.queryId);
        }
        this.$store.dispatch("property/saveStep01", payload).then(
          (response) => {
            console.log(response);
            if (this.queryId) {
              this.$router.push("/new-ad-2/?id=" + this.queryId);
            } else {
              this.$router.push("/new-ad-2/");
            }
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      })
    },
    getAdId() {
      this.queryId = this.$route.query.id;
      if (this.queryId) {
        this.getAdDetails();
      }
      else {
        this.setModelFromStore();
      }
    },
    getAdDetails() {
      RoomAdService.findById(this.queryId).then(
        (response) => {
          this.propertyDetail = response.data;
          this.addPropertyDataToModel();
          this.setModelFromStore();
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    addPropertyDataToModel() {
      let self = this;
      Object.keys(self.model).forEach(function(key)
      {
        self.model[key] = self.propertyDetail.property[key];
      });
      this.model.availableFrom = new Date(this.propertyDetail.availableFrom);
      this.model.availableUntil = new Date(this.propertyDetail.availableUntil);
      this.model.rent = {
        amount: this.propertyDetail.property.rentPrice.toString(),
        currency: this.propertyDetail.property.rentPriceCurrency.id
      };
      this.model.termOfLease = this.propertyDetail.property.termOfLease[0].id;
      this.model.deposit = {
        amount: this.propertyDetail.property.deposit.toString(),
        currency: this.propertyDetail.property.depositCurrency.id
      };
    }
  }
}
</script>

<style>
@import "../../assets/css/style.css";

.stepper .container {
  max-width: 100% !important;
}

.flat-type__box {
  opacity: 0.5;
}

.flat-type__box.active {
  opacity: 1;
}

select.form-control {
  -webkit-appearance: none;
}

.input-wrapper {
  position: relative;
}

.input-wrapper select {
  width: 100px;
  position: absolute;
  top: 1px;
  right: 1px;
  height: 64px;
  font-weight: 700;
  border: none;
  text-align: left;
}

.input-wrapper .form-helper {
  color: #B9B9B9;
  font-size: 16px;
  position: absolute;
  right: 20px;
  top: 20px;
}

.input-wrapper select::before {
  content: '';
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 1px;
  height: 30px;
  position: absolute;
  background: #F3E8E5;
}

.datepicker.control input {
  background: #fff !important;
  padding-left: 25px !important;
  border: 1px solid #6565655c !important;
  box-shadow: none!important;
}

.field:not(:last-child) {
  margin-bottom: 0;
}

.datepicker .dropdown-trigger .input {
  border: none;
}

.datepicker .dropdown .input[readonly]:focus, .datepicker .dropdown .input[readonly].is-focused,
.datepicker .dropdown .input[readonly]:active, .datepicker .dropdown .input[readonly].is-active {
  border: 1px solid #d91f6e;
  outline: none;
  box-shadow: none !important;
}

.datepicker .dropdown-trigger .input[readonly]:active, .datepicker .dropdown-trigger .input[readonly].is-active {
  border: 1px solid #d91f6e;
  outline: none;
  box-shadow: none !important;
}

.icon-arrow,
.icon-calendar {
  position: absolute;
  right: 20px;
  top: 22px;
  display: inline-block;
}

.select-wrapper {
  position: relative;
}

.select-wrapper .icon {
  position: absolute;
  top: 24px;
  right: 15px;
}

select + .icon {
  right: 8px;
}
</style>
