<template>
  <portal to="modal-root">
    <b-modal v-model="isOpen"
             :class="getClass"
             @close="closeModal"
             :scroll="scroll"
             :full-screen="fullScreen">
      <slot/>
    </b-modal>
  </portal>
</template>

<script>
export default {
  props: {
    fullScreen: {
      type: Boolean,
      default: false
    },
    scroll: {
      type: String,
      default: 'keep'
    },
    isMetaverse: {
      type: Boolean,
      default: false
    },
    isCoworking: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    openModal() {
      this.isOpen = false;
    },
    closeModal() {
      this.isOpen = true;
    }
  },
  computed: {
    getClass() {
      if (this.isMetaverse) {
        return 'metaverse-modal';
      }
      if (this.isCoworking) {
        return 'coworking-modal';
      }
      return '';
    }
  }
};
</script>

<style>
@import "../assets/css/modal.css";

.modal {
  z-index: 99999 !important;
}

.modal-background {
  background-color: rgba(255, 255, 255, 0.5) !important;
  z-index: 99991;
}

.modal-content {
  z-index: 99995;
}

.modal-card {
  z-index: 99992;
}

.notices {
  z-index: 99999 !important;
}

.metaverse-modal .modal-background {
  background: url("../assets/img/modal-01.png") no-repeat center center fixed!important;
}

.coworking-modal .modal-background {
  background: #000 url("../assets/img/modal-bg.png") no-repeat fixed center center !important;
}

</style>


