<template>
<div class="staking">

  <TheHeader />
    <main class="page-content">
      <div class="banner">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 col-xl-8 offset-xl-4">
              <div class="steps-group">
                <div class="steps-group__item active">
                  <div class="item-title">Step 1</div>
                  <p class="item-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Praesent at tortor at ex varius
                  </p>
                </div>
                <div class="steps-group__item active">
                  <div class="item-title">Step 2</div>
                  <p class="item-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Praesent at tortor at ex varius
                  </p>
                </div>
                <div class="steps-group__item">
                  <div class="item-title">Step 3</div>
                  <p class="item-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Praesent at tortor at ex varius
                  </p>
                </div>
                <div class="steps-group__item">
                  <div class="item-title">Step 4</div>
                  <p class="item-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Praesent at tortor at ex varius
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ./banner -->

      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-4">
            <div class="summary-box">
              <h4 class="summary-box__title">Staking summary</h4>
              <div class="summary-box__item">
                <div class="item-label">Available LP tokens</div>
                <div class="item-value highlighted">2120.210</div>
              </div>
              <div class="summary-box__item">
                <div class="item-label">Locked LP tokens</div>
                <div class="item-value">30.210</div>
              </div>
              <div class="summary-box__item">
                <div class="item-label">APY:</div>
                <div class="item-value">60%</div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-8">
            <div class="content-wrapper">
              <div class="content-group">
                <div class="content-group__header">
                  <h2 class="content-group__title">Stake</h2>
                  <p class="content-group__text">
                    Rentable Pancake Swap Staking programme
                  </p>
                </div>

                <form class="form--oneRow" action="POST">
                  <div class="dropdown">
                    <button
                      type="button"
                      class="menu-btn dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.626"
                        height="26.505"
                        viewBox="0 0 6.626 26.505"
                      >
                        <g
                          id="menu"
                          transform="translate(-193.333 26.506) rotate(-90)"
                        >
                          <path
                            id="Path_12802"
                            data-name="Path 12802"
                            d="M392.322,194.3a3.313,3.313,0,1,1-4.685,0,3.313,3.313,0,0,1,4.685,0"
                            transform="translate(-366.786 0)"
                            fill="#707070"
                          />
                          <path
                            id="Path_12803"
                            data-name="Path 12803"
                            d="M198.99,194.3a3.313,3.313,0,1,1-4.685,0,3.313,3.313,0,0,1,4.685,0"
                            transform="translate(-183.394 0)"
                            fill="#707070"
                          />
                          <path
                            id="Path_12804"
                            data-name="Path 12804"
                            d="M5.657,194.3a3.313,3.313,0,1,1-4.685,0,3.313,3.313,0,0,1,4.685,0"
                            transform="translate(0 0)"
                            fill="#707070"
                          />
                        </g>
                      </svg>
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a class="dropdown-item" href="#">Action</a>
                      <a class="dropdown-item" href="#">Another action</a>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div>

                  <div class="form-input">
                    <label for="txid">TX ID</label>
                    <input
                      class="form-control"
                      type="text"
                      id="txid"
                      placeholder="Please enter your TXID"
                    />
                  </div>
                  <button type="submit" class="btn-primary btn--lg btn--submit">
                    Submit
                  </button>
                </form>
              </div>

              <div class="content-group">
                <div class="content-group__header">
                  <h2 class="content-group__title">Unstake</h2>
                  <p class="content-group__text">
                    Rentable Pancake Swap Staking programme
                  </p>
                </div>

                <div class="row-box">
                  <div class="table-responsive">
                    <table class="row-box__table">
                      <tr>
                        <td>
                          <span class="table-label">Locked LP tokens</span>
                          <div class="table-value highlighted">47,12</div>
                        </td>
                        <td>
                          <span class="table-label">Rewards</span>
                          <div class="table-value mute">10</div>
                        </td>
                        <td>
                          <div class="progress">
                            <div
                              class="progress-bar in-progress"
                              role="progressbar"
                              style="width: 85%"
                              aria-valuenow="85"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <span class="table-label"
                            >12 days left to unlock</span
                          >
                        </td>
                        <td>
                          <span class="table-label">APY:</span>
                          <div class="table-value mute">60%</div>
                        </td>
                        <td>
                          <button type="button" class="btn-secondary btn--sm">
                            Unstake
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="row-box">
                  <div class="table-responsive">
                    <table class="row-box__table">
                      <tr>
                        <td>
                          <span class="table-label">Locked LP tokens</span>
                          <div class="table-value">47,12</div>
                        </td>
                        <td>
                          <span class="table-label">Rewards</span>
                          <div class="table-value mute">10</div>
                        </td>
                        <td>
                          <div class="progress">
                            <div
                              class="progress-bar is-finished"
                              role="progressbar"
                              style="width: 100%"
                              aria-valuenow="100"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <span class="table-label">Finished</span>
                        </td>
                        <td>
                          <span class="table-label">APY:</span>
                          <div class="table-value mute">60%</div>
                        </td>
                        <td>
                          <div class="btn-group">
                            <button type="button" class="btn-primary">
                              Claim rewards
                            </button>
                            <button type="button" class="btn-secondary">
                              Claim LP + rewards
                            </button>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="row-box">
                  <div class="table-responsive">
                    <table class="row-box__table">
                      <tr>
                        <td>
                          <span class="table-label">Locked LP tokens</span>
                          <div class="table-value">47,12</div>
                        </td>
                        <td>
                          <span class="table-label">Rewards</span>
                          <div class="table-value mute">10</div>
                        </td>
                        <td>
                          <div class="progress">
                            <div
                              class="progress-bar in-progress"
                              role="progressbar"
                              style="width: 85%"
                              aria-valuenow="85"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <span class="table-label"
                            >12 days left to unlock</span
                          >
                        </td>
                        <td>
                          <span class="table-label">APY:</span>
                          <div class="table-value mute">60%</div>
                        </td>
                        <td>
                          <button type="button" class="btn-secondary btn--sm">
                            Unstake
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  <TheFooter />

</div>
  <!-- ./page-content -->
</template>

<script>
import TheHeader from "../components/TheHeader.vue";
import TheFooter from "../components/TheFooter.vue";

export default {
    name: "Search",
    components: { 
        TheHeader,
        TheFooter
    }
};
</script>

<style scoped>
@import "../assets/css/style.css";

.page-content {
    padding-top: 0;
}
</style>
