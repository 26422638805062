import {i18n, fallbackLocale} from '../i18n/i18n'

export default class Util {

  static isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * Localizációtól függően kellene dátumot formázni
   * de az idő rövidsége miatt egyelőre sima iso 8601 formátum lesz
   * @param date
   */
  static formatDateISO(date) {
    if (!date) return '';
    return new Date(date).toISOString().substring(0, 10);
  }

  static formatLocaleDate(date, options = {
    month: 'short',
    day: 'numeric'
  }) {
    if (!date) return '';
    return new Date(date).toLocaleDateString(i18n.locale, options);
  }

  static truncateText(string, maxLength) {
    let length = maxLength || 50;
    if (string.length > length) {
      return string.substring(0, length) + '...';
    }
    return string;
  }

  static getFallbackLanguage(parsedData) {
    if (parsedData[i18n.locale]) {
      return parsedData[i18n.locale];
    } else {
      let i = 0;
      let translatedString = undefined;

      while (i < fallbackLocale.length && !translatedString) {
        translatedString = parsedData[fallbackLocale[i]];
        ++i;
      }

      return translatedString;
    }
  }

  static translationEngine(x, dataArray = []) {
    let self = this;
    let translatedMessage = null;

    if (this.isJson(x) && isNaN(x)) {
      let parsedData = JSON.parse(x)
      translatedMessage = parsedData ? self.getFallbackLanguage(parsedData) : parsedData;
    } else {
      let translatedDataArray = [];

      dataArray.forEach(item => {
        let translatedArrayItem = self.translationEngine(item)
        translatedDataArray.push(translatedArrayItem)
      })

      translatedMessage = i18n.t(x, translatedDataArray);
    }

    return translatedMessage
  }

  static uppercaseFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  static lowercaseFirstLetter(string) {
    return string[0].toLowerCase() + string.slice(1);
  }

  static getImgFromByteArray(encoded) {
    return 'data:image/png;base64,' + encoded;
  }

  static isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }

}
