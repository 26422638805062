<template>
  <footer class="page-footer">
    <div class="container-fluid">
      <div class="row footer-top">
        <div class="col">
          <a href="/"
            ><img
              class="
                align-items-center
                d-flex
                justify-content-center
                footer-top__logo
              "
              src="../assets/img/logos/logo-white.png"
              alt="Rentible logo"
          /></a>
          <p class="footer-top__desc pt-4">
           {{ $cte("footer.copmanyDescription") }}
          </p>
          <div class="footer-top__social">
            <a :href="this.$cte('footer.links.medium')" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="19"
                viewBox="0 0 24 19"
              >
                <path
                  id="medium"
                  d="M22.085,4.733,24,2.9V2.5H17.366L12.638,14.268,7.259,2.5H.3v.4L2.54,5.594a.939.939,0,0,1,.3.783V16.96a1.207,1.207,0,0,1-.323,1.05L0,21.064v.4H7.145v-.4L4.625,18.01a1.244,1.244,0,0,1-.347-1.05V7.806L10.55,21.465h.729L16.672,7.806V18.687c0,.287,0,.346-.188.534L14.544,21.1v.4h9.412v-.4l-1.87-1.831a.556.556,0,0,1-.214-.534V5.267a.554.554,0,0,1,.213-.534Z"
                  transform="translate(0 -2.5)"
                  fill="#fff8f8"
                />
              </svg>
            </a>
            <a :href="this.$cte('footer.links.telegram')" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26.506"
                height="23.078"
                viewBox="0 0 26.506 23.078"
              >
                <g id="XMLID_496_" transform="translate(0 -19.4)">
                  <path
                    id="XMLID_497_"
                    d="M.468,30.466l6.107,2.279,2.364,7.6a.719.719,0,0,0,1.142.344l3.4-2.775a1.016,1.016,0,0,1,1.238-.035l6.14,4.458a.72.72,0,0,0,1.128-.435l4.5-21.637a.72.72,0,0,0-.964-.818L.461,29.119A.72.72,0,0,0,.468,30.466Zm8.09,1.066,11.936-7.351a.208.208,0,0,1,.251.329L10.9,33.667a2.042,2.042,0,0,0-.633,1.222l-.336,2.487a.308.308,0,0,1-.6.044L8.034,32.884A1.2,1.2,0,0,1,8.559,31.532Z"
                    transform="translate(0)"
                    fill="#fff8f8"
                  />
                </g>
              </svg>
            </a>
            <a :href="this.$cte('footer.links.twitter')" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25.854"
                height="21.039"
                viewBox="0 0 25.854 21.039"
              >
                <path
                  id="_x30_4.Twitter"
                  d="M35.754,19.988a10.873,10.873,0,0,1-3.038.84,5.17,5.17,0,0,0,2.327-2.941,11.162,11.162,0,0,1-3.361,1.293A5.3,5.3,0,0,0,22.5,22.8a4.846,4.846,0,0,0,.129,1.2A14.919,14.919,0,0,1,11.742,18.47a5.319,5.319,0,0,0,1.616,7.077,5.089,5.089,0,0,1-2.391-.646A5.356,5.356,0,0,0,15.2,30.168a5.817,5.817,0,0,1-2.391.1,5.322,5.322,0,0,0,4.945,3.684,10.678,10.678,0,0,1-7.853,2.2A15.06,15.06,0,0,0,33.1,23.479a4.773,4.773,0,0,0-.032-.679,10.519,10.519,0,0,0,2.682-2.812Z"
                  transform="translate(-9.9 -17.5)"
                  fill="#fff8f8"
                />
              </svg>
            </a>
            <a :href="this.$cte('footer.links.youtube')" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.467"
                height="26.615"
                viewBox="0 0 22.467 26.615"
              >
                <g id="youtube-logotype" transform="translate(-7.067)">
                  <g
                    id="Group_5059"
                    data-name="Group 5059"
                    transform="translate(7.067)"
                  >
                    <path
                      id="Path_6848"
                      data-name="Path 6848"
                      d="M29.145,39.844a2.838,2.838,0,0,0-2.429-2.208,75.483,75.483,0,0,0-8.412-.311,75.505,75.505,0,0,0-8.414.311,2.837,2.837,0,0,0-2.427,2.208,24.056,24.056,0,0,0-.4,5.311,24.051,24.051,0,0,0,.392,5.311,2.837,2.837,0,0,0,2.428,2.208,75.488,75.488,0,0,0,8.413.311,75.433,75.433,0,0,0,8.413-.311,2.838,2.838,0,0,0,2.428-2.208,24.07,24.07,0,0,0,.393-5.311A24.087,24.087,0,0,0,29.145,39.844Zm-15.67,1.319H11.849V49.8h-1.51V41.163h-1.6V39.748h4.733Zm4.1,8.641H16.219v-.817a2.176,2.176,0,0,1-1.552.92.833.833,0,0,1-.875-.557,3.824,3.824,0,0,1-.12-1.114V42.311h1.358v5.517a4.227,4.227,0,0,0,.012.528c.033.211.136.318.318.318.272,0,.557-.21.858-.636V42.311h1.359V49.8Zm5.158-2.249a5.985,5.985,0,0,1-.137,1.51,1.075,1.075,0,0,1-1.085.842,1.85,1.85,0,0,1-1.4-.829V49.8H18.753V39.748h1.358v3.284a1.839,1.839,0,0,1,1.4-.813,1.074,1.074,0,0,1,1.085.845,5.754,5.754,0,0,1,.137,1.506ZM27.86,46.3H25.145v1.328c0,.694.227,1.04.694,1.04a.583.583,0,0,0,.606-.544,8.542,8.542,0,0,0,.031-.92H27.86v.2c0,.438-.017.739-.029.875a1.935,1.935,0,0,1-.317.813,1.879,1.879,0,0,1-1.642.812,1.944,1.944,0,0,1-1.643-.767,2.9,2.9,0,0,1-.436-1.762V44.754a2.916,2.916,0,0,1,.42-1.766,1.93,1.93,0,0,1,1.626-.768,1.886,1.886,0,0,1,1.6.768,2.945,2.945,0,0,1,.42,1.766V46.3Z"
                      transform="translate(-7.067 -26.37)"
                      fill="#fff8f8"
                    />
                    <path
                      id="Path_6849"
                      data-name="Path 6849"
                      d="M69.338,58.163q-.681,0-.681,1.039V59.9h1.358V59.2C70.014,58.51,69.787,58.163,69.338,58.163Z"
                      transform="translate(-50.579 -41.091)"
                      fill="#fff8f8"
                    />
                    <path
                      id="Path_6850"
                      data-name="Path 6850"
                      d="M52.182,58.163a.973.973,0,0,0-.677.33V63.06a.968.968,0,0,0,.677.335c.392,0,.591-.335.591-1.011v-3.2C52.773,58.51,52.574,58.163,52.182,58.163Z"
                      transform="translate(-38.462 -41.091)"
                      fill="#fff8f8"
                    />
                    <path
                      id="Path_6851"
                      data-name="Path 6851"
                      d="M53.976,16.5a2.2,2.2,0,0,0,1.567-.93v.826h1.372V8.82H55.543v5.788c-.3.43-.592.642-.867.642-.184,0-.292-.109-.322-.321a3.182,3.182,0,0,1-.017-.533V8.82H52.97v5.988a3.826,3.826,0,0,0,.122,1.126A.843.843,0,0,0,53.976,16.5Z"
                      transform="translate(-39.497 -6.231)"
                      fill="#fff8f8"
                    />
                    <path
                      id="Path_6852"
                      data-name="Path 6852"
                      d="M19.263,6.046v4.115h1.522V6.046L22.615,0H21.076L20.038,3.99,18.958,0h-1.6c.321.942.655,1.888.975,2.831A25.031,25.031,0,0,1,19.263,6.046Z"
                      transform="translate(-14.337)"
                      fill="#fff8f8"
                    />
                    <path
                      id="Path_6853"
                      data-name="Path 6853"
                      d="M37.34,16.276a1.87,1.87,0,0,0,1.6-.775,2.962,2.962,0,0,0,.426-1.781V11.068a2.968,2.968,0,0,0-.426-1.784,2.035,2.035,0,0,0-3.2,0,2.943,2.943,0,0,0-.429,1.784V13.72a2.935,2.935,0,0,0,.429,1.781A1.87,1.87,0,0,0,37.34,16.276Zm-.655-5.483c0-.7.213-1.05.655-1.05s.654.349.654,1.05v3.185c0,.7-.212,1.051-.654,1.051s-.655-.35-.655-1.051Z"
                      transform="translate(-27.022 -6.011)"
                      fill="#fff8f8"
                    />
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="row">
            <div class="col footer-top__col">
              <h4 class="mb-3 mt-3">{{ $cte("footer.about") }}</h4>
              <ul class="list-unstyled">
                <li><a :href="this.$cte('footer.links.rentible')" target="_blank">{{ $cte("footer.menu.about.item01") }}</a></li>
                <!-- <li><a :href="this.$cte('footer.links.opportunity')" target="_blank">{{ $cte("footer.menu.about.item02") }}</a></li> -->
                <li><a :href="this.$cte('footer.links.solutions')" target="_blank">{{ $cte("footer.menu.about.item04") }}</a></li>
                <!-- <li><a :href="this.$cte('footer.links.stakeOnBsc')" target="_blank">{{ $cte("footer.menu.about.stakeOnBsc") }}</a></li> -->
                <!-- <li><a :href="this.$cte('footer.links.stakeOnErc')" target="_blank">{{ $cte("footer.menu.about.stakeOnErc") }}</a></li> -->
                <!-- <li><a :href="this.$cte('footer.links.stakeOnBnt')" target="_blank">{{ $cte("footer.menu.about.stakeOnBnt") }}</a></li> -->
                <li><a :href="this.$cte('footer.links.press')" target="_blank">{{ $cte("footer.menu.about.item06") }}</a></li>
              </ul>
            </div>
            <div class="col footer-top__col">
              <h4 class="mb-3 mt-3">{{ $cte("footer.community") }}</h4>
              <ul class="list-unstyled">
                <li><a :href="this.$cte('footer.links.collaborations')" target="_blank">{{ $cte("footer.menu.community.item01") }}</a></li>
                <li><a :href="this.$cte('footer.links.workWithUs')" target="_blank">{{ $cte("footer.menu.community.item02") }}</a></li>
                <li><a class="disabled-link">{{ $cte("footer.menu.community.item03") }}</a></li>
              </ul>
            </div>
            <div class="col footer-top__col">
              <h4 class="mb-3 mt-3">{{ $cte("footer.assistance") }}</h4>
              <ul class="list-unstyled">
                <li><a :href="this.$cte('footer.links.faq')" target="_blank">{{ $cte("footer.menu.assistance.item01") }}</a></li>
                <li><a :href="this.$cte('footer.links.support')" target="_blank">{{ $cte("footer.menu.assistance.item02") }}</a></li>
                <li><a :href="this.$cte('footer.links.riskStatement')" target="_blank">{{ $t("footer.menu.assistance.item03") }}</a></li>
                <li><a :href="this.$cte('footer.links.privacyPolicy')" target="_blank">{{ $t("footer.menu.assistance.item04") }}</a></li>
                <li><a class="disabled-link">{{ $t("footer.menu.assistance.item05") }}</a></li>
                <li><a :href="this.$cte('footer.links.termsAndConditions')" target="_blank">{{ $t("footer.menu.assistance.item06") }}</a></li>
              </ul>
            </div>
            <div class="col footer-top__col">
              <h4 class="mb-3 mt-3">{{ $t("footer.hold_rnb") }}</h4>
              <p class="mb-5">
                {{ $t("footer.hold_rnb_text") }}
              </p>
              <div class="buy-logos">
                <span>{{ $t("footer.buy_rnb") }}</span>
                <a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2a039b1d9bbdccbb91be28691b730ca893e5e743&chain=mainnet" target="_blank">
                  <img src="../assets/img/logos/footer-logo-01.png" alt="." />
                </a>
                <a href="https://global.bittrex.com/account/login" target="_blank">
                  <img src="../assets/img/logos/footer-logo-02.png" alt="." class="p-1"/>
                </a>
                <a href="https://pancakeswap.finance/swap?inputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&outputCurrency=0xadec335a2e3881303a9b0203eb99de12202280df" target="_blank">
                  <img src="../assets/img/logos/footer-logo-03.png" alt="." class="p-1"/>
                </a>
                <a href="https://app.bancor.network/?from=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&to=0x2A039B1D9bBDccBb91be28691b730ca893e5e743" target="_blank">
                  <img src="../assets/img/logos/footer-logo-04.png" alt="." class="p-1"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="footer-bottom">
            <p class="copyright mb-0">
              {{ $t("footer.copyright") }}
            </p>
            <ul class="footer-bottom__menu">
              <li><a :href="this.$cte('footer.links.termsAndConditions')" target="_blank">{{ $cte('footer.menu.termsAndConditions.item01') }}</a></li>
              <li class="ml-2 mr-2">|</li>
              <li><a :href="this.$cte('footer.links.privacyPolicy')" target="_blank">{{ $cte('footer.menu.termsAndConditions.item02') }}</a></li>
              <li class="ml-2 mr-2">|</li>
              <li><a href="https://rentible.io/privacy/" target="_blank">{{ $cte('footer.menu.termsAndConditions.item03') }}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style scoped>
@import "../assets/css/style.css";

.disabled-link {
  cursor: default !important;
  color: #999 !important;
}
</style>
