<template>
 <div class="col-md-6">
  <a :href="$cte(item.link)" target="_blank" class="card card--news mb-4" style="border-radius: 13px">
    <div class="row no-gutters">
      <div class="col-12 col-xl-4 card-image-col">
        <img
          :src="item.image"
          alt="Lorem ipsum dolor sit amet, consectetur adipiscing elit. .."
        />
      </div>
      <div class="col-12 col-xl-8">
        <div class="card-body">
          <div class="card-badges">
            <div class="badge badge--bg1">{{ $cte(item.badge01) }}</div>
            <div class="badge badge--bg2">{{ $cte(item.badge02) }}</div>
          </div>
          <h5 class="card-title mt-3">
            {{ $cte(item.title) }}
          </h5>
          <p class="card-text mb-3">
              {{ $cte(item.text) }}
          </p>
          <div class="card-info">
            <div class="info-date">{{ $cte(item.date) }}</div>
            <div class="info-role"> - {{ $cte(item.role) }}</div>
          </div>
        </div>
      </div>
    </div>
  </a>
  </div>
</template>

<script>

export default {
    name: "CardNews",
    props: {
        item: {
            type: Object,
            required: true
        },
    }
}
</script>

<style scoped>
@import "../assets/css/style.css";

.badge {
  margin-right: 5px;
}
</style>


