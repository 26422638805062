import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './auth/auth.module';
import { search } from './search/search.module';
import { crypto } from './crypto/crypto.module';
import { property } from './property/property.module';
import { chat } from './chat/chat.module';

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        auth,
        chat,
        crypto,
        property,
        search
    }
});

export default store;
