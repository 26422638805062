import axios from "axios";

const BASE_URL = process.env.VUE_APP_BACKEND_URL + "/user";

export default class UserService {
  static getCurrentUser() {
    const url = BASE_URL + "/current";
    return axios.get(url);
  }
}
